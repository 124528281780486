import { PosCheckChargeTax } from "./pos-check-charge-tax.model";
import { PosCheckChargeDiscount } from './pos-check-charge-discount.model';
import { PointerService } from "../services/pointer.service";
import { Charge } from "./charge.model";

export class PosCheckCharge {
  private _id: number;
  private _is_new: boolean;
  private _is_dirty: boolean;
  private _destroy: number;
  private _pos_check_id: number;
  private _charge_id: number;
  private _currency_id: number;
  private _dr_cr: string;
  private _serial_number: number;
  private _amount: number;
  private _description: string;
  private _pos_check_charge_taxes: PosCheckChargeTax[];
  private _pos_check_charge_discounts: PosCheckChargeDiscount[];
  private _charge: Charge;
  
  constructor() {
    this.is_new = true;
    this.is_dirty = false;
    this._pos_check_charge_taxes = [];
    this._pos_check_charge_discounts = [];
  }

  public get pos_check_charge_taxes(): PosCheckChargeTax[] {
    return this._pos_check_charge_taxes;
  }
  public set pos_check_charge_taxes(value: PosCheckChargeTax[]) {
    this._pos_check_charge_taxes = value;
  }

  public get pos_check_charge_discounts(): PosCheckChargeDiscount[] {
    return this._pos_check_charge_discounts;
  }
  public set pos_check_charge_discounts(value: PosCheckChargeDiscount[]) {
    this._pos_check_charge_discounts = value;
  }

  public get id(): number {
    return this._id;
  }
  public set id(value: number) {
    this._id = value;
  }

  public get is_new(): boolean {
    return this._is_new;
  }
  public set is_new(value: boolean) {
    this._is_new = value;
  }

  public get is_dirty(): boolean {
    return this._is_dirty;
  }
  public set is_dirty(value: boolean) {
    this._is_dirty = value;
  }

  public get destroy(): number {
    return this._destroy;
  }
  public set destroy(value: number) {
    this._destroy = value;
  }

  public get pos_check_id(): number {
    return this._pos_check_id;
  }
  public set pos_check_id(value: number) {
    this._pos_check_id = value;
  }

  public get amount(): number {
    return this._amount;
  }
  public set amount(value: number) {
    this._amount = value;
  }

  public get description(): string {
    return this._description;
  }
  public set description(value: string) {
    this._description = value;
  }

  public get serial_number(): number {
    return this._serial_number;
  }
  public set serial_number(value: number) {
    this._serial_number = value;
  }
  public get dr_cr(): string {
    return this._dr_cr;
  }
  public set dr_cr(value: string) {
    this._dr_cr = value;
  }
  public get currency_id(): number {
    return this._currency_id;
  }
  public set currency_id(value: number) {
    this._currency_id = PointerService.getLocalCurrencyId();
  }
  public get charge_id(): number {
    return this._charge_id;
  }
  public set charge_id(value: number) {
    this._charge_id = value;
  }

  public get charge(): Charge {
    return this._charge;
  }
  public set charge(value: Charge) {
    this._charge = value;
  }
  
}