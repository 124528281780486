<div class="modal fade" id="changePasswordModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <form #cpForm="ngForm" (ngSubmit)="onSubmit(cpForm)">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ 'HEADER.CHANGE_PASSWORD' | translate }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="change_passwrd">
          <div class="row">
            <div class="col-lg-12 fill_inp_blk" *ngIf="errorList && errorList.length > 0">
              <label *ngFor="let error of errorList">
                {{ error }}
              </label>
            </div>
            <div class="col-lg-12 fill_inp_blk">
              <label>{{ 'CHANGE_PASSWORD.OLD_PASSWORD' | translate }}</label>
              <input type="password" name="password" ngModel required minlength="8"
              placeholder="{{ 'CHANGE_PASSWORD.OLD_PASSWORD' | translate }}" #passwordControl="ngModel" (click)="openKeyboard($event)">
            </div>
            <div class="col-lg-12 fill_inp_blk">
              <label>{{ 'CHANGE_PASSWORD.NEW_PASSWORD' | translate }}</label>
              <input type="password" name="newpassword" ngModel required minlength="8"
              placeholder="{{ 'CHANGE_PASSWORD.NEW_PASSWORD' | translate }}" #newpasswordControl="ngModel" (click)="openKeyboard($event)">
            </div>
            <div class="col-lg-12 fill_inp_blk">
              <label>{{ 'CHANGE_PASSWORD.CONFIRM_PASSWORD' | translate }}</label>
              <input type="password" name="confirmpassword" ngModel required minlength="8"
              placeholder="{{ 'CHANGE_PASSWORD.CONFIRM_PASSWORD' | translate }}" #confirmpasswordControl="ngModel" (click)="openKeyboard($event)">
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="button_blk">
          <button type="submit" [disabled]="!cpForm.valid" [class]="!cpForm.valid ? 'btn disabled' : 'btn gry_btn'"><i class="fas fa-check-circle"></i>{{ 'HEADER.CHANGE_PASSWORD' | translate }}</button>
        </div>
      </div>
    </form>
    </div>
  </div>
</div>