<div class="wrapg_blk">
  <app-header></app-header>

  <!-- SOF Sub-header HTML -->
  <div class="sub_header_parnt menu_sub_hdr">
    <div class="sub_header_inr only_page_name">
      <h2 class="page_name">KOT details</h2>
    </div>
    <app-business-date></app-business-date>
  </div>
  <!-- EOF Sub-header HTML -->

  <main class="main_cnt_blk">
    <section class="table_tab_parnt">
      <div class="kot_details_blk">
        <div class="kot_details_inr">
          <ul class="nav nav-tabs list_grd_tab" id="viewTab" role="tablist">
            <label>Select view type</label>
            <li class="nav-item" role="presentation">
              <button class="btn nav-link active" id="list-tab" data-bs-toggle="tab" data-bs-target="#list-tab-pane"
                type="button" role="tab" aria-controls="list-tab-pane" aria-selected="true"><i
                  class="bi bi-list"></i></button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="btn nav-link" id="grid-tab" data-bs-toggle="tab" data-bs-target="#grid-tab-pane"
                type="button" role="tab" aria-controls="grid-tab-pane" aria-selected="false"><i
                  class="bi bi-grid-3x3-gap-fill"></i></button>
            </li>
          </ul>
          <div class="tab-content" id="viewTabContent">
            <div class="tab-pane fade show active" id="list-tab-pane" role="tabpanel" aria-labelledby="list-tab"
              tabindex="0">
              <ul class="nav nav-tabs kot_tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="all-kot-tab" data-bs-toggle="tab"
                    data-bs-target="#all-kot-tab-pane" type="button" role="tab" aria-controls="all-kot-tab-pane"
                    aria-selected="false"><i class="bi bi-list-ul"></i>All KOT</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="pending-kot-tab" data-bs-toggle="tab"
                    data-bs-target="#pending-kot-tab-pane" type="button" role="tab" aria-controls="pending-kot-tab-pane"
                    aria-selected="true"><i class="bi bi-hourglass-split"></i>Pending KOT</button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade" id="all-kot-tab-pane" role="tabpanel"
                  aria-labelledby="all-kot-tab" tabindex="0">
                  <div class="grd_parnt_blk">
                    <div class="dev_grd_blk demo-container ">
                      <div id="grid">
                        <dx-data-grid id="gridAllKot" [dataSource]="allKotList" keyExpr="id" [showBorders]="false"
                          [showColumnHeaders]="true" [showRowLines]="true" [showColumnLines]="true">
                          <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
                          <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="false"
                            [visible]="true"></dxo-pager>
                          <dxo-selection mode="single"></dxo-selection>
                          <dxo-filter-row [visible]="true"></dxo-filter-row>
                          <dxo-scrolling mode="standard"></dxo-scrolling>
                          <dxo-editing mode="cell" [allowUpdating]="false">
                          </dxo-editing>
                          <dxi-column dataField="id" [visible]="false"></dxi-column>
                          <dxi-column dataField="pos_check_id" [visible]="false"></dxi-column>
                          <dxi-column dataField="pos_item_id" [visible]="false"></dxi-column>
                         
                          <dxi-column dataField="kot_number" caption="{{ 'COMMON_LABEL.KOT_NUMBER' | translate }}">
                          </dxi-column>
                          <dxi-column dataField="kot_time" caption="{{ 'COMMON_LABEL.KOT_TIME' | translate }}"
                            dataType="date" format="dd/MM/yyyy hh:mm">
                          </dxi-column>
                          <dxi-column dataField="check_no" caption="{{ 'COMMON_LABEL.BILL_NUMBER' | translate }}">
                          </dxi-column>
                          <dxi-column dataField="item_name" caption="{{ 'COMMON_LABEL.ITEM_NAME' | translate }}">
                          </dxi-column>
                          <dxi-column dataField="quantity" caption="{{ 'COMMON_LABEL.QUANTITY' | translate }}" width="10%" alignment="right">
                          </dxi-column>
                          <dxi-column dataField="status" caption="{{ 'COMMON_LABEL.CURRENT_STATUS' | translate }}" alignment="left">
                          </dxi-column>
                          <dxi-column dataField="status_code" [visible]="false">
                          </dxi-column>
                          <dxi-column cellTemplate="cellTemplate" [allowFiltering]="false"
                            caption="Action" alignment="left" cssClass="padg_less">
                          </dxi-column>
                          <div *dxTemplate="let data of 'cellTemplate'">
                              <a href="javascript:void(0)" class="acknwdgeIcon" title="{{ 'COMMON_LABEL.ACKNOWLEDGE' | translate }}"
                                *ngIf="data.data.status_code == POS_ORDER_STATUS_NEW"
                                (click)="updateItemOrderStatus(data.data, POS_ORDER_STATUS_ACKNOWLEDGE)">
                                <i class="acknwdge_grd_icn"></i>
                              </a>
                              <a href="javascript:void(0)" class="cancelIcon" title="{{ 'COMMON_LABEL.CANCEL' | translate }}"
                                *ngIf="data.data.status_code == POS_ORDER_STATUS_NEW"
                                (click)="updateItemOrderStatus(data.data, POS_ORDER_STATUS_CANCEL)">
                                <i class="cancl_grd_icn"></i>
                              </a>
                              <a href="javascript:void(0)" class="inProgressIcon" title="{{ 'COMMON_LABEL.IN_PROGRESS' | translate }}" 
                                *ngIf="data.data.status_code == POS_ORDER_STATUS_ACKNOWLEDGE"
                                (click)="updateItemOrderStatus(data.data, POS_ORDER_STATUS_IN_PROGRESS)">
                                <i class="in_prg_grd_icn"></i>
                              </a>
                              <a href="javascript:void(0)" class="foodReadyIcon" title="{{ 'COMMON_LABEL.FOOD_READY' | translate }}" 
                                *ngIf="data.data.status_code == POS_ORDER_STATUS_IN_PROGRESS"
                                (click)="updateItemOrderStatus(data.data, POS_ORDER_STATUS_FOOD_READY)">
                                <i class="food_redy_grd_icn"></i>
                              </a>
                              <a href="javascript:void(0)" class="foodCompleteIcon" title="{{ 'COMMON_LABEL.FOOD_COMPLETE' | translate }}" 
                                *ngIf="data.data.status_code == POS_ORDER_STATUS_FOOD_READY"
                                (click)="updateItemOrderStatus(data.data, POS_ORDER_STATUS_FOOD_COMPLETED)">
                                <i class="food_complt_grd_icn"></i>
                              </a>
                          </div>
                        </dx-data-grid>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade show active" id="pending-kot-tab-pane" role="tabpanel" aria-labelledby="pending-kot-tab"
                  tabindex="0">
                  <div class="grd_parnt_blk">
                    <div class="dx-viewport demo-container">
                      <div id="grid1">
                        <dx-data-grid id="gridPendingKot" [dataSource]="pendingKotList" keyExpr="id" [showBorders]="false"
                          [showColumnHeaders]="true" [showRowLines]="true" [showColumnLines]="true">
                          <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
                          <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="false"
                            [visible]="true"></dxo-pager>
                          <dxo-selection mode="single"></dxo-selection>
                          <dxo-scrolling mode="standard"></dxo-scrolling>
                          <dxo-filter-row [visible]="true"></dxo-filter-row>
                          <dxo-editing mode="cell" [allowUpdating]="false">
                          </dxo-editing>
                          <dxi-column dataField="id" [visible]="false"></dxi-column>
                          <dxi-column dataField="pos_check_id" [visible]="false"></dxi-column>
                          <dxi-column dataField="pos_item_id" [visible]="false"></dxi-column>
                          <dxi-column dataField="kot_number" caption="{{ 'COMMON_LABEL.KOT_NUMBER' | translate }}">
                          </dxi-column>
                          <dxi-column dataField="kot_time" caption="{{ 'COMMON_LABEL.KOT_TIME' | translate }}"
                            dataType="date" format="dd/MM/yyyy hh:mm">
                          </dxi-column>
                          <dxi-column dataField="check_no" caption="{{ 'COMMON_LABEL.BILL_NUMBER' | translate }}">
                          </dxi-column>
                          <dxi-column dataField="item_name" caption="{{ 'COMMON_LABEL.ITEM_NAME' | translate }}">
                          </dxi-column>
                          <dxi-column dataField="quantity" caption="{{ 'COMMON_LABEL.QUANTITY' | translate }}" width="10%" alignment="right">
                          </dxi-column>
                          <dxi-column dataField="status" caption="{{ 'COMMON_LABEL.CURRENT_STATUS' | translate }}" alignment="left">
                          </dxi-column>
                          <dxi-column dataField="status_code" [visible]="false">
                          </dxi-column>
                          <dxi-column cellTemplate="cellTemplate" [allowFiltering]="false"
                            caption="Action" alignment="left" cssClass="padg_less">
                          </dxi-column>
                          <div *dxTemplate="let data of 'cellTemplate'">
                            <a href="javascript:void(0)" class="acknwdgeIcon" title="{{ 'COMMON_LABEL.ACKNOWLEDGE' | translate }}"
                              *ngIf="data.data.status_code == POS_ORDER_STATUS_NEW"
                              (click)="updateItemOrderStatus(data.data, POS_ORDER_STATUS_ACKNOWLEDGE)">
                              <i class="acknwdge_grd_icn"></i>
                            </a>
                            <a href="javascript:void(0)" class="cancelIcon" title="{{ 'COMMON_LABEL.CANCEL' | translate }}"
                              *ngIf="data.data.status_code == POS_ORDER_STATUS_NEW"
                              (click)="updateItemOrderStatus(data.data, POS_ORDER_STATUS_CANCEL)">
                              <i class="cancl_grd_icn"></i>
                            </a>
                            <a href="javascript:void(0)" class="inProgressIcon" title="{{ 'COMMON_LABEL.IN_PROGRESS' | translate }}" 
                              *ngIf="data.data.status_code == POS_ORDER_STATUS_ACKNOWLEDGE"
                              (click)="updateItemOrderStatus(data.data, POS_ORDER_STATUS_IN_PROGRESS)">
                              <i class="in_prg_grd_icn"></i>
                            </a>
                            <a href="javascript:void(0)" class="foodReadyIcon" title="{{ 'COMMON_LABEL.FOOD_READY' | translate }}" 
                              *ngIf="data.data.status_code == POS_ORDER_STATUS_IN_PROGRESS"
                              (click)="updateItemOrderStatus(data.data, POS_ORDER_STATUS_FOOD_READY)">
                              <i class="food_redy_grd_icn"></i>
                            </a>
                            <a href="javascript:void(0)" class="foodCompleteIcon" title="{{ 'COMMON_LABEL.FOOD_COMPLETE' | translate }}" 
                              *ngIf="data.data.status_code == POS_ORDER_STATUS_FOOD_READY"
                              (click)="updateItemOrderStatus(data.data, POS_ORDER_STATUS_FOOD_COMPLETED)">
                              <i class="food_complt_grd_icn"></i>
                            </a>
                          </div>
                        </dx-data-grid>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade kot_grd_tab_ctnt" id="grid-tab-pane" role="tabpanel" aria-labelledby="grid-tab"
              tabindex="0">
              <div class="row">
                <div class="col-md-6 col-lg-4 col-xxl-3" *ngFor="let check of checkList">
                  <div class="kot_menu_detls">
                    <div class="kot_menu_head">
                      <div class="kot_tbl_no">
                        <label>Table: {{ check.table_code }}</label>
                        <label class="seprator" *ngIf="check.covers"></label>
                        <label *ngIf="check.covers">C: {{ check.covers }}</label>
                      </div>
                      <div class="waiter_name">Server: {{ check.server_name }}</div>
                    </div>
                    <div class="kot_qty_head">
                      <ul>
                        <li>Qty</li>
                        <li>Item</li>
                        <li>Status</li>
                      </ul>
                    </div>
                    <div class="kot_item_detls">
                      <ul *ngIf="check.pos_check_details && check.pos_check_details.length > 0">
                        <ng-container *ngFor="let item of check.pos_check_details">
                          <li [ngClass]="setClasses(item.order_status_code)">
                            <span>{{ item.quantity }}</span>
                            <span>{{ item.item_description }}
                              <ng-container
                                *ngIf="item.pos_check_detail_modifiers && item.pos_check_detail_modifiers.length > 0">
                                <label *ngFor="let modifier of item.pos_check_detail_modifiers">
                                  <i class="fa fa-hand-o-right" aria-hidden="true"></i>{{
                                  modifier.modifier_name_on_check }}
                                </label>
                              </ng-container>
                              <ng-container
                                *ngIf="item.order_status_code.toUpperCase() === 'NEW' || item.order_status_code.toUpperCase() === ''">
                                <label><button class="btn kot_green_btn"><i class="bi bi-check2"></i></button>
                                  <button class="btn kot_red_btn"><i class="bi bi-x"></i></button>
                                </label>
                              </ng-container>
                            </span>
                            <!-- <span>
                              <button class="btn kot_status kot_cncld_icn"></button>
                            </span>
                            <span><button class="btn kot_status kot_new_icn"></button></span> -->
                          </li>
                        </ng-container>
                      </ul>
                    </div>
                    <div class="kot_footr">
                      <div class="row">
                        <div class="col">KOT {{ check.pos_check_details.length }}</div>
                        <div class="col">
                          <span class="kot_crcl white_crcl"></span>16
                        </div>
                        <div class="col">
                          <span class="kot_crcl yellw_crcl"></span>1
                        </div>
                        <div class="col">
                          <span class="kot_crcl green_crcl"></span>1
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="action_btn_parnt">
      <ul>
        <li (click)="onBackClick()">
          <a href="javascript:void(0)" class="form-control" tabindex="0">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 122.88 98.86" style="enable-background:new 0 0 122.88 98.86" xml:space="preserve">
              <g>
                <path
                  d="M0,49.43l48.93,49.43V74.23c30.94-6.41,55.39,0.66,73.95,24.19c-3.22-48.4-36.29-71.76-73.95-73.31V0L0,49.43 L0,49.43L0,49.43z" />
              </g>
            </svg>
            {{ 'ACTION_BUTTON.BACK' | translate }}
          </a>
        </li>
      </ul>
    </section>
  </main>
</div>