import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { City } from '../models/city.model';

@Injectable({
	providedIn: 'root'
})

export class CityService {
	constructor(private http: HttpClient) { }

	getAll() {
    return this.http.get(environment.APIENDPOINT + environment.CITY,
      { params: new HttpParams().append("per_page", environment.API_DATA_PER_PAGE)}
    ).pipe(
      map((response) => {
        if(response["cities"] && response["cities"].length > 0) {
          const cityArray: City[] = [];
          response["cities"].forEach(obj => {
            const city = new City(
              obj.id,
							obj.code,
              obj.city_translations && obj.city_translations.length > 0 ? obj.city_translations[0].description : "",
							obj.state ? obj.state.id : "",
							obj.state && obj.state.state_translations && obj.state.state_translations.length > 0 ? obj.state.state_translations[0].description : "",
							obj.country ? obj.country.id : "",
							obj.country && obj.country.country_translations && obj.country.country_translations.length > 0 ? obj.country.country_translations[0].description : "",
            )

            cityArray.push(city);
          });

          localStorage.setItem('cityData', JSON.stringify(cityArray));
          return cityArray;
        }
      })
    )
  }
}