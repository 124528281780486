export class PosItem {
  constructor(
    public id: number,
    public code: string,
    public account_code: string,
    public item_class: string,
    public hsn_sac_code: string,
    public shortName: string,
    public longName: string,
    public description: string
  ) {}
}