import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { City } from '../models/city.model';
import { Room } from '../models/room.model';

@Injectable({
	providedIn: 'root'
})

export class RoomService {
	constructor(private http: HttpClient) { }

	getAll() {
    return this.http.get(environment.APIENDPOINT + environment.ROOM,
      { 
        params: new HttpParams().append("per_page", environment.API_DATA_PER_PAGE)
      }
    ).pipe(
      map((response) => {
        if(response["rooms"] && response["rooms"].length > 0) {
          const roomArray: Room[] = [];
          
          response["rooms"].forEach(obj => {
            const room = new Room(
              obj.id,
							obj.room_translations && obj.room_translations.length > 0 ? obj.room_translations[0].room_display_number : "",
							obj.room_type && obj.room_type_translations && obj.room_type_translations.length > 0 ? obj.room_type_translations[0].description : "",
							obj.room_translations && obj.room_translations.length > 0 ? obj.room_translations[0].floor_number : "",
							obj.occupancy_status && obj.occupancy_status.occupancy_status_translations && obj.occupancy_status.occupancy_status_translations.length > 0 ? obj.occupancy_status.occupancy_status_translations[0].description : "",
							obj.cleaning_status && obj.cleaning_status.cleaning_status_translations && obj.cleaning_status.cleaning_status_translations.length > 0 ? obj.cleaning_status.cleaning_status_translations[0].description : "",
            )

            roomArray.push(room);
          });

          localStorage.setItem('roomData', JSON.stringify(roomArray));
          return roomArray;
        }
      })
    )
  }

  findGuestDetails(roomId: string, firstName: string, lastName: string, inhouseStatus: string, primaryClient: string) {
    let params = new HttpParams();
    params = params.append("per_page", environment.API_DATA_PER_PAGE)
    
    if(roomId) {
      params = params.append("key_room", roomId);
    }

    if(firstName) {
      params = params.append("key_firstname", firstName);
    }

    if(lastName) {
      params = params.append("key_lastname", lastName);
    }

    if(inhouseStatus) {
      params = params.append("key_inhouse_status", inhouseStatus);
    }

    if(primaryClient == "1") {
      params = params.append("key_primary_client", "true");
    } else {
      params = params.append("key_primary_client", "false");
    }

    return this.http.get(environment.APIENDPOINT + environment.VISIT, { params: params }
    ).pipe(
      map((response) => {
        if(response["visits"] && response["visits"].length > 0) {
          const guestArray: any[] = [];
          response["visits"].forEach(visit => {
            let obj = {};

            let roomNumber = "";
            if (visit.room) {
              if (visit.room.room_translations && visit.room.room_translations.length > 0) {
                roomNumber = visit.room.room_translations[0].room_display_number
              } else {
                roomNumber = visit.room.room_number;
              }
            }

            if (visit.visit_billing) {
              obj["billing_instruction"] = visit.visit_billing.billing_instruction;
            } else {
              obj["billing_instruction"] = "";
            }

            obj['visit_id'] = visit.id;
            obj['inhouse_id'] = visit.inhouse_id;
            obj["guest_name"] = visit.account ? (visit.account.title + " " + visit.account.first_name + " " + visit.account.last_name) : "";
            obj["room_number"] = roomNumber;
            obj["arrival_date"] = visit.arrival_date;
            obj["departure_date"] = visit.departure_date;

            guestArray.push(obj);
          });

          return guestArray;
        }
      })
    )
  }
}