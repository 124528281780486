<div class="wrapg_blk">
  <app-header></app-header>
  <main class="main_cnt_blk">
    <section class="select_optn_parnt">
      <div class="row">
        <div class="col-sm-4 col-md-3 col-xl-2 mb-15">
          <div class="select_out_blk select_optn_inr" (click)="onPosSelection()">
            <a class="form-control pos_icn" tabindex="0">Pos</a>
          </div>
        </div>
        <div class="col-sm-4 col-md-3 col-xl-2 mb-15">
          <div class="select_out_blk select_optn_inr">
            <a target="_blank" class="form-control users_icn" tabindex="0"
              href="{{reportsUrl + '/presets/user'}}">User</a>
          </div>
        </div>
        <div class="col-sm-4 col-md-3 col-xl-2 mb-15">
          <div class="select_out_blk select_optn_inr">
            <a target="_blank" class="form-control preset_icn" tabindex="0"
              href="{{reportsUrl + '/dashboard'}}">Presets</a>
          </div>
        </div>
        <div class="col-sm-4 col-md-3 col-xl-2 mb-15">
          <div class="select_out_blk select_optn_inr">
            <a target="_blank" class="form-control report_icn" tabindex="0"
              href="{{reportsUrl + '/reports/cashiers-scroll'}}">Reports</a>
          </div>
        </div>
        <!-- <div class="col-sm-4 col-md-3 col-xl-2 mb-15">
          <div class="select_out_blk select_optn_inr">
            <a target="_blank" class="form-control report_icn" tabindex="0" [href]="getTargetUrl()"
              rel="noopener noreferrer">InnDCS</a>
          </div>
        </div> -->
      </div>
    </section>
  </main>
</div>

<!-- SOF Table Transfer modal -->
<div class="modal fade" id="nightAuditModal" tabindex="-1" aria-labelledby="exampleNAModalLabel" aria-modal="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleNAModalLabel">Night audit</h5>
        <button type="button" #closeNightAuditModalBtn id="closeNightAuditModalBtn" class="btn-close"
          data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h2 class="title_cls">Business date is less than the system date.</h2>
        <h2 class="title_cls">Current business date is : {{ currentBusinessDate }}</h2>
        <h2 class="title_cls">Do you want to change business date now?</h2>
      </div>
      <div class="modal-footer">
        <div class="button_blk">
          <button type="button" class="btn lg_gry_btn" (click)="changeBusinessDate()">
            <i class="fas fa-check-circle"></i>Yes
          </button>
          <button type="button" class="btn lg_gry_btn" (click)="cancelNightAudit()"><i
              class="fas fa-times-circle"></i>No</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- EOF Table Transfer modal -->

<div class="modal fade" id="subscriptionModal" tabindex="-1" aria-labelledby="exampleSubsModalLabel" aria-modal="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleSubsModalLabel">Subscription alert</h5>
        <button type="button" #closeSubscriptionModalBtn id="closeSubscriptionModalBtn" class="btn-close"
          data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h2 class="title_cls">Your subscription expires in {{ diffDays }} days.</h2>
      </div>
      <div class="modal-footer">
        <div class="button_blk">
          <button type="button" class="btn gry_btn" (click)="redirectToOutletSelection()">
            <i class="fas fa-check-circle"></i>Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</div>