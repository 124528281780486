import { environment } from "../../environments/environment";
import { PointerService } from "../services/pointer.service";
import { PosCheckDetailModifier } from '../models/pos-check-detail-modifier.model';
import { Charge } from "./charge.model";

export class PosCheckDetail {
  private _id: number;
  private _pos_check_id: number;
  private _destroy: number;
  private _is_new: boolean;
  private _is_dirty: boolean;
  private _pos_item_id: number;
  private _shift: number;
  private _business_date: Date;
  private _system_time: Date;
  private _pos_restaurant_id: number;
  private _service: string;
  private _charge_id: number;
  private _kot_no: string;
  private _kot_print_ind: boolean;
  private _kot_print_count: number;
  private _void_quantity: number;
  private _void_no: string;
  private _void_by_id: number;
  private _pos_void_reason_id: number;
  private _void_reason_note: string;
  private _void_at: Date;
  private _void_place: string;
  private _last_action: string;
  private _item_description: string;
  private _pos_menu_id: number;
  private _pos_kitchen_id: number;
  private _serial_number: number;
  private _remark: string;
  private _quantity: number;
  private _rate: number;
  private _amount: number;
  private _mrp: number;
  private _currency_id: number;
  private _pos_item_size: string;
  private _pos_item_type: string;
  private _check_series_no: string;
  private _fnb_series_no: string;
  private _pos_item_name_on_check: string;
  private _do_not_print_price_ind: boolean;
  private _freeze_ind: boolean;
  private _freeze_at: Date;
  private _updated_place: string;
  private _lock_version: number;
  private _pos_check_detail_modifiers: PosCheckDetailModifier[];
  private _item_status: string;
  private _charge: Charge;
  private _markedVoid: boolean;
  private _order_status: string;
  private _order_status_code: string;
  
  constructor() {
    this._business_date = PointerService.getBusinessDate();
    this._system_time = new Date();
    this._last_action = environment.LAST_ACTION_POSTED;
    this._pos_check_detail_modifiers = [];
  }

  get amount(): number {
    let sum = 0;

    if(this.pos_check_detail_modifiers.length > 0) {
      this.pos_check_detail_modifiers.forEach(modifier => {
        if(this.void_quantity) {
          sum += (this.quantity - this.void_quantity) * modifier.amount;
        } else {
          sum += this.quantity * modifier.amount;
        }
      });
    }

    if(this.void_quantity && this.void_quantity != this.quantity) {
      sum += (this.quantity - this.void_quantity) * this.rate;
    } else {
      sum += this.quantity * this.rate;
    }

    return sum;
  }
  set amount(value: number) {
    this._amount = value;
  }

  public get id(): number {
    return this._id;
  }
  public set id(value: number) {
    this._id = value;
  }

  public get pos_check_id(): number {
    return this._pos_check_id;
  }
  public set pos_check_id(value: number) {
    this._pos_check_id = value;
  }

  public get is_new(): boolean {
    return this._is_new;
  }
  public set is_new(value: boolean) {
    this._is_new = value;
  }

  public get is_dirty(): boolean {
    return this._is_dirty;
  }
  public set is_dirty(value: boolean) {
    this._is_dirty = value;
  }

  public get destroy(): number {
    return this._destroy;
  }
  public set destroy(value: number) {
    this._destroy = value;
  }

  public get freeze_at(): Date {
    return this._freeze_at;
  }
  public set freeze_at(value: Date) {
    this._freeze_at = value;
  }

  public get freeze_ind(): boolean {
    return this._freeze_ind;
  }
  public set freeze_ind(value: boolean) {
    this._freeze_ind = value;
  }

  public get pos_item_name_on_check(): string {
    return this._pos_item_name_on_check;
  }
  public set pos_item_name_on_check(value: string) {
    this._pos_item_name_on_check = value;
  }

  public get currency_id(): number {
    return this._currency_id;
  }
  public set currency_id(value: number) {
    this._currency_id = PointerService.getLocalCurrencyId();
  }

  public get mrp(): number {
    return this._mrp;
  }
  public set mrp(value: number) {
    this._mrp = value;
  }

  public get rate(): number {
    return this._rate;
  }
  public set rate(value: number) {
    this._rate = value;
  }

  public get quantity(): number {
    return this._quantity;
  }
  public set quantity(value: number) {
    this._quantity = value;
  }

  public get void_quantity(): number {
    return this._void_quantity;
  }
  public set void_quantity(value: number) {
    this._void_quantity = value;
  }

  public get void_no(): string {
    return this._void_no;
  }
  public set void_no(value: string) {
    this._void_no = value;
  }
  
  public get void_by_id(): number {
    return this._void_by_id;
  }
  public set void_by_id(value: number) {
    this._void_by_id = value;
  }
  
  public get pos_void_reason_id(): number {
    return this._pos_void_reason_id;
  }
  public set pos_void_reason_id(value: number) {
    this._pos_void_reason_id = value;
  }

  public get void_reason_note(): string {
    return this._void_reason_note;
  }
  public set void_reason_note(value: string) {
    this._void_reason_note = value;
  }

  public get serial_number(): number {
    return this._serial_number;
  }
  public set serial_number(value: number) {
    this._serial_number = value;
  }

  public get pos_menu_id(): number {
    return this._pos_menu_id;
  }
  public set pos_menu_id(value: number) {
    this._pos_menu_id = value;
  }

  public get item_description(): string {
    return this._item_description;
  }
  public set item_description(value: string) {
    this._item_description = value;
  }

  public get void_at(): Date {
    return this._void_at;
  }
  public set void_at(value: Date) {
    this._void_at = value;
  }
  
  public get void_place(): string {
    return this._void_place;
  }
  public set void_place(value: string) {
    this._void_place = value;
  }

  public get last_action(): string {
    return this._last_action;
  }
  public set last_action(value: string) {
    this._last_action = value;
  }

  public get charge_id(): number {
    return this._charge_id;
  }
  public set charge_id(value: number) {
    this._charge_id = value;
  }

  public get kot_no(): string {
    return this._kot_no;
  }
  public set kot_no(value: string) {
    this._kot_no = value;
  }
  
  public get kot_print_ind(): boolean {
    return this._kot_print_ind;
  }
  public set kot_print_ind(value: boolean) {
    this._kot_print_ind = value;
  }
  
  public get kot_print_count(): number {
    return this._kot_print_count;
  }
  public set kot_print_count(value: number) {
    this._kot_print_count = value;
  }

  public get pos_restaurant_id(): number {
    return this._pos_restaurant_id;
  }
  public set pos_restaurant_id(value: number) {
    this._pos_restaurant_id = value;
  }

  public get service(): string {
    return this._service;
  }
  public set service(value: string) {
    this._service = value;
  }

  public get business_date(): Date {
    return this._business_date;
  }
  public set business_date(value: Date) {
    this._business_date = value;
  }

  public get system_time(): Date {
    return this._system_time;
  }
  public set system_time(value: Date) {
    this._system_time = value;
  }

  public get shift(): number {
    return this._shift;
  }
  public set shift(value: number) {
    this._shift = value;
  }

  public get pos_item_id(): number {
    return this._pos_item_id;
  }
  public set pos_item_id(value: number) {
    this._pos_item_id = value;
  }

  public get pos_kitchen_id(): number {
    return this._pos_kitchen_id;
  }
  public set pos_kitchen_id(value: number) {
    this._pos_kitchen_id = value;
  }
  
  public get remark(): string {
    return this._remark;
  }
  public set remark(value: string) {
    this._remark = value;
  }
 
  public get pos_item_size(): string {
    return this._pos_item_size;
  }
  public set pos_item_size(value: string) {
    this._pos_item_size = value;
  }
  
  public get pos_item_type(): string {
    return this._pos_item_type;
  }
  public set pos_item_type(value: string) {
    this._pos_item_type = value;
  }
 
  public get check_series_no(): string {
    return this._check_series_no;
  }
  public set check_series_no(value: string) {
    this._check_series_no = value;
  }
  
  public get fnb_series_no(): string {
    return this._fnb_series_no;
  }
  public set fnb_series_no(value: string) {
    this._fnb_series_no = value;
  }
 
  public get do_not_print_price_ind(): boolean {
    return this._do_not_print_price_ind;
  }
  public set do_not_print_price_ind(value: boolean) {
    this._do_not_print_price_ind = value;
  }
 
  public get updated_place(): string {
    return this._updated_place;
  }
  public set updated_place(value: string) {
    this._updated_place = value;
  }
  
  public get lock_version(): number {
    return this._lock_version;
  }
  public set lock_version(value: number) {
    this._lock_version = value;
  }

  public get item_status(): string {
    return this._item_status;
  }
  public set item_status(value: string) {
    this._item_status = value;
  }
  
  public get pos_check_detail_modifiers(): PosCheckDetailModifier[] {
    return this._pos_check_detail_modifiers;
  }
  public set pos_check_detail_modifiers(value: PosCheckDetailModifier[]) {
    this._pos_check_detail_modifiers = value;
  }

  public get charge(): Charge {
    return this._charge;
  }
  public set charge(value: Charge) {
    this._charge = value;
  }

  public get markedVoid(): boolean {
    return this._markedVoid;
  }
  public set markedVoid(value: boolean) {
    this._markedVoid = value;
  }

  public get order_status_code(): string {
    return this._order_status_code;
  }
  public set order_status_code(value: string) {
    this._order_status_code = value;
  }
  
  public get order_status(): string {
    return this._order_status;
  }
  public set order_status(value: string) {
    this._order_status = value;
  }
}