import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class ErrorInterceptorService implements HttpInterceptor {
	constructor(private authService: AuthService) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(catchError(err => {
			let error: any = '';
			if ([401, 403].includes(err.status) && this.authService.user) {
				// auto logout if 401 or 403 response returned from api
				// this.accountService.logout();
				error = "You do not have rights to access this page";
			}
			
			if ([422].includes(err.status) && this.authService.user) {
				error = err.error;
			} else if ([500].includes(err.status)) {
				error = "Something went wrong at server, error code 500!"
			} else if(err.status === 0) {
        error = "Api service is down";
      }
			else {
				error = err.error?.message || err.statusText;
			}

			return throwError(error);
		}))
	}
}