<!-- <div class="error_msg_parnt" *ngIf="messageList && messageList.length > 0">
  <div class="padg_10">
    <h5>Please correct the following errors and try again: <a class="cls_icn" (click)="toggleDiv()"></a>
      <ul>
        <li *ngFor="let error of messageList">
          {{ messageList }}
        </li>
      </ul>
    </h5>
  </div>
</div> -->

<div class="modal fade" id="WarningModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="warningModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="warningModalLabel">Warning</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" *ngIf="messageList && messageList.length > 0">
        <h2 class="title_cls" *ngFor="let error of messageList">
          {{ error }}
        </h2>
      </div>
      <div class="modal-footer">
        <div class="button_blk">
        <button type="button" class="btn gry_btn" data-bs-dismiss="modal"><i class="fas fa-check-circle"></i>Ok</button>
      </div>
      </div>
    </div>
  </div>
</div>