import { Payment } from "./payment.model";

export class PosPaymentRestaurant {
  constructor(
    public id: number,
    public pos_restaurant_id: number,
    public payment_id: number,
    public active_ind: boolean,
    public freeze_ind: boolean,
    public payment: Payment
  ) {}
}