<div class="wrapg_blk">
  <header>
    <div class="logo_blk">
      <h1>{{ 'HEADER.TITLE' | translate }}</h1>
    </div>
  </header>
  <main class="main_cnt_blk">
    <section class="login_blk_parnt">
      <div class="login_inr_contnr">
        <!-- SOF Error Message html -->
        <!-- <div class="error_msg_parnt" *ngIf="error && error.length > 0">
          <h5>Please correct the following errors and try again.</h5>
          <ul>
            <li *ngFor="let message of error">
              {{ message }}
            </li>
          </ul>
        </div> -->

        <div class="error_msg_parnt error_multiple" *ngIf="error && error.length > 0">
          <div class="error_icn">
            <i class="bi bi-exclamation-triangle-fill"></i>
          </div>
          <div class="error_cnt">
            <h5>Please correct the following errors and try again.</h5>
            <ul>
              <li *ngFor="let message of error">
                {{ message }}
              </li>
            </ul>
          </div>
        </div>
        <!-- EOF Error Message html -->
        <div class="login_cnt_blk">
          <div class="login_outlet_icn select_out_blk blue_main_parnt">
            <a class="form-control select_out_icn">
              <span>{{ 'HEADER.LOGIN' | translate }}</span>
            </a>
          </div>
          <div class="keypad_main_blk">
            <input type="number" id="usercodeInput"
              [style]="showUsercodeButton ? 'display: inline-block;' : 'display: none;'"
              (keyup.enter)="onUsercodeEnter()">

            <input type="password" id="passwordInput" class="numericInput" required minlength="8"
              [style]="!showUsercodeButton ? 'display: inline-block;' : 'display: none;'"
              (keyup.enter)="onPasswordEnter()">

            <div id="digitalKeyboardContainer" class="digitalKeyboardContainer"></div>

            <div class="button_blk">
              <button type="button" class="btn" (click)="onUserCodeClick()"
                [style]="showUsercodeButton ? 'display: inline-block;' : 'display: none;'">
                <i class="opng_lgn_btn"></i>{{ 'LOGIN.USER' | translate }}
              </button>

              <button type="button" (click)="onPasswordClick()"
                [style]="!showUsercodeButton ? 'display: inline-block;' : 'display: none;'">
                <i class="opng_lgn_btn"></i>{{ 'LOGIN.PASSWORD' | translate }}
              </button>
            </div>

            <form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)">
              <input type="hidden" name="usercode" [ngModel]="usercode" required />
              <input type="hidden" name="password" [ngModel]="password" />
            </form>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>