import { Pipe, PipeTransform } from '@angular/core';
import { PosItemMenu } from '../../models/pos-item-menu.model';
import { PosMenuHeader } from '../../models/pos-menu-header.model';
import { PosMenuSubHeader } from '../../models/pos-menu-subheader.model';

@Pipe({
  name: 'FilterItemBySubHeader',
  pure: true
})

export class FilterItemBySubHeader implements PipeTransform{
  transform(currentMenuitems: PosItemMenu[], menuHeader: PosMenuHeader, menuSubHeader: PosMenuSubHeader) {
    if(currentMenuitems) { 
      let menuHeaderItems = currentMenuitems.filter(a => a.pos_menu_header_id == menuHeader.id);

      let menuSubHeaderItems = menuHeaderItems.filter(function (item) {
                                return item.menu_sub_header.id === menuSubHeader.id;
                              }).map(function (item) {
                                return item;
                              });
      return menuSubHeaderItems;
    }
  }
}