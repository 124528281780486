export class OutletSetting {
  constructor(
    public id: number,
    public pos_restaurant_id: number,
    public default_menu_id: number,
    public card_details_mandetory: boolean,
    public has_multiple_shifts_ind: boolean,
    public allow_item_rate_change_ind: boolean,
    public debit_card_ind: boolean,
    public call_local_after_print_ind: boolean,
    public call_print_after_settle_ind: boolean,
    public prompt_kot_print_on_copy_check_ind: boolean,
    public refresh_interval: number,
    public print_check_by_account_ind: boolean,
    public print_check_default_count: number,
    public print_guest_name_ind: boolean,
    public print_member_id_ind: boolean,
    public guest_name_mandetory_ind: boolean,
    public covers_mandetory_ind: boolean,
    public print_kot_ind: boolean,
    public hsn_sac_number: number,
    public debit_card_in_use_ind: boolean,
    public debit_card_no_start_position: number,
    public debit_card_no_length: number,
    public void_backdated_check_ind: boolean,
    public print_round_off_ind: boolean,
    public thermal_printer_ind: boolean,
    public happy_hour_ind: boolean,
    public allow_excess_amount_ind: boolean,
    public check_server_side_print_ind: boolean,
    public qsr_in_use_ind: boolean,
    public inhouse_delivery_ind: boolean,
    public self_service_ind: boolean,
    public print_kot_after_settlement_ind: boolean,
    public qr_view_menu_ind: boolean,
    public qr_place_order_ind: boolean,
    public kot_client_side_ind: boolean,
    public allowance_backdated_check_ind: boolean,
    public broadcast_kot_status_ind: boolean,
    public broadcast_table_status_ind: boolean,
    public check_print_required_ind: boolean,
    public gst_number: string,
    public pan_number: string,
    public inclusive_of_tax_ind: boolean
  ) {}
}