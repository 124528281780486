import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Alert, AlertType } from 'projects/pos/src/app/models/alert';
import { AlertService } from 'projects/pos/src/app/services/alert.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-alert',
	templateUrl: 'alert.component.html',
	styleUrls: ['./alert.component.scss']
})

export class AlertComponent implements OnInit, OnDestroy {
	@ViewChild('closeWarningModal') closeWarningModal: ElementRef;
	// Previoud code --- start
	@Input() id = 'default-alert';
	@Input() fade = true;

	alerts: Alert[] = [];
	alertSubscription!: Subscription;
	routeSubscription!: Subscription;
	errorAlerts: Alert[] = [];
	infoAlerts: Alert[] = [];
	successAlerts: Alert[] = [];
	warnAlerts: Alert[] = [];
	alertTime: boolean = true;

	constructor(private router: Router, private alertService: AlertService) { }

	ngOnInit() {
		// subscribe to new alert notifications
		this.alertSubscription = this.alertService.onAlert(this.id)
			.subscribe(alert => {
				// clear alerts when an empty alert is received
				if (!alert.message) {
					// filter out alerts without 'keepAfterRouteChange' flag
					this.alerts = this.alerts.filter(x => x.keepAfterRouteChange);

					// reset 'keepAfterRouteChange' flag on the rest
					this.alerts.forEach(x => x.keepAfterRouteChange = false);
					return;
				}

				// add alert to array
				if (alert.type === 0) {
					this.alertTime = true;
					this.successAlerts = [];
					this.successAlerts.push(alert);
					this.errorAlerts = [];
					this.infoAlerts = [];
					this.warnAlerts = [];
					// document.getElementById("errorDiv").style.display = "none";
					// document.getElementById("successDiv").style.display = "block";
					document.body.scrollTop = 0;
					document.querySelector('body').scrollTo(0, 0)
				} else if (alert.type === 1) {
					this.alertTime = true;
					this.errorAlerts = [];
					this.errorAlerts.push(alert);
					this.successAlerts = [];
					this.infoAlerts = [];
					this.warnAlerts = [];
					// document.getElementById("successDiv").style.display = "none";
					// document.getElementById("errorDiv").style.display = "block";
					document.body.scrollTop = 0;
					document.querySelector('body').scrollTo(0, 0)
				} else if (alert.type === 2) {
					this.alertTime = true;
					this.infoAlerts = [];
					this.infoAlerts.push(alert);
					document.body.scrollTop = 0;
					document.querySelector('body').scrollTo(0, 0)
				} else if (alert.type === 3) {
					this.alertTime = true;
					this.warnAlerts = [];
					this.warnAlerts.push(alert);
					document.body.scrollTop = 0;
					document.querySelector('body').scrollTo(0, 0)
				}
				// this.alerts.push(alert);
				// auto close alert if required
				if (alert) {
					setTimeout(() => {
						this.alertTime = false;
						this.alertService.clear(this.id);
					}, 3000);
				}
			});

		// clear alerts on location change
		this.routeSubscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationStart) {
				this.alertService.clear(this.id);
			}
		});
	}

	ngOnDestroy() {
		// unsubscribe to avoid memory leaks
		this.alertSubscription.unsubscribe();
		this.routeSubscription.unsubscribe();
	}


	isString(value) {
		return typeof value;
	};

	removeAlert(alert: Alert) {
		// check if already removed to prevent error on auto close
		if (!this.alerts.includes(alert)) return;

		if (this.fade) {
			// fade out alert
			alert.fade = true;

			// remove alert after faded out
			setTimeout(() => {
				this.alerts = this.alerts.filter(x => x !== alert);
			}, 50);
		} else {
			// remove alert
			this.alerts = this.alerts.filter(x => x !== alert);
		}
	}

	cssClass(alert: Alert) {
		if (alert?.type === undefined) { } return;

		const classes = ['alert', 'alert-dismissable', 'mt-3', 'container'];

		const alertTypeClass = {
			[AlertType.Success]: 'alert-success',
			[AlertType.Error]: 'alert-danger text-danger',
			[AlertType.Info]: 'alert-info',
			[AlertType.Warning]: 'alert-warning'
		}

		classes.push(alertTypeClass[alert.type]);

		if (alert.fade) {
			classes.push('fade');
		}

		return classes.join(' ');
	}
}