import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { User } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';

declare function initializeKeyboard(event): any;

@Component({
  selector: 'app-lock-screen',
  templateUrl: './lock-screen.component.html'
})
export class LockScreenComponent implements OnInit {
  @ViewChild('closeBtn') closeBtn: ElementRef;

  errorList: any[] = [];
  usercode: string;
  username: string;
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    const userData: User = JSON.parse(localStorage.getItem("userData"));
    
    if(!userData) {
      return;
    }

    this.usercode = userData.code;
    this.username = userData.full_name;
  }

  onSubmit(form): void {
    this.errorList = [];
    if(!form.valid) {
      return;
    }

    const password = form.value.password;

    this.authService.validateLogin(this.usercode, password).subscribe(
      resData => {
        this.closeModal();
      },
      errorMessage => {
        this.errorList.push(errorMessage);
      }
    )
  }

  closeModal(): void {
    this.errorList = [];
    this.closeBtn.nativeElement.click();
  }

  openKeyboard(event): void {
    initializeKeyboard(event.target);
  }

}
