import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { VoidReason } from '../models/void-reason.model';

@Injectable(
  { providedIn: 'root' }
)
export class VoidReasonService {
  constructor(private http: HttpClient) {
  }

  getVoidReasonBy(code: string, description: string) {
    let voidArray: VoidReason[] = [];

    let params = new HttpParams();
    params = params.append("per_page", environment.PER_PAGE).append("key_presets", "true");

    if (code) {
      params = params.append("key_code", code);
    }

    if (description) {
      params = params.append("key_name", description);
    }

    return this.http.get(
      environment.APIENDPOINT + environment.VOID_REASON, {
      params: params
    }
    ).pipe(
      map(response => {
        response["pos_void_reasons"].forEach(voidReason => {
          let objVoid = new VoidReason(
            voidReason.id,
            voidReason.code,
            voidReason.pos_void_reason_translations && voidReason.pos_void_reason_translations.length > 0 ? voidReason.pos_void_reason_translations[0].description : "",
            voidReason.freeze_ind,
            voidReason.freeze_at,
          );

          voidArray.push(objVoid);
        });
        return voidArray;
      })
    );
  }

  getAll() {
    let voidArray: VoidReason[] = [];

    let params = new HttpParams();
    params = params.append("per_page", environment.API_DATA_PER_PAGE);

    return this.http.get(
      environment.APIENDPOINT + environment.VOID_REASON, {
      params: params
    }
    ).pipe(
      map(response => {
        response["pos_void_reasons"].forEach(voidReason => {
          let objVoid = new VoidReason(
            voidReason.id,
            voidReason.code,
            voidReason.pos_void_reason_translations && voidReason.pos_void_reason_translations.length > 0 ? voidReason.pos_void_reason_translations[0].description : "",
            voidReason.freeze_ind,
            voidReason.freeze_at,
          );

          voidArray.push(objVoid);
        });

        localStorage.setItem('voidReason', JSON.stringify(voidArray));
        return voidArray;
      })
    );
  }
}
