<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">{{ 'SALE.REPORT_DETAILS' | translate }}</h5>
  <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
</div>
<div class="modal-body">
  <div class="report_detls_blk">
    <table>
      <tr>
        <th>{{ 'SALE.BILL_TYPE' | translate }}</th>
        <th class="right_algn">{{ 'SALE.TOTAL_BILLS' | translate }}</th>
        <th class="right_algn">{{ 'SALE.TOTAL_REVENUE' | translate }}</th>
      </tr>
      <tr *ngFor="let sale of checkSummary">
        <td>{{ sale.check_lbl }}</td>
        <td class="right_algn">{{ sale.check_count }}</td>
        <td class="right_algn">{{ sale.revenue| number: '1.' + decimalPlace + '-' + decimalPlace }}</td>
      </tr>
      <!-- <tr>
        <td>Grand total</td>
        <td class="right_algn">{{ totalCheckCount }}</td>
        <td class="right_algn">{{ totalCheckRevenue }}</td>
        
      </tr> -->
    </table>
    <table>
      <tr>
        <th>{{ 'SALE.PAYMENT_TYPE' | translate }}</th>
        <th class="right_algn">{{ 'SALE.TOTAL_BILLS' | translate }}</th>
        <th class="right_algn">{{ 'SALE.TOTAL_REVENUE' | translate }}</th>
      </tr>
      <tr *ngFor="let sale of paymentSummary">
        <td>{{ sale.check_lbl }}</td>
        <td class="right_algn">{{ sale.check_count }}</td>
        <td class="right_algn">{{ sale.revenue| number: '1.' + decimalPlace + '-' + decimalPlace }}</td>
      </tr>
      <!-- <tr>
        <td>Grand Total</td>
        <td class="right_algn">{{ i }}</td>
        <td class="right_algn"></td>
      </tr> -->
    </table>
    <table>
      <tr>
        <th>{{ 'SALE.BILL_TYPE' | translate }}</th>
        <th class="right_algn">{{ 'SALE.TOTAL_BILLS' | translate }}</th>
        <th class="right_algn">{{ 'SALE.TOTAL_TIPS' | translate }}</th>
      </tr>
      <tr *ngFor="let sale of tipsSummary">
        <td>{{ sale.check_lbl }}</td>
        <td class="right_algn">{{ sale.check_count }}</td>
        <td class="right_algn">{{ sale.revenue| number: '1.' + decimalPlace + '-' + decimalPlace }}</td>
      </tr>
    </table>
  </div>
</div>
<div class="modal-footer">
  <!-- <div class="button_blk">
    <button type="button" class="btn gry_btn me-3" data-bs-dismiss="modal"><i
        class="fas fa-check-circle"></i>Ok</button>
  </div> -->
</div>