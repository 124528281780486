import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';
import { AlertService } from '../services/alert.service';
import { PointerService } from '../services/pointer.service';
import { EncryptionService } from '../services/encryption.service';
declare function toggleSubscriptionModal(): any;
declare function toggleBusinessDateModal(): any;
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html'
})
export class LandingComponent implements OnInit {
  reportsUrl: string = environment.REPORTS_URL;
  diffDays: number = 0;
  currentBusinessDate: string;
  @ViewChild('closeSubscriptionModalBtn') closeTableTransferModalBtn: ElementRef;
  @ViewChild('closeNightAuditModalBtn') closeNightAuditModalBtn: ElementRef;

  constructor(private authService: AuthService,
    private router: Router,
    private alertService: AlertService,
    private pointerService: PointerService,
    private encryptionService: EncryptionService) { }

  ngOnInit(): void {
    let string = this.encryptionService.encryptionAES("MCA_MCA_AIR_2023-11-30")
  }

  onPosSelection(): void {
    this.authService.getUserOutlets(this.authService.userValue.id)
      .subscribe({
        next: (data: any) => {
          this.checkBusinessDate();
        },
        error: (error: any) => {
          if (error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      });
  }

  isValidLicence() {
    //this.router.navigate(['/outlet']);
    this.pointerService.getAll()
      .subscribe({
        next: (pointerData: any) => {
          if (pointerData) {
            let businessDate = pointerData.filter(t => {
              return t.code.toLowerCase() == environment.POINTER_BUSINESS_DATE.toLowerCase();
            });

            if (businessDate && businessDate.length > 0) {
              businessDate = new Date(businessDate[0].pointer_value);
            }

            let pointerKey = pointerData.filter(t => {
              return t.code.toLowerCase() == environment.POINTER_LICENCE_KEY.toLowerCase();
            });
            if (pointerKey && pointerKey.length > 0) {
              pointerKey = pointerKey[0].pointer_value;
            }

            let licenceKey = this.encryptionService.decryptionAES(pointerKey);

            let saasCompanyCode = licenceKey.split("_")[0];
            let companyCode = licenceKey.split("_")[1];
            let branchCode = licenceKey.split("_")[2];
            let validDate: Date;

            if (!isNaN(licenceKey.split("_")[3])) {
              validDate = new Date(parseFloat(licenceKey.split("_")[3]))
            } else {
              this.alertService.error(environment.INVALID_LICENCE_KEY);
              return;
            }

            if (this.authService.userValue.saas_company_code.toUpperCase() == saasCompanyCode.toUpperCase()
              && this.authService.userValue.company_code.toUpperCase() == companyCode.toUpperCase()
              && this.authService.userValue.branch_code.toUpperCase() == branchCode.toUpperCase()) {

              if (validDate > businessDate) {
                let diff = Math.abs(validDate.getTime() - businessDate.getTime());
                this.diffDays = Math.ceil(diff / (1000 * 3600 * 24));
                console.log("Diff in Days: " + this.diffDays);

                if (this.diffDays <= +environment.SUBSCRIPTION_ALERT_DAYS_COUNT) {
                  toggleSubscriptionModal();
                  //this.alertService.error(environment.SUBSCRIPTION_ALERT + this.diffDays + " days");
                } else {
                  this.router.navigate(['/outlet']);
                }
              } else {
                this.alertService.error(environment.SUBSCRIPTION_EXPIRE_MESSAGE);
              }
            } else {
              this.alertService.error(environment.INVALID_COMPANY_INFO);
            }
          }
          else {
            this.alertService.error(environment.INVALID_LICENCE_KEY);
          }
        },
        error: (error: any) => {
          if (error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      })
  }

  getTargetUrl() {
    let userData: any = JSON.parse(localStorage.getItem("userData"));
    let authToken: any = userData?.token;
    let targetUrl = `${document.location.protocol}//${document.location.hostname}:${environment.INNDCSURLPORT}/auth?key_auth=${authToken}`;
    return targetUrl;
  }

  redirectToOutletSelection(): void {
    this.closeTableTransferModalBtn.nativeElement.click();
    this.router.navigate(['/outlet']);
  }

  checkBusinessDate(): void {
    this.pointerService.getByCode(environment.POINTER_BUSINESS_DATE)
      .subscribe({
        next: (pointerData: any) => {
          if (pointerData && pointerData != null && pointerData.pointer_value != "") {
            this.currentBusinessDate = pointerData.pointer_value;
            let systemDate = new Date();
            if (systemDate > new Date(this.currentBusinessDate)) {
              toggleBusinessDateModal();
            } else {
              this.isValidLicence();
            }
          }
        },
        error: (error: any) => {
          if (error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      })
  }

  cancelNightAudit(): void {
    this.isValidLicence();
    this.closeNightAuditModalBtn.nativeElement.click();
  }

  changeBusinessDate(): void {
    this.pointerService.changeBusinessDate(this.currentBusinessDate, this.authService.userValue.saas_company_id, this.authService.userValue.company_id, this.authService.userValue.branch_id).subscribe({
      next: (data: any) => {
        this.checkBusinessDate(); //keep checking business date till today's date
      }, error: (error: any) => {
        this.closeNightAuditModalBtn.nativeElement.click();
        let errorMsg: string[] = Object.values(error);

        errorMsg.forEach(msg => {
          this.alertService.error(msg);
        });
      }
    })
  }

  // onPosSelection(): void {
  //   this.authService.getUserOutlets(this.authService.userValue.id).subscribe(
  //     data => {
  //       if (data && data.length == 1) {
  //         //this.posRestaurantId = data[0].pos_restaurant_id;
  //         //this.outletService.clearOutletData();

  //         // this.outletService.getLayout(this.posRestaurantId.toString()).subscribe((tableData) => {
  //         //   if (tableData && tableData.length > 0) {
  //         //     if(environment.REDIRECT_TO_DEFAULT_LAYOUT === "1") {
  //         //       this.selectedTable = tableData[0];
  //         //       this.redirectToBilling();
  //         //     }
  //         //     else {
  //         //       this.showLayoutModal = true;
  //         //       this.tableArray = tableData;
  //         //     }                      
  //         //   }
  //         //   else {
  //         //     this.redirectToBilling();
  //         //   }
  //         // });
  //         this.router.navigate(['/outlet']);
  //       }
  //       else {
  //         this.router.navigate(['/outlet']);
  //       }
  //     }
  //   )
  // }
}
