import { PosItem } from './pos-item.model';
import { PosModifier } from './pos-modifier.model';

export class PosItemModifier {
  constructor(
    public id: number,
    public pos_item_id: number,
    public pos_modifier_id: number,
    public pos_item: PosItem,
    public pos_modifier: PosModifier
  ) {}
}