import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MemberSearchService {

  constructor(private http: HttpClient) { }

  fetchMemberDetails(memberId: any, firstName: any, lastName: any) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('per_page', 1000);
    if (memberId) {
      queryParams = queryParams.append('key_like_membership_number', memberId)
    }
    if (firstName) {
      queryParams = queryParams.append('key_firstname', firstName)
    }
    if (lastName) {
      queryParams = queryParams.append('key_lastname', lastName)
    }
    return this.http.get<any>(environment.APIENDPOINT + environment.ACCOUNT, {
      params: queryParams.append('key_club_member_ind', true)
    })
      .pipe(map((results: any) => {
        return results;
      }))
  }

  fetchMemberDetailsByDebitCard(cardNumber: any) {
    return this.http.get<any>(environment.APIENDPOINT + environment.ACCOUNT, {
      params: new HttpParams().append("key_club_member_ind", true)
        .append("key_member_card_number", cardNumber)
    })
      .pipe(map((result: any) => {
        return result;
      }))
  }
}
