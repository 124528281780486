<div class="wrapg_blk">
  <app-header></app-header>

  <!-- SOF Sub-header HTML -->
  <div class="sub_header_parnt menu_sub_hdr">
    <div class="sub_header_inr only_page_name">
      <h2 class="page_name">{{ 'RECALL_BILL.RECALL_BILL' | translate }}</h2>
    </div>
    <app-business-date></app-business-date>
  </div>
  <!-- EOF Sub-header HTML  -->

  <main class="main_cnt_blk">
    <section class="table_tab_parnt">
      <div class="recall_modl_parnt">
        <div class="recall_modl_inr">
          <div class="row">
            <div class="col-md-7 col-xl-8">
              <div class="selct_ans_blk">
                <a href="javascript:void(0)" [class.selected]="tableSelection" *ngIf="tableCode" 
                  data-target="#" class="" (click)="onTableSelection()">
                  <i class="tabl_icn"></i>
                  {{ tableCode }}
                </a>
                <a href="javascript:void(0)" data-target="#" [class.selected]="dateSelection">
                  <i class="calender_icn" (click)="onDateSelection()"></i>
                  {{ businessDate | date: 'dd-MM-yyyy' }}
                </a>
                <a href="javascript:void(0)" data-target="#" [class.selected]="includeOpenCheck"
                  (click)="onOpenCheckSelection()">
                  <i class="recall_check_icn"></i>{{ 'RECALL_BILL.OPEN_BILL' | translate }}
                </a>
              </div>
            </div>
            <div class="col-md-5 col-xl-4">
              <div class="recall_inpt">
                <input type="text" [(ngModel)]="checkNumber" class="form-control"
                  placeholder="To recall check type here" 
                  aria-label="To recall check type here" 
                  (click)="openKeyboard($event)" />
                <div class="button_blk">
                  <button type="button" class="btn gry_btn" (click)="onRecallBillClick()"><i
                      class="bi bi-arrow-repeat"></i>{{ 'RECALL_BILL.RECALL_BILL' | translate }}</button>
                </div>
              </div>
            </div>
            <div class="dx-viewport demo-container">
              <div id="grid">
                <dx-data-grid id="gridCheck" [dataSource]="checkList" keyExpr="id" [showBorders]="false"
                  [showColumnHeaders]="true" [showRowLines]="true" [showColumnLines]="true">
                  <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
                  <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="false" [visible]="true"></dxo-pager>
                  <dxo-selection mode="single"></dxo-selection>
                  <dxo-scrolling mode="standard"></dxo-scrolling>
                  <dxo-editing mode="cell" [allowUpdating]="false">
                  </dxo-editing>
                  <dxi-column dataField="id" [visible]="false"></dxi-column>
                  <dxi-column dataField="check_no" caption="Bill no" cssClass="anchr_clr" cellTemplate="cellTemplate">
                  </dxi-column>
                  <div *dxTemplate="let cell of 'cellTemplate'" (click)="onCheckSelection(cell)">
                    <div class="anchr_clr">{{cell.text}}</div>
                  </div>
                  <dxi-column dataField="guest_name" caption="{{ 'COMMON_LABEL.GUEST_NAME' | translate }}">
                  </dxi-column>
                  <dxi-column dataField="table_code" caption="{{ 'COMMON_LABEL.TABLE_NUMBER' | translate }}">
                  </dxi-column>
                  <dxi-column dataField="check_open_date" caption="{{ 'COMMON_LABEL.OPEN_DATE' | translate }}" dataType="date" format="dd/MM/yyyy">
                  </dxi-column>
                  <dxi-column dataField="check_amount" caption="{{ 'COMMON_LABEL.AMOUNT' | translate }}">
                  </dxi-column>
                  <dxi-column dataField="status" caption="{{ 'COMMON_LABEL.STATUS' | translate }}">
                  </dxi-column>
                  <dxi-column dataField="check_open_by" caption="{{ 'COMMON_LABEL.OPEN_BY' | translate }}">
                  </dxi-column>
                  <dxi-column dataField="check_closed_by" caption="{{ 'COMMON_LABEL.CLOSED_BY' | translate }}">
                  </dxi-column>
                  <dxi-column dataField="check_printed_by" caption="{{ 'COMMON_LABEL.PRINTED_BY' | translate }}">
                  </dxi-column>
                </dx-data-grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="action_btn_parnt">
      <ul>
        <li (click)="onBackClick()">
          <a class="form-control" tabindex="0">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 98.86" style="enable-background:new 0 0 122.88 98.86" xml:space="preserve"><g><path d="M0,49.43l48.93,49.43V74.23c30.94-6.41,55.39,0.66,73.95,24.19c-3.22-48.4-36.29-71.76-73.95-73.31V0L0,49.43 L0,49.43L0,49.43z"/></g>
            </svg>
            {{ 'ACTION_BUTTON.BACK' | translate }}
          </a>
        </li>
      </ul>
    </section>

    <!-- SOF Common keyboard html -->
    <app-qwerty-keyboard></app-qwerty-keyboard>
    <!-- EOF Common keyboard html -->
  </main>
</div>