import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { PointerService } from './pointer.service';

@Injectable({
  providedIn: 'root'
})
export class RecallCheckService {
  constructor(private http: HttpClient) { }

  public getCheckListBy(outletId: string, dateSelection: boolean,
                        includeOpenCheck: boolean, tableSelection: boolean,
                        tableCode: string, checkNo: string) {
    let businessDate = PointerService.getBusinessDate().toISOString().slice(0, 10)
    let params = new HttpParams();
    params = params.append("key_restaurant", outletId)
    params = params.append("per_page", environment.API_DATA_PER_PAGE)

    if (checkNo) {
      params = params.append("key_check_no", checkNo);
    }
    else {
      params = params.append("key_open_check_with_kot", "true");
      params = params.append("key_check_open_date", businessDate);

      if (tableSelection) {
        params = params.append("key_table_code", tableCode);
      }

      if (dateSelection) {
        params = params.append("key_check_open_date", businessDate);
      }

      if (includeOpenCheck) {
        params = params.append("key_open_check", "true");
      }
    }

    return this.http.get(environment.APIENDPOINT + environment.POS_CHECK, {
      params: params
    }).pipe(
      map(response => {
        if (response && response["pos_checks"].length > 0) {
          return this.mapRecallCheckList(response["pos_checks"]);
        }
      }
    ))
  }

  mapRecallCheckList(data): any[] {
    let checkList: any[] = [];
    data.forEach(posCheck => {
      let obj = {};
      obj["id"] = posCheck.id;
      obj["check_no"] = posCheck.check_no;
      obj["guest_name"] = posCheck.guest_name;
      obj["table_code"] = posCheck.table_code;
      obj["check_open_date"] = posCheck.open_date;
      obj["check_amount"] = posCheck.dr_total;
      obj["last_action"] = posCheck.last_action;

      if (posCheck.last_action == environment.LAST_ACTION_POSTED) {
        obj["status"] = environment.CHECK_STATUS_OPEN;
      } else if (posCheck.last_action == environment.LAST_ACTION_SETTLE) {
        obj["status"] = environment.CHECK_STATUS_SETTLE;
      } else if (posCheck.last_action == environment.LAST_ACTION_VOIDED) {
        obj["status"] = environment.CHECK_STATUS_VOID;
      } else {
        obj["status"] = ""
      }

      if (posCheck.server) {
        obj["check_open_by"] = posCheck.server.full_name ? posCheck.server.full_name : posCheck.server.first_name;
      } else {
        obj["check_open_by"] = "";
      }

      if (posCheck.close_cashier) {
        obj["check_closed_by"] = posCheck.close_cashier.full_name ? posCheck.close_cashier.full_name : posCheck.close_cashier.first_name;
      }
      else {
        obj["check_closed_by"] = "";
      }

      if (posCheck.print_cashier) {
        obj["check_printed_by"] = posCheck.print_cashier.full_name ? posCheck.print_cashier.full_name : posCheck.print_cashier.first_name;
      }
      else {
        posCheck["check_printed_by"] = "";
      }

      checkList.push(obj);
    });
    return checkList;
  }
}
