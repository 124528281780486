<div class="wrapg_blk">
  <app-header></app-header>

  <!-- SOF Sub-header HTML -->
  <div class="sub_header_parnt menu_sub_hdr">
    <div class="sub_header_inr only_page_name">
      <h2 class="page_name">Select outlet</h2>
    </div>
    <app-business-date></app-business-date>
  </div>
  <!-- EOF Sub-header HTML -->

  <main class="main_cnt_blk">
    <section class="select_out_parnt" *ngIf="userOutlets && userOutlets.length > 0">
      <div class="row">
        <div class="col-6 col-sm-4 col-md-3 col-xl-2 mb-15"
          *ngFor="let outlet of userOutlets; odd as isOdd; even as isEven;">
          <div class="select_out_blk" [ngClass]="{ 'blue_main_parnt': isEven, 'purple_main_parnt': isOdd }"
            (click)="onOutletSelection(outlet.id)">
            <a class="form-control select_out_icn" tabindex="0">{{ outlet.outlet_name }}</a>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>