export class TableLayout {
  constructor(
    public id: number,
    public table_code: string,
    public table_group: string,
    public table_image: string,
    public serial_number: number,
    public table_seats: number,
    public default_take_away_ind: boolean,
    public for_pending_check_ind: boolean,
    public pos_restaurant_id: number,
    public table_status: string,
    public table_display_code: string,
    public description: string,
    public pos_check_id?: number
  ) {}
}