import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PosCheckChargeDiscount } from '../../models/pos-check-charge-discount.model';
import { PosCheckCharge } from '../../models/pos-check-charge.model';
import { PosCheck } from '../../models/pos-check.model';
import { PosDiscount } from '../../models/pos-discount.model';
import { PointerService } from '../../services/pointer.service';
import { PosCheckService } from '../../services/pos-check.service';
import { PosDiscountService } from '../../services/pos-discount.service';
import { environment } from '../../../environments/environment';
import { AlertService } from '../../services/alert.service';
declare function initializeKeyboard(event): any;

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html'
})
export class DiscountComponent implements OnInit {
  outletId: string = "";
  posCheck: PosCheck;
  posCheckId: string;
  discountList: PosDiscount[] = [];
  showDiscountList: boolean = true;
  selectedDiscount: PosDiscount;
  decimalPlace: number = this.pointerService.getDecimalPlaceValue();
  posDiscountCharge: any;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private posCheckService: PosCheckService,
              private posDiscountService: PosDiscountService,
              private pointerService: PointerService,
              private alertService: AlertService
              ) {}

  ngOnInit(): void {
    this.outletId = this.activatedRoute.snapshot.params["outlet_id"];
    this.posCheckId = this.activatedRoute.snapshot.params["check_id"];
    this.getCheckDetails();
  }

  getCheckDetails(): void {
    this.posCheckService.findBy(this.posCheckId)
    .subscribe({
      next: (data: any) => {
        if (data) {
          this.posCheck = data;
          this.listDiscount();
        }
      },
      error: (error: any) => {
        if(error.errors && error.errors.length > 0) {
          this.alertService.error(error.errors[0]);
        } else {
          this.alertService.error(error);
        }
      }
    });
  }

  listDiscount(): void {
    const discountData: PosDiscount[] = JSON.parse(localStorage.getItem("discountData"));
    if (discountData) {
      this.discountList = discountData;
      this.discountList = this.discountList.filter(x => x.display_in_lookup_ind == true);
    } else {
      this.posDiscountService.getAll()
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.discountList = data;
            this.discountList = this.discountList.filter(x => x.display_in_lookup_ind == true);
          }
        },
        error: (error: any) => {
          if(error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      });
    }
  }

  onBackClick(): void {
    this.router.navigate(['/billing/' + this.outletId + '/view-bill/' + this.posCheckId]);
  }

  onDiscountSelection(discount: PosDiscount): void {
    this.showDiscountList = false;
    this.selectedDiscount = discount;
    this.filterPosCheckDiscountCharge();
  }

  applyDiscount(value, isPercent: boolean, charge: PosCheckCharge): void {
    if(value) {
      let discountAmount = 0;
      if(isPercent) {
        discountAmount = +(charge.amount * value / 100).toFixed(this.decimalPlace);
      } else {
        discountAmount = value;
      }

      if(charge.pos_check_charge_discounts && charge.pos_check_charge_discounts.length > 0) {
        charge.pos_check_charge_discounts[0].amount = discountAmount;
        charge.pos_check_charge_discounts[0].discount_percentage = isPercent ? value : "";
      } else {
        let checkChargeDiscount = new PosCheckChargeDiscount();
        checkChargeDiscount.amount = discountAmount;
        checkChargeDiscount.charge_id = charge.charge_id;
        checkChargeDiscount.currency_id = PointerService.getLocalCurrencyId();
        checkChargeDiscount.description = this.selectedDiscount.description;
        checkChargeDiscount.discount_percentage = isPercent ? value : "";
        checkChargeDiscount.dr_cr = environment.CR_IND;
        checkChargeDiscount.pos_check_charge_id = charge.id;
        checkChargeDiscount.pos_discount_id = this.selectedDiscount.id;
        checkChargeDiscount.serial_number = 1;
        charge.pos_check_charge_discounts.push(checkChargeDiscount);
      }
    }
    this.posCheckService.updateOrder(this.posCheck);
  }

  filterPosCheckDiscountCharge() {
    const uniqueAccountCodes = [...new Set(this.posCheck.pos_check_charges.map(item => item.charge.account_code))];
    console.log(uniqueAccountCodes);

    this.posDiscountCharge = this.selectedDiscount.pos_discount_details.filter(x => uniqueAccountCodes.includes(x.charge.code));
    console.log(this.posDiscountCharge);

    //adding discount object inside respective PosCheckCharge for auto processing on html side
    this.posCheck.pos_check_charges.forEach(checkCharge => {
      const discountCharges = this.posDiscountCharge.filter(x => x.charge.code == checkCharge.charge.account_code);
      if(discountCharges.length > 0) {
        checkCharge['discount'] = discountCharges;

        if(checkCharge['discount'][0].discount_percent) {
          this.applyDiscount(checkCharge['discount'][0].discount_percent, true, checkCharge);
        } else {
          this.applyDiscount(checkCharge['discount'][0].discount_amount, true, checkCharge);
        }
      }
    })
  }

  onSaveDiscount(): void {
    if (this.posCheck.pos_check_details && this.posCheck.pos_check_details.length > 0) {
      this.posCheckService.saveBill(this.posCheck).subscribe(data => {
        this.router.navigate(['/billing/' + this.outletId + '/settlement/'+ this.posCheckId]);
      });
    }
  }

  openKeyboard(event): void {
    initializeKeyboard(event.target);
  }
}
