import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class SaleService {

  constructor(private http: HttpClient) { }

  public getSaleBy(outletId: string, user: User, businessDate: string, shiftNo: number) {
    return this.http.get(environment.APIENDPOINT + environment.SALE, {
      params: new HttpParams().append("key_restaurant", outletId)
                              .append("key_business_date", businessDate)
                              .append("key_shift", shiftNo.toString())
                              .append("key_saas_company_id", user.saas_company_id.toString())
                              .append("key_company_id", user.company_id.toString())
                              .append("key_branch_id", user.branch_id.toString())
    }).pipe(
      map(response => {
      console.log(response);
        return response["inntap_sales_report"];
      })
    )
  }
}
