<header>
  <div class="logo_blk">
    <h1>{{ 'HEADER.TITLE' | translate }}</h1>
    <span class="curnt_outlet" *ngIf="outletName">{{ outletName }}</span>
  </div>
  <div class="user_login_parnt">
    <button class="btn mob_menu"><i class="bi bi-list"></i></button>
    <div class="user_login_info">
      <div class="miscllns_options">
        <ul>
          <li class="view_levl_optn">
            <button class="btn">{{ 'HEADER.VIEW' | translate }}</button>
            <ul class="view_levl_lst" style="display: none;">
              <li (click)="onViewSelection('OPERATIONAL_VIEW')"><a href="javascript:void(0)">{{ 'HEADER.OPERATIONAL_VIEW' | translate }}</a></li>
              <li (click)="onViewSelection('SMART_VIEW')"><a href="javascript:void(0)">{{ 'HEADER.SMART_VIEW' | translate }}</a></li>
              <li>{{ 'HEADER.LANGUAGE' | translate }}
                <span class="demo_view_grp">
                  <select class="form-select" #selLang>
                    <option *ngFor="let language of translate.getLangs()" [value]="language">{{ language }}</option>
                  </select>
                  <button type="button" class="btn" (click)="translateLanguageTo(selLang.value)"><i class="bi bi-check2"></i></button>
                </span>
              </li>
            </ul>
          </li>
          <li class="clr_indctr_icon">
            <button class="btn">{{ 'HEADER.COLOR_INFO' | translate }}</button>
            <ul class="clr_indctr_lst" style="display: none;">
              <li class="avbl_tabl_clr">{{ 'HEADER.AVAILABLE' | translate }}</li>
              <li class="kot_tabl_clr">{{ 'HEADER.RAISE_ORDER' | translate }}</li>
              <li class="prnt_tabl_clr">{{ 'HEADER.PRINT_BILL' | translate }}</li>
              <!-- <li class="rsvrd_tabl_clr">Reservered</li> -->
            </ul>
          </li>
        </ul>
      </div>
      <div class="admin_grp">
        <p>{{ userName }}<span *ngIf="shiftNumber"> | {{ 'HEADER.SHIFT' | translate }} : {{ shiftNumber }}</span></p>
        <div class="hdr_parnt_blk">
          <button class="btn hdr_menu"><i class="bi bi-three-dots-vertical"></i></button>
          <div class="hdr_drp_dwn" style="display: none;">
            <div class="lock_options">
              <ul>
                <li class="home_icon" (click)="onHomeClick()">
                  <a class="form-control" tabindex="0">{{ 'HEADER.HOME' | translate }}</a>     
                </li>
                <li class="change_passwd_icon">
                  <a class="form-control" data-bs-toggle="modal" data-bs-target="#changePasswordModal" tabindex="0">{{ 'HEADER.CHANGE_PASSWORD' | translate }}</a>
                </li>
                <li class="lock_icon">
                  <a class="form-control" data-bs-toggle="modal" data-bs-target="#lockScreenModal" tabindex="0">{{ 'HEADER.LOCK_SCREEN' | translate }}</a>
                </li>
                <li class="logout_icon">
                  <a class="form-control" data-bs-toggle="modal" data-bs-target="#logoutModal" tabindex="0">{{ 'HEADER.LOGOUT' | translate }}</a>     
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<app-change-password></app-change-password>
<app-lock-screen></app-lock-screen>
<app-logout></app-logout>
<app-alert></app-alert>