<div class="wrapg_blk">
  <app-header></app-header>

  <!-- SOF Sub-header HTML -->
  <div class="sub_header_parnt menu_sub_hdr">
    <button class="btn info_btn"><i class="bi bi-info-lg"></i></button>
    <div class="sub_header_inr">
      <h2 class="page_name">Address book</h2>
    </div>
    <app-business-date></app-business-date>
  </div>
  <!-- EOF Sub-header HTML -->

  <main class="main_cnt_blk">
    <section class="table_tab_parnt">
      <div class="row">
        <div class="col-12">
          <div class="address_blk_parnt">
            <div class="address_blk_inr">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-7 col-sm-4 col-lg-3 col-xl-2">
                      <div class="generic_inpt mb-0">
                        <label>Contact number</label>
                        <input type="number" [(ngModel)]="mobileNumber" id="mobileNumber" (keyup.enter)="searchAddress()" autocomplete="none">
                      </div>
                    </div>
                    <div class="col-5 col-md-6 col-lg-8">
                      <div class="tabs address_outr_tab">
                        <ul class="tabs-list">
                          <li class="active" (click)="searchAddress()">
                            <a href="javascript:void(0)" class="scrh_btn_main">
                              <i class="bi bi-search"></i>Search
                            </a>
                          </li>
                          <li (click)="newAddress()">
                            <a href="javascript:void(0)" class="add_new_addrs_btn">
                              <i class="fa fa-user-plus" aria-hidden="true"></i>Add new address
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div id="srch_tab" class="tab active" *ngIf="!showAddressFields">
                    <div class="address_lst_grd_tab">
                      <!-- <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                          <button class="btn nav-link active" id="list-tab" data-bs-toggle="tab"
                            data-bs-target="#list-tab-pane" type="button" role="tab" aria-controls="list-tab-pane"
                            aria-selected="true"><i class="bi bi-list"></i></button>
                        </li>
                        <li class="nav-item" role="presentation">
                          <button class="btn nav-link" id="grid-tab" data-bs-toggle="tab"
                            data-bs-target="#grid-tab-pane" type="button" role="tab" aria-controls="grid-tab-pane"
                            aria-selected="false"><i class="bi bi-grid-3x3-gap-fill"></i></button>
                        </li>
                      </ul> -->
                      <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="list-tab-pane" role="tabpanel"
                          aria-labelledby="list-tab" tabindex="0">
                          <div class="address_lst_grd_blk">
                            <div class="row">
                              <div class="col-md-6 col-lg-4" *ngFor="let account of guestAddressList">
                                <div class="address_lst_cnt address_slcted" (click)="onAddressSelection(account)">
                                  <span class="home_adrs_icn"></span>
                                  <h4>{{ account.title }} {{ account.first_name }} {{ account.last_name }}</h4>
                                  <ul *ngIf="account.account_address">
                                    <li>{{ account.account_address[0].address1 }}</li>
                                    <li>{{ account.account_address[0].address2 }}</li>
                                    <li>{{ account.account_address[0].address3 }}</li>
                                    <li>{{ account.account_address[0].city_name }} {{
                                      account.account_address[0].state_name }} {{
                                      account.account_address[0].country_name }}</li>
                                    <li>{{ account.account_address[0].zip }}</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="tab-pane fade" id="grid-tab-pane" role="tabpanel" aria-labelledby="grid-tab"
                          tabindex="0">
                          <div class="address_lst_grd_blk grd_parnt_blk">
                            <div class="demo-container dev_grd_blk">
                              <div id="descContainer"></div>
                              <div id="gridContact"></div>
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <form (submit)="addAddress(addressForm)" #addressForm="ngForm">
                    <div id="add_addrs_tab" class="tab active" *ngIf="showAddressFields">
                      <div class="add_addrs_tab">
                        <div class="row">
                          <div class="col-sm-6 col-md-4 col-lg-3 mb-15">
                            <div class="generic_inpt">
                              <label>First name</label>
                              <input type="text" name="first_name" required ngModel (click)="openKeyboard($event)" autocomplete="none">
                            </div>
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-3 mb-15">
                            <div class="generic_inpt">
                              <label>Last name</label>
                              <input type="text" name="last_name" ngModel (click)="openKeyboard($event)" autocomplete="none">
                            </div>
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-3 mb-15">
                            <div class="generic_inpt">
                              <label>Email id</label>
                              <input type="email" name="email" ngModel pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$" (click)="openKeyboard($event)" autocomplete="none">
                            </div>
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-3 mb-15">
                            <div class="generic_inpt">
                              <label>Address Type</label>
                              <select name="address_type_id" ngModel>
                                <option selected>Select</option>
                                <option *ngFor="let type of addressTypes" value={{type.id}}>
                                  {{ type.description }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-3 mb-15">
                            <div class="generic_inpt">
                              <label>Address line 1</label>
                              <input type="text" name="address1" ngModel (click)="openKeyboard($event)" autocomplete="none">
                            </div>
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-3 mb-15">
                            <div class="generic_inpt">
                              <label>Address line 2</label>
                              <input type="text" name="address2" ngModel (click)="openKeyboard($event)" autocomplete="none">
                            </div>
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-3 mb-15">
                            <div class="generic_inpt">
                              <label>Address line 3</label>
                              <input type="text" name="address3" ngModel (click)="openKeyboard($event)" autocomplete="none">
                            </div>
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-3 mb-15">
                            <div class="generic_inpt">
                              <label>City</label>
                              <select name="city_id" ngModel (change)="onSelect($event.target.value)">
                                <option *ngFor="let city of cities" value={{city.id}}>
                                  {{ city.description }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-3 mb-15">
                            <div class="generic_inpt">
                              <label>State</label>
                              <input type="text" name="state_id" ngModel (click)="openKeyboard($event)"
                                value={{state_name}} autocomplete="none">
                            </div>
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-3 mb-15">
                            <div class="generic_inpt">
                              <label>Country</label>
                              <input type="text" name="country_id" ngModel (click)="openKeyboard($event)"
                                value={{country_name}}>
                            </div>
                          </div>
                          <div class="col-sm-6 col-md-4 col-lg-3 mb-15">
                            <div class="generic_inpt">
                              <label>Zip/Postcode</label>
                              <input type="text" name="zip" ngModel (click)="openKeyboard($event)" autocomplete="none"> 
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="button_blk" *ngIf="showAddressFields">
                      <button type="submit" class="btn gry_btn">
                        <i class="fas fa-check-circle"></i>Save
                      </button>
                      <button type="button" class="btn red_btn" (click)="onCancel()">
                        <i class="fas fa-times-circle"></i>Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="action_btn_parnt">
      <ul>
        <li (click)="onBackClick()">
          <a class="form-control" tabindex="0">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 98.86" style="enable-background:new 0 0 122.88 98.86" xml:space="preserve"><g><path d="M0,49.43l48.93,49.43V74.23c30.94-6.41,55.39,0.66,73.95,24.19c-3.22-48.4-36.29-71.76-73.95-73.31V0L0,49.43 L0,49.43L0,49.43z"/></g>
            </svg>
            {{ 'ACTION_BUTTON.BACK' | translate }}
          </a>
        </li>
      </ul>
    </section>
    <!-- SOF Common keyboard html -->
    <app-qwerty-keyboard></app-qwerty-keyboard>
    <!-- EOF Common keyboard html -->
  </main>
</div>