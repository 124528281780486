import { environment } from "../../environments/environment";
import { AccountAddress } from './account-address.model';
import { AccountContactNumber } from './account-contact-number.model';
import { User } from "./user.model";

export class Account {
  constructor() {
    this._pos_ind = true;
    this._account_type = environment.ACCOUNT_PROCESSING_TYPE_INDIVIDUAL;
    this._processing_type = environment.ACCOUNT_PROCESSING_TYPE_INDIVIDUAL;
    this._account_contact_number = [];
    this._account_address = [];
  }

  private _id: number;
  private _saas_company_id: number;
  private _company_id: number;
  private _branch_id: number;
  
  private _title: string;
  private _first_name: string;
  private _last_name: string;
  private _pos_ind: boolean;
  private _account_type: string;
  private _processing_type: string;
  private _account_address: AccountAddress[];
  private _account_contact_number: AccountContactNumber[];

  public get saas_company_id(): number {
    return this._saas_company_id;
  }
  public set saas_company_id(value: number) {
    this._saas_company_id = value;
  }

  public get company_id(): number {
    return this._company_id;
  }
  public set company_id(value: number) {
    this._company_id = value;
  }
  
  public get branch_id(): number {
    return this._branch_id;
  }
  public set branch_id(value: number) {
    this._branch_id = value;
  }

  public get id(): number {
    return this._id;
  }
  public set id(value: number) {
    this._id = value;
  }

  public get title(): string {
    return this._title;
  }
  public set title(value: string) {
    this._title = value;
  }
  
  public get first_name(): string {
    return this._first_name;
  }
  public set first_name(value: string) {
    this._first_name = value;
  }
  
  public get last_name(): string {
    return this._last_name;
  }
  public set last_name(value: string) {
    this._last_name = value;
  }

  public get pos_ind(): boolean {
    return this._pos_ind;
  }
  public set pos_ind(value: boolean) {
    this._pos_ind = value;
  }

  public get processing_type(): string {
    return this._processing_type;
  }
  public set processing_type(value: string) {
    this._processing_type = value;
  }

  public get account_type(): string {
    return this._account_type;
  }
  public set account_type(value: string) {
    this._account_type = value;
  }

  public get account_contact_number(): AccountContactNumber[] {
    return this._account_contact_number;
  }
  public set account_contact_number(value: AccountContactNumber[]) {
    this._account_contact_number = value;
  }
  
  public get account_address(): AccountAddress[] {
    return this._account_address;
  }
  public set account_address(value: AccountAddress[]) {
    this._account_address = value;
  }
  
  public assignUserProperties(user: User) {
    this._saas_company_id = user.saas_company_id;
    this._company_id = user.company_id;
    this._branch_id = user.branch_id;
  }

  public static createJson(account: Account) {
    const addresses = [];
    const contactNumbers = [];

    if(account.account_contact_number && account.account_contact_number.length > 0) {
      account.account_contact_number.forEach(element => {
        let objContact = {
          "saas_company_id": element.saas_company_id,
          "company_id": element.company_id, 
          "branch_id": element.branch_id, 
          "contact_number_type_id": element.contact_number_type_id,
          "contact_number_value": element.contact_number_value
        }
        contactNumbers.push(objContact);
      });
    }

    if(account.account_address && account.account_address.length > 0) {
      account.account_address.forEach(element => {
        let objAddress = {
          "saas_company_id": element.saas_company_id,
          "company_id": element.company_id, 
          "branch_id": element.branch_id, 
          "address_type_id": element.address_type_id,
          "address1": element.address1,
          "address2": element.address2,
          "address3": element.address3,
          "city_id": element.city_id,
          "state_id": element.state_id,
          "country_id": element.country_id,
          "zip": element.zip
        }
        addresses.push(objAddress);
      });
    }
    
    const objAccount = {
      "saas_company_id": account.saas_company_id,
      "company_id": account.company_id,
      "branch_id": account.branch_id,
      "title": account.title,
      "first_name": account.first_name,
      "last_name": account.last_name,
      "pos_ind": account.pos_ind,
      "account_type": account.account_type,
      "processing_type": account.processing_type,
      "account_addresses_attributes": addresses,
      "account_contact_numbers_attributes": contactNumbers
    }
    return objAccount;
  }
}