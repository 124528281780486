import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingRoutingModule } from './billing-routing.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ViewBillComponent } from './view-bill/view-bill.component';
import { PlaceOrderComponent } from './place-order/place-order.component';
import { FilterMenuSubHeader } from './custom-filter/menu-sub-header.pipe';
import { FilterItemBySubHeader } from './custom-filter/menu-sub-header-item.pipe';
import { FilterTax } from './custom-filter/charge-tax.pipe';
import { FilterModifierByItem } from './custom-filter/item-modifier.pipe';
import { SaleComponent } from './sale/sale.component';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { RecallCheckComponent } from './recall-check/recall-check.component';
import { ANGComponent } from './ang/ang.component';
import { SettlementComponent } from './settlement/settlement.component';
import { KotComponent } from './kot/kot.component';
import { DiscountComponent } from './discount/discount.component';
import { AddressBookComponent } from './address-book/address-book.component';
import { AllowanceComponent } from './allowance/allowance.component';
import { QsrComponent } from './qsr/qsr.component';
import { RoomPostComponent } from './room-post/room-post.component';
import { MatNativeDateModule } from '@angular/material/core';
import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  declarations: [
    ViewBillComponent, 
    PlaceOrderComponent,
    FilterMenuSubHeader,
    FilterItemBySubHeader,
    FilterModifierByItem,
    FilterTax,
    SaleComponent,
    RecallCheckComponent,
    ANGComponent,
    SettlementComponent,
    KotComponent,
    DiscountComponent,
    AddressBookComponent,
    AllowanceComponent,
    QsrComponent,
    RoomPostComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DxDataGridModule,
    CommonModule,
    SharedModule,
    BillingRoutingModule,
    MatAutocompleteModule,
    MatInputModule,
    MatNativeDateModule,
    MatFormFieldModule,
    QRCodeModule
  ],
  exports: [
    SaleComponent,
    RecallCheckComponent
  ]
})
export class BillingModule { }
