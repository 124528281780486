export class City {
  constructor(
    public id: number,
    public code: string,
    public description: string,
    public state_id: number,
    public state_name: string,
    public country_id: number,
    public country_name: string
  ) { }
}