import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { PosDiscount, PosDiscountDetails } from '../models/pos-discount.model';

@Injectable({
  providedIn: 'root'
})
export class PosDiscountService {

  constructor(private http: HttpClient){}

  getAll(keyPresets?: boolean) {
    let discountArray: PosDiscount[] = [];

    let params = new HttpParams();
    params = params.append("per_page", environment.API_DATA_PER_PAGE);

    if(keyPresets) {
      params = params.append("key_presets", true);
    }
    
    return this.http.get(
      environment.APIENDPOINT + environment.POS_DISCOUNT, {
        params: params
      }
    ).pipe(
      map(response => {
        if(response["pos_discounts"] && response["pos_discounts"].length > 0) {
          response["pos_discounts"].forEach(obj => {
            let detailsArray: PosDiscountDetails[] = [];
  
            if(obj.pos_discount_details && obj.pos_discount_details.length > 0) {
              obj.pos_discount_details.forEach(element => {
                let objDetail = new PosDiscountDetails(
                  element.id,
                  element.pos_discount_id,
                  element.charge_id,
                  element.charge_group_id,
                  element.currency_id,
                  element.discount_percent,
                  element.discount_amount,
                  element.valid_from_date,
                  element.valid_to_date,
                  element.valid_from_time,
                  element.valid_to_time,
                  element.valid_on_days,
                  element.charge
                )
                detailsArray.push(objDetail);
              });
            }
  
            const discount = new PosDiscount(
              obj.id,
              obj.code,
              obj.display_in_lookup_ind,
              obj.pos_discount_translations && obj.pos_discount_translations.length > 0 ? obj.pos_discount_translations[0].description : "",
              detailsArray
            );
            discountArray.push(discount);
          });
          localStorage.setItem("discountData", JSON.stringify(discountArray));
        }
        return discountArray;
      })
    );
  }
}
