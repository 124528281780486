// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  APP_LANGUAGE_ID: "1",
  REPORTS_URL: "http://localhost:3008",
  APIENDPOINT: "http://15.206.100.82:8300",
  INNDCSURLPORT: "4200",
  // APIENDPOINT: "http://192.168.0.105:3001",
  ACTION_CABLE_ENPOINT: "ws://15.206.100.82:8300/cable?auth_token=",
  TABLE_STATUS_CHANNEL: "TableStatusesChannel",
  KOT_SCREEN_CHANNEL: "KotScreenChannel",
  MODULE_NAME: "inntap",
  MODULE_POS: "POS",
  LAST_ACTION_POSTED: "PO",
  LAST_ACTION_SETTLE: "ST",
  LAST_ACTION_VOIDED: "VO",
  PER_PAGE: "10",
  API_DATA_PER_PAGE: "1000000",
  POS_TABLE_STATUS_AVAILABLE: "FR",
  POS_TABLE_STATUS_KOT: "KOT",
  POS_TABLE_STATUS_CHECK: "CHCK",
  POS_IC_MENU_ITEM: "MI",
  POS_IC_OPEN_ITEM: "OI",
  POS_ROUND_OFF_FLOOR_CODE: "0",
  POS_ROUND_OFF_BASED_ON_POINT_FIVE_CODE: "1",
  POS_ROUND_OFF_CEILING_CODE: "2",
  STATUS_KOT: "KOT",
  STATUS_NEW: "NEW",
  STATUS_VOIDED: "VOID",
  DR_IND: "D",
  CR_IND: "C",
  ANG_DISCOUNT_PERCENTAGE: "100",
  CHECK_STATUS_OPEN: "Open",
  CHECK_STATUS_VOID: "Void",
  CHECK_STATUS_SETTLE: "Settle",
  OTHER_ADD_ON_ID: "1",

  POS_ORDER_STATUS_NEW: "NEW",
  POS_ORDER_STATUS_CANCEL: "CXL",
  POS_ORDER_STATUS_ACKNOWLEDGE: "AKNL",
  POS_ORDER_STATUS_IN_PROGRESS: "PGRS",
  POS_ORDER_STATUS_FOOD_READY: "READY",
  POS_ORDER_STATUS_FOOD_COMPLETED: "COMPLETED",

  PROCESSING_TYPE_SETTLMENT_CASH: "CA",
  PROCESSING_TYPE_SETTLMENT_CREDIT_CARD: "CC",
  PROCESSING_TYPE_SETTLMENT_DEBIT_CARD: "CD",
  PROCESSING_TYPE_SETTLMENT_CHEQUE: "CQ",
  PROCESSING_TYPE_SETTLMENT_ONLINE_TRANSFER: "OT",
  ACCOUNT_PROCESSING_TYPE_INDIVIDUAL: "IN",

  REPORT_ZOOM_LEVEL: "150",

  //SOF Api endpoints
  AUTH: "/auth",
  CHANGE_PASSWORD: "/change_password",
  USER: "/api/users",
  POINTER: "/api/excelfo/pointers",
  CHANGE_BUSINESS_DATE: "/api/excelfo/night_audits/business_date_change",
  POS_RESTAURANT: "/api/excelpos/pos_restaurants",
  POS_OUTLET_USER: "/api/excelpos/pos_restaurant_users",
  POS_OUTLET_SETTING: "/api/excelpos/pos_restaurant_settings",
  CURRENCY: "/api/excelcommon/currencies",
  POS_LAYOUT: "/api/excelpos/pos_layouts",
  SHIFT: "/api/excelpos/pos_shifts",
  POS_ITEM_MENU: "/api/excelpos/pos_item_menus",
  POS_ITEM_MODIFIER: "/api/excelpos/pos_item_modifiers",
  POS_MODIFIER: "/api/excelpos/pos_modifiers",
  POS_MODIFIER_GROUP: "/api/excelpos/pos_modifier_groups",
  POS_ITEM: "/api/excelpos/pos_items",
  CHARGE: "/api/excelfo/charges",
  POS_CHECK: "/api/excelpos/pos_checks",
  SALE: "/api/excelpos/reports/inntap_sales_report",
  ANG_CATEGORY: "/api/excelpos/pos_ang_categories",
  SETTLE_CHECK: "/api/excelpos/pos_checks/settle",
  PRINT_CHECK: "/api/excelpos/pos_checks/print",
  POS_DISCOUNT: "/api/excelpos/pos_discounts",
  VOID_CHECK: "/api/excelpos/pos_checks/void",
  ACCOUNT: "/api/excelfo/accounts",
  TABLE_TRANSFER: "/api/excelpos/pos_checks/table_transfer",
  POS_ORDER_STATUS: "/api/excelpos/pos_order_statuses",
  ROOM: "/api/excelfo/rooms",
  VISIT: "/api/excelfo/visits",
  DEBIT_CARDS: "/api/exceldcs/debit_cards",
  //EOF Api endpoints

  //SOF Reports API endpoints
  REPORT_ANG_SPENDING: "/api/excelpos/reports/ang_report",
  REPORT_BILL_DETAILS: "/api/excelpos/reports/check_details",
  REPORT_TIPS_DETAILS: "/api/excelpos/reports/inntap_tips_report",
  REPORT_CHECK_DETAILS: "/api/excelpos/reports/check_details",
  REPORT_CHECK_DETAILS_SETTLEMENT: "/api/excelpos/reports/check_details_settlement",
  REPORT_CHECK_DETAILS_TAX: "/api/excelpos/reports/check_details_tax",
  REPORT_CHECK_DETAILS_DISCOUNT: "/api/excelpos/reports/check_details_discount",
  REPORT_CASHIER_SCROLL: "/api/excelpos/reports/cashier_scroll_v2",
  REPORT_SETTLEMENT: "/api/excelpos/reports/settlement_report",
  REPORT_CONSUMPTION: "/api/excelpos/reports/consumption_report",
  REPORT_VOID_ITEM: "/api/excelpos/reports/inntap_void_items_report",
  REPORT_PENDING_BILL: "/api/excelpos/reports/pending_checks",
  REPORT_DISCOUNT_SUMMARY: "/api/excelpos/reports/discount_summary",
  REPORT_DISCOUNT: "/api/excelpos/reports/discount_report",
  REPORT_CONSUMPTION_ANALYSIS: "/api/excelpos/reports/inntap_consumption_analysis_report",
  //EOF Reports API endpoints

  //SOF Dashboard endpoints
  DASHBOARD_TOP_SELLING_ITEM: "/api/excelpos/reports/top_selling_items",
  DASHBOARD_SETTLEMENT_CHART: "/api/excelpos/reports/sale_by_settlement",
  //EOF Dashboard endpoints

  //SOF Accounts transfer endpoints
  INCOME_ACCOUNT_TRANSFER: "/api/excelpos/account_transfers/transfer_to_account_income",
  SETTLEMENT_ACCOUNT_TRANSFER: "/api/excelpos/account_transfers/transfer_to_account_settlement",
  //EOF Accounts transfer endpoints

  //SOF Presets API endpoints
  ADDRESS_TYPE: "/api/excelfo/address_types",
  CITY: "/api/excelfo/cities",
  STATE: '/api/excelfo/states',
  COUNTRY: '/api/excelfo/countries',
  DEPARTMENT: '/api/excelcommon/departments',
  DOCUMENT_TYPE: '/api/excelcommon/document_types',
  PERIOD: '/api/excelcommon/periods',
  DOCUMENT_NUMBER_GENERATION: "/api/excelcommon/document_number_generations",
  SEQUENTIAL_NUMBER: "/api/excelcommon/next_sequential_numbers",
  VOID_REASON: "/api/excelpos/pos_void_reasons",
  KITCHEN: "/api/excelpos/pos_kitchens",
  CONTACT_NUMBER_TYPE: "/api/excelfo/contact_number_types",
  TITLE: "/api/excelcommon/titles",
  OUTLET: "/api/excelpos/pos_restaurants",
  OUTLET_SETTING: "/api/excelpos/pos_restaurant_settings",
  MENU: "/api/excelpos/pos_menus",
  MENU_HEADER: "/api/excelpos/pos_menu_headers",
  MENU_SUBHEADER: "/api/excelpos/pos_menu_subheaders",
  ROLE: "/api/excelcommon/roles",
  RIGHT: "/api/excelcommon/rights",
  FUNCTIONAL_RIGHT: "/api/excelsecurity/functional_rights",
  PROCESSING_TYPE: "/api/excelcommon/processing_types",
  POS_RESTAURANT_BILLING_HEADER: "/api/excelpos/pos_restaurant_billing_headers",
  CASHIER_SCROLL_COLUMN: "/api/excelpos/cashier_scroll_columns",
  //EOF Presets Api endpoints

  //SOF Pointer codes
  POINTER_CURRENCY_LOCAL1: "CURRLOC1",
  POINTER_DECIMAL_PLACE: "DECIMALPLC",
  POINTER_CHECK_DISCOUNT_CHARGE_ID: "PO_CHKDISC",
  POINTER_USE_SYSTEM_DATE: "USESYSDATE",
  POINTER_BUSINESS_DATE: "BUSI",
  POINTER_CASH_SETTELEMENT: "CAS1",
  //POINTER_STANDARD_DISCOUNT_ID: "PO_STDDISC",
  POINTER_PENDING_TABLE_CODE: "PO_PNDTBCD",
  POINTER_POS_ROUND_OFF_TYPE: "PO_RNDOFTY",
  POINTER_USE_VIRTUAL_KEYBOARD: "POS_VIRKEY",
  POINTER_POS_CONTACT_TYPE_ID_MOBILE: "POS_CNTMOB",
  POINTER_POS_CONTACT_TYPE_ID_EMAIL: "POS_CNTEML",
  POINTER_LICENCE_KEY: "LICENCEKEY",
  POINTER_POS_INHOUSE_SETTLEMENT_CHARGE: "PO_INHSCH",
  POINTER_DEBIT_CARD_PAYMENT_MODE: "DBTCRDCHRG",
  //EOF Pointer codes

  //SOF  POS functional rights
  RIGHT_CODE_POS_CAN_VOID_KOT: "POS_001",
  RIGHT_POS_CAN_VOID_KOT: "Can void KOT",

  RIGHT_CODE_POS_CAN_VOID_CHECK: "POS_002",
  RIGHT_POS_CAN_VOID_CHECK: "Can void check",

  RIGHT_CODE_POS_CAN_REPRINT_CHECK: "POS_003",
  RIGHT_POS_CAN_REPRINT_CHECK: "Can reprint check",

  RIGHT_CODE_POS_CAN_GIVE_DISCOUNT: "POS_004",
  RIGHT_POS_CAN_GIVE_DISCOUNT: "Can give discount",

  RIGHT_CODE_POS_CAN_GIVE_ALLOWANCE: "POS_005",
  RIGHT_POS_CAN_GIVE_ALLOWANCE: "Can give allowance",

  RIGHT_CODE_POS_CAN_PRINT_SETTLED_CHECK: "POS_006",
  RIGHT_POS_CAN_PRINT_SETTLED_CHECK: "Can print settled check",
  //EOF POS functional rights

  //SOF Error messages
  SELECT_ONE_ITEM: "Please select atleast one item",
  GENERAL_API_ERROR_MESSAGE: "An unknown error occurred!",
  API_IS_NOT_WORKING: "Api service is down",
  SELECT_VALID_TABLE: "Please select a valid table",
  PENDING_TABLE_MISSING: "Pending table is not defined. Please add it in pointer",
  ERROR_TRANSFER_TABLE: "Only free table can be selected for table transfer, cancelling table transfer process. Please try again...",
  ERROR_ALLOWANCE: "Allowance can not be marked as table is not free. First settle the existing bill or move to another table",
  ALLOWANCE_NOT_ALLOWED: "Allowance can not be marked for this payment type",
  ALLOWANCE_NOT_SELECTED: "Please mark atleast one payment type for allowance",
  DUPLICATE_PRINT_CHECK: "You do not have rights to reprint the bill",
  DUPLICATE_SETTLE_PRINT_CHECK: "You do not have rights to reprint the settle bill",
  CANNOT_RAISE_ON_RESERVE_TABLE: "You can not raise bill on reserve table. Please select another table.",
  NO_FREE_TABLE_AVAILABLE: "You do not have free table available.",
  INVALID_LICENCE_KEY: "Invalid licence key, please contact administrator",
  INVALID_COMPANY_INFO: "Invalid company info for current user, please contact administrator",
  SUBSCRIPTION_EXPIRE_MESSAGE: "Your subscription has expired, Please contact administrator",
  SUBSCRIPTION_ALERT: "Your subscription expires in ",
  SUBSCRIPTION_ALERT_DAYS_COUNT: "31",
  VOID_PRINT_CHECK: "You can not print void bill",
  ERROR_DISCOUNT_RIGHT: "You do not have rights to apply discount on bill",
  //EOF Error messages

  //SOF Presets Error messages
  MULTIPLE_FILES_UPLOAD: "Cannot use multiple files",
  RELOGIN_ERROR: "Please relogin and try again",
  SELECT_FILE_TO_UPLOAD: "Please select a file to upload",
  NO_DATA_FOUND: "No data available for selected criteria",
  //EOF Presets Error messages

  STIMULSOFT_LICENSE_KEY: "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHnvZNjnU3wuTlinSouxQKltv7W75DA2KsHS6Xgc" +
    "/3k+N/LMPJnBMQKLqcQidszWZBiHjUs5rD+4UZoq65Z/Ya0VsTG04Mc1THCY8sry9I2x7+w2/FO/AEI+" +
    "uzZj0CmtfT/DKyHN0ry+g0cV5gBus8Uku8CEEOUJJ+PgmmtviyWdXc55e/0PXIqZn7MdQ8b4nvt3y9md" +
    "0Z3ZfxahO4JULNjserVj6uRUS1ryemFYYtEDdCvWtb4o6jO0uD6lx06SBixFZS0Sbcy+uU4GLc0Gc18+" +
    "LE8oHUh1kBR9NYpK1058YQeY+KM2vlbGZHw9d5rIQ49sad5eTRHOe4MKzUN4lmQP4i8Uglya79dczsqR" +
    "ytU+qLAKoaHnY64zYBM7bVJVOYwz3w0ug1f963gfht+zomYx84Hrm0TLRMLwfeBontsTCBDfjJM7iVpv" +
    "y70puoFclMM89/5fCNZXlVrezaVP2bB0kkcik2qTb16We8pKHPZA6vNPgzS/ROiO2bOZxsa+SOhu6T4a" +
    "Wa1i59SkUKO5fhse",

  ENCRYPTION_SECRET_KEY: "miransh2022"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
