import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './auth/auth.guard';
import { LandingComponent } from './landing/landing.component';
import { CloseShiftComponent } from './shift/close-shift/close-shift.component';

const routes: Routes = [
  { path: '', redirectTo: '/auth', pathMatch: 'full' },
  { path: 'auth', component: AuthComponent },
  {
    path: 'landing',
    component: LandingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'outlet',
    loadChildren: () => import('./outlet/outlet.module').then(m => m.OutletModule)
  },
  {
    path: 'shift/:outlet_id',
    loadChildren: () => import('./shift/shift.module').then(m => m.ShiftModule)
  },
  {
    path: 'close_shift/:outlet_id',
    component: CloseShiftComponent
  },
  {
    path: 'table-layout/:outlet_id',
    loadChildren: () => import('./table-layout/table-layout.module').then(m => m.TableLayoutModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
