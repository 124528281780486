import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PosCheck } from '../models/pos-check.model';
import { PosCheckCharge } from '../models/pos-check-charge.model';
import { PosCheckDetail } from '../models/pos-check-detail.model';
import { PosCheckDetailModifier } from '../models/pos-check-detail-modifier.model';
import { PosModifier } from '../models/pos-modifier.model';
import { PosCheckChargeTax } from '../models/pos-check-charge-tax.model';
import { PointerService } from './pointer.service';
import { BehaviorSubject, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { ChargeService } from './charge.service';
import { Charge } from '../models/charge.model';
import { catchError, map, tap } from 'rxjs/operators';
import { TableLayout } from '../models/table-layout.model';
import { PosCheckChargeDiscount } from '../models/pos-check-charge-discount.model';
import { PosCheckSettlement } from '../models/pos-check-settlement.model';
import { PosCheckDelivery } from '../models/pos-check-delivery.model';
import { Account } from '../models/account.model';

@Injectable({
  providedIn: 'root'
})
export class PosCheckService {
  decimalPlace: number = this.pointerService.getDecimalPlaceValue();
  posCheckSubject = new BehaviorSubject<PosCheck>(null);

  constructor(private http: HttpClient,
    private pointerService: PointerService,
    private authService: AuthService,
    private chargeService: ChargeService) { }

  changePosCheckSubject(newData: PosCheck) {
    this.posCheckSubject.next(newData);
  }

  public get posCheckValue(): PosCheck {
    return this.posCheckSubject.value;
  }

  public resetPosCheckSubject() {
    if (this.posCheckSubject) {
      this.posCheckSubject.next(null);
    }
  }

  public assignDeliveryAddress(delivery: PosCheckDelivery) {
    let posCheck: PosCheck = this.posCheckSubject.value ? this.posCheckSubject.value : new PosCheck();
    posCheck.guest_name = delivery.first_name + " " + delivery.last_name;
    posCheck.pos_check_delivery.push(delivery);
    this.changePosCheckSubject(posCheck);
  }

  public mapDeliveryFields(accountFields: Account): PosCheckDelivery {
    let account = accountFields['account'] ? accountFields['account'] : accountFields;

    let objDelivery = new PosCheckDelivery();
    objDelivery.account_id = account.id;
    objDelivery.first_name = account.first_name;
    objDelivery.last_name = account.last_name;

    if (account.account_address && account.account_address.length > 0) {
      objDelivery.account_address_id = account.account_address[0].id;
      objDelivery.address1 = account.account_address[0].address1;
      objDelivery.address2 = account.account_address[0].address2;
      objDelivery.address3 = account.account_address[0].address3;
      objDelivery.city = account.account_address[0].city_name;
      objDelivery.state = account.account_address[0].state_name;
      objDelivery.country = account.account_address[0].country_name;
      objDelivery.account_address_id = account.account_address[0].address_type_id;
    }

    if (account.account_contact_number && account.account_contact_number.length > 0) {
      account.account_contact_number.forEach(element => {
        if (this.isEmail(element.contact_number_value)) {
          objDelivery.delivery_email = element.contact_number_value;
        } else {
          objDelivery.delivery_contact_number = element.contact_number_value;
        }
      });
    }

    return objDelivery
  }

  public isEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    email = email.trim();
    return regex.test(email);
  }

  public getCheckDetailsBy(outletId: string, businessDate: string, table: TableLayout) {
    let params = new HttpParams().append("key_restaurant", outletId)
      .append("key_check_open_date", businessDate)
      .append("key_table_code", table.table_code)
      .append("key_for_pos_layout", "true")

    if (table.table_status == environment.POS_TABLE_STATUS_KOT) {
      params.append("key_kot", "true");
    }
    else {
      params.append("key_check", "true");
    }

    return this.http.get(environment.APIENDPOINT + environment.POS_CHECK, {
      params: params
    }).pipe(
      map(response => {
        if (response && response["pos_checks"].length > 0) {
          return this.mapPosCheck(response["pos_checks"][0]);
        }
      })
    )
  }

  public findBy(checkId: string) {
    return this.http.get(environment.APIENDPOINT + environment.POS_CHECK + '/' + checkId)
      .pipe(
        map(response => {
          if (response && response["pos_check"]) {
            return this.mapPosCheck(response["pos_check"]);
          }
        })
      )
  }

  public mapPosCheck(obj): PosCheck {
    let posCheck = new PosCheck();
    posCheck.is_new = false;
    posCheck.is_dirty = true;
    posCheck.branch_id = obj.branch_id;
    posCheck.check_no = obj.check_no;
    posCheck.check_series_no = obj.check_series_no;
    posCheck.close_cashier_id = obj.close_cashier_id;
    posCheck.close_date = obj.close_date;
    posCheck.close_time = obj.close_time;
    posCheck.company_id = obj.company_id;
    posCheck.covers = obj.covers;
    posCheck.cr_total = obj.cr_total;
    posCheck.currency_id = obj.currency_id;
    posCheck.doc_id_rev = obj.doc_id_rev;
    posCheck.dr_total = obj.dr_total;
    posCheck.freeze_at = obj.freeze_at;
    posCheck.freeze_ind = obj.freeze_ind;
    posCheck.from_table_code = obj.from_table_code;
    posCheck.from_void_check_no = obj.from_void_check_no;
    posCheck.guest_gst_number = obj.guest_gst_number;
    posCheck.guest_id = obj.guest_id;
    posCheck.guest_name = obj.guest_name;
    posCheck.happy_hour_ind = obj.happy_hour_ind;
    posCheck.id = obj.id;
    posCheck.last_action = obj.last_action;
    posCheck.membership_number = obj.membership_number;
    posCheck.open_cashier_id = obj.open_cashier_id;
    posCheck.open_date = obj.open_date;
    posCheck.open_shift = obj.open_shift;
    posCheck.open_time = obj.open_time;
    posCheck.pos_ang_category_id = obj.pos_ang_category_id;
    posCheck.pos_restaurant_id = obj.pos_restaurant_id;
    posCheck.pos_void_reason_id = obj.pos_void_reason_id;
    posCheck.print_cashier_id = obj.print_cashier_id;
    posCheck.print_count = obj.print_count;
    posCheck.print_date = obj.print_date;
    posCheck.print_seq_no = obj.print_seq_no;
    posCheck.print_shift = obj.print_shift;
    posCheck.print_time = obj.print_time;
    posCheck.ref_check_no = obj.ref_check_no;
    posCheck.remarks = obj.remarks;
    posCheck.room_settle_ind = obj.room_settle_ind;
    posCheck.round_off = obj.round_off;
    posCheck.saas_company_id = obj.saas_company_id;
    posCheck.server_id = obj.server_id;
    posCheck.split_check_ind = obj.split_check_ind;
    posCheck.split_count = obj.split_count;
    posCheck.table_code = obj.table_code;
    posCheck.take_away_ind = obj.take_away_ind;
    posCheck.tips = obj.tips;
    posCheck.updated_place = obj.updated_place;
    posCheck.void_user_id = obj.void_user_id;
    posCheck.void_date_time = obj.void_date_time;
    posCheck.void_reason_note = obj.void_reason_note;
    posCheck.debit_card_number = obj?.debit_card_number;
    posCheck.debit_card_reference_id = obj?.debit_card_reference_id;

    if (obj.pos_ang_category && obj.pos_ang_category.pos_ang_category_translations && obj.pos_ang_category.pos_ang_category_translations.length > 0) {
      posCheck.pos_ang_category = obj.pos_ang_category.pos_ang_category_translations[0].description;
    } else {
      posCheck.pos_ang_category = "";
    }

    if (obj.pos_restaurant && obj.pos_restaurant.pos_restaurant_translations && obj.pos_restaurant.pos_restaurant_translations.length > 0) {
      posCheck.restaurant_name = obj.pos_restaurant.pos_restaurant_translations[0].description;
    } else {
      posCheck.restaurant_name = "";
    }

    if (obj.print_cashier && obj.print_cashier.full_name) {
      posCheck.print_cashier_name = obj.print_cashier.full_name;
    } else {
      posCheck.print_cashier_name = "";
    }

    if (obj.server && obj.server.full_name) {
      posCheck.server_name = obj.server.full_name;
    } else {
      posCheck.server_name = "";
    }

    this.mapPosCheckDetails(obj, posCheck);
    this.mapPosCheckCharges(obj, posCheck);
    this.mapPosCheckSettlements(obj, posCheck);
    return posCheck;
  }

  mapPosCheckDetails(obj, posCheck: PosCheck) {
    if (obj.pos_check_details && obj.pos_check_details.length > 0) {
      obj.pos_check_details.forEach(element => {
        let posCheckDetail = new PosCheckDetail();
        posCheckDetail.id = element.id;
        posCheckDetail.is_dirty = true;
        posCheckDetail.pos_item_id = element.pos_item_id;
        posCheckDetail.shift = element.shift;
        posCheckDetail.business_date = element.business_date;
        posCheckDetail.system_time = element.system_time;
        posCheckDetail.pos_restaurant_id = element.pos_restaurant_id;
        posCheckDetail.service = element.service;
        posCheckDetail.charge_id = element.charge_id;
        posCheckDetail.kot_no = element.kot_no;
        posCheckDetail.kot_print_ind = element.kot_print_ind;
        posCheckDetail.kot_print_count = element.kot_print_count;
        posCheckDetail.void_quantity = element.void_quantity;
        posCheckDetail.void_no = element.void_no;
        posCheckDetail.void_by_id = element.void_by_id;
        posCheckDetail.pos_void_reason_id = element.pos_void_reason_id;
        posCheckDetail.void_reason_note = element.void_reason_note;
        posCheckDetail.void_at = element.void_at;
        posCheckDetail.void_place = element.void_place;
        posCheckDetail.last_action = element.last_action;
        posCheckDetail.item_description = element.item_description;
        posCheckDetail.pos_menu_id = element.pos_menu_id;
        posCheckDetail.pos_kitchen_id = element.pos_kitchen_id;
        posCheckDetail.serial_number = element.serial_number;
        posCheckDetail.remark = element.remark;
        posCheckDetail.quantity = element.quantity;
        posCheckDetail.rate = element.rate;
        posCheckDetail.amount = +element.amount;
        posCheckDetail.mrp = element.mrp;
        posCheckDetail.currency_id = element.currency_id;
        posCheckDetail.pos_item_size = element.pos_item_size;
        posCheckDetail.pos_item_type = element.pos_item_type;
        posCheckDetail.check_series_no = element.check_series_no;
        posCheckDetail.fnb_series_no = element.fnb_series_no;
        posCheckDetail.pos_item_name_on_check = element.pos_item_name_on_check;
        posCheckDetail.do_not_print_price_ind = element.do_not_print_price_ind;
        posCheckDetail.freeze_ind = element.freeze_ind;
        posCheckDetail.freeze_at = element.freeze_at;
        posCheckDetail.updated_place = element.updated_place;
        posCheckDetail.lock_version = element.lock_version;

        posCheckDetail.item_status = element.last_action == environment.LAST_ACTION_POSTED ? environment.STATUS_KOT : environment.STATUS_VOIDED;

        if (element.charge) {
          let objCharge = new Charge(
            element.charge.id,
            element.charge.code,
            element.charge.payment_ind,
            element.charge.account_code,
            element.charge.tax_ind,
            element.charge.dr_cr,
            element.charge.settlement_process_code,
            element.charge.print_chage_group_code,
            element.charge.department_code,
            element.charge.allowance_allowed_ind,
            element.charge.hsn_sac_code,
            element.charge.inclusive_of_tax_ind,
            element.charge.charge_translations && element.charge.charge_translations.length > 0 ? element.charge.charge_translations[0].description : "",
            element.charge.tax_charge_tax_links
          )
          posCheckDetail.charge = objCharge;
        }

        if (element.pos_check_detail_modifiers && element.pos_check_detail_modifiers.length > 0) {
          element.pos_check_detail_modifiers.forEach(obj => {
            let posCheckDetailModifier = new PosCheckDetailModifier();

            posCheckDetailModifier.id = obj.id;
            posCheckDetailModifier.pos_check_detail_id = obj.pos_check_detail_id;
            posCheckDetailModifier.pos_modifier_id = obj.pos_modifier_id;
            posCheckDetailModifier.serial_number = obj.serial_number;
            posCheckDetailModifier.charged_ind = obj.charged_ind;
            posCheckDetailModifier.currency_id = obj.currency_id;
            posCheckDetailModifier.amount = +obj.amount;
            posCheckDetailModifier.modifier_name_on_check = obj.modifier_name_on_check;
            posCheckDetailModifier.freeze_ind = obj.freeze_ind;
            posCheckDetailModifier.freeze_at = obj.freeze_at;
            posCheckDetailModifier.updated_place = obj.updated_place;

            if (obj.pos_modifier) {
              let posModifier = new PosModifier(
                obj.pos_modifier.id,
                obj.pos_modifier.code,
                obj.pos_modifier.pos_modifier_group_id,
                +obj.pos_modifier.modifier_price,
                obj.pos_modifier.modifier_image,
                obj.pos_modifier.currency_id,
                obj.pos_modifier.pos_modifier_translations && obj.pos_modifier.pos_modifier_translations.length > 0 ? obj.pos_modifier.pos_modifier_translations[0].description : ""
              );

              posCheckDetailModifier.pos_modifier = posModifier;
            }
            posCheckDetail.pos_check_detail_modifiers.push(posCheckDetailModifier);
          });
        } else {
          posCheckDetail.pos_check_detail_modifiers = [];
        }
        posCheck.pos_check_details.push(posCheckDetail);
      });
    }
  }

  mapPosCheckCharges(obj, posCheck: PosCheck) {
    if (obj.pos_check_charges && obj.pos_check_charges.length > 0) {
      posCheck.pos_check_charges = [];
      obj.pos_check_charges.forEach(element => {
        let posCheckCharge = new PosCheckCharge();
        posCheckCharge.is_new = false;
        posCheckCharge.is_dirty = true;
        posCheckCharge.amount = element.amount;
        posCheckCharge.charge_id = element.charge_id;
        posCheckCharge.currency_id = element.currency_id;

        if (element.charge) {
          posCheckCharge.charge = element.charge;
        }

        if (element.charge && element.charge.charge_translations && element.charge.charge_translations.length > 0) {
          posCheckCharge.description = element.charge.charge_translations[0].description;
        } else {
          posCheckCharge.description = "";
        }

        posCheckCharge.dr_cr = element.dr_cr;
        posCheckCharge.id = element.id;
        posCheckCharge.pos_check_id = element.pos_check_id;
        posCheckCharge.serial_number = element.serial_number;

        if (element.pos_check_charge_taxes && element.pos_check_charge_taxes.length > 0) {
          element.pos_check_charge_taxes.forEach(tax => {
            let objTax = new PosCheckChargeTax();

            objTax.is_new = false;
            objTax.is_dirty = true;
            objTax.amount = +tax.amount;
            objTax.pos_check_charge_id = tax.pos_check_charge_id;
            objTax.charge_id = tax.charge_id;
            objTax.currency_id = tax.currency_id;
            objTax.tax_id = tax.tax_id;
            objTax.tax_percentage = tax.tax_percentage;
            objTax.dr_cr = tax.dr_cr;
            objTax.id = tax.id;
            objTax.serial_number = tax.serial_number;
            if (tax.tax && tax.tax.charge_translations && tax.tax.charge_translations.length > 0) {
              objTax.description = tax.tax.charge_translations[0].description;
            }

            posCheckCharge.pos_check_charge_taxes.push(objTax);
          });
        }

        if (element.pos_check_charge_discounts) {
          element.pos_check_charge_discounts.forEach(chargeDiscount => {
            let objDiscount = new PosCheckChargeDiscount();
            objDiscount.amount = chargeDiscount.amount;
            objDiscount.charge_id = chargeDiscount.charge_id;
            objDiscount.pos_check_charge_id = chargeDiscount.pos_check_charge_id;
            objDiscount.currency_id = chargeDiscount.currency_id;
            objDiscount.dr_cr = chargeDiscount.dr_cr;
            objDiscount.id = chargeDiscount.id;
            objDiscount.pos_discount_id = chargeDiscount.pos_discount_id;
            objDiscount.serial_number = chargeDiscount.serial_number;
            objDiscount.discount_percentage = chargeDiscount.discount_percentage;

            if (chargeDiscount.pos_discount && chargeDiscount.pos_discount.pos_discount_translations && chargeDiscount.pos_discount.pos_discount_translations.length > 0) {
              objDiscount.description = chargeDiscount.pos_discount.pos_discount_translations[0].description;

              if (chargeDiscount.charge && chargeDiscount.charge.charge_translations && chargeDiscount.charge.charge_translations.length > 0) {
                objDiscount.description += " on " + chargeDiscount.charge.charge_translations[0].description;
              }
            }

            posCheckCharge.pos_check_charge_discounts.push(objDiscount);
          });
        }

        posCheck.pos_check_charges.push(posCheckCharge);
      });
    }
  }

  mapPosCheckSettlements(obj, posCheck: PosCheck) {
    if (obj.pos_check_settlements && obj.pos_check_settlements.length > 0) {
      posCheck.pos_check_settlements = [];
      obj.pos_check_settlements.forEach(element => {
        let settlement = new PosCheckSettlement();
        settlement.id = element.id;
        settlement.amount = element.amount;
        settlement.card_no = element.card_no;
        settlement.is_new = false;
        settlement.is_dirty = true;
        settlement.charge_id = element.charge_id;
        settlement.currency_id = element.currency_id;
        settlement.settlement_type = element.settlement_type;
        settlement.dr_cr = element.dr_cr;
        settlement.allowance_ind = element.allowance_ind;

        if (element.charge && element.charge.charge_translations && element.charge.charge_translations.length > 0) {
          settlement.description = element.charge.charge_translations[0].description;
        } else {
          settlement.description = "";
        }
        posCheck.pos_check_settlements.push(settlement);
      });
    }
  }

  updateOrder(currentOrder: PosCheck) {
    let user = this.authService.userValue;

    if (!currentOrder.saas_company_id) {
      currentOrder.assignUserProperties(user);
    }

    const chargeList = [...new Set(currentOrder.pos_check_details.map(item => item.charge_id))];

    this.calculateCharges(currentOrder, chargeList);
    this.calculateChargeTaxes(currentOrder);
    this.calculateDrTotal(currentOrder);
    return currentOrder;
  }

  public calculateCharges(currentOrder: PosCheck, chargeList: number[]) {
    if (chargeList.length > 0) {
      chargeList.forEach(chargeId => {
        let isChargeAlreadyPresent = currentOrder.pos_check_charges.filter(x => { return x.charge_id == chargeId });

        let itemArray = currentOrder.pos_check_details.filter(t => {
          return t.charge_id === chargeId;
        });

        let sum = 0;
        itemArray.forEach(item => {
          if (item.last_action == environment.LAST_ACTION_POSTED) {
            sum += +item.amount;
          }
        });

        if (isChargeAlreadyPresent.length > 0) {
          if (itemArray && itemArray.length > 0) {
            if (sum > 0) {
              isChargeAlreadyPresent[0].amount = +sum.toFixed(this.decimalPlace);
              isChargeAlreadyPresent[0].destroy = 0;
            }
            else {
              isChargeAlreadyPresent[0].destroy = 1;
            }
          }
        } else {
          if (itemArray && itemArray.length > 0) {
            let chargeDetail: Charge = this.chargeService.getChargeById(chargeId);
            if (chargeDetail) {
              let objCharge = new PosCheckCharge();
              objCharge.charge_id = chargeId;

              if (sum > 0) {
                objCharge.amount = +sum.toFixed(this.decimalPlace);
                objCharge.destroy = 0;
              }
              else {
                objCharge.destroy = 1;
              }

              objCharge.description = chargeDetail.description;
              objCharge.dr_cr = chargeDetail.dr_cr;
              objCharge.currency_id = PointerService.getLocalCurrencyId();

              currentOrder.pos_check_charges.push(objCharge);
            }
          }
        }
      });
    } else {
      currentOrder.pos_check_charges = [];
    }
  }

  public calculateChargeTaxes(currentOrder: PosCheck) {
    if (currentOrder.pos_check_charges.length > 0) {
      currentOrder.pos_check_charges.forEach(charge => {
        this.getChargeTax(charge);
      })
    }
  }

  public getChargeTax(posCheckCharge: PosCheckCharge) {
    let chargeDetail = this.chargeService.getChargeById(posCheckCharge.charge_id);

    if (chargeDetail && chargeDetail.tax_charge_tax_links && chargeDetail.tax_charge_tax_links.length > 0) {
      if (posCheckCharge.pos_check_charge_taxes && posCheckCharge.pos_check_charge_taxes.length > 0) {
        posCheckCharge.pos_check_charge_taxes.forEach(posCheckChargeTax => {
          let taxList = chargeDetail.tax_charge_tax_links.filter(x => x.id == posCheckChargeTax.tax_id);

          if (taxList.length > 0) {
            if (taxList[0].tax_amount && +taxList[0].tax_amount > 0) {
              posCheckChargeTax.amount = +taxList[0].tax_amount.toFixed(this.decimalPlace);
            }
            else {
              if (posCheckCharge.pos_check_charge_discounts && posCheckCharge.pos_check_charge_discounts.length > 0) {
                // calculate tax after discount
                let amountAfterDiscount = 0;
                let totalDiscount = 0;
                posCheckCharge.pos_check_charge_discounts.forEach(discount => {
                  totalDiscount += +discount.amount;
                });

                amountAfterDiscount = +(posCheckCharge.amount - totalDiscount);
                posCheckChargeTax.amount = +(+amountAfterDiscount * +taxList[0].tax_percentage / 100).toFixed(this.decimalPlace);
              } else {
                posCheckChargeTax.amount = +(+posCheckCharge.amount * +taxList[0].tax_percentage / 100).toFixed(this.decimalPlace);
              }
            }

            //if PosCheckCharge is destroy == 1 then remove it's taxes as well
            if (posCheckCharge.is_dirty && posCheckCharge.destroy == 1) {
              posCheckChargeTax.destroy = 1;
            } else {
              posCheckChargeTax.destroy = 0;
            }
          }
        })
      } else {
        let taxList = [];
        chargeDetail.tax_charge_tax_links.forEach(tax => {
          let objTax = new PosCheckChargeTax();
          objTax.currency_id = PointerService.getLocalCurrencyId();
          objTax.tax_id = tax.id;
          objTax.dr_cr = tax.dr_cr;
          objTax.description = tax.tax_name;
          objTax.tax_percentage = tax.tax_percentage;

          if (tax.tax_amount != null && +tax.tax_amount > 0) {
            objTax.amount = +tax.tax_amount.toFixed(this.decimalPlace);
          }
          else {
            if (posCheckCharge.pos_check_charge_discounts && posCheckCharge.pos_check_charge_discounts.length > 0) {
              // calculate tax after discount
              let amountAfterDiscount = 0;
              let totalDiscount = 0;
              posCheckCharge.pos_check_charge_discounts.forEach(discount => {
                totalDiscount += discount.amount;
              });

              amountAfterDiscount = +(posCheckCharge.amount - totalDiscount);
              objTax.amount = +(+amountAfterDiscount * +tax.tax_percentage / 100).toFixed(this.decimalPlace);
            } else {
              objTax.amount = +(+posCheckCharge.amount * +tax.tax_percentage / 100).toFixed(this.decimalPlace);
            }
          }

          if (posCheckCharge.destroy == 1) {
            objTax.destroy = 1;
          } else {
            objTax.destroy = 0;
          }
          taxList.push(objTax);
        });
        posCheckCharge.pos_check_charge_taxes = taxList;
      }
    }
  }

  public calculateDrTotal(currentOrder: PosCheck) {
    let totalAmount = 0;
    currentOrder.pos_check_charges.forEach(charge => {
      if (charge.destroy != 1) {
        if (typeof (charge.amount) != 'undefined' || charge.amount != null) {
          if (charge.dr_cr === environment.DR_IND) {
            totalAmount += +charge.amount;
          }
        }
      }

      charge.pos_check_charge_discounts.forEach(discount => {
        if (discount.destroy != 1) {
          if (typeof (discount.amount) != 'undefined' || discount.amount != null) {
            totalAmount -= +discount.amount;
          }
        }
      })

      charge.pos_check_charge_taxes.forEach(tax => {
        if (tax.destroy != 1) {
          if (typeof (tax.amount) != 'undefined' || tax.amount != null) {
            totalAmount += +tax.amount;
          }
        }
      })
    });

    // currentOrder.dr_total = +currentOrder.tips.toFixed(this.decimalPlace) + +totalAmount.toFixed(this.decimalPlace);
    currentOrder.dr_total = +totalAmount.toFixed(this.decimalPlace);
  }

  public saveBill(posCheck: PosCheck) {
    let json = PosCheck.createJson(posCheck);
    if (posCheck.is_new) {
      return this.http.post(environment.APIENDPOINT + environment.POS_CHECK,
        { pos_check: json }
      ).pipe(catchError(errorRes => {
        let errorMessage = environment.GENERAL_API_ERROR_MESSAGE;
        if (errorRes.pos_check && errorRes.pos_check.errors && errorRes.pos_check.errors.length > 0) {
          errorMessage = errorRes.pos_check.errors[0];
        }
        else if (errorRes.status === 0) {
          errorMessage = "Api service is down";
        }
        return throwError(errorMessage);
      }), tap(response => {
        if (response && response["pos_check"]) {
          return this.mapPosCheck(response["pos_check"]);
        }
      }));
    }
    else {
      return this.http.put(environment.APIENDPOINT + environment.POS_CHECK + '/' + posCheck.id,
        { pos_check: json }
      ).pipe(catchError(errorRes => {
        let errorMessage = environment.GENERAL_API_ERROR_MESSAGE;
        if (errorRes.pos_check && errorRes.pos_check.errors && errorRes.pos_check.errors.length > 0) {
          errorMessage = errorRes.pos_check.errors[0];
        }
        else if (errorRes.status === 0) {
          errorMessage = "Api service is down";
        }
        return throwError(errorMessage);
      }), tap(response => {
        if (response && response["pos_check"]) {
          return this.mapPosCheck(response["pos_check"]);
        }
      }));
    }
  }

  public settleBill(posCheck: PosCheck) {
    let json = PosCheck.createSettleCheckJson(posCheck);
    return this.http.put(environment.APIENDPOINT + environment.SETTLE_CHECK,
      { id: posCheck.id, pos_check: json }
    ).pipe(catchError(errorRes => {
      let errorMessage = environment.GENERAL_API_ERROR_MESSAGE;
      if (errorRes.pos_check && errorRes.pos_check.errors && errorRes.pos_check.errors.length > 0) {
        errorMessage = errorRes.pos_check.errors[0];
      }
      else if (errorRes.status === 0) {
        errorMessage = "Api service is down";
      }
      return throwError(errorMessage);
    }), tap(resData => {

    }));
  }

  public voidBill(posCheck: PosCheck) {
    let json = PosCheck.createJson(posCheck);
    return this.http.put(environment.APIENDPOINT + environment.VOID_CHECK,
      { id: posCheck.id, pos_check: json }
    ).pipe(catchError(errorRes => {
      let errorMessage = environment.GENERAL_API_ERROR_MESSAGE;
      if (errorRes.pos_check && errorRes.pos_check.errors && errorRes.pos_check.errors.length > 0) {
        errorMessage = errorRes.pos_check.errors[0];
      }
      else if (errorRes.status === 0) {
        errorMessage = "Api service is down";
      }
      return throwError(errorMessage);
    }), tap(resData => {

    }));
  }

  public printBill(posCheck: PosCheck, currentShift: number) {
    let json = {
      "id": posCheck.id,
      "shift_no": currentShift,
      "dr_total": posCheck.dr_total,
      "round_off": posCheck.round_off
    };

    return this.http.put(environment.APIENDPOINT + environment.PRINT_CHECK,
      { id: posCheck.id, pos_check: json }
    ).pipe(catchError(errorRes => {
      let errorMessage = environment.GENERAL_API_ERROR_MESSAGE;
      if (errorRes.pos_check && errorRes.pos_check.errors && errorRes.pos_check.errors.length > 0) {
        errorMessage = errorRes.pos_check.errors[0];
      }
      else if (errorRes.status === 0) {
        errorMessage = environment.API_IS_NOT_WORKING;
      }
      return throwError(errorMessage);
    }), tap(resData => {
    }));
  }

  public addTips(posCheck: PosCheck) {
    let json = {
      "id": posCheck.id,
      "tips": posCheck.tips
    };

    return this.http.put(environment.APIENDPOINT + environment.POS_CHECK + "/" + posCheck.id,
      { id: posCheck.id, pos_check: json }
    ).pipe(catchError(errorRes => {
      let errorMessage = "An unknown error occurred!"
      if (errorRes.error && errorRes.error.pos_check && errorRes.error.pos_check.errors && errorRes.error.pos_check.errors.length > 0) {
        errorMessage = errorRes.error.pos_check.errors[0];
      } else if (errorRes.status === 0) {
        errorMessage = "Api service is down";
      } else if (errorRes.error) {
        errorMessage = errorRes.error;
      }
      return throwError(errorMessage);
    }), tap(resData => {

    }));

  }

  transferTable(posCheck: PosCheck, newTable: string) {
    let json = {
      "id": posCheck.id,
      "table_code": newTable,
      "from_table_code": posCheck.table_code
    };

    return this.http.put(environment.APIENDPOINT + environment.TABLE_TRANSFER,
      { id: posCheck.id, pos_check: json }
    ).pipe(catchError(errorRes => {
      let errorMessage = "An unknown error occurred!"
      if (errorRes.pos_check && errorRes.pos_check.errors && errorRes.pos_check.errors.length > 0) {
        errorMessage = errorRes.pos_check.errors[0];
      } else if (errorRes.status === 0) {
        errorMessage = "Api service is down";
      } else if (errorRes.error) {
        errorMessage = errorRes.error;
      }
      return throwError(errorMessage);
    }), tap(resData => {

    }));
  }
}
