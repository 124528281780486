import { PosModifier } from './pos-modifier.model';

export class PosCheckDetailModifier {
  private _id: number;
  private _pos_check_detail_id: number;
  private _pos_modifier_id: number;
  private _serial_number: number;
  private _charged_ind: boolean;
  private _currency_id: number;
  private _modifier_name_on_check: string;
  private _freeze_ind: false;
  private _freeze_at: Date;
  private _updated_place: string;
  private _pos_modifier: PosModifier;

  public get id(): number {
    return this._id;
  }
  public set id(value: number) {
    this._id = value;
  }
  
  public get pos_check_detail_id(): number {
    return this._pos_check_detail_id;
  }
  public set pos_check_detail_id(value: number) {
    this._pos_check_detail_id = value;
  }
  
  public get pos_modifier_id(): number {
    return this._pos_modifier_id;
  }
  public set pos_modifier_id(value: number) {
    this._pos_modifier_id = value;
  }
  
  public get serial_number(): number {
    return this._serial_number;
  }
  public set serial_number(value: number) {
    this._serial_number = value;
  }
  
  public get charged_ind(): boolean {
    return this._charged_ind;
  }
  public set charged_ind(value: boolean) {
    this._charged_ind = value;
  }
  
  public get currency_id(): number {
    return this._currency_id;
  }
  public set currency_id(value: number) {
    this._currency_id = value;
  }
  private _amount: number;
  public get amount(): number {
    return this._amount;
  }
  public set amount(value: number) {
    this._amount = value;
  }
  
  public get modifier_name_on_check(): string {
    return this._modifier_name_on_check;
  }
  public set modifier_name_on_check(value: string) {
    this._modifier_name_on_check = value;
  }
  
  public get freeze_ind(): false {
    return this._freeze_ind;
  }
  public set freeze_ind(value: false) {
    this._freeze_ind = value;
  }
  
  public get freeze_at(): Date {
    return this._freeze_at;
  }
  public set freeze_at(value: Date) {
    this._freeze_at = value;
  }
  
  public get updated_place(): string {
    return this._updated_place;
  }
  public set updated_place(value: string) {
    this._updated_place = value;
  }

  public get pos_modifier(): PosModifier {
    return this._pos_modifier;
  }
  public set pos_modifier(value: PosModifier) {
    this._pos_modifier = value;
  }
}