<div class="modal fade" id="logoutModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ 'HEADER.LOGOUT' | translate }}</h5>
        <button type="button" #closeLogoutModal class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <h2 class="title_cls mb-0">{{ 'LOGOUT.LOGOUT_MSG' | translate }}</h2>
      </div>
      <div class="modal-footer">
        <div class="button_blk">
          <button type="button" class="btn lg_gry_btn" data-bs-dismiss="modal">
            <i class="fas fa-times-circle"></i>{{ 'LOGOUT.NO' | translate }}
          </button>
          <button type="button" class="btn gry_btn" (click)="onLogout()">
            <i class="fas fa-check-circle"></i>{{ 'LOGOUT.YES' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>