import { Injectable } from '@angular/core';
import { OutletSetting } from '../models/outlet-setting.model';
import { PosRestaurant } from '../models/pos-restaurant.model';
import { Shift } from '../models/shift.model.model';
import { User } from '../models/user.model';
import { PointerService } from './pointer.service';
import { PosCheck } from '../models/pos-check.model';
import { OutletBilling } from '../models/outlet-billing.model';
declare var Stimulsoft: any;

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  options: any = new Stimulsoft.Viewer.StiViewerOptions();
  constructor() { }

  isHappyHour(menuId: number, outletId: string): boolean {
    let isHappyHourApplicable: boolean = false;

    const outletSettings: OutletSetting[] = JSON.parse(localStorage.getItem("outletSetting"));
    if (outletSettings.length > 0) {
      let selectedOutletSetting = outletSettings.filter(x => x.pos_restaurant_id == +outletId);

      if (selectedOutletSetting.length > 0) {
        if (selectedOutletSetting[0].happy_hour_ind) {
          const restaurants: PosRestaurant[] = JSON.parse(localStorage.getItem("posRestaurant"));

          if (restaurants.length > 0) {
            let selectedRestaurant = restaurants.filter(x => x.id == +outletId);
            if (selectedRestaurant.length > 0 && selectedRestaurant[0].pos_menu_restaurants && selectedRestaurant[0].pos_menu_restaurants.length > 0) {
              let posMenuRestaurant = selectedRestaurant[0].pos_menu_restaurants.filter(a => a.pos_menu_id === menuId);

              if (posMenuRestaurant.length > 0) {
                let objRestaurant = posMenuRestaurant[0];
                if (objRestaurant.happy_hour_from_date && objRestaurant.happy_hour_to_date) {
                  let businessDate = PointerService.getBusinessDate();
                  let isValidDate = this.dateCheck(objRestaurant.happy_hour_from_date, objRestaurant.happy_hour_to_date, businessDate);

                  if (isValidDate) {
                    let isValidTime = this.timeCheck(objRestaurant.happy_hour_from_time, objRestaurant.happy_hour_to_time, new Date());

                    if (isValidTime) {
                      if (objRestaurant.happy_hour_dow) {
                        let dOWArray = objRestaurant.happy_hour_dow.split('');
                        let currentDate = new Date(businessDate);
                        let dayOfWeek = currentDate.getDay();

                        if (dOWArray[dayOfWeek] == "Y") {
                          isHappyHourApplicable = true;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return isHappyHourApplicable;
  }

  dateCheck(fromDate: Date, toDate: Date, checkWith: Date): boolean {
    let startDate: Date = new Date(fromDate);
    let endDate: Date = new Date(toDate);
    let compareDate: Date = new Date(checkWith);

    if ((compareDate.setHours(0, 0, 0, 0) <= endDate.setHours(0, 0, 0, 0) && compareDate.setHours(0, 0, 0, 0) >= startDate.setHours(0, 0, 0, 0))) {
      return true;
    }
    return false;
  }

  timeCheck(fromTime: Date, toTime: Date, checkWith: Date): boolean {
    let startDate: Date = new Date(fromTime);
    let endDate: Date = new Date(toTime);
    let compareDate: Date = new Date(checkWith);

    if (compareDate.getTime() <= endDate.getTime() && compareDate.getTime() >= startDate.getTime()) {
      return true;
    }
    return false;
  }

  getCurrentUserShiftNumber(outletId: string): number {
    let shiftNumber = 1;
    const loadedUser: User = JSON.parse(localStorage.getItem("userData"));

    if (loadedUser) {
      const shiftData: Shift[] = JSON.parse(localStorage.getItem("userShiftData"));

      if (shiftData.length > 0) {
        let currentShift = shiftData.filter(x => x.shift_user_id == +loadedUser.id && x.pos_restaurant_id == +outletId);

        if (currentShift && currentShift.length > 0) {
          return currentShift[0].shift_no;
        }
      }
    }
    return shiftNumber;
  }

  hasFunctionalRights(code: string): boolean {
    let hasRights = false;

    const userData: User = JSON.parse(localStorage.getItem("userData"));
    if (userData) {
      if (userData.functional_rights && userData.functional_rights.length > 0) {
        let functionalRight = userData.functional_rights.filter(x => x == code);

        if (functionalRight.length > 0) {
          hasRights = true;
        }
      }
    }
    return hasRights;
  }

  printBill(objPosCheck: PosCheck, isDuplicateCopy = false) {
    let report = Stimulsoft.Report.StiReport.createNewReport();
    this.options.appearance.fullScreenMode = true;
    this.options.toolbar.displayMode = Stimulsoft.Viewer.StiToolbarDisplayMode.Separated;
    let dataSet = new Stimulsoft.System.Data.DataSet();
    let dataSet2 = new Stimulsoft.System.Data.DataSet();
    let dataSet3 = new Stimulsoft.System.Data.DataSet();
    //let dataSet4 = new Stimulsoft.System.Data.DataSet();

    let json = PosCheck.createPrintCheckJson(objPosCheck);
    
    dataSet.readJson(json);
    dataSet2.readJson(json["pos_check_charges"]);
    dataSet3.readJson(json["posCheck.pos_check_settlements"]);

    report.loadFile('/public/mrt/bill_print.mrt');
    report.regData(dataSet, "pos_check", { pos_check: json });
    report.regData(dataSet.pos_check, "pos_check", dataSet);
    report.regData(dataSet2.pos_check_charges, "pos_check_charges", dataSet2);
    report.regData(dataSet3.pos_check_settlements, "pos_check_settlements", dataSet3);

    const billingHeaderData: OutletBilling[] = JSON.parse(localStorage.getItem("outletBillingHeader"));
    const outletBilling = billingHeaderData.filter(a => a.pos_restaurant_id === objPosCheck.pos_restaurant_id);

    // let objCheckBilling = {};
    // if (outletBilling.length > 0) {
    //   objCheckBilling = {
    //     "address1": outletBilling[0].address1,
    //     "address2": outletBilling[0].address2,
    //     "address3": outletBilling[0].address3,
    //     "check_header_line1": outletBilling[0].check_header_line1,
    //     "check_header_line2": outletBilling[0].check_header_line2,
    //     "cin_number": outletBilling[0].cin_number,
    //     "declaration": outletBilling[0].declaration,
    //     "fssai_number": outletBilling[0].fssai_number,
    //     "gst_declaration": outletBilling[0].gst_declaration,
    //     "gst_number": outletBilling[0].gst_number,
    //     "hsn_number": outletBilling[0].hsn_number,
    //     "notes": outletBilling[0].notes,
    //     "pan_number": outletBilling[0].pan_number,
    //     "place_of_supply": outletBilling[0].place_of_supply,
    //     "remarks": outletBilling[0].remarks,
    //     "restaurant_contact": outletBilling[0].restaurant_contact,
    //     "restaurant_email": outletBilling[0].restaurant_email,
    //     "restaurant_name_on_check": outletBilling[0].restaurant_name_on_check ? outletBilling[0].restaurant_name_on_check : objPosCheck.restaurant_name,
    //     "state_code": outletBilling[0].state_code,
    //     "tin_number": outletBilling[0].tin_number,
    //     "payment_qr": outletBilling[0].upi_url + "&am=" + objPosCheck.dr_total + "&cu=INR&tn=" + objPosCheck.check_no,
    //     "zip": outletBilling[0].zip,
    //   }
    // }

    if (report.dictionary.variables.getByName("Declaration") && outletBilling[0].declaration) {
      report.dictionary.variables.getByName("Declaration").valueObject = outletBilling[0].declaration;
    }

    if (report.dictionary.variables.getByName("GST_Number") && outletBilling[0].gst_number) {
      report.dictionary.variables.getByName("GST_Number").valueObject = outletBilling[0].gst_number;
    }

    if (report.dictionary.variables.getByName("PAN_Number") && outletBilling[0].pan_number) {
      report.dictionary.variables.getByName("PAN_Number").valueObject = outletBilling[0].pan_number;
    }

    if (report.dictionary.variables.getByName("HSN_Number") && outletBilling[0].hsn_number) {
      report.dictionary.variables.getByName("HSN_Number").valueObject = outletBilling[0].hsn_number;
    }

    if (report.dictionary.variables.getByName("Address1") && outletBilling[0].address1) {
      report.dictionary.variables.getByName("Address1").valueObject = outletBilling[0].address1;
    }

    if (report.dictionary.variables.getByName("Restaurant_Contact") && outletBilling[0].restaurant_contact) {
      report.dictionary.variables.getByName("Restaurant_Contact").valueObject = outletBilling[0].restaurant_contact;
    }

    if (report.dictionary.variables.getByName("Restaurant_Email") && outletBilling[0].restaurant_email) {
      report.dictionary.variables.getByName("Restaurant_Email").valueObject = outletBilling[0].restaurant_email;
    }

    if (report.dictionary.variables.getByName("Check_Header_Line1") && outletBilling[0].check_header_line1) {
      report.dictionary.variables.getByName("Check_Header_Line1").valueObject = outletBilling[0].check_header_line1;
    }

    if (report.dictionary.variables.getByName("Check_Header_Line2") && outletBilling[0].check_header_line2) {
      report.dictionary.variables.getByName("Check_Header_Line2").valueObject = outletBilling[0].check_header_line2;
    }

    if (report.dictionary.variables.getByName("Duplicate_Copy")) {
      // if (objPosCheck.print_count > 1) {
      if(isDuplicateCopy) {
        report.dictionary.variables.getByName("Duplicate_Copy").valueObject = 1;
      } else {
        report.dictionary.variables.getByName("Duplicate_Copy").valueObject = 0;
      }
    }

    // if(report.dictionary.variables.getByName("Round_Off_Ind")){
    //   if(objPosCheck.round_off) {
    //     report.dictionary.variables.getByName("Round_Off_Ind").valueObject = true;
    //   }
    // }

    if (report.dictionary.variables.getByName("Payment_QR") && outletBilling[0].upi_url) {
      report.dictionary.variables.getByName("Payment_QR").valueObject = outletBilling[0].upi_url + "&am=" + objPosCheck.dr_total + "&cu=INR&tn=" + objPosCheck.check_no;
    }

    if (report.dictionary.variables.getByName("Address2") && outletBilling[0].address2) {
      report.dictionary.variables.getByName("Address2").valueObject = outletBilling[0].address2;
    }

    if (report.dictionary.variables.getByName("Address3") && outletBilling[0].address3) {
      report.dictionary.variables.getByName("Address3").valueObject = outletBilling[0].address3;
    }
    
    if (report.dictionary.variables.getByName("CIN_Number") && outletBilling[0].cin_number) {
      report.dictionary.variables.getByName("CIN_Number").valueObject = outletBilling[0].cin_number;
    }

    if (report.dictionary.variables.getByName("FSSAI_Number") && outletBilling[0].fssai_number) {
      report.dictionary.variables.getByName("FSSAI_Number").valueObject = outletBilling[0].fssai_number;
    }

    if (report.dictionary.variables.getByName("GST_Declaration") && outletBilling[0].gst_declaration) {
      report.dictionary.variables.getByName("GST_Declaration").valueObject = outletBilling[0].gst_declaration;
    }

    if (report.dictionary.variables.getByName("Notes") && outletBilling[0].notes) {
      report.dictionary.variables.getByName("Notes").valueObject = outletBilling[0].notes;
    }

    if (report.dictionary.variables.getByName("Place_Of_Supply") && outletBilling[0].place_of_supply) {
      report.dictionary.variables.getByName("Place_Of_Supply").valueObject = outletBilling[0].place_of_supply;
    }

    if (report.dictionary.variables.getByName("Remarks") && outletBilling[0].remarks) {
      report.dictionary.variables.getByName("Remarks").valueObject = outletBilling[0].remarks;
    }

    if (report.dictionary.variables.getByName("Restaurant_Name_On_Check")) {
      report.dictionary.variables.getByName("Restaurant_Name_On_Check").valueObject = outletBilling[0].restaurant_name_on_check ? outletBilling[0].restaurant_name_on_check : objPosCheck.restaurant_name;
    }

    if (report.dictionary.variables.getByName("State_Code") && outletBilling[0].state_code) {
      report.dictionary.variables.getByName("State_Code").valueObject = outletBilling[0].state_code;
    }

    if (report.dictionary.variables.getByName("TIN_Number") && outletBilling[0].tin_number) {
      report.dictionary.variables.getByName("TIN_Number").valueObject = outletBilling[0].tin_number;
    }

    if (report.dictionary.variables.getByName("Zip") && outletBilling[0].zip) {
      report.dictionary.variables.getByName("Zip").valueObject = outletBilling[0].zip;
    }
    
    return report
  }
}
