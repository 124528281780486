import { Charge } from "./charge.model";

export class PosDiscount {
  constructor(
    public id: number,
    public code: string,  
    public display_in_lookup_ind: boolean,
    public description: string,
    public pos_discount_details?: PosDiscountDetails[]
  ){}
}

export class PosDiscountDetails {
  constructor(
    public id: number,
    public pos_discount_id: number,  
    public charge_id: number,
    public charge_group_id: number,
    public currency_id: number,  
    public discount_percent: number,
    public discount_amount: number,
    public valid_from_date: Date,
    public valid_to_date: Date,
    public valid_from_time: Date,
    public valid_to_time: Date,
    public valid_on_days: Date,
    public charge: Charge
  ) {}
}