import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { NgModule } from '@angular/core';
import { ViewBillComponent } from './view-bill/view-bill.component';
import { PlaceOrderComponent } from './place-order/place-order.component';
import { ANGComponent } from './ang/ang.component';
import { RecallCheckComponent } from './recall-check/recall-check.component';
import { SettlementComponent } from './settlement/settlement.component';
import { KotComponent } from './kot/kot.component';
import { DiscountComponent } from './discount/discount.component';
import { AddressBookComponent } from './address-book/address-book.component'
import { AllowanceComponent } from './allowance/allowance.component';
import { QsrComponent } from './qsr/qsr.component';
import { RoomPostComponent } from './room-post/room-post.component';

const routes: Routes = [
  {
    path: 'billing/:outlet_id',
    canActivate: [AuthGuard],
    children: [
      { path: 'place-order/:table_code', component: PlaceOrderComponent },
      { path: 'qsr/:table_code', component: QsrComponent },
      { path: 'place-order/:table_code/:check_id', component: PlaceOrderComponent },
      { path: 'view-bill/:check_id', component: ViewBillComponent },
      { path: 'ang/:check_id', component: ANGComponent },
      { path: 'settlement/:check_id', component: SettlementComponent },
      { path: 'recall-check/:table_code', component: RecallCheckComponent },
      { path: 'recall-check', component: RecallCheckComponent },
      { path: 'kot', component: KotComponent},
      { path: 'discount/:check_id', component: DiscountComponent },
      { path: 'allowance/:check_id', component: AllowanceComponent },
      { path: 'address-book', component: AddressBookComponent },
      { path: 'room-post/:check_id', component: RoomPostComponent }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BillingRoutingModule {}