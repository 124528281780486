import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { environment } from 'projects/pos/src/environments/environment';
import { PosCheckDetail } from '../../models/pos-check-detail.model';
import { PosCheck } from '../../models/pos-check.model';
import { PosItemMenu } from '../../models/pos-item-menu.model';
import { PosItemModifier } from '../../models/pos-item-modifier.model';
import { PosMenuHeader } from '../../models/pos-menu-header.model';
import { PosMenuRestaurant } from '../../models/pos-menu-restaurant.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ChargeService } from '../../services/charge.service';
import { CommonService } from '../../services/common.service';
import { OutletService } from '../../services/outlet.service';
import { PointerService } from '../../services/pointer.service';
import { PosCheckService } from '../../services/pos-check.service';
import { PosItemMenuService } from '../../services/pos-item-menu.service';
import { PosRestaurant } from '../../models/pos-restaurant.model';
import { OutletSetting } from '../../models/outlet-setting.model';
import { Charge } from '../../models/charge.model';
import { PosCheckDetailModifier } from '../../models/pos-check-detail-modifier.model';
import { AlertService } from 'projects/pos/src/app/services/alert.service';
import { PosPaymentRestaurant } from '../../models/pos-payment-restaurant.model';
import { PosCheckSettlement } from '../../models/pos-check-settlement.model';
import { User } from '../../models/user.model';

declare function enableMenuHeadTab(): any;
declare var Stimulsoft: any;
declare function initializeKeyboard(event): any;

@Component({
  selector: 'app-qsr',
  templateUrl: './qsr.component.html'
})
export class QsrComponent {
  outletId: string = "";
  defaultMenuId: string = "";
  currentMenuItems: PosItemMenu[] = [];
  posItemModifiers: PosItemModifier[] = [];
  menuHeaders: PosMenuHeader[] = [];
  searchItemList: any[] = [];
  tableCode: string = "";
  posCheck: PosCheck = new PosCheck();
  businessDate: string = "";
  currentUserShift: number;
  selectedItemFromKotWindow: PosCheckDetail;
  isOpenItem = environment.POS_IC_OPEN_ITEM;
  posMenuRestaurants: PosMenuRestaurant[] = [];
  showItemAddOns: boolean = false;
  selectedItemModifier: PosItemModifier[] = [];
  decimalPlace: number = this.pointerService.getDecimalPlaceValue();
  maxChars: number = 150;
  currencySymbol: string = "";
  otherAddOnText: string = "";
  otherAddOnPrice: number = 0;
  openItemText: string = "";
  selectedPayment: PosPaymentRestaurant;
  paymentModeList: PosPaymentRestaurant[] = [];
  options: any = new Stimulsoft.Viewer.StiViewerOptions();
  viewer: any = new Stimulsoft.Viewer.StiViewer(this.options, 'StiViewer', false);
  outletSettingData: OutletSetting[] = JSON.parse(localStorage.getItem("outletSetting"));

  @ViewChild("txtSearchInput") txtSearchInput: ElementRef;
  @ViewChild("scrollMe") private myScrollContainer: ElementRef;
  @ViewChild('search') search: ElementRef;
  @ViewChild('closeOtherAddOnModalBtn') closeOtherAddOn: ElementRef;
  @ViewChild('closeOpenItemModalBtn') closeOpenItem: ElementRef;

  constructor(private posItemMenuService: PosItemMenuService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private posCheckService: PosCheckService,
    private commonService: CommonService,
    private chargeService: ChargeService,
    private outletService: OutletService,
    private pointerService: PointerService,
    private changeDetector: ChangeDetectorRef,
    private alertService: AlertService) { }

  ngOnInit(): void {
    enableMenuHeadTab();

    this.outletId = this.activatedRoute.snapshot.params['outlet_id'];
    this.tableCode = this.activatedRoute.snapshot.params["table_code"];

    if (this.outletSettingData) {
      let currentOutletSetting = this.outletSettingData.filter(a => a.pos_restaurant_id === +this.outletId);

      if (currentOutletSetting.length > 0) {
        this.defaultMenuId = currentOutletSetting[0].default_menu_id.toString();
        this.loadDefaultMenuItems();
      }
    } else {
      this.router.navigate(['/outlet']);
    }

    this.currentUserShift = this.commonService.getCurrentUserShiftNumber(this.outletId);

    if (this.outletId) {
      const outletData: PosRestaurant[] = JSON.parse(localStorage.getItem("posRestaurant"));

      if (!outletData) {
        this.outletService.getOutletDetailsBy(this.outletId, PointerService.getISOBusinessDate())
          .subscribe({
            next: (responseData: any) => {
              const currentOutletData = responseData.filter(a => a.id == +this.outletId);

              if (currentOutletData.length > 0) {
                //this.paymentModeList = currentOutletData[0].pos_payment_restaurants.filter(b => b.active_ind);
                this.paymentModeList = currentOutletData[0].pos_payment_restaurants;
                this.posMenuRestaurants = currentOutletData[0].pos_menu_restaurants;
              }
            },
            error: (error: any) => {
              if (error.errors && error.errors.length > 0) {
                this.alertService.error(error.errors[0]);
              } else {
                this.alertService.error(error);
              }
            }
          });
      } else {
        const currentOutletData = outletData.filter(a => a.id == +this.outletId);

        if (currentOutletData.length > 0) {
          // this.paymentModeList = currentOutletData[0].pos_payment_restaurants.filter(b => b.active_ind);
          this.paymentModeList = currentOutletData[0].pos_payment_restaurants;
          this.posMenuRestaurants = currentOutletData[0].pos_menu_restaurants; 
        }
      }
    }

    this.scrollToBottom();
    this.currencySymbol = PointerService.getLocalCurrencySymbol();
  }

  ngAfterViewInit(): void {
    enableMenuHeadTab();
    this.txtSearchInput.nativeElement.focus();
    this.changeDetector.detectChanges();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    }
    catch (err) { }
  }

  mapMenuHeaderSubheader(): void {
    this.menuHeaders = [...new Map(this.currentMenuItems.map((item) => [item.pos_menu_header_id, item.menu_header])).values()];
  }

  onMenuHeaderSelection(): void {
    enableMenuHeadTab();
  }

  onKeyUp(event): void {
    if (event.target.value) {
      this.searchItemList = this.currentMenuItems.filter(x => x.description.toLowerCase().includes(event.target.value.toLowerCase()));
    } else {
      this.searchItemList = [];
    }
  }

  onGridItemSelection(event): void {
    if (event.selectedRowsData.length > 0) {
      this.onItemSelection(event.selectedRowsData[0]);
      this.searchItemList = [];
      this.txtSearchInput.nativeElement.value = "";
      this.txtSearchInput.nativeElement.focus();
    }
  }

  onItemSelection(item: PosItemMenu): void {
    let isHappyHourApplicable: boolean = false;

    if (item && item.pos_menu_id) {
      //isHappyHourApplicable = this.commonService.isHappyHour(item.pos_menu_id, this.outletId);
    }

    const objItem = this.posCheck.pos_check_details.filter(x => x.pos_item_id === item.pos_item_id && x.is_new);

    if (objItem && objItem.length > 0) {
      objItem[0].quantity += 1;
      this.selectedItemFromKotWindow = objItem[0];
    } else {
      // new item added
      let newItem = new PosCheckDetail();
      newItem.is_new = true;
      newItem.pos_item_id = item.pos_item_id;
      newItem.shift = this.currentUserShift;
      newItem.pos_restaurant_id = +this.outletId;
      newItem.service = item.pos_item.account_code;
      newItem.charge_id = isHappyHourApplicable ? item.happy_hour_charge_id : item.pos_item_charge_id;
      newItem.item_description = isHappyHourApplicable ? item.happy_hour_description : item.description;
      newItem.pos_menu_id = item.pos_menu_id;
      newItem.pos_kitchen_id = item.pos_kitchen_id;
      newItem.serial_number = this.posCheck.pos_check_details.length + 1;
      newItem.quantity = 1;
      newItem.rate = isHappyHourApplicable ? item.happy_hour_price : item.pos_item_price;
      newItem.currency_id = PointerService.getLocalCurrencyId();

      if (item.pos_item && item.pos_item.item_class == environment.POS_IC_OPEN_ITEM) {
        newItem.pos_item_name_on_check = item.name_on_check;
      }

      newItem.do_not_print_price_ind = item.do_not_print_price_on_check_ind;

      if (typeof (newItem.charge_id) != 'undefined' || newItem.charge_id != null) {
        let departmentCode: string = this.outletService.getDepartmentCodeBy(this.outletId);
        let chargeCode: string = departmentCode + item.pos_item.account_code;
        let selectedCharge: Charge = this.chargeService.getChargeByCode(chargeCode);

        newItem.charge_id = selectedCharge.id;
      }

      newItem.pos_item_type = item.pos_item.item_class;

      this.posCheck.pos_check_details.push(newItem);
      this.selectedItemFromKotWindow = newItem;
    }

    this.posCheckService.updateOrder(this.posCheck);
  }

  onKotItemSelection(posCheckDetail: PosCheckDetail) {
    this.selectedItemFromKotWindow = posCheckDetail;
    if (this.selectedItemFromKotWindow.item_status != environment.STATUS_VOIDED) {
      const numericInput: HTMLInputElement = document.getElementById("numericInput") as HTMLInputElement;
      if (numericInput && numericInput.value != "") {
        if (this.selectedItemFromKotWindow.is_new) {
          const objIndex = this.posCheck.pos_check_details.findIndex(x => x == this.selectedItemFromKotWindow);
          if (objIndex > -1) {
            this.posCheck.pos_check_details[objIndex].quantity += +numericInput.value;
            this.posCheckService.updateOrder(this.posCheck);
            numericInput.value = "";
          }
        } else {
          //add same item as new kot item with specified qty

          let newItem = new PosCheckDetail();
          newItem.is_new = true;
          newItem.pos_item_id = posCheckDetail.pos_item_id;
          newItem.shift = this.currentUserShift;
          newItem.pos_restaurant_id = +this.outletId;
          newItem.service = posCheckDetail.service;
          newItem.charge_id = posCheckDetail.charge_id;
          newItem.item_description = posCheckDetail.item_description;
          newItem.pos_menu_id = posCheckDetail.pos_menu_id;
          newItem.pos_kitchen_id = posCheckDetail.pos_kitchen_id;
          newItem.serial_number = this.posCheck.pos_check_details.length + 1;
          newItem.rate = posCheckDetail.rate;
          newItem.currency_id = PointerService.getLocalCurrencyId();
          newItem.pos_item_name_on_check = posCheckDetail.pos_item_name_on_check;
          newItem.do_not_print_price_ind = posCheckDetail.do_not_print_price_ind;
          newItem.charge_id = posCheckDetail.charge_id;
          newItem.pos_item_type = posCheckDetail.pos_item_type;

          newItem.quantity = +numericInput.value;
          this.posCheck.pos_check_details.push(newItem);

          this.posCheckService.updateOrder(this.posCheck);
          numericInput.value = "";
          this.selectedItemFromKotWindow = newItem;
        }
      }
    }
  }

  onQtyMinus(posCheckDetail: PosCheckDetail) {
    this.selectedItemFromKotWindow = posCheckDetail;
    if (this.selectedItemFromKotWindow.item_status != environment.STATUS_VOIDED) {
      if (this.selectedItemFromKotWindow.is_new) {
        const objIndex = this.posCheck.pos_check_details.findIndex(x => x == this.selectedItemFromKotWindow);
        if (objIndex > -1 && this.posCheck.pos_check_details[objIndex].quantity > 1) {
          this.posCheck.pos_check_details[objIndex].quantity -= 1;
          this.posCheckService.updateOrder(this.posCheck);
        }
      }
    }
  }

  onQtyAdd(posCheckDetail: PosCheckDetail) {
    this.selectedItemFromKotWindow = posCheckDetail;
    if (this.selectedItemFromKotWindow.item_status != environment.STATUS_VOIDED) {
      if (this.selectedItemFromKotWindow.is_new) {
        const objIndex = this.posCheck.pos_check_details.findIndex(x => x == this.selectedItemFromKotWindow);
        if (objIndex > -1) {
          this.posCheck.pos_check_details[objIndex].quantity += 1;
          this.posCheckService.updateOrder(this.posCheck);
        }
      }
    }
  }

  onDeleteItem(): void {
    if (this.selectedItemFromKotWindow) {
      const objIndex = this.posCheck.pos_check_details.findIndex(x => x.is_new && x.pos_item_id === this.selectedItemFromKotWindow.pos_item_id);
      if (objIndex > -1) {
        this.posCheck.pos_check_details.splice(objIndex, 1);
        this.posCheckService.updateOrder(this.posCheck);
        this.selectedItemFromKotWindow = null;
        this.showItemAddOns = false;
      }
    }
  }

  onNewRateClick() {
    const numericInput: HTMLInputElement = document.getElementById("numericInput") as HTMLInputElement;
    if (this.selectedItemFromKotWindow && this.selectedItemFromKotWindow.is_new && this.selectedItemFromKotWindow.pos_item_type == this.isOpenItem) {
      if (numericInput && numericInput.value != "") {
        let newRate = numericInput.value;
        const objIndex = this.posCheck.pos_check_details.findIndex(x => x.is_new && x.pos_item_id === this.selectedItemFromKotWindow.pos_item_id);
        if (objIndex > -1) {
          this.posCheck.pos_check_details[objIndex].rate = +newRate;
          this.posCheckService.updateOrder(this.posCheck);
          numericInput.value = "";
        }
      }
    }
  }

  onOpenItemTextChange(): void {
    if (this.selectedItemFromKotWindow && this.selectedItemFromKotWindow.is_new && this.selectedItemFromKotWindow.pos_item_type == this.isOpenItem) {
      const objIndex = this.posCheck.pos_check_details.findIndex(x => x.is_new && x.pos_item_id === this.selectedItemFromKotWindow.pos_item_id);
      if (objIndex > -1) {
        this.posCheck.pos_check_details[objIndex].item_description = this.openItemText;
      }

      this.openItemText = "";
      this.closeOpenItem.nativeElement.click();
    }
  }

  onCoversClick() {
    const numericInput: HTMLInputElement = document.getElementById("numericInput") as HTMLInputElement;
    if (numericInput && numericInput.value != "") {
      this.posCheck.covers = +numericInput.value;
      numericInput.value = "";
    }
  }

  loadDefaultMenuItems(): void {
    if (this.defaultMenuId) {
      let menuItems: PosItemMenu[] = [];

      menuItems = this.loadPosItemsBy(this.defaultMenuId);
      if (menuItems.length > 0) {
        //already map items
      }
      else {
        this.posItemMenuService.getPosItemMenuBy(this.defaultMenuId).subscribe((response) => {
          if (response) {
            let menuItems = response.filter(a => a.pos_menu_id === +this.defaultMenuId);
            if (menuItems.length > 0) {
              this.currentMenuItems = menuItems;
              this.mapMenuHeaderSubheader();
            }
          }
        })
      }
    }

    const itemModifierData: PosItemModifier[] = JSON.parse(localStorage.getItem("itemModifier"));
    if (itemModifierData) {
      this.posItemModifiers = itemModifierData;
    } else {
      this.posItemMenuService.getPosItemModifier().subscribe((data) => {
        this.posItemModifiers = data;
      });
    }
  }

  loadPosItemsBy(selectedMenuId: string): PosItemMenu[] {
    const itemMenuData: PosItemMenu[] = JSON.parse(localStorage.getItem("itemMenu"));
    let menuItems: PosItemMenu[] = [];
    if (itemMenuData) {
      menuItems = itemMenuData.filter(a => a.pos_menu_id === +selectedMenuId);
      if (menuItems.length > 0) {
        this.currentMenuItems = menuItems;
        this.mapMenuHeaderSubheader();
      }
    }
    return menuItems;
  }

  onAddOnClick() {
    this.showItemAddOns = !this.showItemAddOns;
    if (this.showItemAddOns) {
      this.selectedItemModifier = this.posItemModifiers.filter(x => x.pos_item_id === this.selectedItemFromKotWindow.pos_item_id);
    }
  }

  onAddOnSelection(selectedModifier: PosItemModifier) {
    if (this.selectedItemFromKotWindow) {
      const objIndex = this.posCheck.pos_check_details.findIndex(x => x.is_new && x.pos_item_id === this.selectedItemFromKotWindow.pos_item_id);
      if (objIndex > -1) {
        //first check if modifier is already added - if not then add
        const objModifierIndex = this.posCheck.pos_check_details[objIndex].pos_check_detail_modifiers.findIndex(x => x.pos_modifier_id == selectedModifier.pos_modifier_id);

        if (objModifierIndex < 0) {
          //same modifier is not added previously - so adding now
          let objDetailModifier = new PosCheckDetailModifier();
          objDetailModifier.amount = +selectedModifier.pos_modifier.modifier_price;
          objDetailModifier.currency_id = selectedModifier.pos_modifier.currency_id ? selectedModifier.pos_modifier.currency_id : PointerService.getLocalCurrencyId();
          objDetailModifier.modifier_name_on_check = selectedModifier.pos_modifier.modifier_name;
          objDetailModifier.pos_modifier = selectedModifier.pos_modifier;
          objDetailModifier.pos_modifier_id = selectedModifier.pos_modifier_id;
          objDetailModifier.serial_number = this.posCheck.pos_check_details[objIndex].pos_check_detail_modifiers.length + 1;

          this.posCheck.pos_check_details[objIndex].pos_check_detail_modifiers.push(objDetailModifier);
          this.posCheck.pos_check_details[objIndex].amount += +objDetailModifier.amount.toFixed(this.decimalPlace);

          this.posCheckService.updateOrder(this.posCheck);
        }
      }
    }
  }

  removeAddOn(selectedModifier: PosItemModifier): void {
    if (this.selectedItemFromKotWindow && this.selectedItemFromKotWindow.is_new) {
      const objIndex = this.posCheck.pos_check_details.findIndex(x => x.is_new && x.pos_item_id === this.selectedItemFromKotWindow.pos_item_id);
      if (objIndex > -1) {
        const objModifierIndex = this.posCheck.pos_check_details[objIndex].pos_check_detail_modifiers.findIndex(x => x.pos_modifier_id === selectedModifier.pos_modifier_id);
        this.posCheck.pos_check_details[objIndex].pos_check_detail_modifiers.splice(objModifierIndex, 1);
        this.posCheckService.updateOrder(this.posCheck);
      }
    }
  }

  onOtherAddOnSelection(): void {
    let otherAddOnId: number = +environment.OTHER_ADD_ON_ID;
    if (this.selectedItemFromKotWindow) {
      const objIndex = this.posCheck.pos_check_details.findIndex(x => x.is_new && x.pos_item_id === this.selectedItemFromKotWindow.pos_item_id);
      if (objIndex > -1) {
        const objModifierIndex = this.posCheck.pos_check_details[objIndex].pos_check_detail_modifiers.findIndex(x => x.pos_modifier_id == otherAddOnId);
        if (objModifierIndex < 0) {
          //same modifier is not added previously - so adding now
          let objDetailModifier = new PosCheckDetailModifier();
          objDetailModifier.amount = this.otherAddOnPrice;
          objDetailModifier.currency_id = PointerService.getLocalCurrencyId();
          objDetailModifier.modifier_name_on_check = this.otherAddOnText;
          objDetailModifier.pos_modifier_id = otherAddOnId;
          objDetailModifier.serial_number = this.posCheck.pos_check_details[objIndex].pos_check_detail_modifiers.length + 1;
          this.posCheck.pos_check_details[objIndex].pos_check_detail_modifiers.push(objDetailModifier);
          this.posCheck.pos_check_details[objIndex].amount += +objDetailModifier.amount.toFixed(this.decimalPlace);

          this.posCheckService.updateOrder(this.posCheck);
        }
      }

      //reset values of pop-up
      this.otherAddOnPrice = 0;
      this.otherAddOnText = "";
      this.closeOtherAddOn.nativeElement.click();
    }
  }
  //EOF AddOn

  onVirtualKeyboardInput(): void {
    if (this.txtSearchInput.nativeElement.value) {
      this.searchItemList = this.currentMenuItems.filter(x => x.description.toLowerCase().includes(this.txtSearchInput.nativeElement.value.toLowerCase()));
    } else {
      this.searchItemList = [];
    }
  }

  openKeyboard(event): void {
    initializeKeyboard(event.target);
    this.changeDetector.detectChanges();
  }

  onSettlementSelection(settlement): void {
    this.posCheck.pos_restaurant_id = +this.outletId;
    this.posCheck.table_code = this.tableCode;

    this.selectedPayment = settlement;
    if (this.posCheck.pos_check_details && this.posCheck.pos_check_details.length > 0) {
      this.posCheckService.saveBill(this.posCheck).subscribe({
        next: (data) => {
          this.posCheck.id = data["pos_check"].id;
          this.posCheck.check_no = data["pos_check"].check_no;
          this.printBill();
        },
        error: error => {
          this.alertService.error(error);
        }
      })
    } else {
      this.alertService.error(environment.SELECT_ONE_ITEM);
    }
  }

  settleBill() {
    this.posCheck.cr_total = this.posCheck.dr_total;

    let settlement = new PosCheckSettlement();
    settlement.is_new = true;
    //settlement.id = this.posCheck.pos_check_settlements.length + 1;
    settlement.allowance_ind = false;
    settlement.amount = this.posCheck.dr_total;
    settlement.card_no = "XXXX";
    settlement.charge_id = this.selectedPayment.payment_id;
    settlement.currency_id = PointerService.getLocalCurrencyId();
    settlement.description = this.selectedPayment.payment.description;
    settlement.dr_cr = this.selectedPayment.payment.dr_cr;
    settlement.settlement_type = this.selectedPayment.payment.settlement_process_code;
    this.posCheck.pos_check_settlements.push(settlement);
    
    this.posCheckService.settleBill(this.posCheck)
      .subscribe({
        next: (data: any) => {
          this.printSettleBillCopy();
        },
        error: (error: any) => {
          if(error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      });
  }

  onBackClick(): void {
    this.router.navigate(['/table-layout/' + this.outletId]);
  }

  printBill(): void {
    if (this.posCheck && this.posCheck.round_off == null) {
      let roundOffType = this.pointerService.filterValueByCode(environment.POINTER_POS_ROUND_OFF_TYPE);
      let amount = 0, roundOffAmount = 0;

      if (roundOffType != null) {
        if (roundOffType == environment.POS_ROUND_OFF_FLOOR_CODE) {
          amount = Math.floor(this.posCheck.dr_total);
          roundOffAmount = amount - this.posCheck.dr_total;
        }
        else if (roundOffType == environment.POS_ROUND_OFF_BASED_ON_POINT_FIVE_CODE) {
          amount = Math.round(this.posCheck.dr_total);
          roundOffAmount = amount - this.posCheck.dr_total;
        }
        else if (roundOffType == environment.POS_ROUND_OFF_CEILING_CODE) {
          amount = Math.ceil(this.posCheck.dr_total);
          roundOffAmount = amount - this.posCheck.dr_total;
        }
        else {
          amount = Math.round(this.posCheck.dr_total);
          roundOffAmount = amount - this.posCheck.dr_total;
        }
      }

      this.posCheck.round_off = +roundOffAmount.toFixed(this.decimalPlace);
      this.posCheck.dr_total = +amount.toFixed(this.decimalPlace);
    }

    this.posCheckService.printBill(this.posCheck, this.currentUserShift)
      .subscribe({
        next: (data: any) => {
          this.posCheck.print_cashier_name = data.pos_check.print_cashier ? data.pos_check.print_cashier.full_name : "";
          this.posCheck.print_time = data.pos_check.print_time ? data.pos_check.print_time : "";
          this.posCheck.print_count = data.pos_check.print_count;
          this.settleBill();
        },
        error: (error: any) => {
          if(error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
    });
  }

  printSettleBillCopy(): void {
    const outletSettingData: OutletSetting[] = JSON.parse(localStorage.getItem("outletSetting"));
    if (outletSettingData) {
      let currentOutletSetting = outletSettingData.filter(a => a.pos_restaurant_id === +this.outletId);

      if (currentOutletSetting.length > 0) {
        let defaultPrintCount = currentOutletSetting[0].print_check_default_count;
        let duplicatePrintCount = 0;

        let report = Stimulsoft.Report.StiReport.createNewReport();
        let report1 = Stimulsoft.Report.StiReport.createNewReport();
        let kotReport = Stimulsoft.Report.StiReport.createNewReport();

        if (defaultPrintCount > 0) {
          if(this.posCheck.print_count <= 1) {
            //First copy - original check - first time will be called
            report = this.commonService.printBill(this.posCheck);
            kotReport = this.printClientSideKot();

            report.render();
            report.print(false);
            

            defaultPrintCount--;
            duplicatePrintCount++;
          } else {
            // for duplicate copy print
            report = this.commonService.printBill(this.posCheck, true);
            report.render();
            report.print(false);

            defaultPrintCount--;
            duplicatePrintCount++;
          }
          report1 = this.commonService.printBill(this.posCheck, true);
          //kotReport = this.printClientSideKot();
        }

        if(defaultPrintCount > 0) {
          // for duplicate copy print
          setTimeout(function(){
            report1.render();
            report1.print(false);

            defaultPrintCount--;
            duplicatePrintCount++; 

            //if(defaultPrintCount > 0) {
              setTimeout(function(){
                //kotReport.render();
                //kotReport.print(false);
              })
            //}

          }, 2000);
        }
      }
    }

    this.printKot();
    
    //reset check object after print
    this.posCheck = new PosCheck();
    this.selectedPayment = null;
  }

  printKot(): void {
    if (this.outletSettingData) {
      let currentOutletSetting = this.outletSettingData.filter(a => a.pos_restaurant_id === +this.outletId);

      if (currentOutletSetting.length > 0) {
        let printKotAfterSettlement: boolean = currentOutletSetting[0].print_kot_after_settlement_ind;
        let printKot: boolean = currentOutletSetting[0].print_kot_ind;
        
        if(printKot && printKotAfterSettlement) {
          //call kot print program
        } else {
          // do nothing
        }
      }
    }   
  }

  printClientSideKot() {
    const loadedUser: User = JSON.parse(localStorage.getItem("userData"));
    let kotItems: PosCheckDetail[] = this.posCheck.pos_check_details;
    
    //let saasCompanyCode = loadedUser.saas_company_code.toLowerCase();
    //let companyCode = loadedUser.company_code.toLowerCase();
    //let branchCode = loadedUser.branch_code.toLowerCase();
    let mapItems = this.mapKotItemsForPrint(kotItems, this.posCheck, false)

    if(mapItems.length > 0){
      let posCheckObj = { kot_report: mapItems };
      let dataSet = new Stimulsoft.System.Data.DataSet("kot_report");
      dataSet.readJson(posCheckObj);

      let newGuid = Stimulsoft.System.Guid.newGuid().toString().substr(0, 8);
      let report = new Stimulsoft.Report.StiReport();
      //report.loadFile("/reports/"+ saasCompanyCode +"/"+ companyCode +"/"+ branchCode+"/"+ "kot.mrt" + "?n=" + n);

      report.loadFile("/public/mrt/"+ "kot.mrt" + "?n=" + newGuid);
      report.regData(dataSet.kot_report, "kot_report", dataSet);

      return report;
    }
  }

  mapKotItemsForPrint(kotItems: PosCheckDetail[], posCheck: any, reprint: boolean) {
    let posCheckKotList = [];
    let posCheckDetailsArray = [];
    let posCheckDetailItems = [];
    let restaurantName = "";
    let voidLabel = "";
    let serverName = "";
    let voidDash = "";
    let voidReason = "";
    if(posCheck.server_name) {
      serverName = posCheck.server_name;
    }
    
    if(posCheck.pos_restaurant_name) {
      restaurantName = posCheck.pos_restaurant_name;
    }

    if(kotItems.length > 0) {
      posCheckDetailItems = kotItems;
    }
    // else {
    //   posCheckDetailItems = this.posCheck.pos_check_details
    // }

    if(posCheckDetailItems.length > 0) {
      posCheckDetailItems.forEach(item => {
        let itemDetails = {
          kot_no: item.kot_no,
          pos_kitchen_id: item.pos_kitchen_id,
          kitchen_name: item.pos_kitchen && item.pos_kitchen.pos_kitchen_translations && item.pos_kitchen.pos_kitchen_translations.length > 0 ? item.pos_kitchen.pos_kitchen_translations[0]["description"] : "",
          system_time: item.system_time,
          business_date: item.business_date,
          quantity: item.quantity,
          void_reason:voidReason,
          item_description: item.pos_item_name_on_check ? item.pos_item_name_on_check : item.item_description
        }

        let modifierText = "";
        if(item.pos_check_detail_modifiers.length > 0){
          item.pos_check_detail_modifiers.forEach(modifier => {
            if(modifier.modifier_name_on_check){
              modifierText += modifier.modifier_name_on_check + ", "
            }
          });
        }

        item["item_description"] = item.item_description + "\n- " + modifierText.replace(/,(\s+)?$/, '');

        posCheckDetailsArray.push(itemDetails);
      });
    
      if (reprint) {
        voidLabel = "DUPLICATE COPY"
      }
      else {
        voidLabel = "";
      }

      posCheckKotList.push({
        restaurant_name: restaurantName,
        check_no: posCheck.check_no,
        table_code: posCheck.table_code,
        covers: posCheck.covers,
        server: serverName,
        parcel: posCheck.take_away_ind == true ? "Yes" : "No",
        guest_name: posCheck.guest_name,
        void_kot: voidLabel,
        void_dash: voidDash,
        pos_check_details: posCheckDetailsArray
      });
    }
    return posCheckKotList;
  }

  onRecallCheckClick(): void {
    this.router.navigate(['/billing/' + this.outletId + '/recall-check']);
  }
}
