import { Injectable, NgZone } from '@angular/core';
import * as ActionCable from 'actioncable';
import { environment } from 'projects/pos/src/environments/environment';
import { User } from '../models/user.model';
import { TableLayout } from '../models/table-layout.model';

@Injectable({
  providedIn: 'root'
})
export class ActionCableService {
  private consumer: any;
  constructor() {}

  subscribeTableChannel() {
    const loadedUser: User = JSON.parse(localStorage.getItem("userData"));
    
    this.consumer = ActionCable.createConsumer(environment.ACTION_CABLE_ENPOINT + loadedUser.token);
    console.log("Trying connection");
    
    this.consumer.subscriptions.create(environment.TABLE_STATUS_CHANNEL, {  
      connected() {
        console.log("Subscription is ready for use");
      },
      disconnected() {
        console.log("Service terminated by WB server");
      },
      received(data) {
        console.log("This is the data received: ", data);
        if(data) { 
          let tableData: TableLayout[] = JSON.parse(localStorage.getItem('tableLayout'));
          
          if(tableData) {
            let selectedTable: TableLayout[] = tableData.filter(x => x.id == data.id && x.table_code == data.table_code);
    
            if(selectedTable.length > 0) {
              selectedTable[0].table_status = data.table_status;
              selectedTable[0].pos_check_id = data.pos_check_id;
              localStorage.setItem('tableLayout', JSON.stringify(tableData));
            }
          }
        }
      }
    })
  }

  subscribeKotChannel(outletId: string) {
    const loadedUser: User = JSON.parse(localStorage.getItem("userData"));
    
    this.consumer = ActionCable.createConsumer(environment.ACTION_CABLE_ENPOINT + loadedUser.token);
    console.log("Trying KOT connection");
    
    this.consumer.subscriptions.create(environment.KOT_SCREEN_CHANNEL +"_"+ outletId, {
      connected() {
        console.log("KOT Subscription is ready for use");
      },
      disconnected() {
        console.log("KOT Service terminated by WB server");
      },
      received(data) {
        return data;
      }
    });
  }

}