import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PosCheckSettlement } from '../../models/pos-check-settlement.model';
import { PosCheck } from '../../models/pos-check.model';
import { PosRestaurant } from '../../models/pos-restaurant.model';
import { PointerService } from '../../services/pointer.service';
import { PosCheckService } from '../../services/pos-check.service';
import { environment } from '../../../environments/environment';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-allowance',
  templateUrl: './allowance.component.html',
  styles: [
  ]
})
export class AllowanceComponent implements OnInit {
  outletId: string = "";
  posCheck: PosCheck;
  posCheckId: string;
  currencySymbol: string = "";

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private posCheckService: PosCheckService,
              private alertService: AlertService) { }

  ngOnInit(): void {
    this.outletId = this.activatedRoute.snapshot.params["outlet_id"];
    this.posCheckId = this.activatedRoute.snapshot.params["check_id"];
    this.getCheckDetails();
    this.currencySymbol = PointerService.getLocalCurrencySymbol();
  }

  getCheckDetails(): void {
    this.posCheckService.findBy(this.posCheckId)
    .subscribe({
      next: (data: any) => {
        if (data) {
          this.posCheck = data;
        }
      },
      error: (error: any) => {
        if(error.errors && error.errors.length > 0) {
          this.alertService.error(error.errors[0]);
        } else {
          this.alertService.error(error);
        }
      }
    });
  }

  onPaymentSelection(selectedPayment: PosCheckSettlement): void {
    if(selectedPayment && selectedPayment.id) {
      const objItem = this.posCheck.pos_check_settlements.filter(x => x.id == selectedPayment.id);
      
      const outletData: PosRestaurant[] = JSON.parse(localStorage.getItem("posRestaurant"));
      const currentOutletData = outletData.filter(a => a.id == +this.outletId);

      if(currentOutletData.length > 0) {
        const selectedCharge = currentOutletData[0].pos_payment_restaurants.filter(b => b.payment_id == objItem[0].charge_id);
      
        if(selectedCharge && selectedCharge.length > 0) {
          if(selectedCharge[0].payment.allowance_allowed_ind) {
            //create copy of exisiting record with allowance ind true
            const newObject = Object.assign(new PosCheckSettlement(), objItem[0]);
            newObject.id = null;
            newObject.allowance_ind = true;
            newObject.is_new = true;
            newObject.is_dirty = false;
            this.posCheck.pos_check_settlements.push(newObject);
          } else {
            // show error
            this.alertService.error(environment.ALLOWANCE_NOT_ALLOWED);
          }
        }
      }
    }
  }

  onBackClick(): void {
    this.router.navigate(['/billing/' + this.outletId + '/view-bill/' + this.posCheckId]);
  }

  markAllowance(): void {
    const isAllowanceMarked = this.posCheck.pos_check_settlements.filter(x => x.is_new && x.allowance_ind == true);

    if(isAllowanceMarked.length > 0) {
      this.posCheckService.settleBill(this.posCheck)
      .subscribe({
        next: (data: any) => {
          this.router.navigate(['/billing/' + this.outletId + '/view-bill/' + this.posCheckId]);
        },
        error: (error: any) => {
          if(error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      });
    } else {
      this.alertService.error(environment.ALLOWANCE_NOT_SELECTED);
    }
  }
}
