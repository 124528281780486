import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { OutletSetting } from '../models/outlet-setting.model';
import { PosRestaurant } from '../models/pos-restaurant.model';
import { Payment } from '../models/payment.model';
import { PosPaymentRestaurant } from '../models/pos-payment-restaurant.model';
import { PosMenuRestaurant } from '../models/pos-menu-restaurant.model';
import { OutletBilling } from '../models/outlet-billing.model';

@Injectable({
  providedIn: 'root'
})
export class OutletService {
  constructor(private http: HttpClient) {
  }

  getOutletDetailsBy(outletId: string, businessDate: string) {
    return this.http.get(environment.APIENDPOINT + environment.POS_RESTAURANT + '/'+ outletId, 
      { params: new HttpParams().append("key_date", businessDate)
      }
    ).pipe(
        map((response) => {
          if (response["pos_restaurant"]) {
            let obj = response["pos_restaurant"];
            let posRestaurant: PosRestaurant[] = [];

            if (localStorage.getItem("posRestaurant") != null) {
              posRestaurant = JSON.parse(localStorage.getItem("posRestaurant"));
            };

            if (posRestaurant.length > 0) {
              const alreadyPresent = posRestaurant.filter(a => a.id === obj.id)

              if (alreadyPresent.length <= 0) {
                const newSetting: PosRestaurant = this.setPosRestaurantData(obj);
                posRestaurant.push(newSetting);
              }
            }
            else {
              const newSetting: PosRestaurant = this.setPosRestaurantData(obj);
              posRestaurant.push(newSetting);
            }

            localStorage.setItem("posRestaurant", JSON.stringify(posRestaurant));
            return posRestaurant;
          }
        })
      )
  }

  setPosRestaurantData(obj): PosRestaurant {
    let posPaymentRestaurant: PosPaymentRestaurant[] = [];
    let posMenuRestaurant: PosMenuRestaurant[] = [];

    if(obj.pos_payment_restaurants && obj.pos_payment_restaurants.length > 0) {
      obj.pos_payment_restaurants.forEach(payment=> {
        let objPayment = new Payment(
          payment.payment.id,
          payment.payment.code,
          payment.payment.payment_ind,
          payment.payment.account_code,
          payment.payment.tax_ind,
          payment.payment.dr_cr,
          payment.payment.settlement_process_code,
          payment.payment.gl_code,
          payment.payment.print_charge_group_code,
          payment.payment.department_code,
          payment.payment.allowance_allowed_ind,
          payment.payment.hsn_sac_code,
          payment.payment.inclusive_of_tax_ind,
          payment.payment.charge_translations && payment.payment.charge_translations.length > 0 ? payment.payment.charge_translations[0]["description"] : ""
        )

        let objRestaurantPayment = new PosPaymentRestaurant(
          payment.id,
          payment.pos_restaurant_id,
          payment.payment_id,
          payment.active_ind,
          payment.freeze_ind,
          objPayment
        )

        posPaymentRestaurant.push(objRestaurantPayment)
      })
    }

    if(obj.pos_menu_restaurants && obj.pos_menu_restaurants.length > 0) {
      obj.pos_menu_restaurants.forEach(menu=> {
        let objMenuRest = new PosMenuRestaurant(
          menu.id,
          menu.pos_menu ? menu.pos_menu.code : "",
          menu.pos_menu_id,
          menu.pos_restaurant_id,
          menu.pos_menu.pos_menu_translations && menu.pos_menu.pos_menu_translations.length > 0 ? menu.pos_menu.pos_menu_translations[0]["long_name"] : "",
          menu.happy_hour_dow,
          menu.happy_hour_from_date,
          menu.happy_hour_from_time,
          menu.happy_hour_to_date,
          menu.happy_hour_to_time,
          menu.valid_dow,
          menu.valid_from_date,
          menu.valid_from_time,
          menu.valid_to_date,
          menu.valid_to_time
        )
        posMenuRestaurant.push(objMenuRest)
      })
    }                                                                                                                                     

    const posRestaurant = new PosRestaurant(
      obj.id, obj.code,
      obj.department_code, obj.serial_number,
      obj.gst_number,
      obj.pos_restaurant_translations && obj.pos_restaurant_translations.length > 0 ? obj.pos_restaurant_translations[0]["description"] : "",
      posPaymentRestaurant, 
      posMenuRestaurant
    )
    return posRestaurant;
  }

  getOutletSettings(outletId: string) {
    return this.http.get(environment.APIENDPOINT + environment.POS_OUTLET_SETTING,
      { params: new HttpParams().append("key_restaurant", outletId) }
    ).pipe(
      map((response) => {
        if (response["pos_restaurant_settings"] && response["pos_restaurant_settings"].length > 0) {
          let outletSetting: OutletSetting[] = [];

          if (localStorage.getItem("outletSetting") != null) {
            outletSetting = JSON.parse(localStorage.getItem("outletSetting"));
          };

          response["pos_restaurant_settings"].forEach(obj => {
            if (outletSetting.length > 0) {
              const alreadyPresent = outletSetting.filter(a => a.pos_restaurant_id === obj.pos_restaurant_id)

              if (alreadyPresent.length <= 0) {
                const newSetting: OutletSetting = this.setOutletSettingData(obj);
                outletSetting.push(newSetting);
              }
            }
            else {
              const newSetting: OutletSetting = this.setOutletSettingData(obj);
              outletSetting.push(newSetting);
            }
          });
          localStorage.setItem("outletSetting", JSON.stringify(outletSetting));
          return outletSetting;
        }
      })
    )
  }

  setOutletSettingData(obj): OutletSetting {
    const objSetting = new OutletSetting(
      obj.id,
      obj.pos_restaurant_id,
      obj.default_menu_id,
      obj.card_details_mandetory,
      obj.has_multiple_shifts_ind,
      obj.allow_item_rate_change_ind,
      obj.debit_card_ind,
      obj.call_local_after_print_ind,
      obj.call_print_after_settle_ind,
      obj.prompt_kot_print_on_copy_check_ind,
      obj.refresh_interval,
      obj.print_check_by_account_ind,
      obj.print_check_default_count,
      obj.print_guest_name_ind,
      obj.print_member_id_ind,
      obj.guest_name_mandetory_ind,
      obj.covers_mandetory_ind,
      obj.print_kot_ind,
      obj.hsn_sac_number,
      obj.debit_card_in_use_ind,
      obj.debit_card_no_start_position,
      obj.debit_card_no_length,
      obj.void_backdated_check_ind,
      obj.print_round_off_ind,
      obj.thermal_printer_ind,
      obj.happy_hour_ind,
      obj.allow_excess_amount_ind,
      obj.check_server_side_print_ind,
      obj.qsr_in_use_ind,
      obj.inhouse_delivery_ind,
      obj.self_service_ind,
      obj.print_kot_after_settlement_ind,
      obj.qr_view_menu_ind,
      obj.qr_place_order_ind,
      obj.kot_client_side_ind,
      obj.allowance_backdated_check_ind,
      obj.broadcast_kot_status_ind,
      obj.broadcast_table_status_ind,
      obj.check_print_required_ind,
      obj.gst_number,
      obj.pan_number,
      obj.inclusive_of_tax_ind
    )

    return objSetting;
  }

  getOutletName(outletId: string): string {
    const restaurants: PosRestaurant[] = JSON.parse(localStorage.getItem("posRestaurant"));

    if(restaurants && restaurants.length > 0) {
      let selectedOutlet = restaurants.filter(x => x.id === +outletId)
      if (selectedOutlet.length > 0) {
        return selectedOutlet[0].restaurant_name;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  getDepartmentCodeBy(outletId: string) {
    let departmentCode: string = "";
    const restaurantData: PosRestaurant[] = JSON.parse(localStorage.getItem("posRestaurant"));

    if(restaurantData && restaurantData.length > 0) {
      let currentRestaurant = restaurantData.filter(x => x.id === +outletId);
      if(currentRestaurant && currentRestaurant.length > 0) {
        departmentCode = currentRestaurant[0].department_code;
      }
    }
    return departmentCode;
  }

  getOutletBillingDetails(outletId: string) {
    return this.http.get(environment.APIENDPOINT + environment.POS_RESTAURANT_BILLING_HEADER,
      { params: new HttpParams().append("key_restaurant", outletId) }
    ).pipe(
      map((response) => {
        if (response["pos_restaurant_billing_headers"] && response["pos_restaurant_billing_headers"].length > 0) {
          let outletBilling: OutletBilling[] = [];

          if (localStorage.getItem("outletBillingHeader") != null) {
            outletBilling = JSON.parse(localStorage.getItem("outletBillingHeader"));
          };

          response["pos_restaurant_billing_headers"].forEach(obj => {
            if (outletBilling.length > 0) {
              const alreadyPresent = outletBilling.filter(a => a.pos_restaurant_id === obj.pos_restaurant_id)

              if (alreadyPresent.length <= 0) {
                const newSetting: OutletBilling = this.setOutletBillingHeader(obj);
                outletBilling.push(newSetting);
              }
            }
            else {
              const newSetting: OutletBilling = this.setOutletBillingHeader(obj);
              outletBilling.push(newSetting);
            }
          });
          localStorage.setItem("outletBillingHeader", JSON.stringify(outletBilling));
          return outletBilling;
        }
      })
    )
  }

  setOutletBillingHeader(obj): OutletBilling {
    const outletBilling = new OutletBilling(
      obj.id,
      obj.saas_company_id,
      obj.company_id,
      obj.branch_id,
      obj.pos_restaurant_id,
      obj.restaurant_name_on_check,
      obj.gst_number,
      obj.pan_number,
      obj.hsn_number,
      obj.tin_number,
      obj.cin_number,
      obj.fssai_number,
      obj.place_of_supply,
      obj.state_code,
      obj.check_header_line1,
      obj.check_header_line2,
      obj.address1,
      obj.address2,
      obj.address3,
      obj.zip,
      obj.restaurant_contact,
      obj.restaurant_email,
      obj.gst_declaration,
      obj.declaration,
      obj.upi_url,
      obj.remarks,
      obj.notes
    );
    return outletBilling;
  }
}