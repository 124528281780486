export class Tax {
  constructor(
    public id: number,
    public from_date: Date,
    public to_date: Date,
    public tax_percentage: number,
    public parent_charge_id: number,
    public tax_amount: number,
    public tax_code: string,
    public dr_cr: string,
    public tax_name: string
  ){}
}