import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableLayout } from '../models/table-layout.model';
import { TableLayoutService } from '../services/table-layout.service';
import { PointerService } from '../services/pointer.service';
import { environment } from '../../environments/environment';
import { PosCheck } from '../models/pos-check.model';
import { PosCheckService } from '../services/pos-check.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VoidReason } from '../models/void-reason.model';
import { VoidReasonService } from '../services/void-reason.service';
import { ActionCableService } from '../services/action-cable.service';
import { debounceTime, first, Subscription } from 'rxjs';
import { User } from '../models/user.model';
import { AlertService } from '../services/alert.service';
import { OutletSetting } from '../models/outlet-setting.model';

declare function enableTabListPanel(): any;
declare function enableParentTabOfTable(sectionCode: string): any;
declare function toggleTableTransferModal(): any;
declare function subCategoryJs(): any;

@Component({
  selector: 'app-table-layout',
  templateUrl: './table-layout.component.html'
})
export class TableLayoutComponent implements OnInit {
  subscription: Subscription;
  outletId: string = "";
  tableLayout: TableLayout[] = [];
  voidReason: VoidReason[] = [];
  tableSection: any[] = [];
  selectedTable: TableLayout;
  tableInfo: string = "";
  pendingTableCode: string = "";
  posCheck: PosCheck;
  businessDate: string;
  error: any[] = [];
  previousTable = this.tableLayoutService.getPreviousSelectedTable;
  tableViewMode = "";

  POS_TABLE_STATUS_AVAILABLE = environment.POS_TABLE_STATUS_AVAILABLE;
  POS_TABLE_STATUS_KOT = environment.POS_TABLE_STATUS_KOT;
  POS_TABLE_STATUS_CHECK = environment.POS_TABLE_STATUS_CHECK;

  isTableTransfer: boolean = false;
  tableTransferredFrom: TableLayout;
  tableTransferredTo: TableLayout;
  @ViewChild('closePendingModalBtn') closePendingModalBtn: ElementRef;
  @ViewChild('closeTableTransferModalBtn') closeTableTransferModalBtn: ElementRef;
  @ViewChild('errorModal') errorModal: ElementRef;

  constructor(private activatedRoute: ActivatedRoute,
    private tableLayoutService: TableLayoutService,
    private router: Router,
    private pointerService: PointerService,
    private posCheckService: PosCheckService,
    private voidReasonService: VoidReasonService,
    private modalService: NgbModal,
    private changeDetector: ChangeDetectorRef,
    private cableService: ActionCableService,
    private alertService: AlertService
  ) {

    window.addEventListener('storage', () => {
      // When local storage changes, dump the list to
      // the console.
      console.log('keys pressed');
    });
  }

  ngOnInit(): void {
    let viewMode = localStorage.getItem("viewMode");
    if (viewMode) {
      this.tableViewMode = viewMode;
    } else {
      this.tableViewMode = "SMART_VIEW";
    }

    enableTabListPanel();

    this.pendingTableCode = this.pointerService.filterValueByCode(environment.POINTER_PENDING_TABLE_CODE);
    this.businessDate = PointerService.getISOBusinessDate();
    this.outletId = this.activatedRoute.snapshot.params['outlet_id'];

    const tableData: TableLayout[] = JSON.parse(localStorage.getItem("tableLayout"));
    if (!tableData) {
      this.tableLayoutService.getTableLayoutBy(this.outletId)
        .pipe(first())
        .subscribe({
          next: (data: any) => {
            this.tableLayout = data;
          },
          error: (error: any) => {
            this.alertService.error(error);
          }
        });
    } else {
      this.tableLayout = tableData;
    }

    this.tableSection = [...new Set(this.tableLayout.map(item => item.table_group))];
    if (!this.previousTable) {
      this.previousTable = this.tableLayout[0];
    }

    const voidReasonData: VoidReason[] = JSON.parse(localStorage.getItem("voidReason"));
    if (!voidReasonData) {
      this.voidReasonService.getAll()
        .subscribe({
          next: (data: any) => {
            this.voidReason = data;
          },
          error: (error: any) => {
            if (error.errors && error.errors.length > 0) {
              this.alertService.error(error.errors[0]);
            } else {
              this.alertService.error(error);
            }
          }
        });
    } else {
      this.voidReason = voidReasonData;
    }

    //this.cableService.subscribeTableChannel();
  }

  // subscribeToChannel() {
  //   const loadedUser: User = JSON.parse(localStorage.getItem("userData"));
  //   const channel: Channel = this.cableService
  //     .cable(environment.ACTION_CABLE_ENPOINT + loadedUser.token)
  //     .channel(environment.TABLE_STATUS_CHANNEL);

  //   // Subscribe to incoming messages
  //   this.subscription = channel.received().subscribe(data => {

  //     if (data) {
  //       let tableData: TableLayout[] = JSON.parse(localStorage.getItem('tableLayout'));

  //       if (tableData) {
  //         let selectedTable: TableLayout[] = tableData.filter(x => x.id == data.id && x.table_code == data.table_code);

  //         if (selectedTable.length > 0) {
  //           selectedTable[0].table_status = data.table_status;
  //           selectedTable[0].pos_check_id = data.pos_check_id;

  //           localStorage.setItem('tableLayout', JSON.stringify(tableData));
  //           this.tableLayout = tableData;
  //           this.onTableSelection(selectedTable[0])
  //         }
  //       }
  //     }
  //   });
  // }

  ngAfterViewInit(): void {
    this.cableService.subscribeTableChannel();

    enableTabListPanel();
    subCategoryJs();

    if (this.previousTable) {
      this.onTableSelection(this.previousTable);
      enableParentTabOfTable(this.previousTable.table_group);
      this.changeDetector.detectChanges();
    }
  }

  onSectionSelection(): void {
    enableTabListPanel();
  }

  onTableSelection(table: TableLayout): void {
    this.selectedTable = table;
    this.tableInfo = "Table selected: " + table.table_display_code;
    this.tableLayoutService.setSelectedTable(table);

    if (this.selectedTable) {
      if (this.isTableTransfer) {
        this.tableTransferredTo = this.selectedTable;
        this.checkTableTransfer();
      }
      else {
        this.posCheck = null;
        setTimeout(() => {
          if (this.selectedTable.table_status.toUpperCase() != this.POS_TABLE_STATUS_AVAILABLE.toUpperCase()) {
            this.posCheckService.getCheckDetailsBy(this.outletId, this.businessDate, this.selectedTable)
              .subscribe({
                next: (data: any) => {
                  this.posCheck = data;
                  if (this.posCheck && this.posCheck.check_no) {
                    this.tableInfo += " | Bill no: " + this.posCheck.check_no;
                  }
                  if (this.posCheck && this.posCheck.guest_name) {
                    this.tableInfo += " | Guest name: " + this.posCheck.guest_name;
                  }
                },
                error: (error: any) => {
                  this.alertService.error(error);
                }
              });
          }
        }, 0);

      }
    }
  }

  checkTableTransfer(): void {
    if (this.selectedTable.table_status.toUpperCase() == this.POS_TABLE_STATUS_AVAILABLE.toUpperCase()
      && this.selectedTable.table_code != this.pendingTableCode) {
      toggleTableTransferModal();
    } else {
      this.isTableTransfer = false;
      this.tableTransferredFrom = null;
      this.tableTransferredTo = null;
    }
  }

  onPlaceOrder(): void {
    let isQsrOutlet = this.checkQsrOutlet();

    if (isQsrOutlet) {
      let layoutData = JSON.parse(localStorage.getItem("tableLayout"));

      if (!layoutData) {
        this.tableLayoutService.getTableLayoutBy(this.outletId).subscribe((data) => {
          this.getAvailableTable(data);
        })
      } else {
        this.getAvailableTable(layoutData);
      }
    } else {
      // for non-qsr outlets - normal flow
      if (this.selectedTable && this.selectedTable.table_status.toUpperCase() === environment.POS_TABLE_STATUS_CHECK) {
        // do nothing
      } else if (this.selectedTable && this.selectedTable.table_code === this.pendingTableCode) {
        // show pop up - can not raise on reserve table
        this.alertService.error(environment.CANNOT_RAISE_ON_RESERVE_TABLE)
      } else if (this.selectedTable && this.selectedTable.table_status.toUpperCase() === environment.POS_TABLE_STATUS_KOT) {
        this.router.navigate(['/billing/' + this.outletId + '/place-order/' + this.selectedTable.table_code + '/' + this.posCheck.id]);
      } else {
        this.router.navigate(['/billing/' + this.outletId + '/place-order/' + this.selectedTable.table_code]);
      }
    }
  }

  checkQsrOutlet() {
    let isQsrOutlet: boolean = false;
    if (this.outletId) {
      const outletSettingData: OutletSetting[] = JSON.parse(localStorage.getItem("outletSetting"));
      if (outletSettingData) {
        let currentOutletSetting = outletSettingData.filter(a => a.pos_restaurant_id === +this.outletId);

        if (currentOutletSetting.length > 0) {
          isQsrOutlet = currentOutletSetting[0].qsr_in_use_ind;
        }
        return isQsrOutlet
      } else {
        this.router.navigate(['/outlet']);
      }
    }
  }

  getAvailableTable(layoutData) {
    //Get any Free table from layout for QSR billing
    let selectedTable = layoutData.filter(t => {
      return t.pos_restaurant_id == +this.outletId && t.table_status.toUpperCase() === environment.POS_TABLE_STATUS_AVAILABLE.toUpperCase()
    });

    if (selectedTable && selectedTable.length > 0) {
      this.router.navigate(['/billing/' + this.outletId + '/qsr/' + selectedTable[0].table_code]);
    } else {
      this.alertService.error(environment.NO_FREE_TABLE_AVAILABLE);
    }
  }

  onViewBill(): void {
    if (this.posCheck) {
      this.router.navigate(['/billing/' + this.outletId + '/view-bill/' + this.posCheck.id]);
    }
  }

  onTableTransferClick(): void {
    this.isTableTransfer = true;
    this.tableTransferredFrom = this.selectedTable;
  }

  onKotListClick(): void {
    this.router.navigate(['/billing/' + this.outletId + '/kot']);
  }

  onChangeOutlet(): void {
    this.tableLayoutService.resetTableSelection();
    this.router.navigate(['/outlet']);
  }

  openModal(content): void {
    this.modalService.open(content);
  }

  onANGClick(): void {
    if (this.posCheck) {
      this.router.navigate(['/billing/' + this.outletId + '/ang/' + this.posCheck.id]);
    }
  }

  onCloseShiftClick(): void {
    this.router.navigate(['/close_shift/' + this.outletId]);
  }

  onRecallCheckClick(): void {
    if (this.selectedTable.table_code) {
      this.router.navigate(['/billing/' + this.outletId + '/recall-check/' + this.selectedTable.table_code]);
    } else {
      this.router.navigate(['/billing/' + this.outletId + '/recall-check']);
    }
  }

  onSearchUserClick(): void {
    this.router.navigate(['/billing/' + this.outletId + '/address-book']);
  }

  moveBillToPendingTable(): void {
    if (this.selectedTable.table_status == environment.POS_TABLE_STATUS_CHECK) {
      if (this.pendingTableCode) {
        //only printed bills will be transfer to pending table
        this.posCheckService.transferTable(this.posCheck, this.pendingTableCode)
          .subscribe({
            next: (data: any) => {
              this.closePendingModalBtn.nativeElement.click();
            },
            error: (error: any) => {
              this.closePendingModalBtn.nativeElement.click();
              if (error.errors && error.errors.length > 0) {
                this.alertService.error(error.errors[0]);
              } else {
                this.alertService.error(error);
              }
            }
          });
      } else {
        this.closePendingModalBtn.nativeElement.click();
        this.alertService.error(environment.PENDING_TABLE_MISSING);
      }
    } else {
      // don't do anything for FR/KOT tables.
      this.closePendingModalBtn.nativeElement.click();
      this.alertService.error(environment.SELECT_VALID_TABLE);
    }
  }

  transferTable(): void {
    if (this.selectedTable.table_status == environment.POS_TABLE_STATUS_AVAILABLE) {
      //only printed bills will be transfer to pending table
      this.posCheckService.transferTable(this.posCheck, this.tableTransferredTo.table_code)
        .subscribe({
          next: (data: any) => {
            this.closeTableTransferModalBtn.nativeElement.click();
            this.isTableTransfer = false;
            this.tableTransferredFrom = null;
            this.tableTransferredTo = null;

            //setTimeout(function() {
            const tableData: TableLayout[] = JSON.parse(localStorage.getItem("tableLayout"));
            this.tableLayout = tableData;

            //this.cableService.subscribeTableChannel();
            //}, 1000);

          },
          error: (error: any) => {
            this.closeTableTransferModalBtn.nativeElement.click();
            this.isTableTransfer = false;
            this.tableTransferredFrom = null;
            this.tableTransferredTo = null;

            if (error.errors && error.errors.length > 0) {
              this.alertService.error(error.errors[0]);
            } else {
              this.alertService.error(error);
            }
          }
        });
    } else {
      // don't do anything for CHCK/KOT tables.
      this.isTableTransfer = false;
      this.tableTransferredFrom = null;
      this.tableTransferredTo = null;
      this.alertService.error(environment.ERROR_TRANSFER_TABLE);
    }
  }

  cancelTableTransfer(): void {
    this.isTableTransfer = false;
    this.tableTransferredFrom = null;
    this.tableTransferredTo = null;
    this.closeTableTransferModalBtn.nativeElement.click();
  }

  ngOnDestroy() {
    // Unsubscribing from the messages Observable automatically
    // unsubscribes from the ActionCable channel as well
    //this.subscription.unsubscribe();
  }
}