import { Component, OnInit } from '@angular/core';
declare function enableNumericKeyboard(): any;

@Component({
  selector: 'app-digital-keyboard',
  templateUrl: './digital-keyboard.component.html'
})
export class DigitalKeyboardComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
    enableNumericKeyboard();
  }

  ngAfterViewInit() {
    let element: HTMLElement = document.getElementById("numericInput") as HTMLElement;
    element.click();
  }
}
