<div class="modal fade lock_scrn_modal" id="lockScreenModal" 
  data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="lockScreenLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)">
       <div class="modal-header"> 
          <button type="button" #closeBtn id="closeBtn" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style="display: none;"></button>
       </div>
        <div class="modal-body">
          <div class="change_passwrd">
            <div class="relogin_parnt_blk">
              <img src="../../../assets/images/user_avatar.png" alt="Avatar">
              <label>{{ username }}</label>
              <div class="relogin_grp_inr">
                <div class="input-group">
                  <span class="input-group-text"><i class="fa fa-lock" aria-hidden="true"></i></span>
                  <input type="password" name="password" class="form-control" ngModel required minlength="8"
                  placeholder="Enter Password" #passwordControl="ngModel" (click)="openKeyboard($event)">
            
                  <button type="submit" [disabled]="!authForm.valid" [class]="!authForm.valid ? 'btn disabled' : 'btn gry_btn'" >
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 88.363 122.879" enable-background="new 0 0 88.363 122.879" xml:space="preserve"><g><path d="M42.078,64.589H3.118C1.396,64.589,0,63.179,0,61.439s1.396-3.149,3.118-3.149h38.958L29.405,43.778 c-1.137-1.298-1.017-3.281,0.268-4.429c1.285-1.148,3.248-1.027,4.385,0.271l17.238,19.742c1.059,1.208,1.027,3.009-0.02,4.179 L34.057,83.26c-1.137,1.298-3.1,1.419-4.385,0.271c-1.284-1.148-1.404-3.132-0.268-4.43L42.078,64.589L42.078,64.589z M47.434,122.786c-1.77,0.428-3.55-0.659-3.978-2.428s0.659-3.55,2.427-3.978c4.258-1.032,7.932-1.761,11.303-2.428 c15.99-3.168,24.566-4.866,24.566-27.147V34.258c0-20.817-9.068-22.512-24.086-25.318c-3.596-0.672-7.498-1.401-11.783-2.441 c-1.768-0.428-2.855-2.208-2.427-3.978c0.428-1.769,2.208-2.855,3.978-2.428c4.02,0.976,7.885,1.698,11.447,2.363 c18.383,3.436,29.482,5.51,29.482,31.801v52.548c0,27.705-10.443,29.773-29.912,33.63 C54.986,121.121,51.215,121.869,47.434,122.786L47.434,122.786z"/></g></svg>
                  </button>  
                </div>
              </div>
              <div class="error_msg_parnt mb-0" *ngIf="errorList && errorList.length > 0">
                <h5 *ngFor="let error of errorList">
                  {{ error }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>