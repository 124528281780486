import { PointerService } from "../services/pointer.service";

export class PosCheckChargeTax {
  private _id: number;
  private _is_new: boolean;
  private _is_dirty: boolean;
  private _pos_check_charge_id: number;
  private _destroy: number;
  private _charge_id: number;
  private _currency_id: number;
  private _tax_id: number;
  private _tax_percentage: number;
  private _dr_cr: string;
  private _serial_number: number;
  private _amount: number;
  private _description: string;
  private _freeze_ind: boolean;
  private _freeze_at: Date;
  
  constructor() {
    this.is_new = true;
    this.is_dirty = false;
  }

  public get id(): number {
    return this._id;
  }
  public set id(value: number) {
    this._id = value;
  }
  
  public get destroy(): number {
    return this._destroy;
  }
  public set destroy(value: number) {
    this._destroy = value;
  }

  public get is_new(): boolean {
    return this._is_new;
  }
  public set is_new(value: boolean) {
    this._is_new = value;
  }

  public get is_dirty(): boolean {
    return this._is_dirty;
  }
  public set is_dirty(value: boolean) {
    this._is_dirty = value;
  }

  public get pos_check_charge_id(): number {
    return this._pos_check_charge_id;
  }
  public set pos_check_charge_id(value: number) {
    this._pos_check_charge_id = value;
  }

  public get freeze_at(): Date {
    return this._freeze_at;
  }
  public set freeze_at(value: Date) {
    this._freeze_at = value;
  }
  public get freeze_ind(): boolean {
    return this._freeze_ind;
  }
  public set freeze_ind(value: boolean) {
    this._freeze_ind = value;
  }
  public get serial_number(): number {
    return this._serial_number;
  }
  public set serial_number(value: number) {
    this._serial_number = value;
  }

  public get amount(): number {
    return this._amount;
  }
  public set amount(value: number) {
    this._amount = value;
  }

  public get description(): string {
    return this._description;
  }
  public set description(value: string) {
    this._description = value;
  }

  public get dr_cr(): string {
    return this._dr_cr;
  }
  public set dr_cr(value: string) {
    this._dr_cr = value;
  }
  public get tax_percentage(): number {
    return this._tax_percentage;
  }
  public set tax_percentage(value: number) {
    this._tax_percentage = value;
  }
  public get tax_id(): number {
    return this._tax_id;
  }
  public set tax_id(value: number) {
    this._tax_id = value;
  }
  public get currency_id(): number {
    return this._currency_id;
  }
  public set currency_id(value: number) {
    this._currency_id = PointerService.getLocalCurrencyId();
  }
  public get charge_id(): number {
    return this._charge_id;
  }
  public set charge_id(value: number) {
    this._charge_id = value;
  }
}