import { Component } from '@angular/core';
import { LoaderComponent } from './shared/loader/loader.component';
import { Spinkit } from 'ng-http-loader';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'TapPos';

  options = {
    autoClose: false,
    keepAfterRouteChange: false
  };

  public loaderComponent = LoaderComponent;
  public spinkit = Spinkit;
}

