import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { ANG } from '../models/ang.model';
import { PosDiscount } from '../models/pos-discount.model';

@Injectable({
  providedIn: 'root'
})
export class ANGService {

  constructor(private http: HttpClient) { }

  public getAll() {
    return this.http.get(environment.APIENDPOINT + environment.ANG_CATEGORY)
      .pipe(
        map(response => {
          const angList: ANG[] = [];
          if (response && response["pos_ang_categories"].length > 0) {
            response["pos_ang_categories"].forEach(obj => {
              let posDiscount;
              if (obj.pos_discount) {
                posDiscount = new PosDiscount(
                  obj.pos_discount.id,
                  obj.pos_discount.code,
                  obj.pos_discount.display_in_lookup_ind,
                  obj.pos_discount.pos_discount_translations && obj.pos_discount.pos_discount_translations.length > 0 ? obj.pos_discount.pos_discount_translations[0].description : ""
                )
              }

              const ang = new ANG(
                obj.id,
                obj.code,
                obj.pos_discount_id,
                obj.pos_ang_category_translations && obj.pos_ang_category_translations.length > 0 ? obj.pos_ang_category_translations[0].description : "",
                posDiscount
              )

              angList.push(ang);
            });
          }
          return angList;
        })
      )
  }
}
