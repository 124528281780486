import { PointerService } from "../services/pointer.service";

export class PosCheckSettlement {
  private _id: number;
  private _charge_id: number;
  private _currency_id: number;
  private _dr_cr: string;
  private _business_date: Date;
  private _system_time: Date;
  private _system_date: Date;
  private _settlement_type: string;
  private _amount: number;
  private _description: string;
  private _card_no: string;
  private _shift_no: number;
  private _allowance_ind: boolean;
  private _room_no: string;
  private _inhouse_id: string;
  private _visit_id: string;
  private _freeze_ind: boolean;
  private _freeze_at: Date;
  private _destroy: number;
  private _is_new: boolean;
  private _is_dirty: boolean; 

  constructor() {
    this._system_date = new Date();
    this._system_time = new Date();
    this._business_date = PointerService.getBusinessDate();
  }

  public get business_date(): Date {
    return this._business_date;
  }
  public set business_date(value: Date) {
    this._business_date = value;
  }

  public get system_date(): Date {
    return this._system_date;
  }
  public set system_date(value: Date) {
    this._system_date = value;
  }

  public get system_time(): Date {
    return this._system_time;
  }
  public set system_time(value: Date) {
    this._system_time = value;
  }

  public get id(): number {
    return this._id;
  }
  public set id(value: number) {
    this._id = value;
  }

  public get freeze_at(): Date {
    return this._freeze_at;
  }
  public set freeze_at(value: Date) {
    this._freeze_at = value;
  }
  public get freeze_ind(): boolean {
    return this._freeze_ind;
  }
  public set freeze_ind(value: boolean) {
    this._freeze_ind = value;
  }

  public get shift_no(): number {
    return this._shift_no;
  }
  public set shift_no(value: number) {
    this._shift_no = value;
  }

  public get room_no(): string {
    return this._room_no;
  }
  public set room_no(value: string) {
    this._room_no = value;
  }

  public get inhouse_id(): string {
    return this._inhouse_id;
  }
  public set inhouse_id(value: string) {
    this._inhouse_id = value;
  }
  
  public get visit_id(): string {
    return this._visit_id;
  }
  public set visit_id(value: string) {
    this._visit_id = value;
  }

  public get allowance_ind(): boolean {
    return this._allowance_ind;
  }
  public set allowance_ind(value: boolean) {
    this._allowance_ind = value;
  }
  public get card_no(): string {
    return this._card_no;
  }
  public set card_no(value: string) {
    this._card_no = value;
  }
  public get amount(): number {
    return this._amount;
  }
  public set amount(value: number) {
    this._amount = value;
  }
  public get settlement_type(): string {
    return this._settlement_type;
  }
  public set settlement_type(value: string) {
    this._settlement_type = value;
  }

  public get description(): string {
    return this._description;
  }
  public set description(value: string) {
    this._description = value;
  }

  public get dr_cr(): string {
    return this._dr_cr;
  }
  public set dr_cr(value: string) {
    this._dr_cr = value;
  }
  public get currency_id(): number {
    return this._currency_id;
  }
  public set currency_id(value: number) {
    this._currency_id = PointerService.getLocalCurrencyId();
  }
  public get charge_id(): number {
    return this._charge_id;
  }
  public set charge_id(value: number) {
    this._charge_id = value;
  }

  public get destroy(): number {
    return this._destroy;
  }
  public set destroy(value: number) {
    this._destroy = value;
  }

  public get is_new(): boolean {
    return this._is_new;
  }
  public set is_new(value: boolean) {
    this._is_new = value;
  }

  public get is_dirty(): boolean {
    return this._is_dirty;
  }
  public set is_dirty(value: boolean) {
    this._is_dirty = value;
  }
}