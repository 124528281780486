import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KotService } from '../../services/kot.service';
import { AlertService } from '../../services/alert.service';
import { PosCheck } from '../../models/pos-check.model';
import { environment } from 'projects/pos/src/environments/environment';
//import { ActionCableService, Channel } from 'angular2-actioncable';
//import * as ActionCable from 'actioncable';
import { Subscription } from 'rxjs';
import { PosOrderStatus } from '../../models/pos-order-status.model';
import { ActionCableService } from '../../services/action-cable.service';
import { PosCheckDetail } from '../../models/pos-check-detail.model';
import { User } from '../../models/user.model';

@Component({
  selector: 'app-kot',
  templateUrl: './kot.component.html',
  styles: [`
    .kot_menu_detls .kot_item_detls ul {
      width: 100% !important;
    }

    .dx-template-wrapper {
      margin-top: -10px !important;
    }
  `]
})
export class KotComponent implements OnInit {
  outletId: string = "";
  businessDate: Date;
  subscription: Subscription;
  checkList: PosCheck[] = [];
  allKotList: any[] = [];
  pendingKotList: any[] = [];
  orderStatusList: PosOrderStatus[] = [];
  POS_ORDER_STATUS_NEW: string = environment.POS_ORDER_STATUS_NEW.toUpperCase();
  POS_ORDER_STATUS_ACKNOWLEDGE: string = environment.POS_ORDER_STATUS_ACKNOWLEDGE.toUpperCase();
  POS_ORDER_STATUS_CANCEL: string = environment.POS_ORDER_STATUS_CANCEL.toUpperCase();
  POS_ORDER_STATUS_IN_PROGRESS: string = environment.POS_ORDER_STATUS_IN_PROGRESS.toUpperCase();
  POS_ORDER_STATUS_FOOD_COMPLETED: string = environment.POS_ORDER_STATUS_FOOD_COMPLETED.toUpperCase();
  POS_ORDER_STATUS_FOOD_READY: string = environment.POS_ORDER_STATUS_FOOD_READY.toUpperCase();
  private consumer: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private kotService: KotService,
    private alertService: AlertService,
    private cableService: ActionCableService
  ) { }

  ngOnInit(): void {
    this.outletId = this.activatedRoute.snapshot.params['outlet_id'];
    this.getKotScreenChecks();
    //this.subscribeKotChannel(this.outletId);

    this.cableService.subscribeKotChannel(this.outletId);

    const orderStatusData: PosOrderStatus[] = JSON.parse(localStorage.getItem("orderStatusData"));
    if (!orderStatusData) {
      this.kotService.getOrderStatuses()
      .subscribe({
        next: (data: any) => {
          this.orderStatusList = data;
        },
        error: (error: any) => {
          if(error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      });
    } else {
      this.orderStatusList = orderStatusData;
    }
  }

  getKotScreenChecks() {
    this.kotService.getKotScreenCheckDetails(this.outletId)
    .subscribe({
      next: (data: PosCheck[]) => {
        this.checkList = data;
        this.mapKOTData();
      },
      error: (error: any) => {
        if(error.errors && error.errors.length > 0) {
          this.alertService.error(error.errors[0]);
        } else {
          this.alertService.error(error);
        }
      }
    });
  }

  onBackClick(): void {
    this.router.navigate(['/table-layout/' + this.outletId]);
  }

  setClasses(statusCode: string): string {
    let className: string = "";

    switch(statusCode.toUpperCase()) {
      case environment.POS_ORDER_STATUS_NEW.toUpperCase() :
        className = "";
      break;
      case environment.POS_ORDER_STATUS_ACKNOWLEDGE.toUpperCase() :
        className = "";
      break;
      case environment.POS_ORDER_STATUS_CANCEL.toUpperCase() :
        className = "kot_cncld";
      break;
      case environment.POS_ORDER_STATUS_FOOD_COMPLETED.toUpperCase() :
        className = "food_cmplte";
      break;
      case environment.POS_ORDER_STATUS_FOOD_READY.toUpperCase() :
        className = "ready_cls";
      break;
      case environment.POS_ORDER_STATUS_IN_PROGRESS.toUpperCase() :
        className = "in_prg_cls quadrat";
      break;
    }
    return className;
  }

  mapKOTData() {
    this.allKotList = [];
    this.pendingKotList = [];

    if(this.checkList && this.checkList.length > 0) {
      this.checkList.forEach(check => {
        check.pos_check_details.forEach(details => {
          let objKot =  {};
          objKot["check_no"] = check.check_no;
          objKot["kot_number"] = details.kot_no;
          objKot["id"] = details.id;
          objKot["pos_check_id"] = details.pos_check_id;
          objKot["pos_item_id"] = details.pos_item_id;
          objKot["kot_time"] = details.system_time;
          objKot["item_name"] = details.item_description;
          objKot["quantity"] = details.quantity;
          objKot["status_code"] = details.order_status_code ? details.order_status_code : environment.POS_ORDER_STATUS_NEW.toUpperCase();
          objKot["status"] = details.order_status ? details.order_status : environment.POS_ORDER_STATUS_NEW.toUpperCase();

          this.allKotList.push(objKot);
          
          if(objKot["status_code"].toUpperCase() != environment.POS_ORDER_STATUS_CANCEL.toUpperCase() || objKot["status_code"].toUpperCase() != environment.POS_ORDER_STATUS_FOOD_COMPLETED.toUpperCase()) {
            this.pendingKotList.push(objKot);
          } else {
            // do not add to pending list
          }
        });
      });
    }
  }

  // subscribeKotChannel(outletId: string) {
  //   const loadedUser: User = JSON.parse(localStorage.getItem("userData"));
    
  //   this.consumer = ActionCable.createConsumer(environment.ACTION_CABLE_ENPOINT + loadedUser.token);
  //   console.log("Trying connection");
    
  //   this.consumer.subscriptions.create(environment.KOT_SCREEN_CHANNEL +"_"+ outletId, {
  //     connected() {
  //       console.log("KOT Subscription is ready for use");
  //     },
  //     disconnected() {
  //       console.log("KOT Service terminated by WB server");
  //     },
  //     received(data) {
  //       if(data && data.pos_check_id) {
  //         let isExistingCheck = this.checkList.filter(x => x.id == data.pos_check_id)[0];
  
  //         if(isExistingCheck) {
  //           if(isExistingCheck.last_action.toUpperCase() == environment.LAST_ACTION_VOIDED.toUpperCase()) {
  //             const index = this.checkList.findIndex(object => {
  //               return object.id === data.pos_check_id;
  //             });
  
  //             this.checkList.splice(index, 1);
  //           } else {
  //             let objItem = isExistingCheck.pos_check_details.filter(x => x.id == data.id && x.pos_item_id == data.pos_item_id)[0];
  
  //             if(objItem) {
  //               const index = isExistingCheck.pos_check_details.findIndex(object => {
  //                 return object.id === data.id;
  //               });
  
  //               if(objItem.last_action.toUpperCase() == environment.LAST_ACTION_VOIDED.toUpperCase()) {
  //                 //remove item - when last_action is voided
  //                 isExistingCheck.pos_check_details.splice(index, 1);
  //               } else {
  //                 //update the status
  //                 isExistingCheck.pos_check_details[index].order_status_code = data.status_code;
  //               } 
  //             } else {
  //               // new Item added
  //               let newKotItem = new PosCheckDetail();
  //               newKotItem.id = data.id;
  //               newKotItem.pos_check_id = data.pos_check_id;
  //               newKotItem.pos_item_id = data.pos_item_id;
  //               newKotItem.order_status_code = data.status_code;
  //               newKotItem.quantity = data.quantity;
  //               newKotItem.item_description = data.pos_item_name;
  //               isExistingCheck.pos_check_details.push(newKotItem);
  
  //               let objKot =  {};
  //               objKot["id"] = data.id;
  //               objKot["pos_check_id"] = data.pos_check_id;
  //               objKot["pos_item_id"] = data.pos_item_id;
  //               objKot["item_name"] = data.pos_item_name;
  //               objKot["quantity"] = data.quantity;
  //               objKot["status_code"] = data.status_code;
  //               this.allKotList.push(objKot);
  //               this.pendingKotList.push(objKot);
  //             }
  //           }
  //         } else {
  //           // new check raised
  //           let newCheck = new PosCheck();
  //           newCheck.id = data.pos_check_id;
  //           newCheck.table_code = data.table_code;
  //           newCheck.server_name = data.server;
  //           newCheck.last_action = data.last_action;
  
  //           let detail = new PosCheckDetail();
  //           detail.id = data.id;
  //           detail.pos_check_id = data.pos_check_id;
  //           detail.pos_item_id = data.pos_item_id;
  //           detail.order_status_code = data.status_code;
  //           detail.quantity = data.quantity;
  //           detail.item_description = data.pos_item_name;
  
  //           newCheck.pos_check_details.push(detail);
  //           this.checkList.push(newCheck)
  
  //           let objKot =  {};
  //           objKot["id"] = data.id;
  //           objKot["pos_check_id"] = data.pos_check_id;
  //           objKot["pos_item_id"] = data.pos_item_id;
  //           objKot["item_name"] = data.pos_item_name;
  //           objKot["quantity"] = data.quantity;
  //           objKot["status_code"] = data.status_code;
  //           this.allKotList.push(objKot);
  //           this.pendingKotList.push(objKot);
  
  //           // if((data.status_code && data.status_code.toUpperCase() != environment.POS_ORDER_STATUS_CANCEL)
  //           // || (data.status_code && data.status_code.toUpperCase() != environment.POS_ORDER_STATUS_FOOD_COMPLETED)) {
  //           //   this.pendingKotList.push(objKot);
  //           // }
  //         }
  //       }
  //     }
  //   });
  // }

  updateItemOrderStatus(data, nextStatus) {
    //console.log(data);
    let newStatus = this.orderStatusList.filter(x => x.code.toUpperCase() == nextStatus.toUpperCase())[0];
    //console.log(newStatus.id)

    let posCheck = {
      id: data.pos_check_id,
      pos_check_details_attributes: [{
        id: data.id,
        pos_check_id: data.pos_check_id,
        pos_item_id: data.pos_item_id,
        pos_order_status_id: newStatus.id
      }]
    }

    this.kotService.updateOrderStatus(posCheck).subscribe({
      next: (data: PosCheck) => {
        const index = this.checkList.findIndex(object => {
          return object.id === data["pos_check"].id;
        });
        //remove first
        this.checkList.splice(index, 1);

        //Add updated check to same array
        let updatedCheck = this.kotService.mapPosCheck(data["pos_check"]);
        this.checkList.push(updatedCheck);

        this.mapKOTData();
      },
      error: (error: any) => {
        if(error.errors && error.errors.length > 0) {
          this.alertService.error(error.errors[0]);
        } else {
          this.alertService.error(error);
        }
      }
    });
  }
}
