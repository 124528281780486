<div class="wrapg_blk">
  <app-header></app-header>

  <!-- SOF Sub-header HTML -->
  <div class="sub_header_parnt menu_sub_hdr">
    <div class="sub_btn_grp">
      <button class="btn info_btn"><i class="bi bi-info-lg"></i></button>
    </div>
    <div class="sub_header_inr">
      <h2 class="page_name">Room post</h2>
      <div class="table_drpdwn">
        <select>
          <option *ngIf="posCheck">{{ posCheck.table_code }}</option>
        </select>
      </div>
      <div class="cover_num_blk">
        <p><label>Covers:</label>
          <input *ngIf="posCheck" type="number" [placeholder]="posCheck.covers ? posCheck.covers : ''" readonly />
        </p>
      </div>
      <div class="gust_info_blk">
        <div class="guest_name_parnt">
          <div class="guest_name_blk">
            <p>
              <label>Name:</label>
              <input *ngIf="posCheck" type="text" readonly
                [placeholder]="posCheck.guest_name ? posCheck.guest_name : '' " />
            </p>
          </div>
          <div class="guest_name_blk">
            <p>
              <label>GST:</label>
              <input *ngIf="posCheck" type="text"
                [placeholder]="posCheck.guest_gst_number ? posCheck.guest_gst_number : ''" readonly />
            </p>
          </div>
        </div>
      </div>
    </div>

    <app-business-date></app-business-date>
  </div>
  <!-- EOF Sub-header HTML -->

  <main class="main_cnt_blk action_btn_hght">
    <section class="table_tab_parnt">
      <div class="disnt_parnt_blk">
        <div class="row">
          <div class="col-12">
            <div class="disnt_parnt_inr">
              <div class="blue_main_parnt">
                <div class="row">
                  <div class="col-6 col-sm-4 col-lg-2" *ngIf="posCheck">
                    <label>Debit total</label>
                    {{ posCheck.dr_total }}
                  </div>
                  <div class="col-6 col-sm-4 col-lg-2" *ngIf="posCheck">
                    <label>Credit total</label>
                    {{ posCheck.cr_total }}
                  </div>
                  <div class="col-6 col-sm-4 col-lg-2" *ngIf="posCheck">
                    <label>Opened on</label>
                    {{ posCheck.open_time | date: 'dd MMM yyyy hh:mm' }}
                  </div>
                  <div class="col-6 col-sm-4 col-lg-2" *ngIf="posCheck">
                    <label>Bill no</label>
                    {{ posCheck.check_no }}
                  </div>
                </div>
              </div>
              <div class="disnt_inr_blk mb-15">
                <div class="row">
                  <div class="col-lg-2 col-xl-2">
                    <div class="generic_inpt">
                      <label>Room number</label>
                      <select (change)="onRoomSelection($event)">
                        <option value="">Please select</option>
                        <option *ngFor='let room of roomData;let i = index' [value]="room.id">
                          {{ room.description }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-4 col-lg-3">
                    <div class="generic_inpt">
                      <label>First name</label>
                      <input type="text" class="form-control" [(ngModel)]="first_name" [maxlength]="maxChars">
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-4 col-lg-3">
                    <div class="generic_inpt">
                      <label>Last name</label>
                      <input type="text" class="form-control" [(ngModel)]="last_name" [maxlength]="maxChars">
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-4 col-xl-4 gust_scrh_radio_blk">
                    <div class="gust_dtls_radio">
                      <div class="radio_blk" tabindex="0">
                        <input type="radio" name="radio" id="radio00" class="form-control radio_clck" value="CI"
                          [checked]="inhouse_status === 'CI'" [(ngModel)]="inhouse_status">
                        <label for="radio00" class="radio_label">Innhouse</label>
                      </div>
                      <span class="radio_dvdr"></span>
                      <div class="radio_blk" tabindex="0">
                        <input type="radio" name="radio" id="radio01" class="form-control radio_clck" value="CO"
                          [checked]="inhouse_status === 'CO'" [(ngModel)]="inhouse_status">
                        <label for="radio01" class="radio_label">Checkout</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-lg-4 col-xl-4 gust_scrh_radio_blk">
                    <div class="gust_dtls_radio">
                      <div class="radio_blk" tabindex="0">
                        <input type="radio" name="radioGuest" id="radio02" class="form-control radio_clck" value="1"
                          [checked]="primary_client === '1'" [(ngModel)]="primary_client">
                        <label for="radio02" class="radio_label">Primary guest</label>
                      </div>
                      <span class="radio_dvdr"></span>
                      <div class="radio_blk" tabindex="0">
                        <input type="radio" name="radioGuest" id="radio03" class="form-control radio_clck" value="0"
                          [checked]="primary_client === '0'" [(ngModel)]="primary_client">
                        <label for="radio03" class="radio_label">All</label>
                      </div>
                    </div>
                    <button type="button" class="btn scrh_btn_main" (click)="searchRecords()"><i
                        class="bi bi-search"></i>Search</button>
                  </div>
                  <div class="col-12 mt-2 mt-md-0">
                    <h2 class="title_cls">{{ subheader_text }}</h2>
                    <div class="dx-viewport demo-container">
                      <div id="grid">
                        <dx-data-grid id="gridGuest" [dataSource]="guestData" keyExpr="visit_id" [showBorders]="false"
                          [showColumnHeaders]="true" [showRowLines]="true" [showColumnLines]="true"
                          (onSelectionChanged)="onGuestChecked($event)">
                          <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
                          <dxo-pager [showNavigationButtons]="true" [showPageSizeSelector]="false"
                            [visible]="true"></dxo-pager>
                          <dxo-selection mode="multiple" showCheckBoxesMode="always" allowSelectAll="false"></dxo-selection>
                          <dxo-scrolling mode="standard"></dxo-scrolling>
                          <dxo-editing mode="cell" [allowUpdating]="false">
                          </dxo-editing>
                          <dxi-column dataField="visit_id" [visible]="false"></dxi-column>
                          <dxi-column dataField="inhouse_id" caption="Inhouse id">
                          </dxi-column>
                          <dxi-column dataField="room_number" caption="Room number">
                          </dxi-column>
                          <dxi-column dataField="guest_name" caption="Guest name">
                          </dxi-column>
                          <dxi-column dataField="arrival_date" caption="Arrival date" dataType="date"
                            format="dd/MM/yyyy">
                          </dxi-column>
                          <dxi-column dataField="departure_date" caption="Departure date" dataType="date"
                            format="dd/MM/yyyy">
                          </dxi-column>
                          <dxi-column dataField="billing_instruction" caption="Billing instruction">
                          </dxi-column>
                        </dx-data-grid>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="action_btn_parnt">
      <ul>
        <li (click)="onRoomPost()">
          <a href="javascript:void(0)" class="form-control" tabindex="0">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99.66 122.88">
              <defs>
                <style>
                  .cls-1 {
                    fill-rule: evenodd;
                  }
                </style>
              </defs>
              <title>entrance-door</title>
              <path class="cls-1"
                d="M0,115H99.66v7.86H0V115Zm29-7.19H20.37v-6h2.24v3.73H29v2.23Zm0-95.36H22.61V16.2H20.37v-6H29v2.23ZM70.66,105.6h6.39v-3.73h2.24v6H70.66V105.6Zm0-95.36h8.63v6H77.05V12.47H70.66V10.24ZM15,5.16H84.72V113.42h5.52V0H8.64V113.42h5.51V5.16Zm6.48,44.63,4.37-1.25V64.88L21.49,63.3V49.79Z" />
            </svg>
            {{ 'ACTION_BUTTON.ROOM_POST' | translate }}
          </a>
        </li>
        <li (click)="onBackClick()">
          <a href="javascript:void(0)" class="form-control" tabindex="0">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 122.88 98.86" style="enable-background:new 0 0 122.88 98.86" xml:space="preserve">
              <style type="text/css">
                .st0 {
                  fill-rule: evenodd;
                  clip-rule: evenodd;
                }
              </style>
              <g>
                <path class="st0"
                  d="M0,49.43l48.93,49.43V74.23c30.94-6.41,55.39,0.66,73.95,24.19c-3.22-48.4-36.29-71.76-73.95-73.31V0L0,49.43 L0,49.43L0,49.43z" />
              </g>
            </svg>
            {{ 'ACTION_BUTTON.BACK' | translate }}
          </a>
        </li>
      </ul>
    </section>

    <!-- SOF Common keyboard html -->
    <app-qwerty-keyboard></app-qwerty-keyboard>
    <!-- EOF Common keyboard html -->
  </main>
</div>