import { PointerService } from "../services/pointer.service";
import { PosCheckCharge } from "./pos-check-charge.model";
import { PosCheckDetail } from "./pos-check-detail.model";
import { PosCheckSettlement } from './pos-check-settlement.model';
import { PosCheckDelivery } from './pos-check-delivery.model';
import { User } from './user.model';

export class PosCheck {
  private _doc_id_rev: string;
  private _is_new: boolean;
  private _is_dirty: boolean;

  private _id: number;
  private _pos_restaurant_id: number;
  private _check_no: string;
  private _open_date: Date;
  private _open_time: Date;
  private _close_date: Date;
  private _close_time: Date;

  private _pos_ang_category_id: number;
  private _table_code: string;
  private _covers: number;
  private _remarks: string;
  private _dr_total: number;
  private _cr_total: number;

  private _tips: number;
  private _round_off: number;
  private _split_count: number;
  private _currency_id: number;
  private _take_away_ind: boolean;
  private _room_settle_ind: boolean;
  private _last_action: string;

  private _happy_hour_ind: boolean;
  private _open_shift: number;
  private _close_shift: number;

  private _print_count: number;
  private _print_date: Date;
  private _print_time: Date;
  private _print_shift: number;
  private _print_seq_no: number;

  private _check_series_no: number;
  private _guest_name: string;
  private _guest_gst_number: string;

  private _void_reason_note: string;
  private _pos_void_reason_id: number;
  private _void_date_time: Date;
  private _from_void_check_no: string;
  private _from_table_code: string;

  private _split_check_ind: boolean;
  private _server_id: number;
  private _server_name: string;

  private _open_cashier_id: number;
  private _close_cashier_id: number;
  private _print_cashier_id: number;
  private _void_user_id: number;

  private _membership_number: string;
  private _guest_id: string;
  private _freeze_ind: boolean;
  private _freeze_at: Date;
  private _updated_place: string;
  private _saas_company_id: number;
  private _company_id: number;
  private _branch_id: number;
  private _restaurant_name: string;
  private _pos_ang_category: string;
  private _print_cashier_name: string;
  private _ref_check_no: string;
  private _pos_check_details: PosCheckDetail[];
  private _pos_check_charges: PosCheckCharge[];
  private _pos_check_settlements: PosCheckSettlement[];
  private _pos_check_delivery: PosCheckDelivery[];
  private _debit_card_number: string;
  private _debit_card_reference_id: string;

  constructor() {
    this._is_new = true;
    this._is_dirty = false;
    this._open_date = PointerService.getBusinessDate();
    this._open_time = new Date();
    this._cr_total = 0;
    this._dr_total = 0;
    this._pos_check_charges = [];
    this._pos_check_details = [];
    this._pos_check_settlements = [];
    this._pos_check_delivery = [];
  }

  get doc_id_rev(): string {
    return this._doc_id_rev;
  }
  set doc_id_rev(value: string) {
    this._doc_id_rev = value;
  }

  get is_new(): boolean {
    return this._is_new;
  }
  set is_new(value: boolean) {
    this._is_new = value;
  }

  get is_dirty(): boolean {
    return this._is_dirty;
  }
  set is_dirty(value: boolean) {
    this._is_dirty = value;
  }

  public get id(): number {
    return this._id;
  }
  public set id(value: number) {
    this._id = value;
  }

  public get pos_restaurant_id(): number {
    return this._pos_restaurant_id;
  }
  public set pos_restaurant_id(value: number) {
    this._pos_restaurant_id = value;
  }

  public get check_no(): string {
    return this._check_no;
  }
  public set check_no(value: string) {
    this._check_no = value;
  }

  get open_date() {
    return this._open_date;
  }
  set open_date(value: Date) {
    this._open_date = value;
  }

  get open_time() {
    return this._open_time;
  }
  set open_time(value: Date) {
    this._open_time = value;
  }

  get close_date() {
    return this._close_date;
  }
  set close_date(value: Date) {
    this._close_date = value;
  }

  get close_time() {
    return this._close_time;
  }
  set close_time(value: Date) {
    this._close_time = value;
  }

  public get pos_ang_category_id(): number {
    return this._pos_ang_category_id;
  }
  public set pos_ang_category_id(value: number) {
    this._pos_ang_category_id = value;
  }

  get table_code() {
    return this._table_code;
  }
  set table_code(code: string) {
    this._table_code = code;
  }

  public get covers(): number {
    return this._covers;
  }
  public set covers(value: number) {
    this._covers = value;
  }

  public get remarks(): string {
    return this._remarks;
  }
  public set remarks(value: string) {
    this._remarks = value;
  }

  public get dr_total(): number {
    return this._dr_total;
  }
  public set dr_total(value: number) {
    this._dr_total = value;
  }

  public get cr_total(): number {
    let sum = 0;
    this.pos_check_settlements.forEach(element => {
      if (element.allowance_ind) {
        sum -= +element.amount;
      }
      else {
        sum += +element.amount;
      }
    });
    return sum;
  }

  public set cr_total(value: number) {
    this._cr_total = value;
  }

  public get tips(): number {
    return this._tips;
  }
  public set tips(value: number) {
    this._tips = value;
  }

  public get round_off(): number {
    return this._round_off;
  }
  public set round_off(value: number) {
    this._round_off = value;
  }

  public get split_count(): number {
    return this._split_count;
  }
  public set split_count(value: number) {
    this._split_count = value;
  }

  public get currency_id(): number {
    return this._currency_id;
  }
  public set currency_id(value: number) {
    this._currency_id = value;
  }

  public get take_away_ind(): boolean {
    return this._take_away_ind;
  }
  public set take_away_ind(value: boolean) {
    this._take_away_ind = value;
  }

  public get room_settle_ind(): boolean {
    return this._room_settle_ind;
  }
  public set room_settle_ind(value: boolean) {
    this._room_settle_ind = value;
  }

  public get last_action(): string {
    return this._last_action;
  }
  public set last_action(value: string) {
    this._last_action = value;
  }

  get happy_hour_ind() {
    return this._happy_hour_ind;
  }
  set happy_hour_ind(value: boolean) {
    this._happy_hour_ind = value;
  }

  get open_shift(): number {
    return this._open_shift;
  }
  set open_shift(value: number) {
    this._open_shift = value;
  }

  public get close_shift(): number {
    return this._close_shift;
  }
  public set close_shift(value: number) {
    this._close_shift = value;
  }

  public get print_count(): number {
    return this._print_count;
  }
  public set print_count(value: number) {
    this._print_count = value;
  }

  public get print_date(): Date {
    return this._print_date;
  }
  public set print_date(value: Date) {
    this._print_date = value;
  }

  public get print_time(): Date {
    return this._print_time;
  }
  public set print_time(value: Date) {
    this._print_time = value;
  }

  public get print_shift(): number {
    return this._print_shift;
  }
  public set print_shift(value: number) {
    this._print_shift = value;
  }

  public get print_seq_no(): number {
    return this._print_seq_no;
  }
  public set print_seq_no(value: number) {
    this._print_seq_no = value;
  }

  public get check_series_no(): number {
    return this._check_series_no;
  }
  public set check_series_no(value: number) {
    this._check_series_no = value;
  }

  public get guest_name(): string {
    return this._guest_name;
  }
  public set guest_name(value: string) {
    this._guest_name = value;
  }

  public get guest_gst_number(): string {
    return this._guest_gst_number;
  }
  public set guest_gst_number(value: string) {
    this._guest_gst_number = value;
  }

  public get void_reason_note(): string {
    return this._void_reason_note;
  }
  public set void_reason_note(value: string) {
    this._void_reason_note = value;
  }

  public get pos_void_reason_id(): number {
    return this._pos_void_reason_id;
  }
  public set pos_void_reason_id(value: number) {
    this._pos_void_reason_id = value;
  }

  public get void_date_time(): Date {
    return this._void_date_time;
  }
  public set void_date_time(value: Date) {
    this._void_date_time = value;
  }

  public get from_void_check_no(): string {
    return this._from_void_check_no;
  }
  public set from_void_check_no(value: string) {
    this._from_void_check_no = value;
  }

  public get from_table_code(): string {
    return this._from_table_code;
  }
  public set from_table_code(value: string) {
    this._from_table_code = value;
  }

  public get split_check_ind(): boolean {
    return this._split_check_ind;
  }
  public set split_check_ind(value: boolean) {
    this._split_check_ind = value;
  }

  public get server_id(): number {
    return this._server_id;
  }
  public set server_id(value: number) {
    this._server_id = value;
  }

  public get server_name(): string {
    return this._server_name;
  }
  public set server_name(value: string) {
    this._server_name = value;
  }

  public get open_cashier_id(): number {
    return this._open_cashier_id;
  }
  public set open_cashier_id(value: number) {
    this._open_cashier_id = value;
  }

  public get close_cashier_id(): number {
    return this._close_cashier_id;
  }
  public set close_cashier_id(value: number) {
    this._close_cashier_id = value;
  }

  public get print_cashier_id(): number {
    return this._print_cashier_id;
  }
  public set print_cashier_id(value: number) {
    this._print_cashier_id = value;
  }

  public get void_user_id(): number {
    return this._void_user_id;
  }
  public set void_user_id(value: number) {
    this._void_user_id = value;
  }

  public get membership_number(): string {
    return this._membership_number;
  }
  public set membership_number(value: string) {
    this._membership_number = value;
  }

  public get guest_id(): string {
    return this._guest_id;
  }
  public set guest_id(value: string) {
    this._guest_id = value;
  }

  public get freeze_ind(): boolean {
    return this._freeze_ind;
  }
  public set freeze_ind(value: boolean) {
    this._freeze_ind = value;
  }

  public get freeze_at(): Date {
    return this._freeze_at;
  }
  public set freeze_at(value: Date) {
    this._freeze_at = value;
  }

  public get updated_place(): string {
    return this._updated_place;
  }
  public set updated_place(value: string) {
    this._updated_place = value;
  }

  public get saas_company_id(): number {
    return this._saas_company_id;
  }
  public set saas_company_id(value: number) {
    this._saas_company_id = value;
  }

  public get company_id(): number {
    return this._company_id;
  }
  public set company_id(value: number) {
    this._company_id = value;
  }

  public get branch_id(): number {
    return this._branch_id;
  }
  public set branch_id(value: number) {
    this._branch_id = value;
  }

  public get restaurant_name(): string {
    return this._restaurant_name;
  }
  public set restaurant_name(value: string) {
    this._restaurant_name = value;
  }

  public get pos_ang_category(): string {
    return this._pos_ang_category;
  }
  public set pos_ang_category(value: string) {
    this._pos_ang_category = value;
  }

  public get print_cashier_name(): string {
    return this._print_cashier_name;
  }
  public set print_cashier_name(value: string) {
    this._print_cashier_name = value;
  }

  public get ref_check_no(): string {
    return this._ref_check_no;
  }
  public set ref_check_no(value: string) {
    this._ref_check_no = value;
  }

  public get pos_check_details(): PosCheckDetail[] {
    return this._pos_check_details;
  }
  public set pos_check_details(value: PosCheckDetail[]) {
    this._pos_check_details = value;
  }

  public get pos_check_charges(): PosCheckCharge[] {
    return this._pos_check_charges;
  }
  public set pos_check_charges(value: PosCheckCharge[]) {
    this._pos_check_charges = value;
  }

  public get pos_check_settlements(): PosCheckSettlement[] {
    return this._pos_check_settlements;
  }
  public set pos_check_settlements(value: PosCheckSettlement[]) {
    this._pos_check_settlements = value;
  }

  public get pos_check_delivery(): PosCheckDelivery[] {
    return this._pos_check_delivery;
  }
  public set pos_check_delivery(value: PosCheckDelivery[]) {
    this._pos_check_delivery = value;
  }

  public get debit_card_number(): string {
    return this._debit_card_number;
  }
  public set debit_card_number(value: string) {
    this._debit_card_number = value;
  }

  public get debit_card_reference_id(): string {
    return this._debit_card_reference_id;
  }
  public set debit_card_reference_id(value: string) {
    this._debit_card_reference_id = value;
  }

  public assignUserProperties(user: User) {
    this._saas_company_id = user.saas_company_id;
    this._company_id = user.company_id;
    this._branch_id = user.branch_id;
  }

  public static createJson(posCheck: PosCheck) {
    const checkCharge = [], checkDetail = [], checkSettlement = [], checkDelivery = [];

    if (!posCheck.ref_check_no) {
      //posCheck.ref_check_no = this.generateNextRefCheckNo();
    }

    if (posCheck._pos_check_details && posCheck._pos_check_details.length > 0) {
      posCheck._pos_check_details.forEach(item => {
        let checkDetailModifier = [];
        item.pos_check_detail_modifiers.forEach(modifier => {
          let objModifier = {
            "id": modifier.id,
            "pos_modifier_id": modifier.pos_modifier_id,
            "serial_number": modifier.serial_number,
            "currency_id": modifier.currency_id,
            "amount": modifier.amount,
            "modifier_name_on_check": modifier.modifier_name_on_check
          }
          checkDetailModifier.push(objModifier);
        })

        let objDetail = {
          "id": item.id,
          "_destroy": item.destroy,
          "pos_item_id": item.pos_item_id,
          "shift": item.shift,
          "business_date": item.business_date,
          "system_time": item.system_time,
          "pos_restaurant_id": item.pos_restaurant_id,
          "service": item.service,
          "charge_id": item.charge_id,
          "void_quantity": item.void_quantity,
          "void_by_id": item.void_by_id,
          "pos_void_reason_id": item.pos_void_reason_id,
          "void_reason_note": item.void_reason_note,
          "void_at": item.void_at,
          "void_place": item.void_place,
          "last_action": item.last_action,
          "item_description": item.item_description,
          "pos_menu_id": item.pos_menu_id,
          "pos_kitchen_id": item.pos_kitchen_id,
          "serial_number": item.serial_number,
          "quantity": item.quantity,
          "rate": item.rate,
          "amount": item.amount,
          "currency_id": item.currency_id,
          "pos_item_name_on_check": item.pos_item_name_on_check,
          "freeze_ind": item.freeze_ind,
          "freeze_at": item.freeze_at,
          "pos_check_detail_modifiers_attributes": checkDetailModifier
        }
        checkDetail.push(objDetail);
      });
    }

    if (posCheck.pos_check_charges && posCheck.pos_check_charges.length > 0) {
      posCheck.pos_check_charges.forEach(charge => {
        let charge_taxes = [], charge_discounts = [];

        if (charge.pos_check_charge_taxes && charge.pos_check_charge_taxes.length > 0) {
          charge.pos_check_charge_taxes.forEach(tax => {
            let objTax = {
              "id": tax.id,
              "_destroy": tax.destroy,
              "charge_id": charge.charge_id,
              "currency_id": tax.currency_id,
              "tax_id": tax.tax_id,
              "tax_percentage": tax.tax_percentage,
              "dr_cr": tax.dr_cr,
              "serial_number": tax.serial_number,
              "amount": tax.amount,
              "freeze_ind": tax.freeze_ind,
              "freeze_at": tax.freeze_at,
              "description": tax.description
            }
            charge_taxes.push(objTax);
          });
        }

        if (charge.pos_check_charge_discounts && charge.pos_check_charge_discounts.length > 0) {
          charge.pos_check_charge_discounts.forEach(discount => {
            let objDiscount = {
              "id": discount.id,
              "_destroy": discount.destroy,
              "pos_check_charge_id": discount.pos_check_charge_id,
              "charge_id": discount.charge_id,
              "currency_id": discount.currency_id,
              "pos_discount_id": discount.pos_discount_id,
              "dr_cr": discount.dr_cr,
              "serial_number": discount.serial_number,
              "amount": discount.amount,
              "description": discount.description,
              "discount_percentage": discount.discount_percentage
            }
            charge_discounts.push(objDiscount);
          })
        }

        let objCharge = {
          "id": charge.id,
          "_destroy": charge.destroy,
          "charge_id": charge.charge_id,
          "currency_id": charge.currency_id,
          "dr_cr": charge.dr_cr,
          "serial_number": charge.serial_number,
          "amount": charge.amount,
          "description": charge.description,
          "pos_check_charge_discounts_attributes": charge_discounts,
          "pos_check_charge_taxes_attributes": charge_taxes
        }

        checkCharge.push(objCharge);
      });
    }

    if (posCheck.pos_check_settlements && posCheck.pos_check_settlements.length > 0) {
      posCheck.pos_check_settlements.forEach(payment => {
        let objSettlement = {
          "id": payment.id,
          "_destroy": payment.destroy,
          "charge_id": payment.charge_id,
          "currency_id": payment.currency_id,
          "dr_cr": payment.dr_cr,
          "settlement_type": payment.settlement_type,
          "description": payment.description,
          "amount": payment.amount,
          "card_no": payment.card_no,
          "allowance_ind": payment.allowance_ind,
          "freeze_ind": payment.freeze_ind,
          "freeze_at": payment.freeze_at
        }
        checkSettlement.push(objSettlement);
      });
    }

    if (posCheck.pos_check_delivery && posCheck.pos_check_delivery.length > 0) {
      posCheck.pos_check_delivery.forEach(delivery => {
        let objDelivery = {
          "id": delivery.id,
          "account_id": delivery.account_id,
          "account_address_id": delivery.account_address_id,
          "address1": delivery.address1,
          "address2": delivery.address2,
          "address3": delivery.address3,
          "city": delivery.city,
          "state": delivery.state,
          "country": delivery.country,
          "delivery_contact_number": delivery.delivery_contact_number,
          "delivery_email": delivery.delivery_email,
          "delivery_at": delivery.delivery_at,
          "schedule_at": delivery.schedule_at,
          "schedule_ind": delivery.schedule_ind,
          "notes": delivery.notes
        }
        checkDelivery.push(objDelivery);
      });
    }

    const objCheck = {
      "id": posCheck.id,
      "pos_restaurant_id": posCheck.pos_restaurant_id,
      //"restaurant_name": posCheck.restaurant_name,
      //"ref_check_no": posCheck.ref_check_no,
      "check_no": posCheck.check_no,
      "open_date": posCheck.open_date,
      "open_time": posCheck.open_time,
      "close_date": posCheck.close_date,
      "close_time": posCheck.close_time,
      "pos_ang_category_id": posCheck.pos_ang_category_id,
      "table_code": posCheck.table_code,
      "covers": posCheck.covers,
      "dr_total": posCheck.dr_total,
      "cr_total": posCheck.cr_total,
      "round_off": posCheck.round_off,
      "split_count": posCheck.split_count,
      "currency_id": posCheck.currency_id,
      "take_away_ind": posCheck.take_away_ind,
      "last_action": posCheck.last_action,
      "happy_hour_ind": posCheck.happy_hour_ind,
      "open_shift": posCheck.open_shift,
      "close_shift": posCheck.close_shift,
      "print_count": posCheck.print_count,
      "print_date": posCheck.print_date,
      "print_time": posCheck.print_time,
      "print_shift": posCheck.print_shift,
      "guest_name": posCheck.guest_name,
      "guest_gst_number": posCheck.guest_gst_number,
      "guest_id": posCheck.guest_id,
      "void_reason_note": posCheck.void_reason_note,
      "pos_void_reason_id": posCheck.pos_void_reason_id,
      "void_date_time": posCheck.void_date_time,
      "from_void_check_no": posCheck.from_void_check_no,
      "from_table_code": posCheck.from_table_code,
      "split_check_ind": posCheck.split_check_ind,
      "server_id": posCheck.server_id,
      "open_cashier_id": posCheck.open_cashier_id,
      "close_cashier_id": posCheck.close_cashier_id,
      "print_cashier_id": posCheck.print_cashier_id,
      "void_user_id": posCheck.void_user_id,
      "membership_number": posCheck.membership_number,
      "freeze_ind": posCheck.freeze_ind,
      "freeze_at": posCheck.freeze_at,
      "updated_place": posCheck.updated_place,
      "saas_company_id": posCheck.saas_company_id,
      "company_id": posCheck.company_id,
      "branch_id": posCheck.branch_id,
      "pos_check_details_attributes": checkDetail,
      "pos_check_charges_attributes": checkCharge,
      "pos_check_settlements_attributes": checkSettlement,
      "pos_check_delivery_details_attributes": checkDelivery,
      "debit_card_number": posCheck.debit_card_number,
      "debit_card_reference_id": posCheck.debit_card_reference_id

    }
    return objCheck;
  }

  public static createSettleCheckJson(posCheck: PosCheck) {
    const checkSettlement = [];
    if (posCheck.pos_check_settlements && posCheck.pos_check_settlements.length > 0) {
      posCheck.pos_check_settlements.forEach(payment => {
        let objSettlement = {
          "id": payment.is_dirty ? payment.id : '',
          "_destroy": payment.destroy,
          "charge_id": payment.charge_id,
          "currency_id": payment.currency_id,
          "dr_cr": payment.dr_cr,
          "settlement_type": payment.settlement_type,
          "description": payment.description,
          "amount": payment.amount,
          "card_no": payment.card_no,
          "allowance_ind": payment.allowance_ind,
          "room_no": payment.room_no,
          "inhouse_id": payment.inhouse_id,
          "visit_id": payment.visit_id,
          "business_date": PointerService.getBusinessDate(),
          "system_date": new Date(),
          "system_time": new Date(),
          "shift_no": posCheck.close_shift,
          "freeze_ind": payment.freeze_ind,
          "freeze_at": payment.freeze_at
        }
        checkSettlement.push(objSettlement);
      });
    }

    const objCheck = {
      "id": posCheck.id,
      "pos_restaurant_id": posCheck.pos_restaurant_id,
      "check_no": posCheck.check_no,
      "pos_ang_category_id": posCheck.pos_ang_category_id,
      "table_code": posCheck.table_code,
      "covers": posCheck.covers,
      "dr_total": posCheck.dr_total,
      "cr_total": posCheck.cr_total,
      "round_off": posCheck.round_off,
      "close_shift": posCheck.close_shift,
      // "print_ind": true,
      "room_settle_ind": posCheck.room_settle_ind,
      "updated_place": posCheck.updated_place,
      "saas_company_id": posCheck.saas_company_id,
      "company_id": posCheck.company_id,
      "branch_id": posCheck.branch_id,
      "pos_check_settlements_attributes": checkSettlement
    }
    return objCheck;
  }

  public static createPrintCheckJson(posCheck: PosCheck) {
    const checkCharge = [], checkDetail = [], checkSettlement = [];

    if (posCheck._pos_check_details && posCheck._pos_check_details.length > 0) {
      posCheck._pos_check_details.forEach(item => {
        let objDetail = {
          "id": item.id,
          "pos_item_id": item.pos_item_id,
          "shift": item.shift,
          "business_date": item.business_date,
          "system_time": item.system_time,
          "pos_restaurant_id": item.pos_restaurant_id,
          "service": item.service,
          "charge_id": item.charge_id,
          "void_quantity": item.void_quantity,
          "void_by_id": item.void_by_id,
          "pos_void_reason_id": item.pos_void_reason_id,
          "void_reason_note": item.void_reason_note,
          "void_at": item.void_at,
          "void_place": item.void_place,
          "last_action": item.last_action,
          "item_description": item.item_description,
          "pos_menu_id": item.pos_menu_id,
          "pos_kitchen_id": item.pos_kitchen_id,
          "serial_number": item.serial_number,
          "quantity": item.quantity,
          "rate": item.rate,
          "amount": item.amount,
          "currency_id": item.currency_id,
          "pos_item_name_on_check": item.pos_item_name_on_check,
          "print_charge_group_code": item.charge ? item.charge.print_chage_group_code : ""
        }
        checkDetail.push(objDetail);
      });
    }

    if (posCheck.pos_check_charges && posCheck.pos_check_charges.length > 0) {
      posCheck.pos_check_charges.forEach(charge => {
        let charge_taxes = [], charge_discounts = [];

        let print_charge_group_code: string = charge.charge ? charge.charge.print_chage_group_code : "";

        if (charge.pos_check_charge_taxes && charge.pos_check_charge_taxes.length > 0) {
          charge.pos_check_charge_taxes.forEach(tax => {
            let objTax = {
              "id": tax.id,
              "charge_id": charge.charge_id,
              "currency_id": tax.currency_id,
              "tax_id": tax.tax_id,
              "tax_percentage": tax.tax_percentage,
              "dr_cr": tax.dr_cr,
              "serial_number": tax.serial_number,
              "amount": tax.amount,
              "description": tax.description,
              "print_charge_group_code": print_charge_group_code
            }
            charge_taxes.push(objTax);
          });
        }

        if (charge.pos_check_charge_discounts && charge.pos_check_charge_discounts.length > 0) {
          charge.pos_check_charge_discounts.forEach(discount => {
            let objDiscount = {
              "id": discount.id,
              "pos_check_charge_id": discount.pos_check_charge_id,
              "charge_id": discount.charge_id,
              "currency_id": discount.currency_id,
              "pos_discount_id": discount.pos_discount_id,
              "dr_cr": discount.dr_cr,
              "serial_number": discount.serial_number,
              "amount": discount.amount,
              "description": discount.description,
              "discount_percentage": discount.discount_percentage,
              "print_charge_group_code": print_charge_group_code
            }
            charge_discounts.push(objDiscount);
          })
        }

        let objCharge = {
          "id": charge.id,
          "charge_id": charge.charge_id,
          "print_charge_group_code": print_charge_group_code,
          "currency_id": charge.currency_id,
          "dr_cr": charge.dr_cr,
          "serial_number": charge.serial_number,
          "amount": charge.amount,
          "description": charge.description,
          "pos_check_charge_discounts_attributes": charge_discounts,
          "pos_check_charge_taxes_attributes": charge_taxes
        }

        checkCharge.push(objCharge);
      });
    }

    if (posCheck.pos_check_settlements && posCheck.pos_check_settlements.length > 0) {
      posCheck.pos_check_settlements.forEach(payment => {
        let objSettlement = {
          "id": payment.id,
          "charge_id": payment.charge_id,
          "currency_id": payment.currency_id,
          "dr_cr": payment.dr_cr,
          "settlement_type": payment.settlement_type,
          "description": payment.description,
          "amount": payment.amount,
          "card_no": payment.card_no,
          "allowance_ind": payment.allowance_ind
        }
        checkSettlement.push(objSettlement);
      });
    }

    const objCheck = {
      "id": posCheck.id,
      "pos_restaurant_id": posCheck.pos_restaurant_id,
      "restaurant_name": posCheck.restaurant_name,
      //"ref_check_no": posCheck.ref_check_no,
      "check_no": posCheck.check_no,
      "open_date": posCheck.open_date,
      "open_time": posCheck.open_time,
      "close_date": posCheck.close_date,
      "close_time": posCheck.close_time,
      "pos_ang_category_id": posCheck.pos_ang_category_id,
      "table_code": posCheck.table_code,
      "covers": posCheck.covers,
      "dr_total": posCheck.dr_total,
      "cr_total": posCheck.cr_total,
      "round_off": posCheck.round_off,
      "split_count": posCheck.split_count,
      "currency_id": posCheck.currency_id,
      "take_away_ind": posCheck.take_away_ind,
      "last_action": posCheck.last_action,
      "happy_hour_ind": posCheck.happy_hour_ind,
      "open_shift": posCheck.open_shift,
      "close_shift": posCheck.close_shift,
      "print_count": posCheck.print_count,
      "print_date": posCheck.print_date,
      "print_time": posCheck.print_time,
      "print_shift": posCheck.print_shift,
      "pos_ang_category": posCheck.pos_ang_category,
      "guest_name": posCheck.guest_name,
      "guest_gst_number": posCheck.guest_gst_number,
      "guest_id": posCheck.guest_id,
      "void_reason_note": posCheck.void_reason_note,
      "pos_void_reason_id": posCheck.pos_void_reason_id,
      "void_date_time": posCheck.void_date_time,
      "from_void_check_no": posCheck.from_void_check_no,
      "from_table_code": posCheck.from_table_code,
      "split_check_ind": posCheck.split_check_ind,
      "server_id": posCheck.server_id,
      "open_cashier_id": posCheck.open_cashier_id,
      "close_cashier_id": posCheck.close_cashier_id,
      "print_cashier_id": posCheck.print_cashier_id,
      "print_cashier_name": posCheck.print_cashier_name,
      "void_user_id": posCheck.void_user_id,
      "membership_number": posCheck.membership_number,
      "freeze_ind": posCheck.freeze_ind,
      "freeze_at": posCheck.freeze_at,
      "updated_place": posCheck.updated_place,
      "saas_company_id": posCheck.saas_company_id,
      "company_id": posCheck.company_id,
      "branch_id": posCheck.branch_id,
      "pos_check_details_attributes": checkDetail,
      "pos_check_charges_attributes": checkCharge,
      "pos_check_settlements_attributes": checkSettlement,
      "debit_card_number": posCheck.debit_card_number,
      "debit_card_reference_id": posCheck.debit_card_reference_id
    }
    return objCheck;
  }
}