import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { User } from '../models/user.model';
import { environment } from '../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { UserOutlet } from '../models/user-outlet.model';

export interface AuthResponseData {
  auth_token: string;
  user: {
    id: string;
    email: string;
    code: string;
    title: string;
    first_name: string;
    last_name: string;
    full_name: string;
    saas_company_id: number;
    company_id: number;
    branch_id: number;
    saas_company_name: string;
    company_name: string;
    branch_name: string;
    saas_company_code: string;
    company_code: string;
    branch_code: string;
    rights: [];
    mobile_number: string;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userSubject = new BehaviorSubject<User>(null);
  public user: Observable<User>;

  constructor(private http: HttpClient, private router: Router) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('userData')));
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  validateLogin(username: string, password: string) {
    return this.http.post<AuthResponseData>(
      environment.APIENDPOINT + environment.AUTH,
      {
        code: username,
        password: password,
        module: environment.MODULE_NAME
      }
    ).pipe(catchError(errorRes => {
      let errorMessage = errorRes;
      if (errorRes.error && errorRes.error.errors && errorRes.error.errors.length && errorRes.error.errors.length > 0) {
        errorMessage = errorRes.error.errors;
      }
      else if (errorRes.status === 0) {
        errorMessage = "Api service is down";
      }
      return throwError(errorMessage);
    }), tap(resData => {
      const user = this.setUser(resData);
      this.userSubject.next(user);
    }));
  }

  validateEmailLogin(email: string, password: string) {
    return this.http.post<AuthResponseData>(
      environment.APIENDPOINT + environment.AUTH,
      {
        email: email,
        password: password,
        module: environment.MODULE_NAME
      }
    ).pipe(catchError(errorRes => {
      let errorMessage = errorRes;
      if (errorRes.error && errorRes.error.errors && errorRes.error.errors.length && errorRes.error.errors.length > 0) {
        errorMessage = errorRes.error.errors;
      }
      else if (errorRes.status === 0) {
        errorMessage = "Api service is down";
      }
      return throwError(errorMessage);
    }), tap(resData => {
      const user = this.setUser(resData);
      this.userSubject.next(user);
    }));
  }

  logout() {
    this.userSubject.next(null);
    localStorage.removeItem("chargeData");
    localStorage.removeItem("itemMenu");
    localStorage.removeItem("itemModifier");
    localStorage.removeItem("outletSetting");
    localStorage.removeItem("pointerData");
    localStorage.removeItem("posRestaurant");
    localStorage.removeItem("tableLayout");
    localStorage.removeItem("userShiftData");
    localStorage.removeItem("userData");
    localStorage.removeItem("currencyData");
    localStorage.removeItem("discountData");
    localStorage.removeItem("voidReason");
    localStorage.removeItem("addressTypes");
    localStorage.removeItem("cityData");
    localStorage.removeItem("outletBillingHeader");

    this.router.navigate(['/auth']);
  }

  autoLogin() {
    const userData: {
      id: string;
      email: string;
      code: string;
      title: string;
      first_name: string;
      last_name: string;
      full_name: string;
      saas_company_id: number;
      company_id: number;
      branch_id: number;
      saas_company_name: string;
      company_name: string;
      branch_name: string;
      token: string;
      mobile_number: string,
      saas_company_code: string,
      company_code: string,
      branch_code: string,
      rights: [];
    } = JSON.parse(localStorage.getItem("userData"));

    if (!userData) {
      return;
    }

    const loadedUser = new User(
      userData.id,
      userData.email,
      userData.code,
      userData.title,
      userData.first_name,
      userData.last_name,
      userData.full_name,
      userData.saas_company_id,
      userData.company_id,
      userData.branch_id,
      userData.saas_company_name,
      userData.company_name,
      userData.branch_name,
      userData.token,
      userData.mobile_number,
      userData.saas_company_code,
      userData.company_code,
      userData.branch_name,
      userData.rights
    );

    if (loadedUser.token) {
      this.userSubject.next(loadedUser);
    }
  }

  getUserFunctionalRights(userId) {
    return this.http.get(
      environment.APIENDPOINT + environment.USER + '/' + userId, {
      params: new HttpParams().append("key_user_rights", "true").append("key_functional_rights", "true")
    }
    ).pipe(catchError(errorRes => {
      return throwError(errorRes);
    }), tap(resData => {
      const userData: {
        id: string;
        email: string;
        code: string;
        title: string;
        first_name: string;
        last_name: string;
        full_name: string;
        saas_company_id: number;
        company_id: number;
        branch_id: number;
        saas_company_name: string;
        company_name: string;
        branch_name: string;
        token: string;
        mobile_number: string;
        saas_company_code: string,
        company_code: string,
        branch_code: string,
        rights: [];
        user_rights: [];
        functional_rights: [];
      } = JSON.parse(localStorage.getItem("userData"));

      if (!userData) {
        return;
      }

      const loadedUser = new User(
        userData.id,
        userData.email,
        userData.code,
        userData.title,
        userData.first_name,
        userData.last_name,
        userData.full_name,
        userData.saas_company_id,
        userData.company_id,
        userData.branch_id,
        userData.saas_company_name,
        userData.company_name,
        userData.branch_name,
        userData.token,
        userData.mobile_number,
        userData.saas_company_code,
        userData.company_code,
        userData.branch_code,
        userData.rights,
        resData['user'] ? resData['user'].user_rights : null,
        resData['user'] ? resData['user'].functional_rights : null
      );

      if (loadedUser.token) {
        localStorage.setItem("userData", JSON.stringify(loadedUser));
        this.userSubject.next(loadedUser);
      }
    }));
  }

  setUser(resData): User {
    const user = new User(
      resData.user.id,
      resData.user.email,
      resData.user.code,
      resData.user.title,
      resData.user.first_name,
      resData.user.last_name,
      resData.user.full_name,
      resData.user.saas_company_id,
      resData.user.company_id,
      resData.user.branch_id,
      resData.user.saas_company_translations ? resData.user.saas_company_translations.name : "",
      resData.user.company_translations ? resData.user.company_translations.name : "",
      resData.user.branch_translations ? resData.user.branch_translations.name : "",
      resData.auth_token,
      resData.user.mobile_number,
      resData.user.saas_company ? resData.user.saas_company.code : "",
      resData.user.company ? resData.user.company.code : "",
      resData.user.branch ? resData.user.branch.code : "",
      resData.user.rights
    );

    return user;
  }

  changePassword(username: string, oldPassword: string, newPassword: string, confirmPassword: string) {
    return this.http.post(
      environment.APIENDPOINT + environment.CHANGE_PASSWORD,
      {
        email: username,
        password: oldPassword,
        new_password: newPassword,
        new_password_confirmation: confirmPassword
      }
    ).pipe(catchError(errorRes => {
      let errorMessage = "An unknown error occurred!"
      if (errorRes.error && errorRes.error.errors && errorRes.error.errors.length && errorRes.error.errors.length > 0) {
        errorMessage = errorRes.error.errors;
      }
      else if (errorRes.status === 0) {
        errorMessage = "Api service is down";
      }
      return throwError(errorMessage);
    }));
  }

  getUserOutlets(userId: string) {
    return this.http.get(environment.APIENDPOINT + environment.POS_OUTLET_USER, {
      params: new HttpParams().append("key_user", userId)
    }).pipe(
      map(response => {
        const loadedUser: User = JSON.parse(localStorage.getItem("userData"));

        if (loadedUser && loadedUser.id == userId) {
          loadedUser.user_outlets = [];

          const userOutlet = [];
          response["pos_restaurant_users"].forEach(outlet => {
            let objOutlet = new UserOutlet(
              outlet.pos_restaurant_id,
              outlet.serial_number,
              outlet.pos_restaurant && outlet.pos_restaurant.pos_restaurant_translations && outlet.pos_restaurant.pos_restaurant_translations.length > 0 ? outlet.pos_restaurant.pos_restaurant_translations[0].description : ""
            )
            userOutlet.push(objOutlet);
          });

          loadedUser.user_outlets = userOutlet;
          localStorage.setItem("userData", JSON.stringify(loadedUser));
        }

        return response["pos_restaurant_users"];
      })
    )
  }
}
