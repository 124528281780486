import { Component, OnInit } from '@angular/core';
import { PointerService } from '../../services/pointer.service';

@Component({
  selector: 'app-business-date',
  templateUrl: './business-date.component.html'
})
export class BusinessDateComponent implements OnInit {
  businessDate: Date;
  constructor() { }

  ngOnInit(): void {
    this.businessDate = PointerService.getBusinessDate();
  }
}
