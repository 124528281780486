import { User } from "./user.model";

export class AccountContactNumber {
	constructor() { }

	private _saas_company_id: number;
	private _company_id: number;
	private _branch_id: number;
	private _contact_number_type_id: number;
	private _contact_number_value: string;

	public get saas_company_id(): number {
    return this._saas_company_id;
  }
  public set saas_company_id(value: number) {
    this._saas_company_id = value;
  }

  public get company_id(): number {
    return this._company_id;
  }
  public set company_id(value: number) {
    this._company_id = value;
  }
  
  public get branch_id(): number {
    return this._branch_id;
  }
  public set branch_id(value: number) {
    this._branch_id = value;
  }

	public get contact_number_type_id(): number {
		return this._contact_number_type_id;
	}
	public set contact_number_type_id(value: number) {
		this._contact_number_type_id = value;
	}
	
	public get contact_number_value(): string {
		return this._contact_number_value;
	}
	public set contact_number_value(value: string) {
		this._contact_number_value = value;
	}

	public assignUserProperties(user: User) {
    this._saas_company_id = user.saas_company_id;
    this._company_id = user.company_id;
    this._branch_id = user.branch_id;
  }

}