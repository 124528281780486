import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountAddress } from '../../models/account-address.model';
import { AccountContactNumber } from '../../models/account-contact-number.model';
import { AddressType } from '../../models/address-type.model';
import { City } from '../../models/city.model';
import { PosCheck } from '../../models/pos-check.model';
import { AuthService } from '../../services/auth.service';
import { PointerService } from '../../services/pointer.service';
import { PosCheckService } from '../../services/pos-check.service';
import { TableLayoutService } from '../../services/table-layout.service';
import { environment } from '../../../environments/environment';
import { Account  } from '../../models/account.model'
import { AccountService } from '../../services/account.service';
import { CityService } from '../../services/city.service';
import { AlertService } from '../../services/alert.service';
import { OutletSetting } from '../../models/outlet-setting.model';
declare function initializeKeyboard(event): any;
declare function showErrorMessage(): any;

@Component({
  selector: 'app-address-book',
  templateUrl: './address-book.component.html',
  styles: [
  ]
})
export class AddressBookComponent implements OnInit {
  mobileNumber: number;
  guestAddressList: Account[] = [];
  showAddressFields: boolean = false;
  outletId: string = "";
  addressTypes: AddressType[] = [];
  cities: City[]= [];
  state_name: string;
  selectedStateId: number;
  selectedCountryId: number;
  country_name: string;
  businessDate: string;
  selectedTable = this.tableLayoutService.getPreviousSelectedTable;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private accountService: AccountService,
              private authService: AuthService,
              private pointerService: PointerService,
              private cityService: CityService,
              private posCheckService: PosCheckService,
              private tableLayoutService: TableLayoutService,
              private alertService: AlertService) { }

  ngOnInit(): void {
    this.outletId = this.activatedRoute.snapshot.params['outlet_id'];
    this.businessDate = PointerService.getISOBusinessDate();

    const addressData: AddressType[] = JSON.parse(localStorage.getItem("addressTypes"));
    if (!addressData) {
      this.accountService.getAddressType()
      .subscribe({
        next: (data: any) => {
          this.addressTypes = data;
        },
        error: (error: any) => {
          if(error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      });
    } else {
      this.addressTypes = addressData;
    }

    const cityData: City[] = JSON.parse(localStorage.getItem("cityData"));
    if(!cityData) {
      this.cityService.getAll()
      .subscribe({
        next: (data: any) => {
          this.cities = data;
        },
        error: (error: any) => {
          if(error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      });
    } else {
      this.cities = cityData;
    }
  }

  searchAddress(): void {
    if(this.mobileNumber) {
      this.showAddressFields = false;
      this.accountService.findAddressBy(this.mobileNumber.toString())
      .subscribe({
        next: (data: any) => {
          this.guestAddressList = data;
        },
        error: (error: any) => {
          if(error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      });
    } else {
      (<HTMLInputElement>document.getElementById("mobileNumber")).style.borderColor = "red";
    }
  }

  newAddress(): void {
    this.showAddressFields = true;
  }

  openKeyboard(event): void {
    initializeKeyboard(event.target);
  }

  onCancel(): void {
    this.router.navigate(['/table-layout/' + this.outletId]);
  }

  onSelect(cityId) {
    if(cityId) {
      const objCity = this.cities.filter((item) => item.id == cityId)[0];
      this.selectedStateId = objCity.state_id;
      this.state_name = objCity.state_name;
      this.country_name = objCity.country_name;
      this.selectedCountryId = objCity.country_id;
    }
  }

  addAddress(form: NgForm): void {
    if(form.valid) {
      const account = new Account();
      const accountAddress = new AccountAddress();
      
      let user = this.authService.userValue;
      account.assignUserProperties(user);
      accountAddress.assignUserProperties(user);
      
      accountAddress.address1 = form.value.address1;
      accountAddress.address2 = form.value.address2;
      accountAddress.address3 = form.value.address3;
      accountAddress.address_type_id = form.value.address_type_id;
      accountAddress.city_id = form.value.city_id;
      accountAddress.state_id = this.selectedStateId;
      accountAddress.country_id = this.selectedCountryId;
      accountAddress.zip = form.value.zip;
      account.account_address.push(accountAddress);

      if(form.value.email) {
        const accountContact = new AccountContactNumber();
        accountContact.assignUserProperties(user);
        accountContact.contact_number_type_id = +this.pointerService.filterValueByCode(environment.POINTER_POS_CONTACT_TYPE_ID_EMAIL);
        accountContact.contact_number_value = form.value.email;
        account.account_contact_number.push(accountContact);
      }

      if(this.mobileNumber) {
        const accountContact = new AccountContactNumber();
        accountContact.assignUserProperties(user);
        accountContact.contact_number_type_id = +this.pointerService.filterValueByCode(environment.POINTER_POS_CONTACT_TYPE_ID_MOBILE);
        accountContact.contact_number_value = this.mobileNumber.toString();
        account.account_contact_number.push(accountContact);
      }

      account.first_name = form.value.first_name;
      account.last_name = form.value.last_name;
      account.title = form.value.title;
      
      this.accountService.save(account)
      .subscribe({
        next: (data: any) => {
          this.onAddressSelection(data);
        },
        error: (error: any) => {
          if(error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      });
    }
  }

  onAddressSelection(account: Account) {
    const objDelivery = this.posCheckService.mapDeliveryFields(account);
    this.posCheckService.assignDeliveryAddress(objDelivery);
    this.getTableStatusAndRedirect();
  }

  getTableStatusAndRedirect() {
    if(this.selectedTable) {
      if (this.selectedTable.table_status.toUpperCase() != environment.POS_TABLE_STATUS_AVAILABLE.toUpperCase()) {
        this.posCheckService.getCheckDetailsBy(this.outletId, this.businessDate, this.selectedTable)
        .subscribe({
          next: (data: any) => {
            if (data) {
              const posCheck: PosCheck = data;
              if (posCheck.id) {
                this.router.navigate(['/billing/' + this.outletId + '/place-order/' + this.selectedTable.table_code + '/' + posCheck.id]);
              }
            }
          },
          error: (error: any) => {
            if(error.errors && error.errors.length > 0) {
              this.alertService.error(error.errors[0]);
            } else {
              this.alertService.error(error);
            }
          }
        });
      } else {
        this.router.navigate(['/billing/' + this.outletId + '/place-order/' + this.selectedTable.table_code]);
      }
    } else {
      //Get any Free table and raise order
      let layoutData = JSON.parse(localStorage.getItem("tableLayout"));

      if(!layoutData) {
        return
      }

      let selectedTable = layoutData.filter(t => {
        return t.pos_restaurant_id == this.outletId && t.table_status.toUpperCase() === environment.POS_TABLE_STATUS_AVAILABLE.toUpperCase()
      });

      if(selectedTable && selectedTable.length > 0) {
        this.router.navigate(['/billing/' + this.outletId + '/place-order/' + selectedTable[0].table_code]); ;
      } else {
        this.alertService.error(environment.NO_FREE_TABLE_AVAILABLE);
      }
    }
  }

  onBackClick(): void {
    let isQsrOutlet = this.checkQsrOutlet();

    if (isQsrOutlet) {
      let layoutData = JSON.parse(localStorage.getItem("tableLayout"));

      if(!layoutData) {
        this.tableLayoutService.getTableLayoutBy(this.outletId).subscribe((data) => {
          this.getAvailableTable(data);
        })
      } else {
        this.getAvailableTable(layoutData);
      }
    } else {
      this.router.navigate(['/table-layout/' + this.outletId]);
    }
  }

  checkQsrOutlet() {
    let isQsrOutlet: boolean = false;
    if (this.outletId) {
      const outletSettingData: OutletSetting[] = JSON.parse(localStorage.getItem("outletSetting"));
      if (outletSettingData) {
        let currentOutletSetting = outletSettingData.filter(a => a.pos_restaurant_id === +this.outletId);

        if (currentOutletSetting.length > 0) {
          isQsrOutlet = currentOutletSetting[0].qsr_in_use_ind;
        }
        return isQsrOutlet
      } else {
        this.router.navigate(['/outlet']);
      }
    }
  }

  getAvailableTable(layoutData) {
    //Get any Free table from layout for QSR billing
    let selectedTable = layoutData.filter(t => {
      return t.pos_restaurant_id == +this.outletId && t.table_status.toUpperCase() === environment.POS_TABLE_STATUS_AVAILABLE.toUpperCase()
    });

    if (selectedTable && selectedTable.length > 0) {
      this.router.navigate(['/billing/' + this.outletId + '/qsr/' + selectedTable[0].table_code]);
    } else {
      this.alertService.error(environment.NO_FREE_TABLE_AVAILABLE);
    }
  }
}
