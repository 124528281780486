import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { PosItemMenuService } from '../../services/pos-item-menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OutletSetting } from '../../models/outlet-setting.model';
import { PosItemMenu } from '../../models/pos-item-menu.model';
import { PosMenuHeader } from '../../models/pos-menu-header.model';
import { TableLayout } from '../../models/table-layout.model';
import { PosCheck } from '../../models/pos-check.model';
import { TableLayoutService } from '../../services/table-layout.service';
import { environment } from '../../../environments/environment';
import { PointerService } from '../../services/pointer.service';
import { PosCheckService } from '../../services/pos-check.service';
import { PosCheckDetail } from '../../models/pos-check-detail.model';
import { CommonService } from '../../services/common.service';
import { ChargeService } from '../../services/charge.service';
import { OutletService } from '../../services/outlet.service';
import { Charge } from '../../models/charge.model';
import { PosItemModifier } from '../../models/pos-item-modifier.model';
import { PosMenuRestaurant } from '../../models/pos-menu-restaurant.model';
import { PosRestaurant } from '../../models/pos-restaurant.model';
import { PosCheckDetailModifier } from '../../models/pos-check-detail-modifier.model';
import { VoidReason } from '../../models/void-reason.model';
import { VoidReasonService } from '../../services/void-reason.service';
import { AlertService } from '../../services/alert.service';
import { User } from '../../models/user.model';
import { MemberSearchService } from '../../services/member-search.service';
import { DebitCardsService } from '../../services/debit-cards.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ViewportScroller } from '@angular/common';
declare function enableMenuHeadTab(): any;
declare function initializeKeyboard(event): any;
declare var Stimulsoft: any;
declare var saveReportPdf: any;
declare function functionExpandCollapse(): any;

@Component({
  selector: 'app-place-order',
  templateUrl: './place-order.component.html'
})
export class PlaceOrderComponent implements OnInit {
  inputControl = new FormControl('');
  memberSearchForm!: FormGroup;
  outletId: string = "";
  defaultMenuId: string = "";
  currentMenuItems: PosItemMenu[] = [];
  posItemModifiers: PosItemModifier[] = [];
  menuHeaders: PosMenuHeader[] = [];
  searchItemList: any[] = [];
  tableCode: string = "";
  posCheck: PosCheck = new PosCheck();
  layout: TableLayout;
  businessDate: string = "";
  currentUserShift: number;
  posCheckId: string = "";
  selectedItemFromKotWindow: PosCheckDetail;
  isOpenItem = environment.POS_IC_OPEN_ITEM;
  posMenuRestaurants: PosMenuRestaurant[] = [];
  showOtherMenu: boolean = false;
  showItemAddOns: boolean = false;
  selectedItemModifier: PosItemModifier[] = [];
  decimalPlace: number = this.pointerService.getDecimalPlaceValue();
  selectedItemVoidQtyDropdownOption: number = 1;
  voidReasonList: VoidReason[] = [];
  maxChars: number = 150;
  void_reason_note: string = "";
  selectedVoidQty: number = 1;
  selectedVoidReason: number = 0;
  currencySymbol: string = "";
  otherAddOnText: string = "";
  otherAddOnPrice: number = 0;
  openItemText: string = "";
  options: any = new Stimulsoft.Viewer.StiViewerOptions();
  viewer: any = new Stimulsoft.Viewer.StiViewer(this.options, 'StiViewer', false);
  selectedMember: any = {};
  blockedMemberSelection: any = {};
  outletSettingData: OutletSetting[] = [];
  @ViewChild('closeBtn1') closeBtn1: ElementRef;
  debitCardDetails: any = [];



  membersDataArray: any = [];


  canVoidKOT: boolean = false;

  @ViewChild("txtSearchInput") txtSearchInput: ElementRef;
  @ViewChild("scrollMe") private myScrollContainer: ElementRef;
  @ViewChild('search') search: ElementRef;
  @ViewChild('closeVRModalBtn') closeBtn: ElementRef;
  @ViewChild('closeOtherAddOnModalBtn') closeOtherAddOn: ElementRef;
  @ViewChild('closeOpenItemModalBtn') closeOpenItem: ElementRef;

  constructor(private posItemMenuService: PosItemMenuService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private tableLayoutService: TableLayoutService,
    private posCheckService: PosCheckService,
    private commonService: CommonService,
    private chargeService: ChargeService,
    private outletService: OutletService,
    private pointerService: PointerService,
    private voidReasonService: VoidReasonService,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private memberSearchService: MemberSearchService,
    private viewportScroller: ViewportScroller,
    private debitCardService: DebitCardsService) { }

  ngOnInit(): void {
    enableMenuHeadTab();
    functionExpandCollapse();
    this.outletId = this.activatedRoute.snapshot.params['outlet_id'];
    this.tableCode = this.activatedRoute.snapshot.params["table_code"];
    this.posCheckId = this.activatedRoute.snapshot.params["check_id"];
    this.outletSettingData = JSON.parse(localStorage.getItem("outletSetting"));
    if (this.outletSettingData) {
      let currentOutletSetting = this.outletSettingData.filter(a => a.pos_restaurant_id === +this.outletId);

      if (currentOutletSetting.length > 0) {
        this.defaultMenuId = currentOutletSetting[0].default_menu_id.toString();
        this.loadDefaultMenuItems();
      }
    } else {
      this.router.navigate(['/outlet']);
    }

    this.memberSearchForm = this.formBuilder.group({
      memberId: [''],
      firstName: [''],
      lastName: [''],
    },
      { validators: this.atLeastOneRequired }
    )

    this.currentUserShift = this.commonService.getCurrentUserShiftNumber(this.outletId);

    if (this.outletId) {
      const posRestaurantData: PosRestaurant[] = JSON.parse(localStorage.getItem("posRestaurant"));

      if (posRestaurantData) {
        let restaurant = posRestaurantData.filter(a => a.id === +this.outletId);
        if (restaurant.length > 0) {
          this.posMenuRestaurants = restaurant[0].pos_menu_restaurants;
        }
      }
    }
    this.loadExistingOrder();
    this.scrollToBottom();

    const voidReasonData: VoidReason[] = JSON.parse(localStorage.getItem("voidReason"));
    if (!voidReasonData) {
      this.voidReasonService.getAll()
        .subscribe({
          next: (data: any) => {
            this.voidReasonList = data;
          },
          error: (error: any) => {
            if (error.errors && error.errors.length > 0) {
              this.alertService.error(error.errors[0]);
            } else {
              this.alertService.error(error);
            }
          }
        });
    } else {
      this.voidReasonList = voidReasonData;
    }

    this.currencySymbol = PointerService.getLocalCurrencySymbol();

    this.canVoidKOT = this.commonService.hasFunctionalRights(environment.RIGHT_CODE_POS_CAN_VOID_KOT);


    this.inputControl.valueChanges.subscribe(value => {
      if (value?.trim()?.length > 5) {
        this.debitCardDetails = {};
        this.debitCardService.fetchDebitCardData(value)
          .subscribe({
            next: (data: any) => {
              this.membersDataArray = [];
              this.memberSearchService.fetchMemberDetailsByDebitCard(value)
                .subscribe({
                  next: (result: any) => {
                    result = result?.accounts[0];
                    if (result?.block_member_remark?.length > 0) {
                      this.alertService.error(result?.title + " " + result?.first_name + " " + result?.last_name + " " + result?.block_member_remark);
                      this.inputControl.setValue("");
                    } else {
                      this.createMemberObj(result);
                      this.debitCardDetails = data?.debit_cards[0];
                    }
                  },
                  error: (err: any) => {
                    this.alertService.error("err", err);
                  }
                })
            },
            error: (err: any) => {
              this.alertService.error("err", err);
            }
          });

      }
    });
  }

  loadExistingOrder() {
    const deliveryDetails = this.posCheckService.posCheckValue;

    this.layout = this.tableLayoutService.getTableDetails(this.outletId, this.tableCode);
    if (this.layout && this.layout.table_status === environment.POS_TABLE_STATUS_KOT) {
      this.businessDate = PointerService.getISOBusinessDate();
      this.posCheckService.findBy(this.posCheckId)
        .subscribe({
          next: (data: any) => {
            this.posCheck = data;
            if (data?.debit_card_number) {
              this.inputControl.setValue(data?.debit_card_number);
            }
            if (deliveryDetails) {
              this.posCheck.pos_check_delivery.push(deliveryDetails.pos_check_delivery[0]);
              this.posCheck.guest_name = deliveryDetails.pos_check_delivery[0].first_name + " " + deliveryDetails.pos_check_delivery[0].last_name;
              // this.posCheck.membership_number =
              this.posCheckService.resetPosCheckSubject();
            }
          },
          error: (error: any) => {
            if (error.errors && error.errors.length > 0) {
              this.alertService.error(error.errors[0]);
            } else {
              this.alertService.error(error);
            }
          }
        });
    }
    else if (this.layout && this.layout.table_status == environment.POS_TABLE_STATUS_AVAILABLE) {
      this.posCheck = new PosCheck();
      this.posCheck.pos_restaurant_id = +this.outletId;
      this.posCheck.table_code = this.tableCode;
      if (deliveryDetails) {
        this.posCheck.pos_check_delivery[0] = deliveryDetails.pos_check_delivery[0];
        this.posCheck.guest_name = deliveryDetails.pos_check_delivery[0].first_name + " " + deliveryDetails.pos_check_delivery[0].last_name;
        this.posCheckService.resetPosCheckSubject();
      }
    }
  }

  atLeastOneRequired(group: FormGroup) {
    const isAtLeastOneFilled = Object.values(group.controls).some(control => control.value?.trim() !== '');
    return isAtLeastOneFilled ? null : { atLeastOneRequired: true };
  }


  ngAfterViewInit(): void {
    enableMenuHeadTab();
    this.txtSearchInput.nativeElement.focus();
    this.changeDetector.detectChanges();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    }
    catch (err) { }
  }


  scrollTo(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  onMemberSearch() {
    if (this.memberSearchForm.valid) {
      this.memberSearchService.fetchMemberDetails(this.memberSearchForm.value.memberId, this.memberSearchForm.value.firstName, this.memberSearchForm.value.lastName).subscribe({
        next: (data: any) => {
          this.membersDataArray = [];
          if (data.accounts && data.accounts.length > 0) {
            data.accounts.forEach((res: any) => {
              let objMember = {};
              objMember["account_id"] = res.id;
              objMember["membership_number"] = res.account_affiliations[0]?.membership_number;
              if (res.account_name) {
                objMember["member_name"] = res.account_name;
              } else {
                objMember["member_name"] = res.title + " " + res.first_name + " " + res.last_name;
              }
              objMember["issue_date"] = res.account_affiliations[0]?.issue_date;
              objMember["birth_date"] = res.birth_date;
              objMember["expiry_date"] = res.account_affiliations[0]?.expiry_date;
              objMember["block_member_remark"] = res.block_member_remark;
              this.membersDataArray.push(objMember);
            })
          }
        },
        error: (error: any) => {
          if (error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      })
    } else {
      this.alertService.error("Please search using at least one criteria!")
    }
  }

  createMemberObj(res: any) {
    let objMember = {};
    objMember["account_id"] = res.id;
    objMember["membership_number"] = res.account_affiliations[0]?.membership_number;
    if (res.account_name) {
      objMember["member_name"] = res.account_name;
    } else {
      objMember["member_name"] = res.title + " " + res.first_name + " " + res.last_name;
    }
    objMember["issue_date"] = res.account_affiliations[0]?.issue_date;
    objMember["birth_date"] = res.birth_date;
    objMember["expiry_date"] = res.account_affiliations[0]?.expiry_date;
    objMember["block_member_remark"] = res.block_member_remark;
    this.membersDataArray.push(objMember);
    this.selectedMember = {};
    this.blockedMemberSelection = {};
    if (this.membersDataArray[0]?.block_member_remark.length > 0) {
      this.blockedMemberSelection = this.membersDataArray[0];
      this.membersDataArray = [];
    } else {
      this.selectedMember = this.membersDataArray[0];
      this.posCheck["_guest_name"] = this.selectedMember?.member_name;
      this.posCheck["_membership_number"] = this.selectedMember?.membership_number;
      this.posCheck["_guest_id"] = this.selectedMember?.account_id;
      this.closeBtn1.nativeElement.click();
    }
  }


  onSelectMember(data: any) {
    this.selectedMember = {};
    this.blockedMemberSelection = {};
    if (data?.data?.block_member_remark.length > 0) {
      this.blockedMemberSelection = data?.data;
      this.membersDataArray = [];
      this.memberSearchForm.reset();
    } else {
      this.selectedMember = data?.data;
      this.posCheck["_guest_name"] = this.selectedMember?.member_name;
      this.posCheck["_membership_number"] = this.selectedMember?.membership_number;
      this.posCheck["_guest_id"] = this.selectedMember?.account_id;
      this.closeBtn1.nativeElement.click();
    }
  }

  mapMenuHeaderSubheader(): void {
    this.menuHeaders = [...new Map(this.currentMenuItems.map((item) => [item.pos_menu_header_id, item.menu_header])).values()];
  }

  onChangeMenu(): void {
    this.showOtherMenu = true;
  }

  onDefaultMenu(): void {
    this.loadDefaultMenuItems();
    this.showOtherMenu = false;
    this.search.nativeElement.click();
  }

  onBackClick(): void {
    this.router.navigate(['/table-layout/' + this.outletId]);
  }

  onMenuHeaderSelection(): void {
    enableMenuHeadTab();
  }

  onKeyUp(event): void {
    if (event.target.value) {
      this.searchItemList = this.currentMenuItems.filter(x => x.description.toLowerCase().includes(event.target.value.toLowerCase()));
    } else {
      this.searchItemList = [];
    }
  }

  onVirtualKeyboardInput(): void {
    if (this.txtSearchInput.nativeElement.value) {
      this.searchItemList = this.currentMenuItems.filter(x => x.description.toLowerCase().includes(this.txtSearchInput.nativeElement.value.toLowerCase()));
    } else {
      this.searchItemList = [];
    }
  }

  onGridItemSelection(event): void {
    if (event.selectedRowsData.length > 0) {
      this.onItemSelection(event.selectedRowsData[0]);
      this.searchItemList = [];
      this.txtSearchInput.nativeElement.value = "";
      this.txtSearchInput.nativeElement.focus();
    }
  }

  onItemSelection(item: PosItemMenu): void {
    let isHappyHourApplicable: boolean = false;

    if (item && item.pos_menu_id) {
      //isHappyHourApplicable = this.commonService.isHappyHour(item.pos_menu_id, this.outletId);
    }

    const objItem = this.posCheck.pos_check_details.filter(x => x.pos_item_id === item.pos_item_id && x.is_new);

    if (objItem && objItem.length > 0) {
      objItem[0].quantity += 1;
      this.selectedItemFromKotWindow = objItem[0];
    } else {
      // new item added
      let newItem = new PosCheckDetail();
      newItem.is_new = true;
      newItem.is_dirty = false;
      newItem.pos_item_id = item.pos_item_id;
      newItem.shift = this.currentUserShift;
      newItem.pos_restaurant_id = +this.outletId;
      newItem.service = item.pos_item.account_code;
      newItem.charge_id = isHappyHourApplicable ? item.happy_hour_charge_id : item.pos_item_charge_id;
      newItem.item_description = isHappyHourApplicable ? item.happy_hour_description : item.description;
      newItem.pos_menu_id = item.pos_menu_id;
      newItem.pos_kitchen_id = item.pos_kitchen_id;
      newItem.serial_number = this.posCheck.pos_check_details.length + 1;
      newItem.quantity = 1;
      newItem.rate = isHappyHourApplicable ? item.happy_hour_price : item.pos_item_price;
      newItem.currency_id = PointerService.getLocalCurrencyId();

      if (item.pos_item && item.pos_item.item_class == environment.POS_IC_OPEN_ITEM) {
        newItem.pos_item_name_on_check = item.name_on_check;
      }

      newItem.do_not_print_price_ind = item.do_not_print_price_on_check_ind;

      if (typeof (newItem.charge_id) != 'undefined' || newItem.charge_id != null) {
        let departmentCode: string = this.outletService.getDepartmentCodeBy(this.outletId);
        let chargeCode: string = departmentCode + item.pos_item.account_code;
        let selectedCharge: Charge = this.chargeService.getChargeByCode(chargeCode);

        newItem.charge_id = selectedCharge.id;
      }

      newItem.pos_item_type = item.pos_item.item_class;

      this.posCheck.pos_check_details.push(newItem);
      this.selectedItemFromKotWindow = newItem;
    }

    this.posCheckService.updateOrder(this.posCheck);
  }

  onKotItemSelection(posCheckDetail: PosCheckDetail) {
    this.selectedItemFromKotWindow = posCheckDetail;
    if (this.selectedItemFromKotWindow.item_status != environment.STATUS_VOIDED) {
      const numericInput: HTMLInputElement = document.getElementById("numericInput") as HTMLInputElement;
      if (numericInput && numericInput.value != "") {
        if (this.selectedItemFromKotWindow.is_new) {
          const objIndex = this.posCheck.pos_check_details.findIndex(x => x == this.selectedItemFromKotWindow);
          if (objIndex > -1) {
            this.posCheck.pos_check_details[objIndex].quantity += +numericInput.value;
            this.posCheckService.updateOrder(this.posCheck);
            numericInput.value = "";
          }
        } else {
          //add same item as new kot item with specified qty

          let newItem = new PosCheckDetail();
          newItem.is_new = true;
          newItem.pos_item_id = posCheckDetail.pos_item_id;
          newItem.shift = this.currentUserShift;
          newItem.pos_restaurant_id = +this.outletId;
          newItem.service = posCheckDetail.service;
          newItem.charge_id = posCheckDetail.charge_id;
          newItem.item_description = posCheckDetail.item_description;
          newItem.pos_menu_id = posCheckDetail.pos_menu_id;
          newItem.pos_kitchen_id = posCheckDetail.pos_kitchen_id;
          newItem.serial_number = this.posCheck.pos_check_details.length + 1;
          newItem.rate = posCheckDetail.rate;
          newItem.currency_id = PointerService.getLocalCurrencyId();
          newItem.pos_item_name_on_check = posCheckDetail.pos_item_name_on_check;
          newItem.do_not_print_price_ind = posCheckDetail.do_not_print_price_ind;
          newItem.charge_id = posCheckDetail.charge_id;
          newItem.pos_item_type = posCheckDetail.pos_item_type;

          newItem.quantity = +numericInput.value;
          this.posCheck.pos_check_details.push(newItem);

          this.posCheckService.updateOrder(this.posCheck);
          numericInput.value = "";
          this.selectedItemFromKotWindow = newItem;
        }
      }
      this.selectedItemVoidQtyDropdownOption = this.selectedItemFromKotWindow.quantity;
    }
  }

  onQtyMinus(posCheckDetail: PosCheckDetail) {
    this.selectedItemFromKotWindow = posCheckDetail;
    if (this.selectedItemFromKotWindow.item_status != environment.STATUS_VOIDED) {
      if (this.selectedItemFromKotWindow.is_new) {
        const objIndex = this.posCheck.pos_check_details.findIndex(x => x == this.selectedItemFromKotWindow);
        if (objIndex > -1 && this.posCheck.pos_check_details[objIndex].quantity > 1) {
          this.posCheck.pos_check_details[objIndex].quantity -= 1;
          this.posCheckService.updateOrder(this.posCheck);
        }
      }
      this.selectedItemVoidQtyDropdownOption = this.selectedItemFromKotWindow.quantity;
    }
  }

  onQtyAdd(posCheckDetail: PosCheckDetail) {
    this.selectedItemFromKotWindow = posCheckDetail;
    if (this.selectedItemFromKotWindow.item_status != environment.STATUS_VOIDED) {
      if (this.selectedItemFromKotWindow.is_new) {
        const objIndex = this.posCheck.pos_check_details.findIndex(x => x == this.selectedItemFromKotWindow);
        if (objIndex > -1) {
          this.posCheck.pos_check_details[objIndex].quantity += 1;
          this.posCheckService.updateOrder(this.posCheck);
        }
      }
      this.selectedItemVoidQtyDropdownOption = this.selectedItemFromKotWindow.quantity;
    }
  }

  onPlaceOrder(): void {
    const coverMandatoryInd = this.outletSettingData[0]?.covers_mandetory_ind;
    const guestNameMandetoryInd = this.outletSettingData[0]?.guest_name_mandetory_ind;
    let guestNameInd = true;
    let coversInd = true;
    if (this.posCheck.pos_check_details && this.posCheck.pos_check_details.length > 0) {
      if (coverMandatoryInd) {
        coversInd = this.posCheck?.covers ? true : false;
      }
      if (guestNameMandetoryInd) {
        guestNameInd = this.posCheck?.guest_name ? true : false;
      }
      if (coversInd && guestNameInd) {
        let allPOItems: PosCheckDetail[] = this.posCheck.pos_check_details.filter(x => x.last_action.toUpperCase() == environment.LAST_ACTION_POSTED);
        let voidMarkedItems: PosCheckDetail[] = this.posCheck.pos_check_details.filter(x => x.markedVoid == true);
        this.posCheck.debit_card_number = this.debitCardDetails?.card_number;
        this.posCheck.debit_card_reference_id = this.debitCardDetails?.id;

        this.posCheckService.saveBill(this.posCheck)
          .subscribe({
            next: (data: PosCheck) => {

              let voidItems: PosCheckDetail[] = data["pos_check"].pos_check_details.filter(function (o1) {
                return voidMarkedItems.some(function (o2) {
                  return o1.pos_item_id === o2.pos_item_id
                    && o1.id == o2.id // assumes unique pos_item_id & id
                });
              });

              let newItems: PosCheckDetail[] = data["pos_check"].pos_check_details.filter(function (o1) {
                // filter out (!) items in result2
                return allPOItems.some(function (o2) {
                  return o1.pos_item_id === o2.pos_item_id && o2.is_new == true // assumes unique pos_item_id
                    && o2.last_action == environment.LAST_ACTION_POSTED
                    && o1.last_action == environment.LAST_ACTION_POSTED;
                });
              });

              if (voidItems.length > 0) {
                this.printClientSideKot([], voidItems, data["pos_check"]);
                setTimeout(() => {
                  this.printClientSideKot(newItems, [], data["pos_check"]);
                }, 3000);
              } else {
                this.printClientSideKot(newItems, [], data["pos_check"]);
              }

              //delay layout refresh - for table status change
              setTimeout(() => {
                this.router.navigate(['/table-layout/' + this.outletId]);
              }, 500);
            },
            error: (error: any) => {
              if (error.errors && error.errors.length > 0) {
                this.alertService.error(error.errors[0]);
              } else {
                this.alertService.error(error);
              }
            }
          });
      } else {
        if (coverMandatoryInd && (!coversInd)) {
          this.alertService.error("Please enter covers!")
        }
        if (guestNameMandetoryInd && (!guestNameInd)) {
          this.alertService.error("Please enter guest name!")
        }
      }
    } else {
      this.alertService.error(environment.SELECT_ONE_ITEM);
    }
  }


  printClientSideKot(kotItems: PosCheckDetail[], voidItems: PosCheckDetail[], posCheck: any) {
    const loadedUser: User = JSON.parse(localStorage.getItem("userData"));

    //let saasCompanyCode = loadedUser.saas_company_code.toLowerCase();
    //let companyCode = loadedUser.company_code.toLowerCase();
    //let branchCode = loadedUser.branch_code.toLowerCase();
    let mapItems = this.mapKotItemsForPrint(kotItems, voidItems, posCheck, false)

    if (mapItems.length > 0) {
      let posCheckObj = { kot_report: mapItems };
      let dataSet = new Stimulsoft.System.Data.DataSet("kot_report");
      dataSet.readJson(posCheckObj);

      let newGuid = Stimulsoft.System.Guid.newGuid().toString().substr(0, 8);
      let report = new Stimulsoft.Report.StiReport();
      //report.loadFile("/reports/"+ saasCompanyCode +"/"+ companyCode +"/"+ branchCode+"/"+ "kot.mrt" + "?n=" + n);

      report.loadFile("/public/mrt/" + "kot.mrt" + "?n=" + newGuid);
      report.regData(dataSet.kot_report, "kot_report", dataSet);

      report.render();
      report.print();
      //saveReportPdf(report);
      //report.print();


    }
  }

  mapKotItemsForPrint(kotItems: PosCheckDetail[], voidItems: PosCheckDetail[], posCheck: any, reprint: boolean) {
    let posCheckKotList = [];
    let posCheckDetailsArray = [];
    let posCheckDetailItems = [];
    let restaurantName = "";
    let voidLabel = "";
    let serverName = "";
    let voidDash = "";
    let voidReason = "";
    if (posCheck.server_name) {
      serverName = posCheck.server_name;
    }

    if (posCheck.pos_void_reason_id) {
      voidReason = this.voidReasonList.filter(x => x.id == this.posCheck.pos_void_reason_id)[0].description;
    }
    else if (posCheck.void_reason_note) {
      voidReason = posCheck.void_reason_note;
    }

    if (posCheck.pos_restaurant_name) {
      restaurantName = posCheck.pos_restaurant_name;
    }

    if (kotItems.length > 0) {
      posCheckDetailItems = kotItems;
    } else if (voidItems.length > 0) {
      posCheckDetailItems = voidItems;
    }
    // else {
    //   posCheckDetailItems = this.posCheck.pos_check_details
    // }

    if (posCheckDetailItems.length > 0) {
      posCheckDetailItems.forEach(item => {
        if (posCheck.pos_void_reason_id) {
          voidReason = this.voidReasonList.filter(x => x.id == posCheck.pos_void_reason_id)[0].description;
        }
        else if (posCheck.void_reason_note) {
          voidReason = posCheck.void_reason_note;
        }

        let itemDetails = {
          kot_no: item.kot_no,
          pos_kitchen_id: item.pos_kitchen_id,
          kitchen_name: item.pos_kitchen && item.pos_kitchen.pos_kitchen_translations && item.pos_kitchen.pos_kitchen_translations.length > 0 ? item.pos_kitchen.pos_kitchen_translations[0]["description"] : "",
          system_time: item.system_time,
          business_date: item.business_date,
          quantity: item.quantity,
          void_reason: voidReason,
          item_description: item.pos_item_name_on_check ? item.pos_item_name_on_check : item.item_description
        }

        let modifierText = "";
        if (item.pos_check_detail_modifiers.length > 0) {
          item.pos_check_detail_modifiers.forEach(modifier => {
            if (modifier.modifier_name_on_check) {
              modifierText += modifier.modifier_name_on_check + ", "
            }
          });
        }

        item["item_description"] = item.item_description + "\n- " + modifierText.replace(/,(\s+)?$/, '');

        posCheckDetailsArray.push(itemDetails);
      });

      if (voidItems.length > 0) {
        voidLabel = "VOID KOT";
        voidDash = "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX"
      }
      else if (reprint) {
        voidLabel = "DUPLICATE COPY"
      }
      else {
        voidLabel = "";
      }

      posCheckKotList.push({
        restaurant_name: restaurantName,
        check_no: posCheck.check_no,
        table_code: posCheck.table_code,
        covers: posCheck.covers,
        server: serverName,
        parcel: posCheck.take_away_ind == true ? "Yes" : "No",
        guest_name: posCheck.guest_name,
        void_kot: voidLabel,
        void_dash: voidDash,
        pos_check_details: posCheckDetailsArray
      });
    }
    return posCheckKotList;
  }

  onPrintCheck() {
    if (this.posCheck.pos_check_details && this.posCheck.pos_check_details.length > 0) {
      let roundOffType = this.pointerService.filterValueByCode(environment.POINTER_POS_ROUND_OFF_TYPE);
      let amount = 0, roundOffAmount = 0;

      if (roundOffType != null) {
        if (roundOffType == environment.POS_ROUND_OFF_FLOOR_CODE) {
          amount = Math.floor(this.posCheck.dr_total);
          roundOffAmount = amount - this.posCheck.dr_total;
        }
        else if (roundOffType == environment.POS_ROUND_OFF_BASED_ON_POINT_FIVE_CODE) {
          amount = Math.round(this.posCheck.dr_total);
          roundOffAmount = amount - this.posCheck.dr_total;
        }
        else if (roundOffType == environment.POS_ROUND_OFF_CEILING_CODE) {
          amount = Math.ceil(this.posCheck.dr_total);
          roundOffAmount = amount - this.posCheck.dr_total;
        }
        else {
          amount = Math.round(this.posCheck.dr_total);
          roundOffAmount = amount - this.posCheck.dr_total;
        }
      }

      this.posCheck.round_off = +roundOffAmount.toFixed(this.decimalPlace);
      this.posCheck.dr_total = amount;

      this.posCheckService.saveBill(this.posCheck).subscribe(data => {
        this.posCheck.id = data["pos_check"].id;
        this.posCheckService.printBill(this.posCheck, this.currentUserShift)
          .subscribe({
            next: (printData: any) => {
              this.printCheck();
            },
            error: (error: any) => {
              if (error.errors && error.errors.length > 0) {
                this.alertService.error(error.errors[0]);
              } else {
                this.alertService.error(error);
              }
            }
          });
      });
    } else {
      this.alertService.error(environment.SELECT_ONE_ITEM);
    }
  }

  printCheck(): void {
    var report = Stimulsoft.Report.StiReport.createNewReport();
    this.options.appearance.fullScreenMode = true;
    this.options.toolbar.displayMode = Stimulsoft.Viewer.StiToolbarDisplayMode.Separated;
    var dataSet = new Stimulsoft.System.Data.DataSet();
    var dataSet2 = new Stimulsoft.System.Data.DataSet();
    var dataSet3 = new Stimulsoft.System.Data.DataSet();

    let json = PosCheck.createPrintCheckJson(this.posCheck);
    dataSet.readJson(json);
    dataSet2.readJson(json["pos_check_charges"]);
    dataSet3.readJson(json["posCheck.pos_check_settlements"]);

    report.loadFile('/reports/checkSplitVersion.mrt');
    report.regData(dataSet, "pos_check", { pos_check: json });
    report.regData(dataSet.pos_check, "pos_check", dataSet);
    report.regData(dataSet2.pos_check_charges, "pos_check_charges", dataSet2);
    report.regData(dataSet3.pos_check_settlements, "pos_check_settlements", dataSet3);
    report.render();
    report.print(false);

    this.router.navigate(['/billing/' + this.outletId + '/view-bill/' + this.posCheck.id]);
  }

  onDeleteItem(): void {
    if (this.selectedItemFromKotWindow) {
      const objIndex = this.posCheck.pos_check_details.findIndex(x => x.is_new && x.pos_item_id === this.selectedItemFromKotWindow.pos_item_id);
      if (objIndex > -1) {
        this.posCheck.pos_check_details.splice(objIndex, 1);
        this.posCheckService.updateOrder(this.posCheck);
        this.selectedItemFromKotWindow = null;
        this.showItemAddOns = false;
      }
    }
  }

  onNewRateClick() {
    const numericInput: HTMLInputElement = document.getElementById("numericInput") as HTMLInputElement;
    if (this.selectedItemFromKotWindow && this.selectedItemFromKotWindow.is_new && this.selectedItemFromKotWindow.pos_item_type == this.isOpenItem) {
      if (numericInput && numericInput.value != "") {
        let newRate = numericInput.value;
        const objIndex = this.posCheck.pos_check_details.findIndex(x => x.is_new && x.pos_item_id === this.selectedItemFromKotWindow.pos_item_id);
        if (objIndex > -1) {
          this.posCheck.pos_check_details[objIndex].rate = +newRate;
          this.posCheckService.updateOrder(this.posCheck);
          numericInput.value = "";
        }
      }
    }
  }

  onOpenItemTextChange(): void {
    if (this.selectedItemFromKotWindow && this.selectedItemFromKotWindow.is_new && this.selectedItemFromKotWindow.pos_item_type == this.isOpenItem) {
      const objIndex = this.posCheck.pos_check_details.findIndex(x => x.is_new && x.pos_item_id === this.selectedItemFromKotWindow.pos_item_id);
      if (objIndex > -1) {
        this.posCheck.pos_check_details[objIndex].item_description = this.openItemText;
      }

      this.openItemText = "";
      this.closeOpenItem.nativeElement.click();
    }
  }

  onCoversClick() {
    const numericInput: HTMLInputElement = document.getElementById("numericInput") as HTMLInputElement;
    if (numericInput && numericInput.value != "") {
      this.posCheck.covers = +numericInput.value;
      numericInput.value = "";
    }
  }

  loadDefaultMenuItems(): void {
    if (this.defaultMenuId) {
      let menuItems: PosItemMenu[] = [];

      menuItems = this.loadPosItemsBy(this.defaultMenuId);
      if (menuItems.length > 0) {
        //already map items
      }
      else {
        this.posItemMenuService.getPosItemMenuBy(this.defaultMenuId)
          .subscribe({
            next: (response: any) => {
              if (response) {
                let menuItems = response.filter(a => a.pos_menu_id === +this.defaultMenuId);
                if (menuItems.length > 0) {
                  this.currentMenuItems = menuItems;
                  this.mapMenuHeaderSubheader();
                }
              }
            },
            error: (error: any) => {
              if (error.errors && error.errors.length > 0) {
                this.alertService.error(error.errors[0]);
              } else {
                this.alertService.error(error);
              }
            }
          });
      }
    }

    const itemModifierData: PosItemModifier[] = JSON.parse(localStorage.getItem("itemModifier"));
    if (itemModifierData) {
      this.posItemModifiers = itemModifierData;
    } else {
      this.posItemMenuService.getPosItemModifier()
        .subscribe({
          next: (data: any) => {
            this.posItemModifiers = data;
          },
          error: (error: any) => {
            if (error.errors && error.errors.length > 0) {
              this.alertService.error(error.errors[0]);
            } else {
              this.alertService.error(error);
            }
          }
        });
    }
  }

  loadOtherMenuItems(posMenuId: string): void {
    let menuItems: PosItemMenu[] = [];
    menuItems = this.loadPosItemsBy(posMenuId);
    if (menuItems.length > 0) {
      //already map items
    }
    else {
      this.posItemMenuService.getPosItemMenuBy(posMenuId)
        .subscribe({
          next: (response: any) => {
            if (response) {
              let menuItems = response.filter(a => a.pos_menu_id === +posMenuId);
              if (menuItems.length > 0) {
                this.currentMenuItems = menuItems;
                this.mapMenuHeaderSubheader();
              }
            }
          },
          error: (error: any) => {
            if (error.errors && error.errors.length > 0) {
              this.alertService.error(error.errors[0]);
            } else {
              this.alertService.error(error);
            }
          }
        });
    }
    this.showOtherMenu = false;
  }

  loadPosItemsBy(selectedMenuId: string): PosItemMenu[] {
    const itemMenuData: PosItemMenu[] = JSON.parse(localStorage.getItem("itemMenu"));
    let menuItems: PosItemMenu[] = [];
    if (itemMenuData) {
      menuItems = itemMenuData.filter(a => a.pos_menu_id === +selectedMenuId);
      if (menuItems.length > 0) {
        this.currentMenuItems = menuItems;
        this.mapMenuHeaderSubheader();
      }
    }
    return menuItems;
  }

  //SOF AddOn
  onAddOnClick() {
    this.showItemAddOns = !this.showItemAddOns;
    if (this.showItemAddOns) {
      this.selectedItemModifier = this.posItemModifiers.filter(x => x.pos_item_id === this.selectedItemFromKotWindow.pos_item_id);
    }
  }

  onAddOnSelection(selectedModifier: PosItemModifier) {
    if (this.selectedItemFromKotWindow) {
      const objIndex = this.posCheck.pos_check_details.findIndex(x => x.is_new && x.pos_item_id === this.selectedItemFromKotWindow.pos_item_id);
      if (objIndex > -1) {
        //first check if modifier is already added - if not then add
        const objModifierIndex = this.posCheck.pos_check_details[objIndex].pos_check_detail_modifiers.findIndex(x => x.pos_modifier_id == selectedModifier.pos_modifier_id);

        if (objModifierIndex < 0) {
          //same modifier is not added previously - so adding now
          let objDetailModifier = new PosCheckDetailModifier();
          objDetailModifier.amount = +selectedModifier.pos_modifier.modifier_price;
          objDetailModifier.currency_id = selectedModifier.pos_modifier.currency_id ? selectedModifier.pos_modifier.currency_id : PointerService.getLocalCurrencyId();
          objDetailModifier.modifier_name_on_check = selectedModifier.pos_modifier.modifier_name;
          objDetailModifier.pos_modifier = selectedModifier.pos_modifier;
          objDetailModifier.pos_modifier_id = selectedModifier.pos_modifier_id;
          objDetailModifier.serial_number = this.posCheck.pos_check_details[objIndex].pos_check_detail_modifiers.length + 1;

          this.posCheck.pos_check_details[objIndex].pos_check_detail_modifiers.push(objDetailModifier);
          this.posCheck.pos_check_details[objIndex].amount += +objDetailModifier.amount.toFixed(this.decimalPlace);

          this.posCheckService.updateOrder(this.posCheck);
        }
      }
    }
  }

  removeAddOn(selectedModifier: PosItemModifier): void {
    if (this.selectedItemFromKotWindow && this.selectedItemFromKotWindow.is_new) {
      const objIndex = this.posCheck.pos_check_details.findIndex(x => x.is_new && x.pos_item_id === this.selectedItemFromKotWindow.pos_item_id);
      if (objIndex > -1) {
        const objModifierIndex = this.posCheck.pos_check_details[objIndex].pos_check_detail_modifiers.findIndex(x => x.pos_modifier_id === selectedModifier.pos_modifier_id);
        this.posCheck.pos_check_details[objIndex].pos_check_detail_modifiers.splice(objModifierIndex, 1);
        this.posCheckService.updateOrder(this.posCheck);
      }
    }
  }

  onOtherAddOnSelection(): void {
    let otherAddOnId: number = +environment.OTHER_ADD_ON_ID;
    if (this.selectedItemFromKotWindow) {
      const objIndex = this.posCheck.pos_check_details.findIndex(x => x.is_new && x.pos_item_id === this.selectedItemFromKotWindow.pos_item_id);
      if (objIndex > -1) {
        const objModifierIndex = this.posCheck.pos_check_details[objIndex].pos_check_detail_modifiers.findIndex(x => x.pos_modifier_id == otherAddOnId);
        if (objModifierIndex < 0) {
          //same modifier is not added previously - so adding now
          let objDetailModifier = new PosCheckDetailModifier();
          objDetailModifier.amount = this.otherAddOnPrice;
          objDetailModifier.currency_id = PointerService.getLocalCurrencyId();
          objDetailModifier.modifier_name_on_check = this.otherAddOnText;
          objDetailModifier.pos_modifier_id = otherAddOnId;
          objDetailModifier.serial_number = this.posCheck.pos_check_details[objIndex].pos_check_detail_modifiers.length + 1;
          this.posCheck.pos_check_details[objIndex].pos_check_detail_modifiers.push(objDetailModifier);
          this.posCheck.pos_check_details[objIndex].amount += +objDetailModifier.amount.toFixed(this.decimalPlace);

          this.posCheckService.updateOrder(this.posCheck);
        }
      }

      //reset values of pop-up
      this.otherAddOnPrice = 0;
      this.otherAddOnText = "";
      this.closeOtherAddOn.nativeElement.click();
    }
  }
  //EOF AddOn

  //SOF Void Item
  counter(): any[] {
    return new Array(this.selectedItemVoidQtyDropdownOption);
  }

  onVoidReasonSelection(reason: VoidReason): void {
    this.selectedVoidReason = reason.id;
  }

  //event handler for the select element's change event
  selectChangeHandler(event: any) {
    //update the ui
    this.selectedVoidQty = event.target.value;
  }

  onVoidItem() {
    if (this.selectedItemFromKotWindow && this.selectedItemFromKotWindow.is_dirty) {
      const objIndex = this.posCheck.pos_check_details.findIndex(x => x.id == this.selectedItemFromKotWindow.id && x.pos_item_id === this.selectedItemFromKotWindow.pos_item_id);
      let currentItem: PosCheckDetail = this.posCheck.pos_check_details[objIndex];
      if (currentItem.last_action != environment.LAST_ACTION_VOIDED) {
        if (objIndex > -1) {
          let itemQty = this.posCheck.pos_check_details[objIndex].quantity;

          if (itemQty == this.selectedVoidQty) {
            currentItem
            currentItem.last_action = environment.LAST_ACTION_VOIDED;
            currentItem.item_status = environment.STATUS_VOIDED;
            currentItem.void_quantity = this.selectedVoidQty;
            currentItem.void_at = new Date();
            currentItem.void_reason_note = this.void_reason_note;
            currentItem.markedVoid = true;
          } else {
            currentItem.quantity = (itemQty - this.selectedVoidQty);

            //add new Item with void qty 
            let newItem = new PosCheckDetail();
            newItem.is_new = false;
            newItem.is_dirty = true;
            newItem.pos_item_id = currentItem.pos_item_id;
            newItem.shift = this.currentUserShift;
            newItem.pos_restaurant_id = +this.outletId;
            newItem.service = currentItem.service;
            newItem.charge_id = currentItem.charge_id;
            newItem.item_description = currentItem.item_description;
            newItem.pos_menu_id = currentItem.pos_menu_id;
            newItem.pos_kitchen_id = currentItem.pos_kitchen_id;
            newItem.serial_number = this.posCheck.pos_check_details.length + 1;
            newItem.quantity = this.selectedVoidQty;
            newItem.rate = currentItem.rate;
            newItem.currency_id = PointerService.getLocalCurrencyId();
            newItem.pos_item_name_on_check = currentItem.pos_item_name_on_check;
            newItem.do_not_print_price_ind = currentItem.do_not_print_price_ind;
            newItem.charge_id = currentItem.charge_id;
            newItem.pos_item_type = currentItem.pos_item_type;
            newItem.item_status = environment.STATUS_VOIDED;
            newItem.last_action = environment.LAST_ACTION_VOIDED;

            newItem.pos_void_reason_id = this.selectedVoidReason;
            newItem.void_quantity = this.selectedVoidQty;
            newItem.void_at = new Date();
            newItem.void_reason_note = this.void_reason_note;
            newItem.markedVoid = true;
            this.posCheck.pos_check_details.push(newItem);
          }

          this.posCheckService.updateOrder(this.posCheck);
          this.selectedItemFromKotWindow = null;
          this.showItemAddOns = false;
          this.void_reason_note = "";
          this.selectedVoidReason = 0;
          this.selectedVoidQty = 1;
        }
      }
    }
    this.closeBtn.nativeElement.click();
  }
  //EOF Void Item

  openKeyboard(event): void {
    initializeKeyboard(event.target);
    this.changeDetector.detectChanges();
  }
}
