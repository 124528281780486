import { Pipe, PipeTransform } from '@angular/core';
import { TableLayout } from '../models/table-layout.model';

@Pipe({
  name: 'FilterTableBySection',
  pure: true
})
export class FilterTableBySection implements PipeTransform{
  transform(layout: TableLayout[], section: string) {
    if(layout) { 
      let sectionTables = layout.filter(a => a.table_group == section);
      return sectionTables;
    }
  }
}