import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { TableLayout } from '../models/table-layout.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableLayoutService {
  lastSelectedTable = new BehaviorSubject<TableLayout>(null);
  constructor(private http: HttpClient) { }

  getTableLayoutBy(outletId: string) {
    return this.http.get(environment.APIENDPOINT + environment.POS_LAYOUT,
      { params: new HttpParams().append("key_restaurant", outletId).append("per_page", environment.API_DATA_PER_PAGE)}
    ).pipe(
      map((response) => {
        if(response["pos_layouts"] && response["pos_layouts"].length > 0) {
          const tableLayoutArray: TableLayout[] = [];
          response["pos_layouts"].forEach(obj => {
            const layout = new TableLayout(
              obj.id,
              obj.table_code,
              obj.table_group,
              obj.table_image,
              obj.serial_number,
              obj.table_seats,
              obj.default_take_away_ind,
              obj.for_pending_check_ind,
              obj.pos_restaurant_id,
              obj.table_status,
              obj.pos_layout_translations && obj.pos_layout_translations.length > 0 ? obj.pos_layout_translations[0].table_display_code : "",
              obj.pos_layout_translations && obj.pos_layout_translations.length > 0 ? obj.pos_layout_translations[0].description : ""
            )

            tableLayoutArray.push(layout);
          });

          localStorage.setItem('tableLayout', JSON.stringify(tableLayoutArray));
          return tableLayoutArray;
        }
      })
    )
  }

  getTableDetails(outletId: string, tableCode: string): TableLayout  {
    let layoutData = JSON.parse(localStorage.getItem("tableLayout"));

    if(!layoutData) {
      return
    }

    let selectedTable = layoutData.filter(t => {
      return t.pos_restaurant_id = outletId && t.table_code === tableCode 
    });

    if(selectedTable && selectedTable.length > 0) {
      return selectedTable[0];
    }
  }

  setSelectedTable(tableLayout: TableLayout) {
    this.lastSelectedTable.next(tableLayout);
  }

  public get getPreviousSelectedTable(): TableLayout {
    return this.lastSelectedTable.value;
  }

  public resetTableSelection() {
    if (this.lastSelectedTable) {
      this.lastSelectedTable.next(null);
    }
  }
}
