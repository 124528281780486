import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-qwerty-keyboard',
  templateUrl: './qwerty-keyboard.component.html',
  styles: [
  ]
})
export class QwertyKeyboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
