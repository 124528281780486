import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableLayout } from '../../models/table-layout.model';
import { PointerService } from '../../services/pointer.service';
import { environment } from '../../../environments/environment';
//import { ActionCableService, Channel } from 'angular2-actioncable';
import { Subscription } from 'rxjs';
import { User } from '../..//models/user.model';
import { OutletService } from '../../services/outlet.service';
import { ShiftService } from '../../services/shift.service';
import { TranslateService } from '@ngx-translate/core';
import { ActionCableService } from '../../services/action-cable.service';

declare function toggleHeaderOption(): any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [
    // `:host {
    //   display: flex;
    // }`
  ]

})
export class HeaderComponent implements OnInit {
  subscription: Subscription;
  outletId: string;
  outletName: string = "";
  shiftNumber: number;
  userName: string = "";
  useVirtualKeyboard: string = "";

  constructor(private activatedRoute: ActivatedRoute,
              private outletService: OutletService,
              private shiftService: ShiftService,
              private pointerService: PointerService,
              private cableService: ActionCableService,
              private router: Router,
              public translate: TranslateService)
  {
    translate.addLangs(['English', 'Hindi', 'Marathi']);
    translate.setDefaultLang('English');
  }

  ngOnInit(): void {
    this.outletId = this.activatedRoute.snapshot.params['outlet_id'];
    this.outletName = this.outletService.getOutletName(this.outletId);
    
    const loadedUser: User = JSON.parse(localStorage.getItem("userData"));
    if(loadedUser) {
      this.userName = loadedUser.full_name;

      if(this.outletId) {
        this.shiftNumber = this.shiftService.getUserShiftNo(this.outletId, loadedUser.id);
      }
    }
    toggleHeaderOption();

    this.useVirtualKeyboard = this.pointerService.filterValueByCode(environment.POINTER_USE_VIRTUAL_KEYBOARD);
    
    this.cableService.subscribeTableChannel();
  }

  @HostListener('window:storage')
  onStorageChange() {
    console.log("The user just pressed!");
  }

  translateLanguageTo(lang) {
    this.translate.use(lang);
    localStorage.setItem("language", lang);
  }

  onViewSelection(value) {
    localStorage.setItem("viewMode", value);
  }

  // subscribeToChannel() {
  //   const loadedUser: User = JSON.parse(localStorage.getItem("userData"));
  //   const channel: Channel = this.cableService
  //     .cable(environment.ACTION_CABLE_ENPOINT + loadedUser.token)
  //     .channel(environment.TABLE_STATUS_CHANNEL);

  //   // Subscribe to incoming messages
  //   this.subscription = channel.received().subscribe(data => {
  //     if(data) {
  //       let tableData: TableLayout[] = JSON.parse(localStorage.getItem('tableLayout'));
        
  //       if(tableData) {
  //         let selectedTable: TableLayout[] = tableData.filter(x => x.id == data.id && x.table_code == data.table_code);
  
  //         if(selectedTable.length > 0) {
  //           selectedTable[0].table_status = data.table_status;
  //           selectedTable[0].pos_check_id = data.pos_check_id;
  //           localStorage.setItem('tableLayout', JSON.stringify(tableData));
  //         }
  //       }
  //     }
  //   });
  // }

  // enableKeyboardSetting(): void {
  //   const virtualKeyboard = JSON.parse(localStorage.getItem("enableVirtualKeyboard"));
  //   if(virtualKeyboard) {
  //     if(virtualKeyboard == "Y") {
  //       localStorage.setItem("enableVirtualKeyboard", JSON.stringify("N"));
  //     } else {
  //       localStorage.setItem("enableVirtualKeyboard", JSON.stringify("Y"));
  //     }
  //   } else {
  //     localStorage.setItem("enableVirtualKeyboard", JSON.stringify("Y"));
  //   }
  // }

  ngOnDestroy() {
    // Unsubscribing from the messages Observable automatically
    // unsubscribes from the ActionCable channel as well
    //this.subscription.unsubscribe();
  }

  onHomeClick(): void {
    this.router.navigate(['/landing']);
  }
}
