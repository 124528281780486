import { Pipe, PipeTransform } from '@angular/core';
import { PosItemModifier } from '../../models/pos-item-modifier.model';
import { PosItemMenu } from '../../models/pos-item-menu.model';

@Pipe({
  name: 'FilterModifierByItem',
  pure: true
})

export class FilterModifierByItem implements PipeTransform{
  transform(currentPosItemModifier: PosItemModifier[], posItemMenu: PosItemMenu) {
    if(currentPosItemModifier && posItemMenu) { 
      let filteredModifier = currentPosItemModifier.filter(a => a.pos_item_id == posItemMenu.pos_item_id);
      return filteredModifier;
    }
  }
}