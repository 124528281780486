export class OutletBilling {
  constructor(
    public id: number,
    public saas_company_id: number,
    public company_id: number,
    public branch_id: number,
    public pos_restaurant_id: number,
    public restaurant_name_on_check: number,
    public gst_number: string,
    public pan_number: string,
    public hsn_number: string,
    public tin_number: string,
    public cin_number: string,
    public fssai_number: string,
    public place_of_supply: string,
    public state_code: number,
    public check_header_line1: string,
    public check_header_line2: string,
    public address1: string,
    public address2: string,
    public address3: string,
    public zip: string,
    public restaurant_contact: string,
    public restaurant_email: string,
    public gst_declaration: string,
    public declaration: string,
    public upi_url: string,
    public remarks: string,
    public notes: string
  ) { }
}