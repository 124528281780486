import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../models/user.model';
import { AuthService } from '../services/auth.service';
import { fromEvent, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { AlertService } from '../services/alert.service';
declare function enableNumericKeyboard(): any;

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html'
})
export class AuthComponent implements OnInit {
  showUsercodeButton = true;
  showPasswordButton = false;
  usercode: string = "";
  password: string = "";
  error: any[] = [];
  private unsubscriber : Subject<void> = new Subject<void>();

  constructor(private authService: AuthService,
              private router: Router,
              private alertService: AlertService,
              public translate: TranslateService) {}
  
  @ViewChild('authForm') form: NgForm;

  ngOnInit(): void {
    enableNumericKeyboard();

    let language = localStorage.getItem("language");
    if(language) {
      this.translate.setDefaultLang(language);
    }

    localStorage.removeItem("chargeData");
    localStorage.removeItem("itemMenu");
    localStorage.removeItem("itemModifier");
    localStorage.removeItem("outletSetting");
    localStorage.removeItem("pointerData");
    localStorage.removeItem("posRestaurant");
    localStorage.removeItem("tableLayout");
    localStorage.removeItem("userShiftData");
    localStorage.removeItem("userData");
    localStorage.removeItem("currencyData");
    localStorage.removeItem("discountData");
    localStorage.removeItem("voidReason");
    localStorage.removeItem("orderStatusData");

    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {
      history.pushState(null, '');
      this.error = [];
      this.error.push(`You can't go back at this time. Please relogin to continue`);
    });
  }

  ngAfterViewInit() {
    let element: HTMLElement = document.getElementById("usercodeInput") as HTMLElement;
    element.click();
  }

  onUserCodeClick(): void {
    if((<HTMLInputElement>document.getElementById("usercodeInput")).value != "") {
      this.showUsercodeButton = false; 
      this.showPasswordButton = true;
      this.usercode = (<HTMLInputElement>document.getElementById("usercodeInput")).value;

      (<HTMLInputElement>document.getElementById("usercodeInput")).setAttribute("disabled","disabled");
      (<HTMLInputElement>document.getElementById("passwordInput")).removeAttribute("disabled");
      (<HTMLInputElement>document.getElementById("passwordInput")).value = "";
      (<HTMLInputElement>document.getElementById("usercodeInput")).value = "";
    }
    else {
      (<HTMLInputElement>document.getElementById("usercodeInput")).style.borderColor = "red";
    }
  }

  onPasswordClick(): void {
    if((<HTMLInputElement>document.getElementById("passwordInput")).value != "") {
      this.password = (<HTMLInputElement>document.getElementById("passwordInput")).value;
      this.form.ngSubmit.emit();
    } else {
      (<HTMLInputElement>document.getElementById("passwordInput")).style.borderColor = "red";
    }
  }

  onSubmit(form: NgForm): void {
    this.error = [];
    if(!form.valid) {
      return;
    }
    else {
      this.authService.validateLogin(this.usercode, this.password)
      .pipe(first())
        .subscribe({
          next: (resData: any) => {
            const user = this.authService.setUser(resData);
            this.setUserData(user);
          },
          error: (error: any) => {
            if(error.errors && error.errors.length > 0) {
              this.error.push(error.errors[0]);
            } else {
              this.error.push(error);
            }
            this.showUsercodeButton = true;
            (<HTMLInputElement>document.getElementById("usercodeInput")).value = this.usercode;
          }
      })
    }
  }

  setUserData(user: User): void {
    if(user.id) {
      localStorage.setItem("userData", JSON.stringify(user));
      
      this.authService.getUserFunctionalRights(user.id).subscribe(
        () => {
          this.router.navigate(['/landing']);
        }
      )
    }
  }

  onUsercodeEnter(): void {
    this.onUserCodeClick();
  }

  onPasswordEnter(): void {
    this.onPasswordClick();
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }
}
