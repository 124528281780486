import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Shift } from '../../models/shift.model.model';
import { User } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';
import { PointerService } from '../../services/pointer.service';
import { ShiftService } from '../../services/shift.service';

declare function enableNumericKeyboard(): any;

@Component({
  selector: 'app-close-shift',
  templateUrl: './close-shift.component.html'
})
export class CloseShiftComponent implements OnInit {
  closingBalance: string = "";
  error: any[] = [];
  outletId: string = "";
  businessDate: string;
  userShift: Shift;
  loadedUser: User = JSON.parse(localStorage.getItem("userData"));

  constructor(private shiftService: ShiftService,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private router: Router) { }
     
  @ViewChild('authForm') form: NgForm;

  ngOnInit(): void {
    enableNumericKeyboard();
    this.businessDate = PointerService.getISOBusinessDate();
    this.outletId = this.activatedRoute.snapshot.params['outlet_id'];

    let currentShiftNo = this.shiftService.getUserShiftNo(this.outletId, this.loadedUser.id);
    this.shiftService.getShiftDetailsBy(this.outletId, this.loadedUser.id.toString(), this.businessDate, currentShiftNo.toString())
    .subscribe({
      next: (resData: any) => {
        if (resData && resData.length > 0) {
          if(!resData[0].shift_closed_ind) {
            this.userShift = resData[0];
          } else {
            this.router.navigate(['/']);
          }
        }
      },
      error: (error: any) => {
        if(error.errors && error.errors.length > 0) {
          this.error.push(error.errors[0]);
        } else {
          this.error.push(error);
        }
      }
    });
  }

  ngAfterViewInit() {
    let element: HTMLElement = document.getElementById("closingBalanceInput") as HTMLElement;
    element.click();
  }

  onClosingBalanceInputClick(): void {
    if ((<HTMLInputElement>document.getElementById("closingBalanceInput")).value != "") {
      this.closingBalance = (<HTMLInputElement>document.getElementById("closingBalanceInput")).value;
      this.form.ngSubmit.emit();
    } else {
      (<HTMLInputElement>document.getElementById("closingBalanceInput")).style.borderColor = "red";
    }
  }

  onClosingBalanceEnter(): void {
    this.onClosingBalanceInputClick();
  }

  onSubmit(form: NgForm): void {
    this.error = [];
    if (!form.valid) {
      return;
    }
    else {
      this.userShift.closing_balance = +this.closingBalance;
      this.userShift.shift_closed_ind = true;
      this.updateUserShift();
    }
  }

  updateUserShift(): void {
    let user = this.authService.userValue;
    if (user) {
      this.shiftService.update(this.userShift)
      .subscribe({
        next: (data: any) => {
          this.authService.logout()
        },
        error: (error: any) => {
          if(error.errors && error.errors.length > 0) {
            this.error.push(error.errors[0]);
          } else {
            this.error.push(error);
          }
        }
      });
    } else {
      this.authService.logout()
    }
  }

}
