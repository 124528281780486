import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {
  @ViewChild('closeLogoutModal') closeLogoutModal: ElementRef;
  constructor(private authService: AuthService) { }
  
  ngOnInit(): void {
  }

  onLogout(): void {
    this.authService.logout();
    this.closeLogoutModal.nativeElement.click();
  }

}
