import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DebitCardsService {

  constructor(private http: HttpClient) { }

  fetchDebitCardData(cardNumber: any) {
    let pointers = JSON.parse(localStorage.getItem("pointerData"));
    let businessDate = pointers?.filter((res: any) => res.code == "BUSI")[0]?.pointer_value;
    let queryParams = new HttpParams();
    queryParams = queryParams.append('key_business_date', businessDate)
      .append('key_card_number', cardNumber)
      .append('key_current_ind', true);
    return this.http.get<any>(environment.APIENDPOINT + environment.DEBIT_CARDS, {
      params: queryParams
    }).
      pipe(map((results: any) => {
        return results;
      }))
  }


}
