<div class="wrapg_blk">
  <app-header></app-header>

  <!-- SOF Sub-header HTML -->
  <div class="sub_header_parnt menu_sub_hdr">
    <div class="sub_btn_grp">
      <button class="btn info_btn"><i class="bi bi-info-lg"></i></button>
    </div>
    <div class="sub_header_inr">
      <h2 class="page_name">{{ 'ACTION_BUTTON.A&G' | translate }}</h2>
      <div class="table_drpdwn">
        <select>
          <option *ngIf="posCheck">{{ posCheck.table_code }}</option>
        </select>
      </div>
      <div class="cover_num_blk">
        <p>
          <label>{{ 'COMMON_LABEL.COVERS' | translate }}:</label>
          <input *ngIf="posCheck" type="number" [placeholder]="posCheck.covers ? posCheck.covers : ''" readonly />
        </p>
      </div>
      <div class="gust_info_blk">
        <div class="guest_name_parnt">
          <div class="guest_name_blk">
            <p>
              <label>{{ 'COMMON_LABEL.NAME' | translate }}:</label>
              <input *ngIf="posCheck" type="text" readonly [placeholder]="posCheck.guest_name ? posCheck.guest_name : ''" />
            </p>
          </div>
          <div class="guest_name_blk">
            <p>
              <label>{{ 'COMMON_LABEL.GST' | translate }}:</label>
              <input *ngIf="posCheck" type="text" [placeholder]="posCheck.guest_gst_number ? posCheck.guest_gst_number : ''" readonly />
            </p>
          </div>
        </div>
      </div>
    </div>

    <app-business-date></app-business-date>
  </div>
  <!-- EOF Sub-header HTML -->

  <main class="main_cnt_blk action_btn_hght">
    <section class="table_tab_parnt">
      <div class="row">
        <div class="col-12 mb-15">
          <div class="disnt_parnt_blk discnt_reasn_blk">
            <div class="row">
              <div class="col-sm-4 col-md-3 col-xl-2 mb-15" *ngFor="let ang of angCategories; odd as isOdd; even as isEven;">
                <div class="paymt_blk" [ngClass]="{ 'blue_main_parnt': isEven, 'purple_main_parnt': isOdd }">
                  <a href="javascript:void(0)" class="form-control ang_icn" tabindex="0" (click)="onANGSelection(ang)">
                    <label>{{ ang.description }}</label>
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
    <section class="action_btn_parnt">
      <ul>
        <li (click)="onSaveClick()" [class.unavlbl_btn]="!selectedANG">
          <a href="javascript:void(0)" class="form-control" tabindex="0">
            <svg enable-background="new 0 0 26 26" version="1.1" viewBox="0 0 26 26" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M16.8035889,14.4605103c2.0820923-1.2811279,3.4776611-3.5723267,3.4776611-6.1885376  C20.28125,4.262207,17.0146484,1,13,1S5.71875,4.262207,5.71875,8.2719727c0,2.6162109,1.3955688,4.9074097,3.4776611,6.1885376  c-4.4957886,1.0071411-7.6505127,3.7583618-7.6505127,7.0878296C1.5458984,24.2729492,8.7460938,25,13,25  s11.4541016-0.7270508,11.4541016-3.4516602C24.4541016,18.2188721,21.2993774,15.4676514,16.8035889,14.4605103z   M7.21875,8.2719727C7.21875,5.0893555,9.8125,2.5,13,2.5s5.78125,2.5893555,5.78125,5.7719727S16.1875,14.043457,13,14.043457  S7.21875,11.4545898,7.21875,8.2719727z M13,23.5c-6.1149902,0-9.7753906-1.289978-9.9536743-1.9567261  C3.0509644,18.2345581,7.5145874,15.543457,13,15.543457c5.4848633,0,9.9481201,2.6906128,9.9536133,5.9988403  C22.7797852,22.2085571,19.1190186,23.5,13,23.5z"/></svg>
            {{ 'ACTION_BUTTON.A&G' | translate }}
          </a>
        </li>
        <li (click)="onBackClick()">
          <a href="javascript:void(0)" class="form-control" tabindex="0">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 98.86" style="enable-background:new 0 0 122.88 98.86" xml:space="preserve"><g><path d="M0,49.43l48.93,49.43V74.23c30.94-6.41,55.39,0.66,73.95,24.19c-3.22-48.4-36.29-71.76-73.95-73.31V0L0,49.43 L0,49.43L0,49.43z"/></g>
            </svg>
            {{ 'ACTION_BUTTON.BACK' | translate }}
          </a>
        </li>
        
      </ul>
    </section>
  </main>
</div>