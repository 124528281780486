<div class="modal fade show lock_scrn_modal" id="WarningModal"
	data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="warningModalLabel" role="dialog"
	aria-modal="true"
	*ngIf="alertTime && errorAlerts.length > 0"
	[style.display]="alertTime && errorAlerts.length > 0 ? 'block' : 'none'"
	>
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="warningModalLabel">Warning</h5>
				<!-- <button type="button" #closeWarningModal class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
			</div>
			<div class="modal-body" *ngIf="errorAlerts && errorAlerts.length > 0">
				<ul class="list-group list-group-flush">
					<ng-container *ngFor="let alert of errorAlerts">
						<ng-container *ngIf="isString(alert.message)=='object'">
							<ng-container *ngFor="let alertMessage of alert['message']">
								<li class="list-group-item" [innerHTML]="alertMessage">
								</li>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="isString(alert.message)=='string'">
							<li class="list-group-item" [innerHTML]="alert.message">
							</li>
						</ng-container>
					</ng-container>
				</ul>

				<!-- <h2 class="title_cls" *ngFor="let error of errorAlerts">
          {{ errorAlerts }}
        </h2> -->
			</div>
			<div class="modal-footer">
				<div></div>
				<!-- <div class="button_blk">
					 <button type="button" class="btn gry_btn" data-bs-dismiss="modal"><i
							class="fas fa-check-circle"></i>Ok</button>
				</div> -->
			</div>
		</div>
	</div>
</div>