import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { Pointer } from '../models/pointer.model';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PointerService {
  constructor(private http: HttpClient) { }

  public getAll() {
    let params = new HttpParams();
    params = params.append("key_module", environment.MODULE_POS).append("per_page", environment.API_DATA_PER_PAGE);

    return this.http.get(environment.APIENDPOINT + environment.POINTER, {
      params: params
    }
    ).pipe(
      map(response => {
        const pointerList: Pointer[] = [];
        if(response && response["pointers"].length > 0) {
          response["pointers"].forEach(obj => {
             const pointer = new Pointer(
               obj.id,
               obj.code,
               obj.description,
               obj.pointer_value
             )

             pointerList.push(pointer);
          });
          localStorage.setItem('pointerData', JSON.stringify(pointerList)); 
          return pointerList;
        }
      })
    )
  }

  public getByCode(code: string){
    return this.http.get(environment.APIENDPOINT + environment.POINTER, {
      params: new HttpParams().append("key_code", code)
    }
    ).pipe(
      map(response => {
        if(response["pointers"] && response["pointers"].length > 0)
          return response["pointers"][0];
        else 
          return "";
      })
    )
  }

  public filterValueByCode(code: string): string {
    const pointerData = JSON.parse(localStorage.getItem('pointerData'));
    
    if(!pointerData) {
      return "";
    }
    
    let objPointer = pointerData.filter(t => {
      return t.code.toLowerCase() == code.toLowerCase();
    });

    if(objPointer && objPointer.length > 0) {
      return objPointer[0].pointer_value;
    } else {
      return "";
    }
  }

  public getDecimalPlaceValue(): number {
    const pointerData = JSON.parse(localStorage.getItem('pointerData'));
    
    if(!pointerData) {
      return 2;
    }

    let objPointer = pointerData.filter(t => {
      return t.code.toLowerCase() == environment.POINTER_DECIMAL_PLACE.toLowerCase();
    });

    if(objPointer && objPointer.length > 0) {
      return +objPointer[0].pointer_value;
    } else {
      return 2;
    }
  }

  public getCurrencyByCode(code: string){
    return this.http.get(environment.APIENDPOINT + environment.CURRENCY, {
      params: new HttpParams().append("key_code", code).append("per_page", environment.API_DATA_PER_PAGE)
    }
    ).pipe(
      map(response => {
        if(response["currencies"] && response["currencies"].length > 0) {
          localStorage.setItem("currencyData", JSON.stringify(response["currencies"][0]));
        }
      })
    )
  }

  public static getLocalCurrencyId() {
    const currencyData = JSON.parse(localStorage.getItem("currencyData"));
    if(currencyData) {
      return currencyData.id;
    }
    else {
      return "";
    }
  }

  public static getLocalCurrencySymbol() {
    const currencyData = JSON.parse(localStorage.getItem("currencyData"));
    if(currencyData) {
      return currencyData.symbol ? currencyData.symbol : currencyData.code;
    }
    else {
      return "";
    }
  }

  public static getBusinessDate(): Date {
    const pointerData = JSON.parse(localStorage.getItem('pointerData'));
    //const pointerCode = environment.POINTER_USE_SYSTEM_DATE;
    let returnDate: Date = new Date();
    
    if(pointerData) {
      let businessDate = pointerData.filter(t => {
        return t.code.toLowerCase() == environment.POINTER_BUSINESS_DATE.toLowerCase();
      });

      if(businessDate && businessDate.length > 0) {
        returnDate = new Date(businessDate[0].pointer_value);
      }
      
      // let objPointer = pointerData.filter(t => {
      //   return t.code.toLowerCase() == pointerCode.toLowerCase();
      // });

      // if(objPointer && objPointer.length > 0) {
      //   if(objPointer[0].pointer_value.toUpperCase() === "N") {
      //     let businessDate = pointerData.filter(t => {
      //       return t.code.toLowerCase() == environment.POINTER_BUSINESS_DATE.toLowerCase();
      //     });
  
      //     if(businessDate && businessDate.length > 0) {
      //       returnDate = new Date(businessDate[0].pointer_value);
      //     }
      //   }
      // }
    }
    return returnDate;
  }

  public static getISOBusinessDate(): string {
    return this.getBusinessDate().toISOString().slice(0, 10);
  }

  public changeBusinessDate(currentBusinessDate: string, saasCompanyId: number, companyId: number, branchId: number) {
    return this.http.post(environment.APIENDPOINT + environment.CHANGE_BUSINESS_DATE,
      { 
        "night_audit": {
          "business_date": currentBusinessDate,
          "saas_company_id": saasCompanyId,
          "company_id": companyId,
          "branch_id": branchId
        }
      }
    ).pipe(catchError(errorRes => {
      let errorMessage = "An unknown error occurred!"
      if (errorRes.night_audit && errorRes.night_audit.errors) {
        errorMessage = errorRes.night_audit.errors;
      }
      else if (errorRes.status === 0) {
        errorMessage = "Api service is down";
      }
      return throwError(errorMessage);
    }), tap(resData => {

    }));
  }
}
