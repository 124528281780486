import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../models/user.model';
import { PointerService } from '../services/pointer.service';
import { ChargeService } from '../services/charge.service';
import { OutletService } from '../services/outlet.service';
import { environment } from '.././../environments/environment';
import { AlertService } from '../services/alert.service';

@Component({
  selector: 'app-outlet',
  templateUrl: './outlet.component.html'
})
export class OutletComponent implements OnInit {
  userOutlets: any[];
  businessDate: string;

  constructor(private router: Router,
              private pointerService: PointerService,
              private chargeService: ChargeService,
              private outletService: OutletService,
              private alertService: AlertService
             ) { }

  ngOnInit(): void {
    const loadedUser: User = JSON.parse(localStorage.getItem("userData"));

    if(!loadedUser) {
      return;
    }

    this.userOutlets = loadedUser.user_outlets;

    const pointerData = JSON.parse(localStorage.getItem("pointerData"));
    const chargeData = JSON.parse(localStorage.getItem("chargeData"));
    
    if (!chargeData) {
      this.chargeService.getAll()
      .subscribe({
        next: (data: any) => {
          //do nothing
        },
        error: (error: any) => {
          if(error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      });
    }

    if (!pointerData) {
      this.pointerService.getAll()
      .subscribe({
        next: (data: any) => {
          if (data) {
            let currencyLocal = this.pointerService.filterValueByCode(environment.POINTER_CURRENCY_LOCAL1);
            this.pointerService.getCurrencyByCode(currencyLocal).subscribe();
          }
        },
        error: (error: any) => {
          if(error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      });
    }
    else {
      const currencyData = JSON.parse(localStorage.getItem("currencyData"));
      if (!currencyData) {
        let currencyLocal = this.pointerService.filterValueByCode(environment.POINTER_CURRENCY_LOCAL1);
        this.pointerService.getCurrencyByCode(currencyLocal)
        .subscribe({
          next: (data: any) => {
            // do nothing
          },
          error: (error: any) => {
            if(error.errors && error.errors.length > 0) {
              this.alertService.error(error.errors[0]);
            } else {
              this.alertService.error(error);
            }
          }
        });
      }
    }

    this.businessDate = PointerService.getISOBusinessDate();
  }

  onOutletSelection(outletId: string): void {
    this.outletService.getOutletDetailsBy(outletId, this.businessDate)
    .subscribe({
      next: (data: any) => {
        this.getOutletBillingInfo(outletId);
      },
      error: (error: any) => {
        if(error.errors && error.errors.length > 0) {
          this.alertService.error(error.errors[0]);
        } else {
          this.alertService.error(error);
        }
      }
    });
  }

  getOutletBillingInfo(outletId: string): void {
    this.outletService.getOutletBillingDetails(outletId)
    .subscribe({
      next: (data: any) => {
        this.router.navigate(['/shift/'+ outletId]);
      },
      error: (error: any) => {
        if(error.errors && error.errors.length > 0) {
          this.alertService.error(error.errors[0]);
        } else {
          this.alertService.error(error);
        }
      }
    });
  }
}
