import { UserOutlet } from './user-outlet.model';

export class User {
  constructor(
    public id: string,
    public email:string,
    public code: string,
    public title: string,
    public first_name: string,
    public last_name: string,
    public full_name: string,
    public saas_company_id: number,
    public company_id: number,
    public branch_id: number,
    public saas_company_name: string,
    public company_name: string,
    public branch_name: string,
    public token: string,
    public mobile_number: string,
    public saas_company_code: string,
    public company_code: string,
    public branch_code: string,
    public rights?: [],
    public user_rights?: [],
    public functional_rights?: [],
    public user_outlets?: UserOutlet[]
  ){}
}