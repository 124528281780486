import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { Account } from '../models/account.model';
import { AddressType } from '../models/address-type.model';
import { throwError } from 'rxjs';
import { AccountContactNumber } from '../models/account-contact-number.model';
import { AccountAddress } from '../models/account-address.model';

@Injectable({
  providedIn: 'root'
})

export class AccountService {
  userValue: any;
  constructor(private http: HttpClient) { }

  public findAddressBy(contactValue: string) {
    let accountArray: Account[] = [];
    return this.http.get(environment.APIENDPOINT + environment.ACCOUNT,
      { params: new HttpParams().append("key_contact_number", contactValue).append("per_page", "100") })
      .pipe(
        map(data => {
          if (data && data['accounts'] && data['accounts'].length > 0) {
            data['accounts'].forEach(obj => {
              const account = new Account();
              account.id = obj.id;
              account.first_name = obj.first_name;
              account.last_name = obj.last_name;
              account.title = obj.title;
  
              if (obj.account_addresses && obj.account_addresses.length > 0) {
                obj.account_addresses.forEach(element => {
                  let objAddress = new AccountAddress();
                  objAddress.id = element.id;
                  objAddress.address1 = element.address1;
                  objAddress.address2 = element.address2;
                  objAddress.address3 = element.address3;
                  objAddress.address_type_id = element.address_type ? element.address_type.id : "";
                  objAddress.city_id = element.city_id;
                  objAddress.state_id = element.state_id;
                  objAddress.country_id = element.country_id;
                  objAddress.zip = element.zip;

                  if(element.city && element.city.city_translations && element.city.city_translations.length > 0) {
                    objAddress.city_name = element.city.city_translations[0].description;
                  } else {
                    objAddress.city_name = "";
                  }

                  if(element.state && element.state.state_translations && element.state.state_translations.length > 0) {
                    objAddress.state_name = element.state.state_translations[0].description;
                  } else {
                    objAddress.state_name = "";
                  }

                  if(element.country && element.country.country_translations && element.country.country_translations.length > 0) {
                    objAddress.country_name = element.country.country_translations[0].description;
                  } else {
                    objAddress.country_name = "";
                  }

                  account.account_address.push(objAddress);
                }); 
              }
              else {
                account.account_address = [];
              }
  
              if(obj.account_contact_numbers && obj.account_contact_numbers.length > 0) {
                obj.account_contact_numbers.forEach(element => {
                  let accountContact = new AccountContactNumber();
                  accountContact.contact_number_type_id = element.contact_number_type_id;
                  accountContact.contact_number_value = element.contact_number_value;
                  account.account_contact_number.push(accountContact);
                });
              }
              else {
                account.account_contact_number = [];
              }
  
              accountArray.push(account);
            });
          }
          return accountArray;
        })
      )
  }

  public getAddressType() {
    let addressTypeArray: AddressType[] = [];
    return this.http.get(environment.APIENDPOINT + environment.ADDRESS_TYPE)
      .pipe(map(data => {
        if (data["address_types"] && data["address_types"].length > 0) {
          data["address_types"].forEach(obj => {
            const adType = new AddressType(
              obj.id,
              obj.address_type_translations && obj.address_type_translations.length > 0 ? obj.address_type_translations[0].description : ""
            );
            addressTypeArray.push(adType);
          });
        }
        localStorage.setItem('addressTypes', JSON.stringify(addressTypeArray));
        return addressTypeArray;
      })
    )
  }

  public save(account: Account) {
    let json = Account.createJson(account);
    
    return this.http.post(environment.APIENDPOINT + environment.ACCOUNT,
      { account: json }
      ).pipe(catchError(errorRes => {
        let errorMessage = "An unknown error occurred!"
        if (errorRes.account && errorRes.account.errors && errorRes.account.errors.length > 0) {
          errorMessage = errorRes.account.errors[0];
        }
        else if (errorRes.status === 0) {
          errorMessage = "Api service is down";
        }
        return throwError(errorMessage);
      }), tap(resData => {

    }));
  }

}