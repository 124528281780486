import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PosCheck } from '../../models/pos-check.model';
import { PointerService } from '../../services/pointer.service';
import { PosCheckService } from '../../services/pos-check.service';
import { AlertService } from '../../services/alert.service';
import { RoomService } from '../../services/room.service';
import { CommonService } from '../../services/common.service';
import { PosCheckSettlement } from '../../models/pos-check-settlement.model';
import { environment } from 'projects/pos/src/environments/environment';
import { ChargeService } from '../../services/charge.service';

@Component({
  selector: 'app-room-post',
  templateUrl: './room-post.component.html'
})
export class RoomPostComponent {
  outletId: string = "";
  posCheck: PosCheck;
  posCheckId: string;
  decimalPlace: number = this.pointerService.getDecimalPlaceValue();
  roomData: any;
  maxChars: number = 50;
  inhouse_status: string = "CI";
  primary_client: string = "1";
  first_name: string = "";
  last_name: string = "";
  selected_room: string = "";
  subheader_text: string = "";
  guestData: any[] = [];
  selectedVisitId: string = "";
  selectedInhouseId: string = "";
  selectedRoomNo: string = "";

  currentUserShift = this.commonService.getCurrentUserShiftNumber(this.outletId);

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private posCheckService: PosCheckService,
    private pointerService: PointerService,
    private roomService: RoomService,
    private alertService: AlertService,
    private commonService: CommonService,
    private chargeService: ChargeService) { }

  ngOnInit(): void {
    this.outletId = this.activatedRoute.snapshot.params["outlet_id"];
    this.posCheckId = this.activatedRoute.snapshot.params["check_id"];
    this.getCheckDetails();
    this.getAllRooms();

  }

  getAllRooms() {
    this.roomService.getAll()
      .subscribe({
        next: (data) => {
          let allItems: any = [];
          data?.forEach(element => {
            let obj = {}
            obj['id'] = element.id;
            obj['description'] = element.room_display_number;
            obj['floor_number'] = element.floor_number;
            obj['room_type'] = element.room_type;
            obj['occupancy_status'] = element.occupancy_status;
            obj['cleaning_status'] = element.cleaning_status;
            allItems.push(obj);
          });

          this.roomData = allItems;
          //localStorage.setItem('roomData', JSON.stringify(allItems));
        },
        error: error => {
          this.alertService.error(error);
        }
      });
  }

  getCheckDetails(): void {
    this.posCheckService.findBy(this.posCheckId)
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.posCheck = data;
          }
        },
        error: (error: any) => {
          if (error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      });
  }

  onRoomPost(): void {
    if (this.selectedVisitId != "") {
      this.addPaymentToCheck();
    } else {
      this.alertService.error("Please select guest for room post");
    }
  }

  addPaymentToCheck() {
    this.posCheck.close_shift = this.currentUserShift;
    let inhouseSettlementChargeId: string = this.pointerService.filterValueByCode(environment.POINTER_POS_INHOUSE_SETTLEMENT_CHARGE);
    
    if(inhouseSettlementChargeId == "") {
      this.alertService.error("Pointer missing for room post charge");
      return
    } else {
     
      this.chargeService.fetchChargeById(+inhouseSettlementChargeId).subscribe({
        next: (data: any) => {
          if (data && data.charge) {
            this.posCheck.pos_check_settlements = [];
            let settlement = new PosCheckSettlement();
            settlement.is_new = true;
            settlement.id = this.posCheck.pos_check_settlements.length + 1;
            settlement.allowance_ind = false;
            settlement.amount = this.posCheck.dr_total;
            settlement.room_no = this.selectedRoomNo;
            settlement.inhouse_id = this.selectedInhouseId;
            settlement.visit_id = this.selectedVisitId;
            settlement.charge_id = +inhouseSettlementChargeId;
            settlement.currency_id = PointerService.getLocalCurrencyId();
            settlement.dr_cr = data.charge.dr_cr;
            settlement.settlement_type = data.charge.settlement_process_code;

            this.posCheck.pos_check_settlements.push(settlement);

            this.posCheck.room_settle_ind = true;

            this.posCheckService.settleBill(this.posCheck)
              .subscribe({
                next: (data: any) => {
                  //delay layout refresh - for table status change
                  setTimeout(() => {
                    this.router.navigate(['/table-layout/' + this.outletId]);
                  }, 500);
                },
                error: (error: any) => {
                  if (error.errors && error.errors.length > 0) {
                    this.alertService.error(error.errors[0]);
                  } else {
                    this.alertService.error(error);
                  }
                }
            });
          }
        },
        error: (error: any) => {
          if (error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      });
    }
  }

  onGuestChecked(event): void {
    if (event.selectedRowsData && event.selectedRowsData.length > 0) {
      if (event.selectedRowsData.length > 1) {
        this.selectedVisitId = "";
        this.selectedInhouseId = "";
        this.selectedRoomNo = "";
        this.alertService.error("Please select only one guest");
      } else {
        this.selectedVisitId = event.selectedRowsData[0].visit_id;
        this.selectedInhouseId = event.selectedRowsData[0].inhouse_id;
        this.selectedRoomNo = event.selectedRowsData[0].room_number;
      }
    }
  }

  searchRecords(): void {
    this.subheader_text = "Guest details for: ";

    if (this.selected_room) {
      this.subheader_text += "Room no: " + this.selected_room;
    }

    if (this.first_name) {
      this.subheader_text += "First name: " + this.first_name;
    }

    if (this.last_name) {
      this.subheader_text += "Last name: " + this.last_name;
    }

    this.roomService.findGuestDetails(this.selected_room, this.first_name, this.last_name, this.inhouse_status, this.primary_client)
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.guestData = data;
          }
        },
        error: (error: any) => {
          if (error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      });
  }

  onRoomSelection(event): void {
    this.selected_room = event.target.value;
  }

  onBackClick(): void {
    this.router.navigate(['/billing/' + this.outletId + '/view-bill/' + this.posCheckId]);
  }
}
