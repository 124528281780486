import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PosCheckChargeDiscount } from '../../models/pos-check-charge-discount.model';
import { PosCheck } from '../../models/pos-check.model';
import { TableLayout } from '../../models/table-layout.model';
import { VoidReason } from '../../models/void-reason.model';
import { CommonService } from '../../services/common.service';
import { PointerService } from '../../services/pointer.service';
import { PosCheckService } from '../../services/pos-check.service';
import { TableLayoutService } from '../../services/table-layout.service';
import { VoidReasonService } from '../../services/void-reason.service';
import { environment } from '../../../environments/environment';
import { AlertService } from '../../services/alert.service';
import { OutletSetting } from '../../models/outlet-setting.model';
import { OutletBilling } from '../../models/outlet-billing.model';
declare var Stimulsoft: any;

@Component({
  selector: 'app-view-bill',
  templateUrl: './view-bill.component.html'
})
export class ViewBillComponent implements OnInit {
  options: any = new Stimulsoft.Viewer.StiViewerOptions();
  viewer: any = new Stimulsoft.Viewer.StiViewer(this.options, 'StiViewer', false);

  outletId: string = "";
  posCheck: PosCheck;
  posCheckId: string;
  chargeTotal: number = 0;
  taxTotal: number = 0;
  discountTotal: number = 0;
  tipsAmount: number = 0;
  layout: TableLayout;
  discountList: PosCheckChargeDiscount[] = [];
  lastActionPosted = environment.LAST_ACTION_POSTED;
  lastActionSettled = environment.LAST_ACTION_SETTLE;
  lastActionVoided = environment.LAST_ACTION_VOIDED;
  voidReasonList: VoidReason[] = [];
  maxChars: number = 200;
  voidReasonNote: string = "";
  selectedVoidReason: number = 0;
  currencySymbol: string = "";
  currentShift: number;
  balanceAmount: number = 0;
  drTotal: any = 0;
  qrCodeUrl: string = "";
  decimalPlace: number = this.pointerService.getDecimalPlaceValue();
  @ViewChild('closeVRModalBtn') closeBtn: ElementRef;
  @ViewChild('closeTipsModalBtn') closeTips: ElementRef;

  canVoidBill: boolean = false;
  canGiveAllowance: boolean = false;
  canGiveDiscount: boolean = false;
  canReprintBill: boolean = false;
  canPrintSettledBill: boolean = false;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private posCheckService: PosCheckService,
    private tableLayoutService: TableLayoutService,
    private pointerService: PointerService,
    private voidReasonService: VoidReasonService,
    private commonService: CommonService,
    private alertService: AlertService) { }

  ngOnInit(): void {
    this.outletId = this.activatedRoute.snapshot.params["outlet_id"];
    this.posCheckId = this.activatedRoute.snapshot.params["check_id"];
    this.getCheckDetails();

    const voidReasonData: VoidReason[] = JSON.parse(localStorage.getItem("voidReason"));
    if (!voidReasonData) {
      this.voidReasonService.getAll()
        .subscribe({
          next: (data: any) => {
            this.voidReasonList = data;
          },
          error: (error: any) => {
            if (error.errors && error.errors.length > 0) {
              this.alertService.error(error.errors[0]);
            } else {
              this.alertService.error(error);
            }
          }
        });
    } else {
      this.voidReasonList = voidReasonData;
    }

    this.currencySymbol = PointerService.getLocalCurrencySymbol();
    this.currentShift = this.commonService.getCurrentUserShiftNumber(this.outletId);

    this.canVoidBill = this.commonService.hasFunctionalRights(environment.RIGHT_CODE_POS_CAN_VOID_CHECK);
    this.canGiveAllowance = this.commonService.hasFunctionalRights(environment.RIGHT_CODE_POS_CAN_GIVE_ALLOWANCE);
    this.canGiveDiscount = this.commonService.hasFunctionalRights(environment.RIGHT_CODE_POS_CAN_GIVE_DISCOUNT);
    this.canReprintBill = this.commonService.hasFunctionalRights(environment.RIGHT_CODE_POS_CAN_REPRINT_CHECK);
    this.canPrintSettledBill = this.commonService.hasFunctionalRights(environment.RIGHT_CODE_POS_CAN_PRINT_SETTLED_CHECK);
  }

  getCheckDetails(): void {
    this.posCheckService.findBy(this.posCheckId).subscribe(data => {
      if (data) {
        this.posCheck = data;
        this.calculateChargeTotal();
        this.balanceAmount = +((+this.posCheck.dr_total + +this.posCheck.tips) - (+this.posCheck.cr_total)).toFixed(this.decimalPlace);
        this.layout = this.tableLayoutService.getTableDetails(this.outletId, this.posCheck.table_code);
        this.drTotal = (+this.posCheck?.dr_total).toFixed(this.decimalPlace);
        const billingHeaderData: OutletBilling[] = JSON.parse(localStorage.getItem("outletBillingHeader"));
        const outletBilling = billingHeaderData?.filter(a => a.pos_restaurant_id === +this.outletId);

        if (outletBilling?.length > 0 && outletBilling[0]?.upi_url != "") {
          this.qrCodeUrl = outletBilling[0].upi_url + "&am=" + this.posCheck.dr_total + "&cu=INR&tn=" + this.posCheck.check_no;
        }
      }
    });
  }

  calculateChargeTotal(): void {
    if (this.posCheck.pos_check_charges) {
      this.posCheck.pos_check_charges.forEach(charge => {
        if (charge.dr_cr.toUpperCase() === environment.DR_IND) {
          this.chargeTotal += +charge.amount;
        } else {
          this.chargeTotal -= +charge.amount;
        }

        if (charge.pos_check_charge_discounts) {
          charge.pos_check_charge_discounts.forEach(discount => {
            this.discountTotal += +discount.amount;
            this.discountList.push(discount);
          });
        }

        if (charge.pos_check_charge_taxes) {
          charge.pos_check_charge_taxes.forEach(tax => {
            this.taxTotal += +tax.amount;
          });
        }
      });

      this.chargeTotal = +this.chargeTotal.toFixed(this.decimalPlace);
      this.taxTotal = +this.taxTotal.toFixed(this.decimalPlace);
      this.discountTotal = +this.discountTotal.toFixed(this.decimalPlace);
    }
  }

  onBackClick(): void {
    this.router.navigate(['/table-layout/' + this.outletId]);
  }

  onANGClick(): void {
    if (this.posCheck && this.posCheck.last_action == this.lastActionPosted) {
      this.router.navigate(['/billing/' + this.outletId + '/ang/' + this.posCheckId]);
    }
  }

  onLocalClick(): void {
    if (this.posCheck && this.posCheck.last_action == this.lastActionPosted && this.posCheck.print_count > 0) {
      this.router.navigate(['/billing/' + this.outletId + '/settlement/' + this.posCheckId]);
    }
  }

  onDisountClick(): void {
    if (this.canGiveDiscount) {
      if (this.posCheck && this.posCheck.last_action == this.lastActionPosted && this.posCheck.print_count <= 0) {
        this.router.navigate(['/billing/' + this.outletId + '/discount/' + this.posCheckId]);
      } else {
        this.alertService.error(environment.ERROR_DISCOUNT_RIGHT)
      }
    } else {
      this.alertService.error(environment.ERROR_DISCOUNT_RIGHT);
    }
  }

  onPrintClick(): void {
    this.chargeTotal = 0;
    this.taxTotal = 0;
    this.discountTotal = 0;
    this.tipsAmount = 0;
    this.discountList = [];

    this.posCheckService.findBy(this.posCheckId).subscribe(data => {
      if (data) {
        this.posCheck = data;
        this.calculateChargeTotal();
        this.balanceAmount = +((+this.posCheck.dr_total + +this.posCheck.tips) - (+this.posCheck.cr_total)).toFixed(this.decimalPlace);
        this.layout = this.tableLayoutService.getTableDetails(this.outletId, this.posCheck.table_code);

        if (this.posCheck) {
          if (this.posCheck.last_action.toUpperCase() != environment.LAST_ACTION_VOIDED.toUpperCase()) {
            if (this.posCheck.print_count > 0) {
              if (this.posCheck.last_action.toUpperCase() == environment.LAST_ACTION_SETTLE.toUpperCase()) {
                if (this.canPrintSettledBill) {
                  this.printCheck();
                } else {
                  this.alertService.error(environment.DUPLICATE_SETTLE_PRINT_CHECK);
                }
              } else {
                if (this.canReprintBill) {
                  this.printCheck();
                } else {
                  this.alertService.error(environment.DUPLICATE_PRINT_CHECK);
                }
              }
            } else {
              if (this.posCheck.round_off == null) {
                let roundOffType = this.pointerService.filterValueByCode(environment.POINTER_POS_ROUND_OFF_TYPE);
                let amount = 0, roundOffAmount = 0;

                if (roundOffType != null) {
                  if (roundOffType == environment.POS_ROUND_OFF_FLOOR_CODE) {
                    amount = Math.floor(this.posCheck.dr_total);
                    roundOffAmount = amount - this.posCheck.dr_total;
                  }
                  else if (roundOffType == environment.POS_ROUND_OFF_BASED_ON_POINT_FIVE_CODE) {
                    amount = Math.round(this.posCheck.dr_total);
                    roundOffAmount = amount - this.posCheck.dr_total;
                  }
                  else if (roundOffType == environment.POS_ROUND_OFF_CEILING_CODE) {
                    amount = Math.ceil(this.posCheck.dr_total);
                    roundOffAmount = amount - this.posCheck.dr_total;
                  }
                  else {
                    amount = Math.round(this.posCheck.dr_total);
                    roundOffAmount = amount - this.posCheck.dr_total;
                  }
                }

                this.posCheck.round_off = +roundOffAmount.toFixed(this.decimalPlace);
                this.posCheck.dr_total = +amount.toFixed(this.decimalPlace);
                this.balanceAmount = +((+this.posCheck.dr_total + +this.posCheck.tips) - (+this.posCheck.cr_total)).toFixed(this.decimalPlace);
              }
              this.printCheck();
            }
          } else {
            this.alertService.error(environment.VOID_PRINT_CHECK);
          }
        }

        const billingHeaderData: OutletBilling[] = JSON.parse(localStorage.getItem("outletBillingHeader"));
        const outletBilling = billingHeaderData?.filter(a => a.pos_restaurant_id === +this.outletId);

        if (outletBilling?.length > 0 && outletBilling[0]?.upi_url != "") {
          this.qrCodeUrl = outletBilling[0].upi_url + "&am=" + this.posCheck.dr_total + "&cu=INR&tn=" + this.posCheck.check_no;
        }
      }
    });
  }

  printCheck(): void {
    const outletSettingData: OutletSetting[] = JSON.parse(localStorage.getItem("outletSetting"));
    if (outletSettingData) {
      let currentOutletSetting = outletSettingData.filter(a => a.pos_restaurant_id === +this.outletId);

      if (currentOutletSetting.length > 0) {
        let defaultPrintCount = currentOutletSetting[0].print_check_default_count;
        let duplicatePrintCount = 0;

        this.posCheckService.printBill(this.posCheck, this.currentShift)
          .subscribe({
            next: (data: any) => {
              this.posCheck.print_cashier_name = data.pos_check.print_cashier ? data.pos_check.print_cashier.full_name : "";
              this.posCheck.print_time = data.pos_check.print_time ? data.pos_check.print_time : "";
              this.posCheck.print_count = data.pos_check.print_count;
              let report = Stimulsoft.Report.StiReport.createNewReport();
              let report1 = Stimulsoft.Report.StiReport.createNewReport();

              if (defaultPrintCount > 0) {
                if (this.posCheck.print_count <= 1) {
                  //First copy - original check - first time will be called
                  report = this.commonService.printBill(this.posCheck);
                  report.render();
                  report.print(false);

                  defaultPrintCount--;
                } else {
                  // for duplicate copy print
                  report = this.commonService.printBill(this.posCheck, true);
                  report.render();
                  report.print(false);

                  defaultPrintCount--;
                }
                report1 = this.commonService.printBill(this.posCheck, true);
              }

              if (defaultPrintCount > 0) {
                // for duplicate copy print
                for (let i = 0; i < defaultPrintCount; i++) {
                  setTimeout(function () {
                    report1.render();
                    report1.print(false);
                  }, 2000 + i * 2000)
                }
              }
            },
            error: (error: any) => {
              if (error.errors && error.errors.length > 0) {
                this.alertService.error(error.errors[0]);
              } else {
                this.alertService.error(error);
              }
            }
          });
      }
      // this.options.appearance.fullScreenMode = true;
      // this.options.toolbar.displayMode = Stimulsoft.Viewer.StiToolbarDisplayMode.Separated;
      // let dataSet = new Stimulsoft.System.Data.DataSet();
      // let dataSet2 = new Stimulsoft.System.Data.DataSet();
      // let dataSet3 = new Stimulsoft.System.Data.DataSet();

      // let json = PosCheck.createPrintCheckJson(this.posCheck);
      // dataSet.readJson(json);
      // dataSet2.readJson(json["pos_check_charges"]);
      // dataSet3.readJson(json["posCheck.pos_check_settlements"]);

      // report.loadFile('/public/mrt/check_thermal_printer.mrt');
      // report.regData(dataSet, "pos_check", { pos_check: json });
      // report.regData(dataSet.pos_check, "pos_check", dataSet);
      // report.regData(dataSet2.pos_check_charges, "pos_check_charges", dataSet2);
      // report.regData(dataSet3.pos_check_settlements, "pos_check_settlements", dataSet3);
    }
  }

  onVoidReasonSelection(reason: VoidReason): void {
    this.selectedVoidReason = reason.id;
  }

  onVoidClick(makeDuplicateCopy: boolean) {
    this.posCheck.pos_void_reason_id = this.selectedVoidReason;
    this.posCheck.void_reason_note = this.voidReasonNote;

    this.posCheckService.voidBill(this.posCheck)
      .subscribe({
        next: (data: any) => {
          if (makeDuplicateCopy) {
            const newCheck = Object.assign(new PosCheck(), this.posCheck);
            this.createDuplicateBillCopy(newCheck);
          } else {
            this.closeBtn.nativeElement.click();
            this.router.navigate(['/table-layout/' + this.outletId]);
          }
        },
        error: (error: any) => {
          this.closeBtn.nativeElement.click();
          if (error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      });
  }

  saveTips(): void {
    this.posCheck.tips = +this.tipsAmount;
    //this.posCheck.dr_total = +this.posCheck.dr_total;
    this.posCheckService.addTips(this.posCheck)
      .subscribe({
        next: (data: any) => {
          this.balanceAmount = +((+this.posCheck.dr_total + +this.posCheck.tips) - (+this.posCheck.cr_total)).toFixed(this.decimalPlace);
          this.closeTips.nativeElement.click();
        },
        error: (error: any) => {
          if (error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      });
  }

  onAllowanceClick(): void {
    if (this.canGiveAllowance) {
      const tableData: TableLayout[] = JSON.parse(localStorage.getItem("tableLayout"));
      const currentTableStatus = tableData.filter(x => x.table_code == this.posCheck.table_code)[0].table_status;

      if (currentTableStatus != environment.POS_TABLE_STATUS_AVAILABLE) {
        this.alertService.error(environment.ERROR_ALLOWANCE);
      } else {
        this.router.navigate(['/billing/' + this.outletId + '/allowance/' + this.posCheckId]);
      }
    }
  }

  createDuplicateBillCopy(newCheck: PosCheck) {
    newCheck.from_void_check_no = this.posCheck.check_no;
    newCheck.round_off = null;
    newCheck.id = null;
    newCheck.is_dirty = false;
    newCheck.is_new = true;
    newCheck.check_no = null;
    newCheck.check_series_no = null;
    newCheck.pos_ang_category_id = null;
    newCheck.void_date_time = null;
    newCheck.void_reason_note = null;
    newCheck.last_action = environment.LAST_ACTION_POSTED;

    newCheck.pos_check_details = newCheck.pos_check_details.filter(item => item.last_action != environment.LAST_ACTION_VOIDED);

    newCheck.pos_check_details.forEach(item => {
      item.id = null;
      item.kot_no = null;
      item.kot_print_count = null;
      item.kot_print_ind = false;
    })

    newCheck.pos_check_charges.forEach(charge => {
      charge.id = null;

      charge.pos_check_charge_discounts.forEach(discount => {
        discount.id = null;
        discount.pos_check_charge_id = null;
      })

      charge.pos_check_charge_taxes.forEach(tax => {
        tax.id = null;
        tax.pos_check_charge_id = null;
      })
    })

    this.posCheckService.saveBill(newCheck)
      .subscribe({
        next: (data: any) => {
          this.closeBtn.nativeElement.click();
          this.router.navigate(['/billing/' + this.outletId + '/place-order/' + data['pos_check'].table_code + '/' + data['pos_check'].id]);
        },
        error: (error: any) => {
          this.closeBtn.nativeElement.click();
          if (error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      });
  }

  onRoomPostClick(): void {
    if (this.posCheck && this.posCheck.last_action == this.lastActionPosted && this.posCheck.print_count > 0) {
      this.router.navigate(['/billing/' + this.outletId + '/room-post/' + this.posCheckId]);
    }
  }
}
