import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { PointerService } from './pointer.service';
import { PosCheck } from '../models/pos-check.model';
import { PosCheckDetail } from '../models/pos-check-detail.model';
import { PosCheckDetailModifier } from '../models/pos-check-detail-modifier.model';
import { PosOrderStatus } from '../models/pos-order-status.model';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KotService {
  constructor(private http: HttpClient) { }

  public getKotScreenCheckDetails(outletId: string) {
    let businessDate = PointerService.getBusinessDate().toISOString().slice(0, 10)

    let params = new HttpParams().append("key_restaurant", outletId)
      .append("key_check_open_date", businessDate)
      .append("key_check_details", "true")

    return this.http.get(environment.APIENDPOINT + environment.POS_CHECK, {
      params: params
    }).pipe(
      map(response => {
        if (response && response["pos_checks"].length > 0) {
          let checkList: PosCheck[] = [];
          response["pos_checks"].forEach(obj => {
            if (obj.last_action.toUpperCase() != environment.LAST_ACTION_VOIDED) {
              checkList.push(this.mapPosCheck(obj));
            }
          });
          return checkList;
        }
      }
    ));
  }

  public mapPosCheck(obj): PosCheck {
    let posCheck = new PosCheck();
    let checkDetailList: PosCheckDetail[] = [];

    posCheck.check_no = obj.check_no;
    posCheck.check_series_no = obj.check_series_no;
    posCheck.covers = obj.covers;
    posCheck.id = obj.id;
    posCheck.last_action = obj.last_action;
    posCheck.open_time = obj.open_time;
    posCheck.pos_restaurant_id = obj.pos_restaurant_id;
    posCheck.table_code = obj.table_code;
    posCheck.take_away_ind = obj.take_away_ind;

    if (obj.pos_restaurant && obj.pos_restaurant.pos_restaurant_translations && obj.pos_restaurant.pos_restaurant_translations.length > 0) {
      posCheck.restaurant_name = obj.pos_restaurant.pos_restaurant_translations[0].description;
    } else {
      posCheck.restaurant_name = "";
    }

    if (obj.server && obj.server.full_name) {
      posCheck.server_name = obj.server.full_name;
    } else {
      posCheck.server_name = "";
    }

    obj.pos_check_details.forEach(detail => {
      let kotItem = new PosCheckDetail();

      kotItem.id = detail.id;
      kotItem.pos_check_id = detail.pos_check_id;
      kotItem.pos_item_id = detail.pos_item_id;
      kotItem.quantity = detail.quantity;
      kotItem.kot_no = detail.kot_no;
      kotItem.system_time = detail.system_time;
      kotItem.order_status_code = detail.pos_order_status ? detail.pos_order_status.code : "";
      kotItem.order_status = (detail.pos_order_status && detail.pos_order_status.pos_order_status_translations && detail.pos_order_status.pos_order_status_translations.length > 0) ? detail.pos_order_status.pos_order_status_translations[0].description : "";
      kotItem.item_description = detail.pos_item_name_on_check ? detail.pos_item_name_on_check : detail.item_description;

      let itemModifier = [];
      if (detail.pos_check_detail_modifiers && detail.pos_check_detail_modifiers.length > 0) {
        detail.pos_check_detail_modifiers.forEach(element => {
          let detailModifier = new PosCheckDetailModifier();

          detailModifier.pos_check_detail_id = element.pos_check_detail_id;
          detailModifier.modifier_name_on_check = element.pos_modifier && element.pos_modifier.pos_modifier_translations && element.pos_modifier.pos_modifier_translations.length > 0 ? element.pos_modifier.pos_modifier_translations[0].description : element.modifier_name_on_check;
          itemModifier.push(detailModifier);
        });
      }

      kotItem.pos_check_detail_modifiers = itemModifier;

      if (detail.last_action.toUpperCase() != environment.LAST_ACTION_VOIDED) {
        checkDetailList.push(kotItem);
      }
    });

    if (checkDetailList.length > 0) {
      posCheck.pos_check_details = checkDetailList;
    }

    return posCheck;
  }

  public getOrderStatuses() {
    return this.http.get(environment.APIENDPOINT + environment.POS_ORDER_STATUS
    ).pipe(
      map(response => {
        let statusArray: PosOrderStatus[] = []
        if (response["pos_order_statuses"] && response["pos_order_statuses"].length > 0) {
          response["pos_order_statuses"].forEach(obj => {
            let orderStatus = new PosOrderStatus(
              obj.id,
              obj.code,
              obj.pos_order_status_translations && obj.pos_order_status_translations.length > 0 ? obj.pos_order_status_translations[0]["description"] : ""
            );

            statusArray.push(orderStatus);
          });
          localStorage.setItem("orderStatusData", JSON.stringify(statusArray));
        }
        return statusArray;
      }
      ));
  }

  public updateOrderStatus(posCheck: object) {
    return this.http.put(environment.APIENDPOINT + environment.POS_CHECK + '/' + posCheck['id'],
      { pos_check: posCheck }
    ).pipe(catchError(errorRes => {
      let errorMessage = environment.GENERAL_API_ERROR_MESSAGE;
      if (errorRes.pos_check && errorRes.pos_check.errors && errorRes.pos_check.errors.length > 0) {
        errorMessage = errorRes.pos_check.errors[0];
      }
      else if (errorRes.status === 0) {
        errorMessage = "Api service is down";
      }
      return throwError(errorMessage);
    }), tap(response => {
      if (response && response["pos_check"]) {
        return response["pos_check"]
      }
    }));
  }
}
