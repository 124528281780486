import { ChangeDetectorRef, Component } from '@angular/core';
import { LoaderService } from 'projects/pos/src/app/services/loader.service';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  //isLoading: Subject<boolean> = this.loaderService.isLoading;
  isLoading:any
  //color: ThemePalette = 'primary';
  //mode: ProgressSpinnerMode = 'indeterminate';
  //value = 50;

  constructor(private loaderService: LoaderService,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit(){
    this.loaderService.isLoading.subscribe(data=>{
      this.isLoading=data;
    })
  }

  ngAfterViewChecked(): void {
    this.ref.detectChanges();
  }

}
