import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators'
import { PosItemMenu } from '../models/pos-item-menu.model'
import { PosItem } from '../models/pos-item.model';
import { PosMenuHeader } from '../models/pos-menu-header.model';
import { PosMenuSubHeader } from '../models/pos-menu-subheader.model';
import { PosItemModifier } from '../models/pos-item-modifier.model';
import { PosModifier } from '../models/pos-modifier.model';

@Injectable({
  providedIn: 'root'
})
export class PosItemMenuService {

  constructor(private http: HttpClient) { }

  getPosItemMenuBy(menuId: string) {
    let itemMenuArray: PosItemMenu[] = [];
    if (localStorage.getItem("pos_item_menus") != null) {
      itemMenuArray = JSON.parse(localStorage.getItem("pos_item_menus"));
    };

    return this.http.get(environment.APIENDPOINT + environment.POS_ITEM_MENU,
      { params: new HttpParams().append("key_menu", menuId).append("per_page", "100000") }
    ).pipe(
      map((response) => {
        if (response["pos_item_menus"] && response["pos_item_menus"].length > 0) {
          response["pos_item_menus"].forEach(obj => {
            let item: PosItemMenu;
            item = this.mapPosItemMenu(obj);
            itemMenuArray.push(item);
          });

          localStorage.setItem("itemMenu", JSON.stringify(itemMenuArray));
          return itemMenuArray;
        }
      }
    ))
  }

  mapPosItemMenu(obj): PosItemMenu {
    let item: PosItem;
    let menuHead: PosMenuHeader = null;
    let menuSubHead: PosMenuSubHeader = null;

    if (obj.pos_item) {
      item = new PosItem(
        obj.id,
        obj.pos_item.code,
        obj.pos_item.account_code,
        obj.pos_item.item_class,
        obj.pos_item.hsn_sac_code,
        obj.pos_item.pos_item_translations && obj.pos_item.pos_item_translations.length > 0 ? obj.pos_item.pos_item_translations[0]["short_name"] : "",
        obj.pos_item.pos_item_translations && obj.pos_item.pos_item_translations.length > 0 ? obj.pos_item.pos_item_translations[0]["long_name"] : "",
        obj.pos_item.pos_item_translations && obj.pos_item.pos_item_translations.length > 0 ? obj.pos_item.pos_item_translations[0]["description"] : "",
      );
    }

    if (obj.pos_menu_header) {
      menuHead = new PosMenuHeader(
        obj.pos_menu_header.id,
        obj.pos_menu_header.code,
        obj.pos_menu_header.serial_number,
        obj.pos_menu_header.image_path,
        obj.pos_menu_header.pos_menu_header_translations && obj.pos_menu_header.pos_menu_header_translations.length > 0 ? obj.pos_menu_header.pos_menu_header_translations[0]["description"] : "",
      )
    }

    if (obj.pos_menu_subheader) {

      menuSubHead = new PosMenuSubHeader(
        obj.pos_menu_subheader.id,
        obj.pos_menu_subheader.code,
        obj.pos_menu_subheader.serial_number,
        obj.pos_menu_subheader.image_path,
        obj.pos_menu_subheader.pos_menu_subheader_translations && obj.pos_menu_subheader.pos_menu_subheader_translations.length > 0 ? obj.pos_menu_subheader.pos_menu_subheader_translations[0]["description"] : "",
      )
    }

    const posMenuItem = new PosItemMenu(
      obj.id,
      obj.saas_company_id,
      obj.company_id,
      obj.branch_id,
      obj.pos_menu_id,
      obj.pos_item_id,
      obj.pos_item_image,
      obj.pos_menu_header_id,
      obj.pos_menu_header_id,
      obj.pos_item_price,
      obj.happy_hour_price,
      obj.currency_id,
      obj.pos_kitchen_id,
      obj.pos_item_charge_id,
      obj.happy_hour_charge_id,
      obj.serial_number,
      obj.cmp_mod,
      obj.do_not_print_price_on_check_ind,
      obj.price_inclusive_tax_ind,
      obj.pos_item_menu_translations && obj.pos_item_menu_translations.length > 0 ? obj.pos_item_menu_translations[0]["description"] : "",
      obj.pos_item_menu_translations && obj.pos_item_menu_translations.length > 0 ? obj.pos_item_menu_translations[0]["happy_hour_description"] : "",
      obj.pos_item_menu_translations && obj.pos_item_menu_translations.length > 0 ? obj.pos_item_menu_translations[0]["name_on_check"] : "",
      menuHead,
      menuSubHead,
      item
    );

    return posMenuItem;
  }

  getPosItemModifier() {
    return this.http.get(environment.APIENDPOINT + environment.POS_ITEM_MODIFIER)
    .pipe(
      map((response) => {
        if(response["pos_item_modifiers"] && response["pos_item_modifiers"].length > 0) {
          const posItemModifierList: PosItemModifier[] = [];

          response["pos_item_modifiers"].forEach(obj => {
            let item: PosItemModifier;
            item = this.mapPosItemModifier(obj);
            posItemModifierList.push(item);
          })

          localStorage.setItem("itemModifier", JSON.stringify(posItemModifierList));
          return posItemModifierList;
        }
      })
    )
  }

  mapPosItemModifier(obj): PosItemModifier {
    let posItem: PosItem;
    let posModifier: PosModifier;
  
    if (obj.pos_item) {
      posItem = new PosItem(
        obj.pos_item.id,
        obj.pos_item.code,
        obj.pos_item.account_code,
        obj.pos_item.item_class,
        obj.pos_item.hsn_sac_code,
        obj.pos_item.pos_item_translations && obj.pos_item.pos_item_translations.length > 0 ? obj.pos_item.pos_item_translations[0]["short_name"] : "",
        obj.pos_item.pos_item_translations && obj.pos_item.pos_item_translations.length > 0 ? obj.pos_item.pos_item_translations[0]["long_name"] : "",
        obj.pos_item.pos_item_translations && obj.pos_item.pos_item_translations.length > 0 ? obj.pos_item.pos_item_translations[0]["description"] : "",
      );
    }

    if(obj.pos_modifier) {
      posModifier = new PosModifier(
        obj.pos_modifier.id,
        obj.pos_modifier.code,
        obj.pos_modifier.pos_modifier_group_id,
        obj.pos_modifier.modifier_price,
        obj.pos_modifier.modifier_image,
        obj.pos_modifier.currency_id,
        obj.pos_modifier.pos_modifier_translations && obj.pos_modifier.pos_modifier_translations.length > 0 ? obj.pos_modifier.pos_modifier_translations[0].description : ""
      );
    }

    let posItemModifier: PosItemModifier = new PosItemModifier(
      obj.id, obj.pos_item.id, obj.pos_modifier_id, posItem, posModifier
    );

    return posItemModifier;
  }
}
