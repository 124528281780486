import { Injectable } from '@angular/core';
import { Charge } from '../models/charge.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { PointerService } from './pointer.service';
import { Tax } from '../models/tax.model';

@Injectable(
  { providedIn: 'root'}
)
export class ChargeService {
  constructor(private http: HttpClient){
  }

  getAll() {
    let chargeArray: Charge[] = [];
    let businessDate = PointerService.getISOBusinessDate();

    return this.http.get(
      environment.APIENDPOINT + environment.CHARGE, {
        params: new HttpParams().append("key_charge_indicator", "true").append("key_module", environment.MODULE_POS).append("per_page", environment.API_DATA_PER_PAGE).append("key_business_date", businessDate)
      }
    ).pipe(
      map(response => {
        response["charges"].forEach(obj => {
          let taxArray: Tax[] = [];

          if (obj.tax_charge_tax_links && obj.tax_charge_tax_links.length > 0) {
            obj.tax_charge_tax_links.forEach(tax => {
              let objTax = new Tax(
                tax.tax_id,
                tax.from_date, 
                tax.to_date, 
                tax.tax_percentage, 
                tax.parent_charge_id, 
                tax.tax_amount,
                tax.tax ? tax.tax.code : "", 
                tax.tax ? tax.tax.dr_cr : "",
                tax.tax && tax.tax.charge_translations && tax.tax.charge_translations.length > 0 ? tax.tax.charge_translations[0].description : ""
              )

              taxArray.push(objTax);
            });
          }

          const charge = new Charge(
            obj.id,
            obj.code,
            obj.payment_ind,
            obj.account_code, 
            obj.tax_ind,
            obj.dr_cr,
            obj.settlement_process_code,
            obj.print_charge_group_code,
            obj.department_code,
            obj.allowance_allowed_ind,
            obj.hsn_sac_code,
            obj.inclusive_of_tax_ind,
            obj.charge_translations && obj.charge_translations.length > 0 ? obj.charge_translations[0].description : "",
            taxArray
          );
          chargeArray.push(charge);
        });
        localStorage.setItem("chargeData", JSON.stringify(chargeArray));
      })
    );
  }

  getChargesPayments(code: string, description: string) {
    let chargeArray: Charge[] = [];

    let params = new HttpParams();
    params = params.append("per_page", environment.API_DATA_PER_PAGE).append("key_presets", "true").append("key_module", environment.MODULE_POS);

    if (code) {
      params = params.append("key_code", code);
    }

    if (description) {
      params = params.append("key_name", description);
    }

    return this.http.get(
      environment.APIENDPOINT + environment.CHARGE, {
        params: params
      }
    ).pipe(
      map(response => {
        response["charges"].forEach(obj => {
          let taxArray: Tax[] = [];

          if (obj.tax_charge_tax_links && obj.tax_charge_tax_links.length > 0) {
            obj.tax_charge_tax_links.forEach(tax => {
              let objTax = new Tax(
                tax.tax_id,
                tax.from_date, 
                tax.to_date, 
                tax.tax_percentage, 
                tax.parent_charge_id, 
                tax.tax_amount,
                tax.tax ? tax.tax.code : "", 
                tax.tax ? tax.tax.dr_cr : "",
                tax.tax.charge_translations && tax.tax.charge_translations.length > 0 ? tax.tax.charge_translations[0].description : ""
              )

              taxArray.push(objTax);
            });
          }

          const charge = new Charge(
            obj.id,
            obj.code,
            obj.payment_ind,
            obj.account_code, 
            obj.tax_ind,
            obj.dr_cr,
            obj.settlement_process_code,
            obj.print_charge_group_code,
            obj.department_code,
            obj.allowance_allowed_ind,
            obj.hsn_sac_code,
            obj.inclusive_of_tax_ind,
            obj.charge_translations && obj.charge_translations.length > 0 ? obj.charge_translations[0].description : "",
            taxArray
          );
          chargeArray.push(charge);
        });
        return chargeArray;
      })
    );
  }

  getChargeById(chargeId: number): Charge {
    const chargeData: Charge[] = JSON.parse(localStorage.getItem("chargeData"));
    if(!chargeData) {
      return;
    }

    let selectedCharge = chargeData.filter(t => {
      return t.id === chargeId
    });

    if(selectedCharge && selectedCharge.length > 0) {
      return selectedCharge[0];
    }
  }

  getChargeByCode(chargeCode: string) {
    const chargeData: Charge[] = JSON.parse(localStorage.getItem("chargeData"));
    if(!chargeData) {
      return;
    }

    let selectedCharge = chargeData.filter(t => {
      return t.code === chargeCode
    });

    if(selectedCharge && selectedCharge.length > 0) {
      return selectedCharge[0];
    }
  }

  fetchChargeById(id: number) {
    return this.http.get<any>(environment.APIENDPOINT + environment.CHARGE + "/" + id)
      .pipe(map((results: any) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        return results;
    }));
  }
}
