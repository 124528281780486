import { PosPaymentRestaurant } from "./pos-payment-restaurant.model";
import { PosMenuRestaurant } from './pos-menu-restaurant.model';

export class PosRestaurant {
  constructor(
    public id: number,
    public code: string,
    public department_code: string,
    public serial_number: number,
    public gst_number: string,
    public restaurant_name: string,
    public pos_payment_restaurants: PosPaymentRestaurant[],
    public pos_menu_restaurants: PosMenuRestaurant[]
  ) {}
}