import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '../../models/user.model';
import { PointerService } from '../../services/pointer.service';
import { ShiftService } from '../../services/shift.service';
import { SaleService } from '../../services/sale.service';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-sale',
  templateUrl: './sale.component.html'
})
export class SaleComponent implements OnInit {
  outletId: string = "";
  checkSummary: any[] = [];
  paymentSummary: any[] = [];
  tipsSummary: any[] = [];
  businessDate: string;
  headerLabel: string = "";
  decimalPlace: number = this.pointerService.getDecimalPlaceValue();

  totalCheckCount: number = 0;
  totalCheckRevenue: number = 0;
  totalPaymentCount: number = 0;
  totalPaymentRevenue: number = 0;
  totalTipCount: number = 0;
  totalTipRevenue: number = 0;

  constructor(private saleService: SaleService,
    private shiftService: ShiftService,
    private activateRoute: ActivatedRoute,
    private alertService: AlertService,
    private pointerService: PointerService) { }

  ngOnInit(): void {
    this.businessDate = PointerService.getISOBusinessDate();
  }

  ngAfterViewInit(): void {
    this.outletId = this.activateRoute.snapshot.params['outlet_id'];
    const loadedUser: User = JSON.parse(localStorage.getItem("userData"));
    if (!loadedUser) {
      return;
    }

    let shiftNo = this.shiftService.getUserShiftNo(this.outletId, loadedUser.id);
    this.saleService.getSaleBy(this.outletId, loadedUser, this.businessDate, shiftNo)
      .subscribe({
        next: (data: any) => {
          if (data && data.length > 0) {
            this.checkSummary = data.filter(x => x.group_sr_no == 1);
            this.paymentSummary = data.filter(x => x.group_sr_no == 2);
            this.tipsSummary = data.filter(x => x.group_sr_no == 3);

          }
        },
        error: (error: any) => {
          if (error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      });

    this.headerLabel = "Daily sales | " + this.businessDate + " | Shift #" + shiftNo;
  }
}
