import { Pipe, PipeTransform } from '@angular/core';
import { PosCheckCharge } from '../../models/pos-check-charge.model';

@Pipe({
  name: 'FilterTax',
  pure: true
})
export class FilterTax implements PipeTransform {
  
  transform(posCheckCharges: PosCheckCharge[]) {
    if(posCheckCharges) {
      let taxArray = [];
      const uniqueCharges = [...new Set(posCheckCharges.map(item => item.pos_check_charge_taxes))];
      
      uniqueCharges.forEach(charge => {
        charge.forEach(element => {
          const objIndex = taxArray.findIndex(x => x.tax_id === element.tax_id);
          
          if(objIndex === -1) {
            taxArray.push(element);
          } else {
            taxArray[objIndex].amount += +element.amount;
          }
        });
      });
      return taxArray;
    }
  } 
}