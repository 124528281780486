import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ActionCableService } from 'angular2-actioncable';
import { AuthGuard } from '../auth/auth.guard';
import { BillingModule } from '../billing/billing.module';
import { SharedModule } from '../shared/shared.module';
import { TableLayoutComponent } from './table-layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterTableBySection } from './section-table.pipe';
@NgModule({
  declarations: [
    FilterTableBySection,
    TableLayoutComponent
  ],
  imports: [
    NgbModule,
    SharedModule,
    BillingModule,
    RouterModule.forChild([
      { path: '', component: TableLayoutComponent, canActivate: [AuthGuard]}
    ])
  ],
  exports: [
    TableLayoutComponent
  ],
  providers: [
    ActionCableService
  ]
})
export class TableLayoutModule { }
