import { User } from "./user.model";

export class AccountAddress {
	constructor() { }

  private _id: number;
	private _saas_company_id: number;
  private _company_id: number;
  private _branch_id: number;
	private _address_type_id: number;
	private _address1: string;
  private _address2: string;
  private _address3: string;
  private _city_id: number;
  private _state_id: number;
	private _country_id: number;
  private _zip: string;

  private _city_name: string;
  private _state_name: string;
  private _country_name: string;

  public get id(): number {
    return this._id;
  }
  public set id(value: number) {
    this._id = value;
  }
  
	public get saas_company_id(): number {
    return this._saas_company_id;
  }
  public set saas_company_id(value: number) {
    this._saas_company_id = value;
  }

  public get company_id(): number {
    return this._company_id;
  }
  public set company_id(value: number) {
    this._company_id = value;
  }
  
  public get branch_id(): number {
    return this._branch_id;
  }
  public set branch_id(value: number) {
    this._branch_id = value;
  }

	public get address_type_id(): number {
		return this._address_type_id;
	}
	public set address_type_id(value: number) {
		this._address_type_id = value;
	}

	public get address1(): string {
    return this._address1;
  }
  public set address1(value: string) {
    this._address1 = value;
  }
  
  public get address2(): string {
    return this._address2;
  }
  public set address2(value: string) {
    this._address2 = value;
  }

  public get address3(): string {
    return this._address3;
  }
  public set address3(value: string) {
    this._address3 = value;
  }
  
  public get state_id(): number {
    return this._state_id;
  }
  public set state_id(value: number) {
    this._state_id = value;
  }

	public get city_id(): number {
		return this._city_id;
	}
	public set city_id(value: number) {
		this._city_id = value;
	}
	public get country_id(): number {
		return this._country_id;
	}
	public set country_id(value: number) {
		this._country_id = value;
	}
	public get zip(): string {
		return this._zip;
	}
	public set zip(value: string) {
		this._zip = value;
	}


  public get city_name(): string {
    return this._city_name;
  }
  public set city_name(value: string) {
    this._city_name = value;
  }
  
  public get state_name(): string {
    return this._state_name;
  }
  public set state_name(value: string) {
    this._state_name = value;
  }
  
  public get country_name(): string {
    return this._country_name;
  }
  public set country_name(value: string) {
    this._country_name = value;
  }
  
	public assignUserProperties(user: User) {
    this._saas_company_id = user.saas_company_id;
    this._company_id = user.company_id;
    this._branch_id = user.branch_id;
  }
}