export class Shift {
  constructor(
    public id: number, 
    public pos_restaurant_id: number,
    public shift_no: number,
    public business_date: Date,
    public currency_id: number,
    public opening_balance: number, 
    public paid_in_amount: number,
    public paid_out_amount: number,
    public closing_balance: number,
    public shift_closed_ind: boolean, 
    public shift_user_id: number,
    public freeze_ind: boolean,
    public freeze_at: Date,
    public updated_place: string,
    public saas_company_id: number,
    public company_id: number,
    public branch_id: number,
  ) {}

  public static createJson(shift: Shift) {
    let objShift = {
      "id": shift.id,
      "pos_restaurant_id": shift.pos_restaurant_id,
      "shift_no": shift.shift_no,
      "business_date": new Date(shift.business_date).toISOString().slice(0, 10), 
      "currency_id": shift.currency_id,
			"opening_balance": shift.opening_balance,
      "paid_in_amount": shift.paid_in_amount,
      "paid_out_amount": shift.paid_out_amount,
      "closing_balance": shift.closing_balance,
      "shift_closed_ind": shift.shift_closed_ind,
			"shft_user_id": shift.shift_user_id,
      "freeze_ind": shift.freeze_ind,
      "freeze_at": shift.freeze_at,
      "updated_place": shift.updated_place,
      "saas_company_id": shift.saas_company_id,
      "company_id": shift.company_id,
      "branch_id": shift.branch_id
    }
    return objShift;
  }
} 