export class PosModifier {
  constructor(
    public id: number,
    public code: string,
    public pos_modifier_group_id: number,
    public modifier_price: number,
    public modifier_image: string,
    public currency_id: number,
    public modifier_name: string
  ) {}
}