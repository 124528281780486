import { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, exhaustMap, take } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) {}
  
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.authService.user.pipe(
      take(1),
      exhaustMap(user => {
        if(!user) {
          return next.handle(req);
        }

        if(req.headers.get('Anonymous') == '') {
          const newHeaders = req.headers.delete('Anonymous');
          const newRequest = req.clone({ headers: newHeaders });
          return next.handle(newRequest);
        }

        const modifiedRequest = req.clone({
          headers: req.headers.append('Authorization', user.token)
          .append('Content-Type', 'application/json')
        })

        return next.handle(modifiedRequest)
        .pipe(
          catchError(error => {
            if(error.errors && error.errors.length > 0) {
              console.log("error is intercept", error.errors);
              return throwError(error.errors[0]);
            }
            return throwError(error)
          })
        );
      })
    )
  } 
}