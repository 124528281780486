export class PosMenuRestaurant {
  constructor(
    public id: number,
    public code: string,
    public pos_menu_id: number,
    public pos_restaurant_id: number,
    public description: string,

    public happy_hour_dow: string,
    public happy_hour_from_date: Date,
    public happy_hour_from_time: Date,
    public happy_hour_to_date: Date,
    public happy_hour_to_time: Date,
    
    public valid_dow: string,
    public valid_from_date: Date,
    public valid_from_time: Date,
    public valid_to_date: Date,
    public valid_to_time: Date
  ) {}
}