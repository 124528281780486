import { Component, OnInit, Input  } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit {
  @Input() messageList: any[];
  showErrorDiv = true;

  ngOnInit(): void {

  }

  toggleDiv(): void {
    this.messageList = [];
  }

}
