import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PosCheckChargeDiscount } from '../../models/pos-check-charge-discount.model';
import { PosCheck } from '../../models/pos-check.model';
import { PointerService } from '../../services/pointer.service';
import { PosCheckService } from '../../services/pos-check.service';
import { environment } from '../../../environments/environment';
import { ANG } from '../../models/ang.model';
import { ANGService } from '../../services/ang.service';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-ang',
  templateUrl: './ang.component.html'
})
export class ANGComponent implements OnInit {
  outletId: string = "";
  posCheckId: string = "";
  angCategories: ANG[] = [];
  selectedANG: ANG;
  posCheck: PosCheck;
  decimalPlace: number = this.pointerService.getDecimalPlaceValue();

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private angService: ANGService,
              private posCheckService: PosCheckService,
              private pointerService: PointerService,
              private alertService: AlertService) { }

  ngOnInit(): void {
    this.outletId = this.activatedRoute.snapshot.params["outlet_id"];
    this.posCheckId = this.activatedRoute.snapshot.params["check_id"];
    
    this.angService.getAll()
    .subscribe({
      next: (data: any) => {
        this.angCategories = data;
      },
      error: (error: any) => {
        if(error.errors && error.errors.length > 0) {
          this.alertService.error(error.errors[0]);
        } else {
          this.alertService.error(error);
        }
      }
    });

    this.posCheckService.findBy(this.posCheckId)
    .subscribe({
      next: (data: any) => {
        this.posCheck = data;
      },
      error: (error: any) => {
        if(error.errors && error.errors.length > 0) {
          this.alertService.error(error.errors[0]);
        } else {
          this.alertService.error(error);
        }
      }
    });
  }

  onBackClick(): void {
    this.router.navigate(['/billing/' + this.outletId + '/view-bill/' + this.posCheckId]);
  }

  onANGSelection(ang: ANG): void {
    this.selectedANG = ang;
    console.log(ang);
  }

  onSaveClick(): void {
    if(this.selectedANG) {
      this.posCheckService.findBy(this.posCheckId)
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.posCheck = data;
            this.posCheck.pos_ang_category_id = this.selectedANG.id;
  
            this.posCheck.pos_check_charges.forEach(posCheckCharge => {
              if(posCheckCharge.charge_id) {
                let chargeDiscount = new PosCheckChargeDiscount();
  
                chargeDiscount.pos_check_charge_id = posCheckCharge.id;
                chargeDiscount.amount = (posCheckCharge.amount * (+environment.ANG_DISCOUNT_PERCENTAGE) / 100);
                chargeDiscount.charge_id = posCheckCharge.charge_id;
                chargeDiscount.currency_id = PointerService.getLocalCurrencyId();
                chargeDiscount.dr_cr = environment.CR_IND;
                chargeDiscount.pos_discount_id = this.selectedANG.pos_discount_id;
                chargeDiscount.discount_percentage = +environment.ANG_DISCOUNT_PERCENTAGE;
        
                posCheckCharge.pos_check_charge_discounts.push(chargeDiscount);
  
                if(posCheckCharge.pos_check_charge_taxes && posCheckCharge.pos_check_charge_taxes.length > 0) {
                  posCheckCharge.pos_check_charge_taxes.forEach(tax => {
                    if(tax.is_dirty) {
                      tax.amount = +((posCheckCharge.amount - chargeDiscount.amount) * tax.tax_percentage / 100).toFixed(this.decimalPlace);
                      if(tax.amount <= 0) {
                        tax.destroy = 1;
                      }
                    }
                  })
                }
              }
            });
            
            this.posCheckService.calculateDrTotal(this.posCheck);
            
            this.posCheckService.saveBill(this.posCheck)
            .subscribe({
              next: (data: any) => {
                if(data) {
                  this.router.navigate(['/table-layout/' + this.outletId]);
                }
              },
              error: (error: any) => {
                if(error.errors && error.errors.length > 0) {
                  this.alertService.error(error.errors[0]);
                } else {
                  this.alertService.error(error);
                }
              }
            });
            
          }
        },
        error: (error: any) => {
          if(error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      });
    }
  }
}
