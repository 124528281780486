<div class="overlay">
  <div class="loader-wrapper">
    <div class="loader-box">
      <div class="icon">
        <i class="fa fa-cutlery" aria-hidden="true"></i>
      </div>
    </div>
  </div>

  <!-- <mat-progress-spinner class="spinner" [diameter]="50" [color]="color" [mode]="mode" [value]="value">
  </mat-progress-spinner> -->
</div>