import { PosMenuHeader } from './pos-menu-header.model';
import { PosMenuSubHeader } from './pos-menu-subheader.model';
import { PosItem } from './pos-item.model';

export class PosItemMenu {
  constructor(
    public id: number,
    public saas_company_id: number,
    public company_id: number,
    public branch_id: number,
    public pos_menu_id: number,
    public pos_item_id: number,
    public pos_item_image: string,
    public pos_menu_header_id: number,
    public pos_menu_subheader_id: number,
    public pos_item_price: number,
    public happy_hour_price: number,
    public currency_id: number,
    public pos_kitchen_id: number,
    public pos_item_charge_id: number,
    public happy_hour_charge_id: number,
    public serial_number: number,
    public cmp_mod: boolean,
    public do_not_print_price_on_check_ind: boolean,
    public price_inclusive_tax_ind: boolean,
    public description: string,
    public happy_hour_description: string,
    public name_on_check: string,
    public menu_header: PosMenuHeader,
    public menu_sub_header: PosMenuSubHeader,
    public pos_item: PosItem
  ) { }
}