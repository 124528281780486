<div class="wrapg_blk">
  <app-header></app-header>
  <main class="main_cnt_blk">
    <section class="login_blk_parnt">
      <div class="login_inr_contnr">
        <!-- SOF Error Message html -->
        <div class="error_msg_parnt" *ngIf="error && error.length > 0">
          <h5>Please correct the following errors and try again.</h5>
          <ul>
            <li *ngFor="let message of error">
              {{ message }}
            </li>
          </ul>
        </div>
        <!-- EOF Error Message html -->
        <div class="login_cnt_blk">
          <div class="login_outlet_icn select_out_blk blue_main_parnt">
            <a class="form-control select_out_icn">
              <span>Close shift</span>
            </a>
          </div>
          <div class="keypad_main_blk">
            <input type="number" id="closingBalanceInput" 
              (keyup.enter)="onClosingBalanceEnter()">
            <div id="digitalKeyboardContainer" class="digitalKeyboardContainer"></div>

            <div class="button_blk">
              <button type="button" (click)="onClosingBalanceInputClick()">
                <i class="opng_lgn_btn"></i>Closing Balance
              </button>
            </div>

            <form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)">
              <input type="hidden" name="closingBalance" [ngModel]="closingBalance" />
            </form>
          </div>
        </div>
      </div>
    </section>
  </main>
</div>