import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import { PosCheckSettlement } from '../../models/pos-check-settlement.model';
import { PosCheck } from '../../models/pos-check.model';
import { PosPaymentRestaurant } from '../../models/pos-payment-restaurant.model';
import { PosRestaurant } from '../../models/pos-restaurant.model';
import { CommonService } from '../../services/common.service';
import { OutletService } from '../../services/outlet.service';
import { PointerService } from '../../services/pointer.service';
import { PosCheckService } from '../../services/pos-check.service';
import { AlertService } from '../../services/alert.service';
import { environment } from 'projects/pos/src/environments/environment';
import { DebitCardsService } from '../../services/debit-cards.service';
declare function initializeKeyboard(event): any;

@Component({
  selector: 'app-settlement',
  templateUrl: './settlement.component.html'
})
export class SettlementComponent implements OnInit {
  outletId: string = "";
  posCheck: PosCheck;
  posCheckId: string;
  paymentModeList: PosPaymentRestaurant[] = [];
  showPaymentModes: boolean = true;
  selectedPayment: PosPaymentRestaurant;
  amount: number = 0;
  documentNumber: string = "";
  cashSettlementCode = environment.POINTER_CASH_SETTELEMENT.toLocaleLowerCase();
  balance: number;
  decimalPlace: number = this.pointerService.getDecimalPlaceValue();
  checkBoxesMode: string;
  debitCardPointer: any;
  debitCardDetails: any = [];

  @ViewChild(DxDataGridComponent, { static: false }) grid: DxDataGridComponent;
  selectedRowIndex = -1;
  constructor(private outletService: OutletService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private posCheckService: PosCheckService,
    private pointerService: PointerService,
    private commonService: CommonService,
    private alertService: AlertService,
    private debitCardService: DebitCardsService) { }

  ngOnInit(): void {
    this.checkBoxesMode = 'always';
    this.outletId = this.activatedRoute.snapshot.params["outlet_id"];
    this.posCheckId = this.activatedRoute.snapshot.params["check_id"];
    this.debitCardPointer = JSON.parse(localStorage.getItem("pointerData"))?.filter(res => res.code == environment.POINTER_DEBIT_CARD_PAYMENT_MODE)[0]?.pointer_value;
    this.getCheckDetails();

    const outletData: PosRestaurant[] = JSON.parse(localStorage.getItem("posRestaurant"));

    if (!outletData) {
      this.outletService.getOutletDetailsBy(this.outletId, PointerService.getISOBusinessDate())
        .subscribe({
          next: (responseData: any) => {
            const currentOutletData = responseData.filter(a => a.id == +this.outletId);

            if (currentOutletData.length > 0) {
              //this.paymentModeList = currentOutletData[0].pos_payment_restaurants.filter(b => b.active_ind);
              this.paymentModeList = currentOutletData[0].pos_payment_restaurants;
            }
          },
          error: (error: any) => {
            if (error.errors && error.errors.length > 0) {
              this.alertService.error(error.errors[0]);
            } else {
              this.alertService.error(error);
            }
          }
        });
    } else {
      const currentOutletData = outletData.filter(a => a.id == +this.outletId);

      if (currentOutletData.length > 0) {
        // this.paymentModeList = currentOutletData[0].pos_payment_restaurants.filter(b => b.active_ind);
        this.paymentModeList = currentOutletData[0].pos_payment_restaurants;
      }
    }
  }

  getCheckDetails(): void {
    this.debitCardPointer = JSON.parse(localStorage.getItem("pointerData"))?.filter(res => res.code == environment.POINTER_DEBIT_CARD_PAYMENT_MODE)[0]?.pointer_value;
    this.posCheckService.findBy(this.posCheckId)
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.posCheck = data;
            this.callDebitCardDetails();
            this.balance = +((+this.posCheck.dr_total + +this.posCheck.tips) - (+this.posCheck.cr_total)).toFixed(this.decimalPlace);
            this.amount = this.balance;
          }
        },
        error: (error: any) => {
          if (error.errors && error.errors.length > 0) {
            this.alertService.error(error.errors[0]);
          } else {
            this.alertService.error(error);
          }
        }
      });
  }


  callDebitCardDetails() {
    if (this.posCheck?.debit_card_number) {
      this.documentNumber = this.posCheck?.debit_card_number.slice(-4);
      this.selectedPayment = this.paymentModeList.filter(res => res.payment_id == this.debitCardPointer)[0];
      this.showPaymentModes = false;
      this.debitCardService?.fetchDebitCardData(this.posCheck?.debit_card_number)
        .subscribe({
          next: (result: any) => {
            this.debitCardDetails = result?.debit_cards[0];
            if (+this.posCheck?.dr_total > +result?.debit_cards[0]?.balance_amount) {
              this.amount = +result?.debit_cards[0]?.balance_amount
            }
          },
          error(err) {
            this.alertService.error(err);
          },
        })
    }
  }

  onSettlementSelection(settlement): void {
    console.log(settlement);
    this.selectedPayment = settlement;
    if (this.selectedPayment.payment_id == this.debitCardPointer) {
      this.callDebitCardDetails();
    }
    this.showPaymentModes = false;

  }

  onCreditClick(): void {

    if (this.selectedPayment && this.amount > 0) {
      this.addPaymentToCheck();
    }
  }

  onNewClick(): void {
    this.showPaymentModes = true;
    this.amount = this.balance;
    this.documentNumber = "";
  }

  onSettleClick(): void {
    if (this.balance <= 0) {
      let currentUserShift = this.commonService.getCurrentUserShiftNumber(this.outletId);
      this.posCheck.close_shift = currentUserShift;
      this.posCheckService.settleBill(this.posCheck)
        .subscribe({
          next: (data: any) => {
            //delay layout refresh - for table status change
            setTimeout(() => {
              this.router.navigate(['/table-layout/' + this.outletId]);
            }, 500);
          },
          error: (error: any) => {
            if (error.errors && error.errors.length > 0) {
              this.alertService.error(error.errors[0]);
            } else {
              this.alertService.error(error);
            }
          }
        });
    }
  }

  onBackClick(): void {
    this.router.navigate(['/billing/' + this.outletId + '/view-bill/' + this.posCheckId]);
  }

  selectedChanged(e): void {
    this.selectedRowIndex = e.component.getRowIndexByKey(e.selectedRowKeys[0]);
  }

  deleteRow(): void {
    console.log(this.selectedRowIndex);
    this.grid.instance.deleteRow(this.selectedRowIndex);
  }

  addPaymentToCheck() {
    if (this.selectedPayment?.payment_id == this.debitCardPointer && this.amount > this.debitCardDetails?.balance_amount) {
      this.alertService.error("Insufficient balance. Please recharge the card! ");
      return;
    }
    let settlement = new PosCheckSettlement();
    settlement.is_new = true;
    settlement.id = this.posCheck.pos_check_settlements.length + 1;
    settlement.allowance_ind = false;
    settlement.amount = this.amount;
    settlement.card_no = this.documentNumber;
    settlement.charge_id = this.selectedPayment.payment_id;
    settlement.currency_id = PointerService.getLocalCurrencyId();
    settlement.description = this.selectedPayment.payment.description;
    settlement.dr_cr = this.selectedPayment.payment.dr_cr;
    settlement.settlement_type = this.selectedPayment.payment.settlement_process_code;
    if (this.posCheck?.debit_card_number && this.selectedPayment.payment_id == this.debitCardPointer) {
      settlement.card_no = this.posCheck?.debit_card_number;
    }
    this.posCheck.pos_check_settlements.push(settlement);

    this.balance = +((+this.posCheck.dr_total + +this.posCheck.tips) - (+this.posCheck.cr_total)).toFixed(this.decimalPlace);
    //reset values
    this.amount = this.balance;
    this.documentNumber = "";
    this.selectedPayment = null;
  }

  openKeyboard(event): void {
    initializeKeyboard(event.target);
  }

}
