import { PosDiscount } from './pos-discount.model';

export class ANG {
  constructor(
    public id: number,
    public code: string,
    public pos_discount_id: number,
    public description: string,
    public pos_discount: PosDiscount
  ) {}
}
