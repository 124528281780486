import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  key: string = "z!!!!!!!1sdfadsf56adf456asdfasdf";
  appProperties = {
    VALUES: {
      KEY: "MTIzNDU2Nzg5MEFCQ0RFRkdISUpLTE1O",
      IV: "MTIzNDU2Nzg="
    }
  }

  constructor() { }

  encryptionAES(encryptStr: any) {
    // Encrypt
    const ciphertext = CryptoJS.AES.encrypt(encryptStr, environment.ENCRYPTION_SECRET_KEY);
    return ciphertext.toString();
  }

  decryptionAES(decryptStr: any) {
    // Decrypt
    const bytes = CryptoJS.AES.decrypt(decryptStr, environment.ENCRYPTION_SECRET_KEY);
    const plaintext = bytes.toString(CryptoJS.enc.Utf8);
    return plaintext;
  }
}
