export class Payment {
  constructor(
    public id: number,
    public code: string,
    public payment_ind: boolean,
    public account_code: string,
    public tax_ind: boolean,
    public dr_cr: string,
    public settlement_process_code: string,
    public gl_code: string,
    public print_charge_group_code: string,
    public department_code: string,
    public allowance_allowed_ind: boolean,
    public hsn_sac_code: string,
    public inclusive_of_tax_ind: boolean,
    public description: string
  ) {}
}