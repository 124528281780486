import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DigitalKeyboardComponent } from './digital-keyboard/digital-keyboard.component';
import { FormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';
import { BusinessDateComponent } from './business-date/business-date.component';
import { HeaderComponent } from './header/header.component';
import { ErrorComponent } from './error/error.component';
import { LogoutComponent } from './logout/logout.component';
import { LockScreenComponent } from './lock-screen/lock-screen.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { QwertyKeyboardComponent } from './qwerty-keyboard/qwerty-keyboard.component';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptorService } from 'projects/pos/src/app/services/loader-interceptor.service';

import { TranslateModule } from '@ngx-translate/core';
import { AlertModule } from './alert/alert.module';
import { DateSuffix } from '../billing/custom-filter/date-suffix.pipe';

@NgModule({
  declarations: [
    DigitalKeyboardComponent,
    LoaderComponent,
    BusinessDateComponent,
    HeaderComponent,
    ErrorComponent,
    LogoutComponent,
    LockScreenComponent,
    ChangePasswordComponent,
    QwertyKeyboardComponent,
    DateSuffix
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    AlertModule,
    MatProgressSpinnerModule
  ],
  exports: [
    CommonModule,
    TranslateModule,
    DigitalKeyboardComponent,
    LoaderComponent,
    BusinessDateComponent,
    HeaderComponent,
    ErrorComponent,
    QwertyKeyboardComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true,
    },
  ]
})
export class SharedModule {}
