import { Pipe, PipeTransform } from '@angular/core';
import { PosItemMenu } from '../../models/pos-item-menu.model';
import { PosMenuHeader } from '../../models/pos-menu-header.model';

@Pipe({
  name: 'FilterMenuSubHeader',
  pure: true
})

export class FilterMenuSubHeader implements PipeTransform{
  transform(items: PosItemMenu[], menuHeader: PosMenuHeader) {
    if(items) { 
      let menuHeaderItems = items.filter(a => a.pos_menu_header_id == menuHeader.id);
      let menuSubHeads = menuHeaderItems.map(a => a.menu_sub_header);

      let uniqueSubheaders = Array.from(new Set(menuSubHeads.map((object)=> JSON.stringify(object)))).map((string) => JSON.parse(string))
      //console.log(uniqueSubheaders);
      return uniqueSubheaders;
    }
  }
}