export class PosCheckDelivery {
	private _id: number;
	private _account_id: number;
	private _account_address_id: number;
	private _account_address_type: string;
	private _first_name: string;
	private _last_name: string;
	private _address1: string;
	private _address2: string;
	private _address3: string;
	private _city: string;
	private _state: string;
	private _country: string;
	private _pincode: string;
	private _delivery_contact_number: string;
	private _delivery_email: string;
	private _delivery_at: Date;
	private _schedule_at: Date;
	private _schedule_ind: boolean;
	private _notes: string;

	public get id(): number {
		return this._id;
	}
	public set id(value: number) {
		this._id = value;
	}

	public get account_id(): number {
		return this._account_id;
	}
	public set account_id(value: number) {
		this._account_id = value;
	}

	public get account_address_id(): number {
		return this._account_address_id;
	}
	public set account_address_id(value: number) {
		this._account_address_id = value;
	}

	public get account_address_type(): string {
		return this._account_address_type;
	}
	public set account_address_type(value: string) {
		this._account_address_type = value;
	}

	public get first_name(): string {
		return this._first_name;
	}
	public set first_name(value: string) {
		this._first_name = value;
	}

	public get last_name(): string {
		return this._last_name;
	}
	public set last_name(value: string) {
		this._last_name = value;
	}

	public get address1(): string {
		return this._address1;
	}
	public set address1(value: string) {
		this._address1 = value;
	}

	public get address2(): string {
		return this._address2;
	}
	public set address2(value: string) {
		this._address2 = value;
	}

	public get address3(): string {
		return this._address3;
	}
	public set address3(value: string) {
		this._address3 = value;
	}

	public get city(): string {
		return this._city;
	}
	public set city(value: string) {
		this._city = value;
	}

	public get state(): string {
		return this._state;
	}
	public set state(value: string) {
		this._state = value;
	}

	public get country(): string {
		return this._country;
	}
	public set country(value: string) {
		this._country = value;
	}

	public get pincode(): string {
		return this._pincode;
	}
	public set pincode(value: string) {
		this._pincode = value;
	}

	public get delivery_contact_number(): string {
		return this._delivery_contact_number;
	}
	public set delivery_contact_number(value: string) {
		this._delivery_contact_number = value;
	}

	public get delivery_email(): string {
		return this._delivery_email;
	}
	public set delivery_email(value: string) {
		this._delivery_email = value;
	}

	public get delivery_at(): Date {
		return this._delivery_at;
	}
	public set delivery_at(value: Date) {
		this._delivery_at = value;
	}
	
	public get schedule_at(): Date {
		return this._schedule_at;
	}
	public set schedule_at(value: Date) {
		this._schedule_at = value;
	}
	
	public get schedule_ind(): boolean {
		return this._schedule_ind;
	}
	public set schedule_ind(value: boolean) {
		this._schedule_ind = value;
	}
	
	public get notes(): string {
		return this._notes;
	}
	public set notes(value: string) {
		this._notes = value;
	}
}