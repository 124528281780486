import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Shift } from '../models/shift.model.model';
import { PointerService } from './pointer.service';

@Injectable({
  providedIn: 'root'
})
export class ShiftService {

  constructor(private http: HttpClient) { }

  public getAll(outletId: string, userId: string, businessDate: string) {
    return this.http.get(environment.APIENDPOINT + environment.SHIFT, {
      params: new HttpParams().append("key_restaurant", outletId)
                              .append("key_user", userId)
                              .append("key_business_date", businessDate)
                              .append("key_shift_closed_ind", "false")
    }).pipe(
      map(response => {
        response["pos_shifts"].forEach(obj => {
          this.mapAndSaveUserShift(obj);
        })
        return response["pos_shifts"];
      })
    )
  }

  public getShiftDetailsBy(outletId: string, userId: string, businessDate: string, shiftNo: string) {
    return this.http.get(environment.APIENDPOINT + environment.SHIFT, {
      params: new HttpParams().append("key_restaurant", outletId)
                              .append("key_user", userId)
                              .append("key_business_date", businessDate)
                              .append("key_shift_no", shiftNo)
    }).pipe(
      map(response => {
        return response["pos_shifts"];
      })
    )
  }

  public isValidShift(outletId: string, userId: string, businessDate: string, shiftNo: string) {
    return this.http.get(environment.APIENDPOINT + environment.SHIFT, {
      params: new HttpParams().append("key_restaurant", outletId)
                              .append("key_user", userId)
                              .append("key_business_date", businessDate)
                              .append("key_shift_no", shiftNo)
                              .append("key_shift_closed_ind", "true")
    }).pipe(
      map(response => {
        return response["pos_shifts"];
      })
    )
  }

  public create(objShift: Shift) {
    const json = Shift.createJson(objShift);
    return this.http.post(environment.APIENDPOINT + environment.SHIFT, json)
    .pipe(
      map(data => {
        this.mapAndSaveUserShift(data["pos_shift"]);
        return data;
      })
    )
  }

  public update(objShift: Shift) {
    const json = Shift.createJson(objShift);
    return this.http.put(environment.APIENDPOINT + environment.SHIFT +'/'+ objShift.id, json)
    .pipe(
      map(response => {
        return response;
      })
    )
  }

  public mapAndSaveUserShift(obj): void {
    let userShifts: Shift[] = [];

    if (localStorage.getItem("userShiftData") != null) {
      userShifts = JSON.parse(localStorage.getItem("userShiftData"));
    };

    if (userShifts.length > 0) {
      const alreadyPresent = userShifts.filter(a => a.pos_restaurant_id == obj.pos_restaurant_id 
                                              && a.shift_user_id == obj.shft_user_id
                                              && a.business_date == obj.business_date)

      if (alreadyPresent.length <= 0) {
        const newSetting: Shift = this.setUserShift(obj);
        userShifts.push(newSetting);
      }
    }
    else {
      const newSetting: Shift = this.setUserShift(obj);
      userShifts.push(newSetting);
    }
    
    localStorage.setItem("userShiftData", JSON.stringify(userShifts));
  }

  private setUserShift(obj): Shift {
    const userShift = new Shift(
      obj.id, obj.pos_restaurant_id, obj.shift_no, obj.business_date,
      obj.currency_id, obj.opening_balance, obj.paid_in_amount,
      obj.paid_out_amount, obj.closing_balance, obj.shift_closed_ind, obj.shft_user_id,
      obj.freeze_ind, obj.freeze_at, obj.updated_place, obj.saas_company_id, obj.company_id, obj.branch_id
    );

    return userShift;
  }

  public getUserShiftNo(outletId: string, userId: string) {
    const shiftData: Shift[] = JSON.parse(localStorage.getItem("userShiftData"));

    if(shiftData) {
      let currentUserShift = shiftData.filter(t => {
        return t.pos_restaurant_id === +outletId && t.shift_user_id === +userId
      });
  
      if(currentUserShift && currentUserShift.length > 0) {
        return currentUserShift[0].shift_no;
      } else {
        return 1;
      }
    }
    else {
      return 1
    }
  }
}
