import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PointerService } from '../../services/pointer.service';
import { RecallCheckService } from '../../services/recall-check.service';
import { AlertService } from '../../services/alert.service';
import { OutletSetting } from '../../models/outlet-setting.model';
import { TableLayoutService } from '../../services/table-layout.service';
import { environment } from 'projects/pos/src/environments/environment';
declare function initializeKeyboard(event): any;

@Component({
  selector: 'app-recall-check',
  templateUrl: './recall-check.component.html'
})
export class RecallCheckComponent implements OnInit {
  businessDate: Date;
  checkList: any;
  outletId: string;
  checkNumber: string;
  tableCode: string;
  includeOpenCheck: boolean = false;
  dateSelection: boolean = false;
  tableSelection: boolean = false;
  
  constructor(private recallCheckService: RecallCheckService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private alertService: AlertService,
              private tableLayoutService: TableLayoutService) { }

  ngOnInit(): void {
    this.outletId = this.activatedRoute.snapshot.params["outlet_id"];
    this.tableCode = this.activatedRoute.snapshot.params["table_code"];
    this.businessDate = PointerService.getBusinessDate();
    this.getCheckBySelectionCriteria();
  }

  onTableSelection() {
    this.tableSelection = !this.tableSelection;
    this.getCheckBySelectionCriteria();
  }

  onDateSelection() {
    this.dateSelection = !this.dateSelection;
    this.getCheckBySelectionCriteria();
  }

  onOpenCheckSelection() {
    this.includeOpenCheck = !this.includeOpenCheck;
    this.getCheckBySelectionCriteria();
  }

  onRecallBillClick() {
    this.recallCheckService.getCheckListBy(this.outletId, false, false, false, "", this.checkNumber)
    .subscribe({
      next: (data: any) => {
        this.checkList = data;
      },
      error: (error: any) => {
        if(error.errors && error.errors.length > 0) {
          this.alertService.error(error.errors[0]);
        } else {
          this.alertService.error(error);
        }
      }
    });
  }

  getCheckBySelectionCriteria() {
    this.recallCheckService.getCheckListBy(this.outletId, this.dateSelection, this.includeOpenCheck, this.tableSelection, this.tableCode, "")
    .subscribe({
      next: (data: any) => {
        this.checkList = data;
      },
      error: (error: any) => {
        if(error.errors && error.errors.length > 0) {
          this.alertService.error(error.errors[0]);
        } else {
          this.alertService.error(error);
        }
      }
    });
  }

  onCheckSelection(cell) {
    this.router.navigate(['/billing/' + this.outletId + '/view-bill/' + cell.data.id]);
  }

  onBackClick(): void {
    let isQsrOutlet = this.checkQsrOutlet();

    if (isQsrOutlet) {
      let layoutData = JSON.parse(localStorage.getItem("tableLayout"));

      if(!layoutData) {
        this.tableLayoutService.getTableLayoutBy(this.outletId).subscribe((data) => {
          this.getAvailableTable(data);
        })
      } else {
        this.getAvailableTable(layoutData);
      }
    } else {
      this.router.navigate(['/table-layout/' + this.outletId]);
    }
  }

  openKeyboard(event): void {
    initializeKeyboard(event.target);
  }

  checkQsrOutlet() {
    let isQsrOutlet: boolean = false;
    if (this.outletId) {
      const outletSettingData: OutletSetting[] = JSON.parse(localStorage.getItem("outletSetting"));
      if (outletSettingData) {
        let currentOutletSetting = outletSettingData.filter(a => a.pos_restaurant_id === +this.outletId);

        if (currentOutletSetting.length > 0) {
          isQsrOutlet = currentOutletSetting[0].qsr_in_use_ind;
        }
        return isQsrOutlet
      } else {
        this.router.navigate(['/outlet']);
      }
    }
  }

  getAvailableTable(layoutData) {
    //Get any Free table from layout for QSR billing
    let selectedTable = layoutData.filter(t => {
      return t.pos_restaurant_id == +this.outletId && t.table_status.toUpperCase() === environment.POS_TABLE_STATUS_AVAILABLE.toUpperCase()
    });

    if (selectedTable && selectedTable.length > 0) {
      this.router.navigate(['/billing/' + this.outletId + '/qsr/' + selectedTable[0].table_code]);
    } else {
      this.alertService.error(environment.NO_FREE_TABLE_AVAILABLE);
    }
  }
}
