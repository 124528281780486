import { Tax } from "./tax.model";

export class Charge {
  constructor(
    public id: number,
    public code: string,
    public payment_ind: boolean,
    public account_code: string,
    public tax_ind: boolean,
    public dr_cr: string,
    public settlement_process_code: string,
    public print_chage_group_code: string,
    public department_code: string,
    public allowance_allowed_ind: boolean,
    public hsn_sac_code: string,
    public inclusive_of_tax_ind: boolean,
    public description: string,
    public tax_charge_tax_links: Tax[]
  ){}
}