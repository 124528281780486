import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

declare function initializeKeyboard(event): any;
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {
  errorList: any[] = [];
  username: string;
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    const userData = JSON.parse(localStorage.getItem("userData"));

    if (!userData) {
      return;
    }

    this.username = userData.email;
  }

  onSubmit(form): void {
    this.errorList = [];
    if (!form.valid) {
      return;
    }

    const password = form.value.password;
    const newPassword = form.value.newpassword;
    const confirmPassword = form.value.confirmpassword;

    this.authService.changePassword(this.username, password, newPassword, confirmPassword).subscribe(
      resData => {
        this.errorList.push("Password has been changed. Please relogin.");
      },
      errorMessage => {
        this.errorList.push(errorMessage);
      }
    )
  }

  openKeyboard(event): void {
    initializeKeyboard(event.target);
  }

}
